import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { InternalHttpInterceptor } from '@app/shared/http';

@Injectable()
export class AddLocaleHeaderInterceptor extends InternalHttpInterceptor {
  constructor(private translateService: TranslateService) {
    super();
  }

  interceptInternally(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(
      req.clone({
        headers: req.headers.set('X-Robaws-Language', this.translateService.currentLang).set('Accept-Language', this.translateService.currentLang),
      }),
    );
  }
}
