import { Injectable } from '@angular/core';
import { NameTokens } from '@shared/domain';
import { InternalServiceMessageEvent, InternalServiceMessageService } from './internal-service-message.service';
import { robawsWindow } from '@app/shared/helpers/window.helper';

@Injectable({
  providedIn: 'root',
})
export class UrlService {
  constructor(private internalServiceMessageService: InternalServiceMessageService) {}

  public getRobawsNgUrl(append: string) {
    return robawsWindow._robaws.robawsNgBaseUrl + append;
  }

  public navigateTo(nameToken: NameTokens, parameters: any = {}): void {
    this.internalServiceMessageService.send(
      new InternalServiceMessageEvent('robaws-ng', 'robaws', 'navigate', {
        nameToken,
        parameters,
      }),
    );
  }

  public navigateToUnauthorizedLogin(): void {
    this.internalServiceMessageService.send(new InternalServiceMessageEvent('robaws-ng', 'robaws', 'navigate-to-login-unauthorized', {}));
  }

  public navigateToResourceType(resourceType: string, resourceId: string): void {
    this.internalServiceMessageService.send(
      new InternalServiceMessageEvent('robaws-ng', 'robaws', 'navigate-to-resource-type', {
        resourceType,
        resourceId,
      }),
    );
  }

  public navigateToEditWorkflow(id: string): void {
    this.navigateTo(NameTokens.EDIT_WORKFLOW, { id: id });
  }

  public navigateToWorkflowRevision(revisionId: string): void {
    this.navigateTo(NameTokens.WORKFLOW_REVISION, { revisionId });
  }

  navigateToEditFormTemplate(id: string): void {
    this.navigateTo(NameTokens.EDIT_FORM_TEMPLATE, { id: id });
  }

  navigateToManageFormTemplates(): void {
    this.navigateTo(NameTokens.MANAGE_FORM_TEMPLATE);
  }
}
