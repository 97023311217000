import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import {
  ResourceTypeAction,
  ResourceTypeActionParameter,
  ResourceTypeActionParameterPossibleValue,
  ResourceTypeAttribute,
  ResourceTypeEmailTemplate,
  ResourceTypeExtraField,
  ResourceTypeLink,
  ResourceTypeMetadata,
  ResourceTypePropertyPossibleValue,
} from '@shared/domain';
import { ResourceTypeMapper, ResourceTypeProvider } from '@app/shared/services';

import {
  AutomationResourceType,
  AutomationResourceTypeAction,
  AutomationResourceTypeActionParameter,
  AutomationResourceTypeActionParameterPossibleValue,
  AutomationResourceTypeAttribute,
  AutomationResourceTypeEmailTemplate,
  AutomationResourceTypeExtraField,
  AutomationResourceTypeLink,
  AutomationResourceTypeMetadata,
  AutomationResourceTypePropertyPossibleValue,
} from '@app/automation/domain';
import { withCache } from '@ngneat/cashew';

@Injectable({
  providedIn: 'root',
})
export class AutomationResourceTypeService implements ResourceTypeProvider<AutomationResourceTypeMetadata> {
  constructor(private httpClient: HttpClient) {}

  public getResourceTypes(): Observable<AutomationResourceType[]> {
    return this.httpClient.get<AutomationResourceType[]>('/automation/resource-types', {
      context: withCache(),
    });
  }

  public getMetadata(name: string): Observable<AutomationResourceTypeMetadata> {
    return this.httpClient.get<AutomationResourceTypeMetadata>('/automation/resource-types/' + name, {
      context: withCache(),
    });
  }
}

@Injectable({
  providedIn: 'root',
})
export class AutomationResourceTypeMapper implements ResourceTypeMapper<AutomationResourceTypeMetadata> {
  public mapMetadata(metadata: AutomationResourceTypeMetadata): ResourceTypeMetadata {
    return {
      name: metadata.name,
      displayName: metadata.displayName,
      attributes: metadata.attributes.map((item) => {
        return this.mapAttribute(item);
      }),
      extraFields: metadata.extraFields.map((item) => {
        return this.mapExtraField(item);
      }),
      links: metadata.links.map((item) => {
        return this.mapLink(item);
      }),
      events: [],
      actions: metadata.actions.map((item) => {
        return this.mapAction(item);
      }),
      emailTemplates:
        metadata.emailTemplates?.map((item) => {
          return this.mapEmailTemplate(item);
        }) || [],
    };
  }

  private mapAttribute(attribute: AutomationResourceTypeAttribute): ResourceTypeAttribute {
    return {
      name: attribute.name,
      displayName: attribute.displayName,
      dataType: attribute.dataType,
      readOnly: attribute.readOnly,
      sortable: attribute.sortable,
      primary: false,
      possibleValues: attribute.possibleValues
        ? attribute.possibleValues.map((item) => {
            return this.mapPossibleValue(item);
          })
        : [],
      attributes: [],
    };
  }

  private mapExtraField(extraField: AutomationResourceTypeExtraField): ResourceTypeExtraField {
    return {
      name: extraField.name,
      displayName: extraField.displayName,
      dataType: extraField.dataType,
      sortable: extraField.sortable,
      possibleValues: extraField.possibleValues
        ? extraField.possibleValues.map((item) => {
            return this.mapPossibleValue(item);
          })
        : [],
      group: extraField.group,
    };
  }

  private mapPossibleValue(possibleValue: AutomationResourceTypePropertyPossibleValue): ResourceTypePropertyPossibleValue {
    return {
      name: possibleValue.displayName,
      value: possibleValue.value,
    };
  }

  private mapLink(link: AutomationResourceTypeLink): ResourceTypeLink {
    return {
      name: link.name,
      displayName: link.displayName,
      resource: link.resource,
      type: link.type,
      foreignKey: link.foreignKey,
    };
  }

  private mapAction(action: AutomationResourceTypeAction): ResourceTypeAction {
    return {
      name: action.id,
      displayName: action.name,
      method: action.method,
      resourceRelativePath: action.resourceRelativePath,
      createdResourceTypeId: action.createdResourceTypeId,
      parameters:
        action.parameters?.map((item) => {
          return this.mapParameter(item);
        }) || [],
    };
  }

  private mapParameter(parameter: AutomationResourceTypeActionParameter): ResourceTypeActionParameter {
    return {
      name: parameter.id,
      displayName: parameter.name,
      dataType: parameter.dataType,
      possibleValues:
        parameter.possibleValues?.map((item) => {
          return this.mapParameterPossibleValue(item);
        }) || [],
    };
  }

  private mapParameterPossibleValue(possibleValue: AutomationResourceTypeActionParameterPossibleValue): ResourceTypeActionParameterPossibleValue {
    return {
      name: possibleValue.displayName,
      value: possibleValue.value,
    };
  }
  private mapEmailTemplate(emailTemplate: AutomationResourceTypeEmailTemplate): ResourceTypeEmailTemplate {
    return {
      name: emailTemplate.id,
      displayName: emailTemplate.name,
    };
  }
}

export const AUTOMATION_RESOURCE_TYPE_MAPPER = new AutomationResourceTypeMapper();
