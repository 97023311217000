import { Component, Input } from '@angular/core';

import { FormatDataNode } from '@app/automation/domain';
import { RobawsNgConfigurerCardService } from '@shared/services';

import { FormatDataNodeConfigurerComponent } from './format-data-node-configurer/format-data-node-configurer.component';
import { AbstractWorkflowNodeComponent } from '../abstract-workflow-node-component';
import { WorkflowNodeData } from '../workflow-node/workflow-node-data';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'format-data-node',
  templateUrl: 'format-data-node.component.html',
})
export class FormatDataNodeComponent extends AbstractWorkflowNodeComponent {
  @Input({ required: true })
  public workflowNodeData: WorkflowNodeData<FormatDataNode>;

  constructor(
    private robawsNgConfigurerCardService: RobawsNgConfigurerCardService,
    private translateService: TranslateService,
  ) {
    super();
  }

  public openConfigurer(): void {
    this.robawsNgConfigurerCardService.createAndOpenConfigurerCard(FormatDataNodeConfigurerComponent, {
      title: this.translateService.instant('formatDataNode.title'),
      inputs: {
        currentNode: this.workflowNodeData.currentNode,
        resourceTypeMetadata: this.workflowNodeData.resourceTypeMetadata,
      },
    });
  }
}
