import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { DataType } from '@shared/domain';
import { withCache } from '@ngneat/cashew';
import { RobawsOperatorDTO } from '@app/robaws/domain/RobawsOperatorDTO';

@Injectable({
  providedIn: 'root',
})
export class RobawsOperatorService {
  constructor(private httpClient: HttpClient) {}

  public getSupportedOperations(dataType: DataType): Observable<RobawsOperatorDTO[]> {
    return this.httpClient.get<RobawsOperatorDTO[]>('/robaws-operators/' + dataType.toString(), {
      context: withCache(),
    });
  }
}
