import { WorkflowNode } from './WorkflowNode';
import { NodeDTO } from '@app/automation/domain';

export class SendTextMessageNode extends WorkflowNode {
  constructor(
    public destinations: string[],
    public message: string,
    public override nextNode?: NodeDTO<any>,
  ) {
    super(nextNode);
  }
}
