import { WorkflowNode } from './WorkflowNode';
import { NodeDTO } from '@app/automation/domain';

export class CopyValueNode extends WorkflowNode {
  constructor(
    public sourcePath?: string,
    public targetPath?: string,
    public override nextNode?: NodeDTO<any>,
  ) {
    super(nextNode);
  }
}
