export type MetadataProviderType = 'AUTOMATION' | 'ROBAWS';

export type ResourceType = {
  readonly name: string;
  readonly displayName: string;
};

export enum DataType {
  TEXT = 'TEXT',
  DATE = 'DATE',
  DATE_TIME = 'DATE_TIME',
  DECIMAL = 'DECIMAL',
  NUMBER = 'NUMBER',
  BOOLEAN = 'BOOLEAN',
  COMPLEX = 'COMPLEX',
  RESOURCE_REF = 'RESOURCE_REF',
}

export type ResourceTypeMetadata = {
  name: string;
  displayName: string;
  basePath?: string;
  attributes: ResourceTypeAttribute[];
  extraFields: ResourceTypeExtraField[];
  links: ResourceTypeLink[];
  events: ResourceTypeEvent[];
  actions: ResourceTypeAction[];
  emailTemplates: ResourceTypeEmailTemplate[];
};

export type ResourceTypePropertyPossibleValue = {
  name: string;
  value: string;
};

export class ResourceTypeEntity {
  public readonly id: string;
  public readonly label: string;
}

export type ResourceTypeProperty = {
  name: string;
  displayName: string;
  dataType: DataType;
  sortable: boolean;
  possibleValues?: ResourceTypePropertyPossibleValue[];
};

export type ResourceTypeAttribute = ResourceTypeProperty & {
  primary: boolean;
  readOnly: boolean;
  attributes?: ResourceTypeAttribute[];
};

export type ResourceTypeExtraField = ResourceTypeProperty & {
  group: string;
};

export type ResourceTypeLink = {
  name: string;
  displayName: string;
  resource: string;
  type: string;
  foreignKey: string;
};

export type ResourceTypeEvent = {
  name: string;
  type: string;
};

export type ResourceTypeAction = {
  name: string;
  displayName: string;
  method?: string;
  resourceRelativePath?: string;
  createdResourceTypeId?: string;
  parameters?: ResourceTypeActionParameter[];
};

export type ResourceTypeActionParameterPossibleValue = ResourceTypePropertyPossibleValue;

export type ResourceTypeActionParameter = {
  name: string;
  displayName: string;
  dataType: DataType;
  possibleValues?: ResourceTypeActionParameterPossibleValue[];
};

export type ResourceTypeEmailTemplate = {
  name: string;
  displayName: string;
};
