import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

export type TimeDiffOptions = {
  showDiffPrefixAndSuffix: boolean;
};

const INTERVALS: Array<{ unit: string; seconds: number }> = [
  { unit: 'year', seconds: 31536000 },
  { unit: 'month', seconds: 2592000 },
  { unit: 'week', seconds: 604800 },
  { unit: 'day', seconds: 86400 },
  { unit: 'hour', seconds: 3600 },
  { unit: 'minute', seconds: 60 },
  { unit: 'second', seconds: 1 },
];

@Pipe({
  name: 'timeDiff',
  standalone: true,
})
export class TimeDiffPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  public transform(value: any, options: TimeDiffOptions = { showDiffPrefixAndSuffix: true }): any {
    if (value) {
      const expiryDate = new Date(value);
      const now = new Date();
      let seconds = (expiryDate.getTime() - now.getTime()) / 1000;
      const past = Math.sign(seconds) === -1;

      if (past) {
        seconds = Math.abs(seconds); // convert to positive
      }

      for (let i = 0; i < INTERVALS.length; i++) {
        const interval = INTERVALS[i];
        let amount = Math.floor(seconds / interval.seconds);

        if (amount > 0) {
          const timeDiff = this.formatTime(amount, interval.unit);
          const timeLeft = seconds - amount * interval.seconds;

          if (i + 1 >= INTERVALS.length) {
            return this.addPrefixAndSuffix(timeDiff, past, options);
          }
          const nextInterval = INTERVALS[i + 1];
          amount = Math.floor(timeLeft / nextInterval.seconds);
          return this.addPrefixAndSuffix(timeDiff + ' ' + this.formatTime(amount, nextInterval.unit), past, options);
        }
      }
    }
    return value;
  }

  private formatTime(counter: number, timeUnit: string): string {
    return counter + ' ' + this.translateService.instant(timeUnit + (counter === 1 ? '' : 's'));
  }

  private addPrefixAndSuffix(value: string, past: boolean, options: TimeDiffOptions): string {
    if (!options.showDiffPrefixAndSuffix) {
      return value;
    }
    const prefix = this.translateWithDefault('time-diff.' + (past ? 'ago' : 'left') + '.prefix', '');
    const suffix = this.translateWithDefault('time-diff.' + (past ? 'ago' : 'left') + '.suffix', '');

    return (prefix && prefix.trim().length > 0 ? prefix + ' ' : '') + value + (suffix && suffix.trim().length > 0 ? ' ' + suffix : '');
  }

  private translateWithDefault(key: string, defaultValue: string): string {
    const translation = this.translateService.instant(key);
    return translation === key ? defaultValue : translation;
  }
}
