import { APP_INITIALIZER, DoBootstrap, ErrorHandler, Injector, NgModule } from '@angular/core';
import { CommonModule, LOCATION_INITIALIZED } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpBackend, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { NgSelectModule } from '@ng-select/ng-select';
import {
  InternalServiceMessageService,
  PageService,
  PathService,
  RobawsNgConfigurerCardService,
  RootContainerService,
  ToastService,
  UrlService,
  UserService,
} from '@shared/services';
import { getCookie } from '@shared/helpers';
import { AddLocaleHeaderInterceptor, AddNoCacheHeadersInterceptor, AddSessionQueryParamInterceptor } from '@shared/http';
import { AutomationModule } from '@app/automation';
import { FormTemplateModule } from '@app/form-template/components/form-template.module';
import { TranslationHttpLoader } from '@app/translation-http-loader';
import { DatadogErrorHandler } from '@app/datadog-error-handler';
import { TranslationHelper } from '@app/shared/helpers/translation.helper';
import { HttpCacheInterceptorModule } from '@ngneat/cashew';
import { RobawsModule } from '@app/robaws/components/robaws.module';
import { WhoAmIService } from '@app/shared/services/whoami.service';
import { LoginUnauthorizedInterceptor } from '@app/shared/http/interceptors/login-unauthorized-interceptor';

export let AppInjector: Injector;

export function HttpLoaderFactory(http: HttpBackend, urlService: UrlService) {
  return new TranslationHttpLoader(http, urlService);
}

export function appInitializerFactory(translate: TranslateService, injector: Injector) {
  return () =>
    new Promise<any>((resolve: any) => {
      const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));

      locationInitialized.then(async () => {
        // Setting the default language based on the language cookie
        const language = getCookie('ROBAWS_LOCALE') ?? 'nl';
        await useLanguage(language);
        translate.setDefaultLang(language);
        resolve(null);
      });
    });

  async function useLanguage(language: string): Promise<void> {
    return new Promise((resolve, reject) => {
      translate.use(language).subscribe({
        complete() {
          resolve();
        },
        error() {
          reject();
        },
      });
    });
  }
}

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HttpCacheInterceptorModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpBackend, UrlService],
      },
      defaultLanguage: 'nl',
    }),
    AutomationModule,
    RobawsModule,
    NgSelectModule,
    FormTemplateModule,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: DatadogErrorHandler,
    },
    UrlService,
    PageService,
    PathService,
    RootContainerService,
    ToastService,
    InternalServiceMessageService,
    TranslateService,
    UserService,
    WhoAmIService,
    RobawsNgConfigurerCardService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AddSessionQueryParamInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoginUnauthorizedInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AddNoCacheHeadersInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AddLocaleHeaderInterceptor,
      deps: [TranslateService],
      multi: true,
    },
    TranslationHelper,
  ],
})
export class AppModule implements DoBootstrap {
  constructor(private injector: Injector) {
    AppInjector = this.injector;
  }

  ngDoBootstrap(): void {
    // do nothing
    console.debug('application inited');
  }
}
