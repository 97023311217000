import {
  AfterContentInit,
  Component,
  computed,
  ContentChildren,
  EventEmitter,
  input,
  model,
  Output,
  QueryList,
  signal,
  TemplateRef,
  ViewChild,
} from '@angular/core';

import { ResourceTypePropertyPossibleValue } from '@shared/domain';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { MultiSelect, MultiSelectModule } from 'primeng/multiselect';
import { MatIcon } from '@angular/material/icon';
import { NgIf, NgTemplateOutlet } from '@angular/common';
import { NgModelChangeDebouncedDirective } from '@ui/ng-model-change-debounced.directive';
import { RobawsNgSelectComponent, RobawsNgSelectFilterEvent } from '@ui/robaw-ng-select/robaws-ng-select.component';
import { RobawsNgTemplateDirective } from '@shared/components/robaws-ng-template.directive';
import { Nullable } from 'primeng/ts-helpers';

@Component({
  selector: 'possible-values-combo',
  templateUrl: 'possible-values-combo.component.html',
  standalone: true,
  imports: [
    NgSelectModule,
    FormsModule,
    MultiSelectModule,
    MatIcon,
    NgIf,
    NgModelChangeDebouncedDirective,
    RobawsNgSelectComponent,
    RobawsNgTemplateDirective,
    NgTemplateOutlet,
  ],
})
export class PossibleValuesComboComponent implements AfterContentInit {
  public possibleValues = input<ResourceTypePropertyPossibleValue[]>([]);
  public value = model<string | undefined | null>(undefined);
  public label = input<string | undefined | null>(undefined);
  public multiple = input<boolean>(false);
  public clearIcon = input<boolean>(true);

  @Output()
  public valueChange: EventEmitter<string | undefined | null> = new EventEmitter<string | undefined | null>();
  protected currentFilter = signal<string>('');
  protected filteredPossibleValues = computed<ResourceTypePropertyPossibleValue[]>(() => {
    return this.possibleValues().filter((item) => item.name && item.name.toLowerCase().includes(this.currentFilter().toLowerCase()));
  });
  protected multiSelectValue = computed<string[]>(() => {
    const value = this.value();

    return value ? value.split(',') : [];
  });
  protected inputTemplate = signal<TemplateRef<any> | undefined>(undefined);
  @ViewChild('multiSelect')
  private multiSelect: MultiSelect;
  @ContentChildren(RobawsNgTemplateDirective)
  private templates: Nullable<QueryList<RobawsNgTemplateDirective>>;

  public ngAfterContentInit(): void {
    if (this.templates) {
      this.templates.forEach((template) => {
        if (template.type === 'input') {
          this.inputTemplate.set(template.template);
        }
      });
    }
  }

  public onValueChange(value: string | undefined): void {
    this.value.set(value);
    this.valueChange.emit(this.value() ?? '');
  }

  public onMultiSelectValueChange(value: any[]): void {
    value = value || [];

    this.value.set(value.join(','));
    this.valueChange.emit(this.value() ?? '');
  }

  protected clear(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();

    this.value.set(undefined);
    this.valueChange.emit(this.value() ?? '');
    this.multiSelect.close(event);
  }

  protected onFilter($event: RobawsNgSelectFilterEvent): void {
    this.currentFilter.set($event.filter);
  }
}
