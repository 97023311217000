<ng-select [(ngModel)]="currentNode.configuration.template"
           [items]="availableTemplates"
           [placeholder]="'template' | translate"
           bindLabel="name"
           bindValue="id"/>

<ng-select (open)="senderSearchText$.next('')"
           [(ngModel)]="currentNode.configuration.sender"
           [items]="availableSenders$ | async"
           [loading]="sendersLoading"
           [minTermLength]="0"
           [placeholder]="'sender' | translate"
           [typeahead]="senderSearchText$"
           bindLabel="label" bindValue="value"
           groupBy="group"/>

<ng-select [(ngModel)]="currentNode.configuration.to"
           [addTag]="addLiteralValue"
           [items]="toPaths$ | async"
           [multiple]="true"
           [placeholder]="'to' | translate"
           bindLabel="displayName"
           bindValue="path"
           groupBy="groupDisplayName"/>

<ng-select [(ngModel)]="currentNode.configuration.cc"
           [addTag]="addLiteralValue"
           [items]="ccPaths$ | async"
           [multiple]="true"
           [placeholder]="'CC'"
           bindLabel="displayName"
           bindValue="path"
           groupBy="groupDisplayName"/>

<ng-select [(ngModel)]="currentNode.configuration.bcc"
           [addTag]="addLiteralValue"
           [items]="bccPaths$ | async"
           [multiple]="true"
           [placeholder]="'BCC'"
           bindLabel="displayName"
           bindValue="path"
           groupBy="groupDisplayName"/>


<div class="p-float-label" *ngIf="!templateBased">
  <input [(ngModel)]="currentNode.configuration.subject"
         pInputText
         type="text"/>
  <label>{{ 'subject' | translate }}</label>
</div>

<div class="p-float-label" *ngIf="!templateBased">
  <textarea [(ngModel)]="currentNode.configuration.body"
            pInputTextarea
            rows="5"
            type="text"></textarea>
  <label>{{ 'content' | translate }}</label>
</div>
