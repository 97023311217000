import { TreeNode } from '@ui/tree-select/tree-node.type';

export class Tree {
  public searchText: string | undefined;
  private availableNodes: TreeNode[] = [];
  private customValueNode: TreeNode | undefined;

  constructor(
    private items: TreeNode[],
    private value: string | undefined | string[],
  ) {
    this.updateAvailableNodes();
  }

  public setSearchText(searchText: string | undefined): void {
    this.searchText = searchText;

    if (searchText == undefined || searchText.length == 0) {
      this.customValueNode = undefined;
    } else {
      this.customValueNode = {
        value: searchText,
        label: searchText,
        selectedLabel: searchText,
        children: [],
        selectable: true,
      };
    }

    this.updateAvailableNodes();
  }

  public getAvailableItems(): TreeNode[] {
    return this.availableNodes;
  }

  public isSelected(node: TreeNode): boolean {
    if (this.value && this.hasValue()) {
      if (this.value.constructor === Array) {
        return this.value.includes(node.value);
      }
      return this.value === node.value;
    }
    return false;
  }

  public hasSelectionInside(node: TreeNode): boolean {
    for (const child of node.children ?? []) {
      if (this.isSelected(child) || this.hasSelectionInside(child)) {
        return true;
      }
    }
    return false;
  }

  public isSelectedOrHasSelectionInside(node: TreeNode): boolean {
    if (!this.hasValue()) {
      return false;
    }

    if (this.isSelected(node)) {
      return true;
    } else {
      if (this.hasSelectionInside(node)) {
        return true;
      }
    }
    return false;
  }

  public findNode(value: string, nodes: TreeNode[] = this.items): TreeNode | undefined {
    for (const node of nodes) {
      if (node.value === value) {
        return node;
      } else if (this.hasChildren(node)) {
        const childNode = this.findNode(value, node.children);

        if (childNode) {
          return childNode;
        }
      }
    }
    return undefined;
  }

  public getCurrentNode(): TreeNode | undefined {
    if (this.value && this.hasValue()) {
      if (this.value.constructor === Array) {
        return undefined;
      } else {
        return this.findNode((this.value as string)!);
      }
    }

    return undefined;
  }

  public hasCurrentNode(): boolean {
    return this.getCurrentNode() !== undefined;
  }

  public getNodesWithChildren(nodes: TreeNode[] = this.getAvailableItems()): TreeNode[] {
    const nodesWithChildren = [];

    for (const child of nodes) {
      if (this.hasChildren(child)) {
        nodesWithChildren.push(child);
      }
    }

    return nodesWithChildren;
  }

  public getNodesWithoutChildren(nodes: TreeNode[] = this.getAvailableItems()): TreeNode[] {
    const nodesWithoutChildren = [];

    for (const child of nodes) {
      if (!this.hasChildren(child)) {
        nodesWithoutChildren.push(child);
      }
    }

    return nodesWithoutChildren;
  }

  public getCustomValueNode(): TreeNode | undefined {
    return this.customValueNode;
  }

  public shouldShowCustomValueNode(): boolean {
    const currentNode = this.getCurrentNode();
    const customNode = this.getCustomValueNode();

    return customNode !== undefined && (currentNode === undefined || currentNode.value !== this.searchText);
  }

  private updateAvailableNodes() {
    this.availableNodes = this.cloneNodesWithFilter(this.items, (node) =>
      this.containsSearchTextOrHasChildContainingSearchText(this.searchText, node),
    );
  }

  private cloneNodesWithFilter(nodes: TreeNode[], filter: (item: TreeNode) => boolean): TreeNode[] {
    return nodes.filter(filter).map((node) => this.cloneNodeWithFilter(node, filter));
  }

  private cloneNodeWithFilter(node: TreeNode, filter: (item: TreeNode) => boolean): TreeNode {
    return {
      value: node.value,
      label: node.label,
      selectedLabel: node.selectedLabel,
      children: this.cloneNodesWithFilter(node.children, filter),
      selectable: node.selectable,
    };
  }

  private containsSearchTextOrHasChildContainingSearchText(searchText: string | undefined, node: TreeNode): boolean {
    if (searchText == undefined || searchText.length == 0) {
      return true;
    }
    if (node.selectedLabel.toLowerCase().includes(searchText.toLowerCase())) {
      return true;
    }

    if (this.hasChildren(node)) {
      for (const child of node.children) {
        if (this.containsSearchTextOrHasChildContainingSearchText(searchText, child)) {
          return true;
        }
      }
    }

    return false;
  }

  private hasValue(): boolean {
    return this.value != undefined && this.value.length > 0;
  }

  setValue(value: string | undefined | string[]) {
    this.value = value;
    // this.updateAvailableNodes();
  }

  private hasChildren(node: TreeNode): boolean {
    return node.children && node.children.length > 0;
  }
}
