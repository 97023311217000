import { Component, HostListener, Input } from '@angular/core';

import { MetadataWithPath, Condition, MetadataProviderType } from '@shared/domain';

import { AutomationConstants, AutomationResourceTypeMetadata, WorkflowUpdateDTO } from '@app/automation/domain';
import { AUTOMATION_RESOURCE_TYPE_MAPPER } from '@app/automation/services';
import { AutomationOperationData } from '@app/automation/components/automation-operation-builder/automation-operation-builder.component';

export type TriggerNodeConfigurerData = {
  resourceTypeMetadata: AutomationResourceTypeMetadata;
  workflow: WorkflowUpdateDTO;
};

@Component({
  selector: 'trigger-node-configurer',
  templateUrl: 'trigger-node-configurer.component.html',
  styleUrls: ['trigger-node-configurer.component.scss'],
})
export class TriggerNodeConfigurerComponent {
  protected currentCondition?: Condition;
  private editingCondition = false;
  protected conditionBuilderData: MetadataWithPath & AutomationOperationData;
  protected metadataProviderType: MetadataProviderType = 'AUTOMATION';

  private _data: TriggerNodeConfigurerData;

  @Input()
  public get data(): TriggerNodeConfigurerData {
    return this._data;
  }

  public set data(data: TriggerNodeConfigurerData) {
    this._data = data;

    if (data.workflow.triggerNode.configuration.conditions.length === 0) {
      this.createCondition();
    }
  }

  @HostListener('TriggerNodeConditionChangedEvent', ['$event'])
  protected onConditionChanged(event: Event): void {
    event.stopPropagation();
    this.reset();
  }

  protected createCondition(): void {
    this.editingCondition = false;
    this.currentCondition = new Condition();
    this.updatePropertyPathBuilderData();
  }

  protected editCondition(condition: Condition): void {
    this.editingCondition = true;
    this.currentCondition = condition;
    this.updatePropertyPathBuilderData();
  }

  protected handleCancel(): void {
    this.reset();
  }

  protected handleConditionBuilderResult(condition: Condition): void {
    if (this.editingCondition && this.currentCondition) {
      const index = this.data.workflow.triggerNode.configuration.conditions.indexOf(this.currentCondition);
      this.data.workflow.triggerNode.configuration.conditions[index] = condition;
    } else {
      this.data.workflow.triggerNode.configuration.conditions.push(condition);
    }
    document.dispatchEvent(new CustomEvent(AutomationConstants.TRIGGER_NODE_CONDITIONS_CHANGED_EVENT));

    this.currentCondition = undefined;
  }

  private updatePropertyPathBuilderData(): void {
    this.conditionBuilderData = {
      metadata: AUTOMATION_RESOURCE_TYPE_MAPPER.mapMetadata(this.data.resourceTypeMetadata),
      path: this.currentCondition?.path ?? '',
      operator: this.currentCondition?.operation ?? undefined,
      value: this.currentCondition?.value ?? undefined,
    };
  }

  private reset(): void {
    this.currentCondition = undefined;
    this.updatePropertyPathBuilderData();
  }
}
