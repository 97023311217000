import { WorkflowNode } from './WorkflowNode';
import { NodeDTO } from '@app/automation/domain';

export class FormatDataNode extends WorkflowNode {
  constructor(
    public expression?: string,
    public override nextNode?: NodeDTO<any>,
  ) {
    super(nextNode);
  }
}
