import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { RippleModule } from 'primeng/ripple';
import { FormsModule } from '@angular/forms';
import { InputNumberModule } from 'primeng/inputnumber';
import { NgModelChangeDebouncedDirective } from '@ui/ng-model-change-debounced.directive';
import { TranslateModule } from '@ngx-translate/core';

type VisiblePageVO = {
  page: number;
  label: string;
};

@Component({
  selector: 'dynamic-overview-paginator',
  templateUrl: 'dynamic-overview-paginator.component.html',
  styleUrls: ['dynamic-overview-paginator.component.scss'],
  standalone: true,
  imports: [MatIcon, RippleModule, FormsModule, InputNumberModule, NgModelChangeDebouncedDirective, TranslateModule],
})
export class DynamicOverviewPaginatorComponent implements OnInit, OnChanges {
  @Input({ required: true })
  public totalItems: number;
  @Input({ required: true })
  public pageSize: number;
  @Input({ required: true })
  public page: number;
  @Input()
  public amountOfVisiblePages = 5;

  @Output()
  public pageChange = new EventEmitter<number>();
  @Output()
  public pageSizeChange = new EventEmitter<number>();

  protected visiblePages: VisiblePageVO[] = [];
  protected totalPages: number = 0;
  protected firstItem: number = 0;
  protected lastItem: number = 0;

  public ngOnInit(): void {
    this.recalculate();
  }

  public ngOnChanges(): void {
    this.recalculate();
  }

  protected goToFirst(): void {
    this.changePage(0);
  }

  protected goToPrevious(): void {
    this.changePage(this.page - 1);
  }

  protected goTo(page: number): void {
    this.changePage(page);
  }

  protected goToNext(): void {
    this.changePage(this.page + 1);
  }

  protected goToLast(): void {
    this.changePage(this.totalPages - 1);
  }

  protected onPageSizeChange(pageSize: number): void {
    this.pageSize = pageSize;
    this.recalculate();
    this.pageSizeChange.emit(this.pageSize);
  }

  private changePage(page: number): void {
    if (page < 0) {
      page = 0;
    }
    if (page >= this.totalPages) {
      page = this.totalPages - 1;
    }
    this.page = page;
    this.recalculate();

    this.pageChange.emit(this.page);
  }

  private recalculate(): void {
    this.totalPages = Math.ceil(this.totalItems / this.pageSize);
    this.firstItem = this.pageSize * this.page + 1;
    this.lastItem = Math.min(this.pageSize * (this.page + 1), this.totalItems);
    this.updateVisiblePages();
  }

  private updateVisiblePages(): void {
    const [start, end] = this.calculatePageBoundaries();

    this.visiblePages = [];

    for (let i = start; i <= end; i++) {
      this.visiblePages.push({
        page: i,
        label: (i + 1).toString(),
      });
    }
  }

  private calculatePageBoundaries(): [number, number] {
    let start = Math.max(0, Math.ceil(this.page - this.amountOfVisiblePages / 2));
    const end = Math.min(this.totalPages - 1, start + (this.amountOfVisiblePages - 1));

    const delta = this.amountOfVisiblePages - (end - start + 1);
    start = Math.max(0, start - delta);

    return [start, end];
  }
}
