import { WorkflowNode } from './WorkflowNode';
import { NodeDTO } from './NodeDTO';

export class CreateRobawsResourceNode extends WorkflowNode {
  constructor(
    public resourceType: string,
    public parameters: Record<string, string>,
    public override nextNode: NodeDTO<any> | undefined,
  ) {
    super(nextNode);
  }
}
