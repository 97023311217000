import { Component, Input } from '@angular/core';

import { TableColumnData } from '../table-column';

@Component({
  templateUrl: 'text-table-column.component.html',
})
export class TextTableColumnComponent {
  @Input()
  public data?: TableColumnData;
}
