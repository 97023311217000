import { concatMap, Observable, of } from 'rxjs';
import { PageDTO } from '@shared/domain';

export function fetchAllPages<T>(fetchFn: (page: number) => Observable<PageDTO<T>>, page = 0, allItems: T[] = []): Observable<T[]> {
  return fetchFn(page).pipe(
    concatMap((pageData: PageDTO<T>) => {
      const newItems = allItems.concat(pageData.items);

      if (pageData.totalPages > pageData.currentPage + 1) {
        return fetchAllPages(fetchFn, page + 1, newItems);
      } else {
        return of(newItems);
      }
    }),
  );
}
