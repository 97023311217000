import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { DataTableModule } from '@ui/data-table';
import { WorkflowService } from '@app/automation/services';

import { ListWorkflowsComponent } from './list-workflows.component';

@NgModule({
  imports: [CommonModule, DataTableModule, MatSnackBarModule],
  declarations: [ListWorkflowsComponent],
  providers: [WorkflowService],
})
export class ListWorkflowsModule {}
