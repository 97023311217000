import { Component, ComponentRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewContainerRef, ViewEncapsulation } from '@angular/core';

import { AlertHelper } from '@shared/helpers';

import { NodeDTO } from '@app/automation/domain';

import { createNodeComponent, createNodeRepresentation } from '../nodes.factory';
import { AbstractWorkflowNodeComponent } from '../abstract-workflow-node-component';
import { CreateNewNodeRequest } from './add-node/add-node-configurer/add-node-configurer.component';
import { WorkflowNodeData } from './workflow-node-data';

@Component({
  selector: 'workflow-node',
  templateUrl: 'workflow-node.component.html',
  styleUrls: ['workflow-node.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [AlertHelper],
})
export class WorkflowNodeComponent implements OnInit, OnDestroy {
  @Input({ required: true })
  public workflowNodeData: WorkflowNodeData<any>;

  @Input()
  public allowPointerCursor = true;

  @Input()
  public deleteConfirmationTranslationKey = 'confirmation.content.node';

  @Output()
  public nodeClick: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public deleteClicked: EventEmitter<any> = new EventEmitter<any>();

  private nextNode: ComponentRef<any>;

  constructor(private viewContainerRef: ViewContainerRef) {}

  ngOnInit(): void {
    const nextNode = this.getNextNode();

    if (nextNode != null) {
      this.nextNode = createNodeComponent(
        this.workflowNodeData.workflowId,
        this.workflowNodeData.currentNode,
        nextNode.type,
        nextNode,
        this.workflowNodeData.resourceTypeMetadata,
        this.viewContainerRef,
        undefined,
      );
    }

    document.addEventListener('robaws-ng-configurer-card-close', this.handleCardClose.bind(this));
  }

  ngOnDestroy(): void {
    document.removeEventListener('robaws-ng-configurer-card-close', this.handleCardClose.bind(this));
  }

  protected createNewNode(createNewNodeRequest: CreateNewNodeRequest): void {
    const nextNode = this.workflowNodeData.currentNode.configuration.nextNode;
    const newNextNode = createNodeRepresentation(createNewNodeRequest.nodeType, nextNode, createNewNodeRequest.data);

    this.workflowNodeData.currentNode.configuration.nextNode = newNextNode;

    if (nextNode) {
      const index = this.viewContainerRef.indexOf(this.nextNode.hostView);

      if (index > -1) {
        this.viewContainerRef.detach(index);
      }
    }

    this.nextNode = createNodeComponent(
      this.workflowNodeData.workflowId,
      this.workflowNodeData.currentNode,
      createNewNodeRequest.nodeType,
      newNextNode,
      this.workflowNodeData.resourceTypeMetadata,
      this.viewContainerRef,
      this.nextNode,
    );

    // if the node has a configurer, open it automatically
    if (this.nextNode.instance instanceof AbstractWorkflowNodeComponent) {
      this.nextNode.instance.openConfigurer();
    }
  }

  protected getNextNode(): NodeDTO<any> {
    return this.workflowNodeData.currentNode.configuration.nextNode;
  }

  protected onNodeClick(): void {
    this.nodeClick.emit();
    this.markAsSelected();
  }

  protected onDelete(): void {
    if (this.workflowNodeData.previousNode) {
      this.workflowNodeData.previousNode.configuration.nextNode = this.getNextNode();
    }

    document.dispatchEvent(new CustomEvent('RerenderNodeTreeEvent'));
  }

  private handleCardClose(): void {
    this.viewContainerRef.element.nativeElement.querySelector('.workflow-node-card').classList.remove('selected');
  }

  private unselectAll(): void {
    document.querySelectorAll('.workflow-node-card.selected').forEach((node) => {
      node.classList.remove('selected');
    });
  }

  private markAsSelected(): void {
    this.unselectAll();
    this.viewContainerRef.element.nativeElement.querySelector('.workflow-node-card').classList.add('selected');
  }
}
