import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import Swal, { SweetAlertIcon, SweetAlertResult } from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
export class AlertHelper {
  constructor(private translateService: TranslateService) {}

  public fireToast(icon: SweetAlertIcon, text: string, duration = 3000, showDenyButton = false, denyText = ''): Promise<SweetAlertResult> {
    return Swal.fire({
      icon: icon,
      title: text,
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      showDenyButton: showDenyButton,
      denyButtonText: denyText,
      timer: duration,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      },
    });
  }

  public askConfirmation(title: string, html: string, confirm: () => void, showIcon = true): void {
    Swal.fire({
      title: title,
      html: html,
      icon: showIcon ? 'error' : undefined,
      iconColor: '#C10300',
      confirmButtonColor: '#008448',
      cancelButtonColor: '#C10300',
      showCancelButton: true,
      confirmButtonText: this.translateService.instant('yes'),
      cancelButtonText: this.translateService.instant('no'),
    }).then(async (response: SweetAlertResult) => {
      if (response.isConfirmed) {
        await confirm();
      }
    });
  }
}
