import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
  selector: '[robawsNgTemplate]',
  standalone: true,
})
export class RobawsNgTemplateDirective {
  @Input('robawsNgTemplate')
  public type: string | undefined;

  constructor(public template: TemplateRef<any>) {}
}
