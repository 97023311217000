<div [class]="'item-container' + (showExpandIcon ? ' show-expand-icon' : '') + (selected ? ' selected' : '') + (node.selectable ? ' selectable' : '')"
     (click)="$event.stopPropagation(); this.onItemSelect()">

  <mat-icon *ngIf="showExpandIcon"
            class="icon"
            [fontIcon]="expanded ? 'expand_more': 'keyboard_arrow_right'"
            (click)="onIconClick($event)"/>

  <ng-container *ngIf="showCustomValue">
    <span class="add-item">{{ 'ui.tree-select.add-item' | translate }}</span>
    <span>"{{ this.node.label }}"</span>
  </ng-container>

  <span *ngIf="!isCustomValue">{{ this.node.label }}</span>
</div>
