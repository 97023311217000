@if (this.isLoaded()) {
  <div class="view-controls">
    <view-tabs [viewContentType]="this.viewContentType"
               [metadata]="this.metadata"
               (tabChange)="onTabChange($event)" />

    <view-filters *ngIf="this.currentView"
                  [viewId]="this.currentView.id"
                  [viewContentType]="this.viewContentType"
                  [metadata]="this.metadata"
                  [viewFilterGroup]="this.currentView.filterGroup"
                  [additionalFilters]="this.currentView.additionalFilters"
                  [searchQuery]="this.searchText"
                  (searchQueryChanged)="this.onSearchTextUpdate($event)"
                  (filtersChanged)="this.onFiltersSaved($event)" />

    <mat-icon (click)="this.openSettingsDialog()"
              fontIcon="settings"
              class="settings-icon" />
  </div>

  @if (this.viewLoading) {
    <p-table [value]="placeholderRows" [tableStyle]="{ 'min-width': '50rem' }" class="placeholder-table">
      <ng-template pTemplate="header">
        <tr>
          <th>
            <p-skeleton />
          </th>
          <th>
            <p-skeleton />
          </th>
          <th>
            <p-skeleton />
          </th>
          <th>
            <p-skeleton />
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-i>
        <tr>
          <td>
            <p-skeleton />
          </td>
          <td>
            <p-skeleton />
          </td>
          <td>
            <p-skeleton />
          </td>
          <td>
            <p-skeleton />
          </td>
        </tr>
      </ng-template>
    </p-table>
  } @else if (this.viewColumns.length === 0) {
    <div class="empty-view">

      <div>
        <p>{{ 'overviews.no-columns' | translate }}</p>

        <button class="add-quick-filter robaws-ng-muted-button" (click)="this.openSettingsDialog()">
          <mat-icon fontIcon="settings" />
          {{ 'overviews.change-columns' | translate }}
        </button>
      </div>
    </div>
  } @else {
    <p-table [lazy]="true"
             [metaKeySelection]="true"
             [resizableColumns]="true"
             [lazyLoadOnInit]="false"
             [loading]="dataLoading"
             [columns]="this.viewColumns"
             [value]="this.currentRows"
             [rows]="this.currentView.pageSize"
             [totalRecords]="this.totalRows"
             [multiSortMeta]="this.sorts"
             [(selection)]="this.selectedItems"
             (onLazyLoad)="this.loadViewData($event)"
             (onColResize)="this.onColumnResize()"
             (wheel)="this.onScroll()"
             columnResizeMode="expand"
             selectionMode="multiple"
             sortMode="multiple"
             scrollHeight="100%"
             #table>
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th class="p-frozen-column" style="width: 4rem" pResizableColumn>
            <p-tableHeaderCheckbox />
          </th>

          @for (column of columns; track column) {
            @if (column.sortable) {
              <th [pSortableColumn]="column.path"
                  [style]="column.width ? column.width + 'px' : ''"
                  pResizableColumn>
                <div class="p-title">
                  <div class="p-title-content">
                    <div>{{ column.name }}</div>
                  </div>
                  <p-sortIcon class="p-title-sorter" *ngIf="column.sortable" [field]="column.path"></p-sortIcon>
                </div>
              </th>
            } @else {
              <th pResizableColumn>
                <div class="p-title">
                  <div class="p-title-content">
                    <div>{{ column.name }}</div>
                  </div>
                </div>
              </th>
            }
          }
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
        <tr [pSelectableRow]="rowData"
            (dblclick)="onRowDoubleClick($event, rowData)"
            [pSelectableRowIndex]="rowIndex"
            [attr.style]="(rowData.color ? 'background: ' + rowData.color + '; ' : '') + 'color: ' + rowData.textColor + ';'">
          <td class="p-frozen-column" (click)="$event.stopPropagation()" [attr.style]="rowData.color ? 'background: ' + rowData.color : ''">
            <p-tableCheckbox [value]="rowData" (click)="this.checkRangeSelect($event, rowIndex)" />
          </td>
          <td *ngFor="let column of columns">
            @if (column.canNavigate(rowData)) {
              <strong class="navigate-link" (click)="column.navigateTo(rowData)">{{ column.valueProvider(rowData) }}</strong>
            } @else {
              <span>{{ column.valueProvider(rowData) }}</span>
            }
          </td>
        </tr>
      </ng-template>
    </p-table>

    <p-contextMenu *ngIf="table.el && !isTouchDevice()"
                   [target]="table.el.nativeElement"
                   [model]="this.contextMenuItems">

      <ng-template pTemplate="item" let-item>
        <a pRipple class="flex align-items-center p-menuitem-link">
          <mat-icon [fontIcon]="item.icon" [ngStyle]="item.iconStyle" />
          <span class="ml-2" style="line-height: 24px;">{{ item.label }}</span>
        </a>
      </ng-template>
    </p-contextMenu>

    <dynamic-overview-paginator [totalItems]="this.totalRows"
                                [pageSize]="this.currentView.pageSize"
                                [page]="this.currentPage"
                                (pageChange)="this.loadViewPage($event)"
                                (pageSizeChange)="this.onPageSizeChange($event)" />
  }

  <view-settings-dialog [view]="this.currentView"
                        [paths]="this.metadataPaths"
                        (settingsChanged)="this.onViewSettingsChanged($event)"
                        (viewDeleted)="this.onViewDeleted()" />
}
