<mat-expansion-panel (opened)="setExpanded.emit(index)" [expanded]="isSelected" class="resource-type" hideToggle>
  <mat-expansion-panel-header primaryDarkColor>
    <mat-panel-title class="resource-type-title">
      {{ 'dataExportConfiguration.module' | translate:{ name: displayResourceType ? displayResourceType.name : data.module } }}
    </mat-panel-title>

    <mat-icon (click)="syncModule($event)" class="export-resource">upload</mat-icon>
    <mat-icon (click)="deleteResourceType.emit(index); $event.stopImmediatePropagation();" class="resource-delete">delete</mat-icon>
  </mat-expansion-panel-header>
  <div class="resource-type-body">
    <ng-select (change)="onChangeResourceType()" *ngIf="resourceTypes?.length"
               [(ngModel)]="data.module"
               [clearable]="false"
               [items]="resourceTypes"
               [placeholder]="'resourceType' | translate"
               appendTo=".robaws-ng"
               bindLabel="name"
               bindValue="id">
    </ng-select>

    <mat-accordion class="list-attrs" (cdkDropListDropped)="drop($event)" *ngIf="data.attributes?.length" [cdkDropListData]="data.attributes"
                   cdkDropList>
      <ng-container *ngFor="let value of data.attributes; let i = index">
        <data-export-resource-type-attribute class="attr" (deleteAttribute)="deleteAttribute(i)" (updateAttribute)="updateAttribute(i, $event)"
                                             [data]="value"
                                             [isSelected]="i === expandedIndex"
                                             [resourceType]="data.module"
                                             cdkDrag></data-export-resource-type-attribute>
      </ng-container>
    </mat-accordion>
  </div>
  <mat-action-row>
    <button *ngIf="data.module"
            (click)="addAttribute()"
            class="btn-add"
            mat-button
            primaryColor>
      {{ 'addAttribute' | translate }}
    </button>
  </mat-action-row>
</mat-expansion-panel>
