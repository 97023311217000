import { Component, OnDestroy, OnInit, QueryList, ViewChildren, ViewContainerRef } from '@angular/core';
import { filter, switchMap } from 'rxjs/operators';
import { BehaviorSubject, EMPTY, Subscription, tap, zip } from 'rxjs';
import { plainToInstance } from 'class-transformer';
import { TranslateService } from '@ngx-translate/core';

import { AbstractWebComponent } from '@shared/components/abstract-web.component';
import { InternalServiceMessageEvent, InternalServiceMessageService, ToastService } from '@shared/services';

import { DataExportConfiguration, DataExportConfigurationUpdateDTO, DataExportStatusDto } from '@app/robaws/domain';
import { DataExportConfigurationService, RobawsResourceTypeService } from '@app/robaws/services';
import { AlertHelper } from '@shared/helpers';
import { DataExportResourceTypeComponent } from '@app/robaws/components/data-export-configuration/data-export-resource-type/data-export-resource-type.component';

@Component({
  selector: 'data-export-configuration',
  templateUrl: 'data-export-configuration.component.html',
  styleUrls: ['data-export-configuration.component.scss'],
})
export class DataExportConfigurationComponent extends AbstractWebComponent implements OnInit, OnDestroy {
  public status: DataExportStatusDto;
  public data: DataExportConfigurationUpdateDTO;
  // UI
  public expandedIndex = -1;
  public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly googleSheetsUrlPrefix: string = 'https://docs.google.com/spreadsheets/d/';
  private messageReceiver: Subscription;
  private updateStatusTask: number;

  @ViewChildren(DataExportResourceTypeComponent)
  protected dataExportResourceTypes: QueryList<DataExportResourceTypeComponent>;

  constructor(
    protected override viewContainerRef: ViewContainerRef,
    private internalServiceMessageService: InternalServiceMessageService,
    private dataExportConfigurationService: DataExportConfigurationService,
    private robawsResourceTypeService: RobawsResourceTypeService,
    private toastService: ToastService,
    private translateService: TranslateService,
    private alertHelper: AlertHelper,
  ) {
    super(viewContainerRef);
  }

  public ngOnInit(): void {
    this.fetchConnectionStatus();
    this.fetchConfig();

    this.messageReceiver = this.internalServiceMessageService
      .onMessageReceive('any')
      .pipe(
        filter((it) => it.action === 'config-save' && it.data === 'data-export'),
        switchMap(() => this.save()),
      )
      .subscribe(() => {
        this.internalServiceMessageService.send(new InternalServiceMessageEvent('robaws-ng', 'robaws', 'config-saved', 'data-export'));
      });
  }

  public ngOnDestroy(): void {
    this.save().subscribe();
    this.messageReceiver.unsubscribe();
    clearTimeout(this.updateStatusTask);
  }

  fetchConfig(): void {
    this.isLoading$.next(true);
    zip(this.dataExportConfigurationService.getConfig(), this.robawsResourceTypeService.getResourceTypes())
      .subscribe({
        next: ([data]) => {
          this.data = data.toDataExportConfigurationUpdateDTO();
        },
      })
      .add(() => {
        this.isLoading$.next(false);
      });
  }

  disconnect(): void {
    this.isLoading$.next(true);
    this.dataExportConfigurationService
      .disconnect()
      .subscribe({
        next: () => {
          this.fetchConnectionStatus();
        },
        error: (ex) => {
          this.toastService.fireToast(ex.message);
        },
      })
      .add(() => {
        this.isLoading$.next(false);
      });
  }

  connect(): void {
    this.isLoading$.next(true);

    this.save()
      .pipe(switchMap(() => this.dataExportConfigurationService.getConnectionUrl()))
      .subscribe({
        next: (url) => {
          window.location.href = url;
        },
        error: (ex) => {
          this.isLoading$.next(false);
          this.toastService.fireToast(ex.message);
        },
      });
  }

  fetchConnectionStatus(): void {
    this.dataExportConfigurationService.getConnectionStatus().subscribe({
      next: (status: DataExportStatusDto) => {
        this.status = status;

        this.updateStatusTask = window.setTimeout(this.fetchConnectionStatus.bind(this), status.exporting ? 3000 : 10000);
      },
    });
  }

  addResourceType(): void {
    this.data.configurations.push({
      module: '',
      attributes: [],
    });
    this.setExpanded(this.data.configurations.length - 1);
  }

  deleteResourceType(resourceIndex: number): void {
    this.alertHelper.askConfirmation(
      this.translateService.instant('confirmation.title'),
      this.translateService.instant('confirmation.content.node'),
      () => {
        this.data.configurations.splice(resourceIndex, 1);
        this.updateResourceTypeComponents();
      },
    );
  }

  updateResourceTypeComponents(): void {
    for (const dataExportResourceType of this.dataExportResourceTypes) {
      dataExportResourceType.populateResourceTypes();
    }
  }

  setExpanded(index: number): void {
    this.expandedIndex = index;
  }

  handleCopy(): void {
    navigator.clipboard.writeText(JSON.stringify(this.data.configurations));
    this.toastService.fireToast(this.translateService.instant('dataExportConfiguration.copyMessage'));
  }

  async handleClickPaste() {
    this.paste(await navigator.clipboard.readText());
  }

  paste = (pasteString: string): void => {
    try {
      const parsed = plainToInstance(DataExportConfiguration, <DataExportConfiguration[]>JSON.parse(pasteString));
      this.data.configurations = parsed.map(({ module, attributes }) => {
        return {
          module,
          attributes: attributes || [],
        };
      });
      this.toastService.fireToast(this.translateService.instant('dataExportConfiguration.pasteMessage'));
    } catch (ex) {
      this.toastService.fireToast(this.translateService.instant('dataExportConfiguration.pasteErrorMessage'));
    }
  };

  handlePaste(event: ClipboardEvent): void {
    const clipboardData = event.clipboardData;
    const text = clipboardData?.getData('text') || '';
    this.paste(text);
  }

  protected export(module: string): void {
    this.isLoading$.next(true);

    this.save()
      .pipe(
        tap(() => {
          if (this.hasDuplicateModules(this.data.configurations)) {
            throw new Error('Duplicates');
          }
        }),
        switchMap(() => this.dataExportConfigurationService.sync(module)),
      )
      .subscribe({
        next: () => {
          this.toastService.fireToast(this.translateService.instant('dataExportConfiguration.exportSuccessMessage'));
          clearTimeout(this.updateStatusTask);
          this.fetchConnectionStatus();
        },
        error: (err) => {
          if (err.message == 'Duplicates') {
            this.toastService.fireToast(this.translateService.instant('dataExportConfiguration.duplicateModules'));
          } else {
            this.toastService.fireToast(this.translateService.instant('dataExportConfiguration.exportErrorMessage'));
          }
        },
      })
      .add(() => {
        this.isLoading$.next(false);
      });
  }

  private hasDuplicateModules(configurations: any[]): boolean {
    return configurations.some((config, index) => configurations.findIndex((c) => c.module === config.module) !== index);
  }

  protected save() {
    if (!this.data) {
      return EMPTY;
    }
    return this.dataExportConfigurationService.updateConfig(this.data.toData());
  }

  protected openSheet(): void {
    if (this.status) {
      window.open(this.googleSheetsUrlPrefix + this.status.sheetId, '_blank');
    }
  }
}
