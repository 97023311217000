import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';

import { InputTextModule } from 'primeng/inputtext';

import { NodesModule } from '../../nodes/nodes.module';

import { WorkflowWizardComponent } from './workflow-wizard.component';
import { MaterialLoaderDirective } from '@ui/material-loader/material-loader.directive';
import { RobawsNgCardComponent } from '@ui/robaws-ng-card/robaws-ng-card.component';
import { RobawsNgCardContentComponent } from '@ui/robaws-ng-card/robaws-ng-card-content/robaws-ng-card-content.component';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    TranslateModule,
    MatFormFieldModule,
    MatButtonModule,

    InputTextModule,

    NodesModule,
    MaterialLoaderDirective,
    RobawsNgCardComponent,
    RobawsNgCardContentComponent,
  ],
  declarations: [WorkflowWizardComponent],
})
export class WorkflowWizardModule {}
