<robaws-ng-card (click)="onNodeClick()"
                [class]="'workflow-node-card' + (allowPointerCursor ? ' pointer-cursor' : '')">
  <robaws-ng-card-header>
    <robaws-ng-card-title>
      <ng-content select="[slot=title]" />

      <delete-icon *ngIf="workflowNodeData.currentNode.type !== 'robaws_trigger'"
                   (deleteAccepted)="this.onDelete()"
                   [confirmationMessage]="this.deleteConfirmationTranslationKey | translate"
                   styleClass="delete-button"/>
    </robaws-ng-card-title>
  </robaws-ng-card-header>

  <robaws-ng-card-content>
    <ng-content select="[slot=content]" />
  </robaws-ng-card-content>
</robaws-ng-card>

<add-node (createNewNodeRequest)="this.createNewNode($event)"
          [resourceTypeMetadata]="workflowNodeData.resourceTypeMetadata" />

<robaws-ng-card *ngIf="!getNextNode()" class="workflow-end-card">
  <robaws-ng-card-header>
    <robaws-ng-card-title>
      <span class="title-content">
        {{ 'end' | translate }}
      </span>
    </robaws-ng-card-title>
  </robaws-ng-card-header>
</robaws-ng-card>
