import { Component, Input, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { filter, switchMap, tap } from 'rxjs/operators';
import { BehaviorSubject, EMPTY, Observable, Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';

import { AbstractWebComponent } from '@shared/components/abstract-web.component';
import { InternalServiceMessageEvent, InternalServiceMessageService, PageService, ToastService, UrlService } from '@shared/services';
import { CallbackActions } from '@shared/domain';

import { AutomationResourceTypeService, WorkflowService } from '@app/automation/services';
import { AutomationResourceTypeMetadata, EnableWorkflowDTO, WorkflowDTO, WorkflowUpdateDTO } from '@app/automation/domain';

import { EditWorkflowNameDialogComponent } from './edit-workflow-name-dialog/edit-workflow-name-dialog.component';
import { EnableWorkflowDialogComponent } from './enable-workflow-dialog/enable-workflow-dialog.component';
import { EnableWorkflowDialogResult } from './enable-workflow-dialog/enable-workflow-dialog-result';
import { AlertHelper } from '@shared/helpers';
import { AutomationAuditLogDialogComponent } from '@app/automation/components/automation-audit-log-dialog/automation-audit-log-dialog.component';

export type EditWorfklowNameDialogData = {
  workflowName: string;
};

@Component({
  selector: 'edit-workflow',
  templateUrl: 'edit-workflow.component.html',
  styleUrls: ['edit-workflow.component.scss'],
})
export class EditWorkflowComponent extends AbstractWebComponent implements OnInit, OnDestroy {
  @Input({ required: true })
  public id: string;
  protected currentWorkflow?: WorkflowUpdateDTO;
  protected isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private messageReceiver: Subscription;
  private metadata: AutomationResourceTypeMetadata;

  @ViewChild('auditLogDialog')
  private auditLogDialog: AutomationAuditLogDialogComponent;

  constructor(
    public dialog: MatDialog,
    private automationResourceTypeService: AutomationResourceTypeService,
    protected override viewContainerRef: ViewContainerRef,
    private internalServiceMessageService: InternalServiceMessageService,
    private pageService: PageService,
    private translateService: TranslateService,
    private workflowService: WorkflowService,
    private toastService: ToastService,
    private alertHelper: AlertHelper,
    private urlService: UrlService,
  ) {
    super(viewContainerRef);
  }

  public ngOnInit(): void {
    this.isLoading$.next(true);

    this.workflowService
      .findWorkflowById(this.id)
      .pipe(
        tap((workflow: WorkflowDTO) => {
          this.currentWorkflow = workflow.toWorkflowUpdateDTO();
          this.updatePageName();
          this.updateActiveState(workflow.enabled, workflow.deletedAt != null);
        }),
        switchMap((workflow: WorkflowDTO) => {
          //Preload metadata for the trigger node
          return this.automationResourceTypeService.getMetadata(workflow.resourceType);
        }),
      )
      .subscribe({
        next: (metadata) => (this.metadata = metadata),
        complete: () => {
          this.isLoading$.next(false);
        },
      });

    this.messageReceiver = this.internalServiceMessageService
      .onMessageReceive('any')
      .pipe(filter((it) => it.action === 'button-clicked'))
      .subscribe((event) => {
        if (!this.currentWorkflow) {
          return;
        }

        switch (event.data) {
          case CallbackActions.EDIT_WORKFLOW_NAME:
            this.openEditWorkflowNameDialog();
            break;
          case CallbackActions.ENABLE_WORKFLOW:
            this.openEnableWorkflowDialog();
            break;
          case CallbackActions.DISABLE_WORKFLOW:
            this.disableWorkflow();
            break;
          case CallbackActions.OPEN_AUDIT_LOG:
            this.openAuditLogDialog();
            break;
        }
      });
  }

  public ngOnDestroy(): void {
    if (this.messageReceiver) {
      this.messageReceiver.unsubscribe();
    }

    this.saveWorkflow().subscribe();
  }

  protected saveWorkflow(): Observable<WorkflowDTO> {
    if (!this.currentWorkflow) {
      return EMPTY;
    }

    this.workflowService.isSaving$.next(true);
    return this.workflowService.updateWorkflow(this.id, this.currentWorkflow).pipe(
      tap({
        next: (workflow: WorkflowDTO) => (this.currentWorkflow = workflow.toWorkflowUpdateDTO()),
        complete: () => this.workflowService.isSaving$.next(false),
        error: () => this.workflowService.isSaving$.next(false),
      }),
    );
  }

  protected navigateToRevision(revisionId: string): void {
    this.saveWorkflow().subscribe(() => {
      this.urlService.navigateToWorkflowRevision(revisionId);
    });
  }

  private updatePageName(): void {
    if (this.currentWorkflow && this.currentWorkflow.name) {
      this.pageService.setPageTitle(this.currentWorkflow.name);
    }
  }

  private updateActiveState(enabled: boolean, deleted: boolean): void {
    if (!this.currentWorkflow) {
      return;
    }
    // This is for the correct button to be shown in the header
    this.internalServiceMessageService.send(
      new InternalServiceMessageEvent('automation', 'robaws', 'workflow.loaded', {
        enabled: enabled,
        deleted: deleted,
      }),
    );
  }

  private openEditWorkflowNameDialog(): void {
    if (!this.currentWorkflow) {
      return;
    }
    const dialogRef = this.dialog.open(EditWorkflowNameDialogComponent, {
      data: {
        workflowName: this.currentWorkflow.name,
      },
    });

    dialogRef.afterClosed().subscribe((result): void => {
      if (result && this.currentWorkflow) {
        this.currentWorkflow.name = result;
        this.updatePageName();
      }
    });
  }

  private openEnableWorkflowDialog(): void {
    if (!this.currentWorkflow) {
      return;
    }
    const dialogRef = this.dialog.open(EnableWorkflowDialogComponent);

    dialogRef.afterClosed().subscribe((result: EnableWorkflowDialogResult): void => {
      if (!result || !this.currentWorkflow) {
        return;
      }

      if (this.currentWorkflow.triggerNode.configuration.conditions.length == 0) {
        this.alertHelper.askConfirmation(
          this.translateService.instant('confirmation.title'),
          this.translateService.instant('confirmation.content.enabling-without-triggers', {
            resourceType: this.metadata?.displayName ?? this.currentWorkflow.resourceType,
          }),
          () => {
            this.saveAndEnableWorkflow(result.enableWorkflowDTO);
          },
          false,
        );
      } else {
        this.saveAndEnableWorkflow(result.enableWorkflowDTO);
      }
    });
  }

  private saveAndEnableWorkflow(enableWorkflowDTO: EnableWorkflowDTO): void {
    this.saveWorkflow()
      .pipe(switchMap((workflow: WorkflowDTO) => this.workflowService.enableWorkflow(workflow.workflowId, enableWorkflowDTO)))
      .subscribe({
        complete: () => {
          this.updateActiveState(true, false);
          this.toastService.fireToast(this.translateService.instant('workflows.workflow-enabled'));
        },
        error: (err: any) => {
          if (err instanceof HttpErrorResponse) {
            this.toastService.fireToast(err.error.message);
          }
        },
      });
  }

  private disableWorkflow(): void {
    this.saveWorkflow()
      .pipe(switchMap((workflow: WorkflowDTO) => this.workflowService.disableWorkflow(workflow.workflowId)))
      .subscribe({
        complete: () => {
          this.updateActiveState(false, false);

          this.toastService.fireToast(this.translateService.instant('workflows.workflow-disabled'));
        },
      });
  }

  private openAuditLogDialog(): void {
    this.auditLogDialog.openDialog();
  }
}
