import { catchError, forkJoin, mergeMap, Observable, of, toArray } from 'rxjs';

import { AutomationResourceTypeAction, AutomationResourceTypeLink, AutomationResourceTypeMetadata, WorkflowNodeType } from '@app/automation/domain';

import { WorkflowNodeCategoryType, WorkflowNodeTypeVO } from '../../add-node-configurer/add-node-configurer.component';
import { NodeProvider, NodeProviderContext } from '../node-provider';

export const executeRobawsResourceActionNodeProvider: NodeProvider = (context: NodeProviderContext): Observable<WorkflowNodeTypeVO[]> => {
  return getExecuteRobawsResourceActionNodes(context);
};

function getExecuteRobawsResourceActionNodes(context: NodeProviderContext): Observable<WorkflowNodeTypeVO[]> {
  const availableResourceTypes: string[] = context.metadata.links
    .map((link: AutomationResourceTypeLink) => link.resource)
    .filter((value: string, index: number, self: string[]) => self.indexOf(value) === index);

  const observables = availableResourceTypes.map((resourceType) => {
    return context.automationResourceTypeService.getMetadata(resourceType).pipe(catchError(() => of(undefined)));
  });

  return forkJoin([of(context.metadata), ...observables]).pipe(
    mergeMap((metadatas) => {
      return metadatas.map((metadata) => {
        if (metadata) {
          return getResourceTypeActionNodes(metadata);
        } else {
          return [];
        }
      });
    }),
    toArray(),
    mergeMap((nodes) => {
      const actions: string[] = [];
      const distinctNodes = nodes.flat().filter((node) => {
        if (actions.includes(node.data.actionId)) {
          return false;
        }
        actions.push(node.data.actionId);
        return true;
      });

      return of(distinctNodes.flat());
    }),
  );
}

function getResourceTypeActionNodes(metadata: AutomationResourceTypeMetadata): WorkflowNodeTypeVO[] {
  return metadata.actions.map((action: AutomationResourceTypeAction): WorkflowNodeTypeVO => {
    return {
      type: WorkflowNodeType.execute_robaws_resource_action,
      icon: getExecuteRobawsResourceActionNodeIcon(action),
      category: WorkflowNodeCategoryType.ACTION,
      color: 'darkorange',
      priority: 4,
      name: action.name,
      data: {
        actionId: action.id,
        action: action,
      },
    };
  });
}

function getExecuteRobawsResourceActionNodeIcon(action: AutomationResourceTypeAction): string {
  switch (action.id) {
    case 'delete':
      return 'delete';
    case 'archive':
      return 'archive';
    case 'lock':
      return 'lock';
    case 'send-to-accountant':
      return 'send';
    case 'create-project-from-offer':
      return 'add';
    default:
      return 'play_arrow';
  }
}
