import { Component, ComponentRef, Input, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { RobawsNgConfigurerCardService } from '@shared/services';
import { Condition } from '@shared/domain';

import { AutomationResourceTypeService } from '@app/automation/services';
import { RobawsTriggerNode, WorkflowUpdateDTO } from '@app/automation/domain';

import { TriggerNodeConfigurerComponent } from '../trigger-node/trigger-node-configurer/trigger-node-configurer.component';
import { AbstractWorkflowNodeComponent } from '../abstract-workflow-node-component';
import { WorkflowNodeData } from '../workflow-node/workflow-node-data';
import { WorkflowNodeComponent } from '../workflow-node/workflow-node.component';

@Component({
  selector: 'trigger-node',
  templateUrl: 'trigger-node.component.html',
})
export class TriggerNodeComponent extends AbstractWorkflowNodeComponent implements OnInit, OnDestroy {
  @Input({ required: true })
  public workflowId: string;
  @Input({ required: true })
  public workflow: WorkflowUpdateDTO;

  protected workflowNodeData: WorkflowNodeData<RobawsTriggerNode>;
  private nodeClickSubscription: Subscription;
  private resourceTypeLoaded = false;
  private workflowNodeComponent: ComponentRef<WorkflowNodeComponent>;

  constructor(
    private automationResourceTypeService: AutomationResourceTypeService,
    private robawsNgConfigurerCardService: RobawsNgConfigurerCardService,
    private viewContainerRef: ViewContainerRef,
    private translateService: TranslateService,
  ) {
    super();
  }

  protected get conditions(): Condition[] {
    return this.workflowNodeData.currentNode.configuration.conditions;
  }

  ngOnInit(): void {
    const resourceType = this.workflow.resourceType;

    if (!this.resourceTypeLoaded && resourceType) {
      this.automationResourceTypeService.getMetadata(resourceType).subscribe({
        next: (resourceTypeMetadata) => {
          this.workflowNodeData = {
            previousNode: undefined,
            currentNode: this.workflow.triggerNode,
            resourceTypeMetadata: resourceTypeMetadata,
            workflowId: this.workflowId,
          };
          this.resourceTypeLoaded = true;
          this.createNodeComponent();
        },
      });
    }

    document.addEventListener('RerenderNodeTreeEvent', this.rerenderNodeTree.bind(this));
  }

  ngOnDestroy(): void {
    if (this.nodeClickSubscription) {
      this.nodeClickSubscription.unsubscribe();
    }

    document.removeEventListener('RerenderNodeTreeEvent', this.rerenderNodeTree.bind(this));
  }

  public openConfigurer(): void {
    this.robawsNgConfigurerCardService.createAndOpenConfigurerCard(TriggerNodeConfigurerComponent, {
      title: this.translateService.instant('triggerNodes.heading'),
      inputs: {
        data: {
          resourceTypeMetadata: this.workflowNodeData.resourceTypeMetadata,
          workflow: this.workflow,
        },
      },
    });
  }

  private rerenderNodeTree() {
    const index = this.viewContainerRef.indexOf(this.workflowNodeComponent.hostView);

    if (index >= 0) {
      this.viewContainerRef.detach(index);
    }

    this.createNodeComponent();
  }

  private createNodeComponent(): void {
    this.workflowNodeComponent = this.viewContainerRef.createComponent(WorkflowNodeComponent, {
      projectableNodes: this.getProjectableNodes(),
    });

    this.workflowNodeComponent.instance.workflowNodeData = this.workflowNodeData;
    this.nodeClickSubscription = this.workflowNodeComponent.instance.nodeClick.subscribe(() => this.openConfigurer());
  }

  private getProjectableNodes(): Node[][] {
    if (this.workflowNodeComponent) {
      return [
        [this.workflowNodeComponent.location.nativeElement.querySelector('[slot="title"]')],
        [this.workflowNodeComponent.location.nativeElement.querySelector('[slot="content"]')],
      ];
    }

    return [
      [this.viewContainerRef.element.nativeElement.querySelector('[slot="title"]')],
      [this.viewContainerRef.element.nativeElement.querySelector('[slot="content"]')],
    ];
  }
}
