import { Component, Input, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { AbstractWebComponent } from '@app/shared';
import { BehaviorSubject, EMPTY, Observable, Subscription } from 'rxjs';
import { PageService } from '@shared/services';
import { FormTemplateService } from '@app/form-template/services';
import { tap } from 'rxjs/operators';
import { FormTemplateDTO } from '@app/form-template/domain/FormTemplateDTO';
import { FormTemplateSection } from '@app/form-template/domain';

@Component({
  selector: 'app-edit-form-template',
  templateUrl: './edit-form-template.component.html',
  styleUrls: ['./edit-form-template.component.scss'],
})
export class EditFormTemplateComponent extends AbstractWebComponent implements OnInit, OnDestroy {
  @Input({ required: true })
  public id: string;
  public formTemplate: FormTemplateDTO;
  public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private messageReceiver?: Subscription;

  constructor(
    private pageService: PageService,
    protected override viewContainerRef: ViewContainerRef,
    public formTemplateService: FormTemplateService,
  ) {
    super(viewContainerRef);
  }

  public ngOnInit(): void {
    this.isLoading$.next(true);
    this.formTemplateService.getFormTemplateById(parseInt(this.id)).subscribe((it) => {
      this.formTemplate = it;

      if (this.formTemplate.sections.length == 0) {
        this.formTemplate.sections.push(new FormTemplateSection('', []));
      }

      this.isLoading$.next(false);
      this.updatePageName();
    });
  }

  public ngOnDestroy(): void {
    if (this.messageReceiver) {
      this.messageReceiver.unsubscribe();
    }
    this.saveFormTemplate().subscribe();
  }

  public addSection(): void {
    this.formTemplate.sections.push(new FormTemplateSection('', []));
  }

  protected saveFormTemplate(): Observable<FormTemplateDTO> {
    if (!this.formTemplate) {
      return EMPTY;
    }

    this.formTemplateService.isSaving$.next(true);
    return this.formTemplateService.updateFormTemplate(parseInt(this.id), this.formTemplate).pipe(
      tap({
        complete: () => this.formTemplateService.isSaving$.next(false),
        error: () => this.formTemplateService.isSaving$.next(false),
      }),
    );
  }

  selectText(input: HTMLInputElement) {
    input.select();
  }

  private updatePageName(): void {
    if (this.formTemplate && this.formTemplate.name) {
      this.pageService.setPageTitle(this.formTemplate.name);
    }
  }
}
