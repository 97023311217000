<div class="view-tabs-container">
  <div class="view-tabs"
       cdkDropList
       cdkDropListOrientation="horizontal"
       (cdkDropListDropped)="onTabDrop($event)"
       (wheel)="this.handleWheelEvent($event)"
       #viewTabsElement>

    @for (tab of activeViews; track tab) {
      <p [class]="'view-tab' + (tab === this.focussedView ? ' active' : '')"
         (click)="this.activateAndFocusTab(tab)"
         (mouseup)="this.handleMouseUpEvent($event, tab)"
         [pTooltip]="tab.name"
         tooltipStyleClass="material-tooltip"
         tooltipPosition="top"
         cdkDrag
         [cdkDragDisabled]="(tab.parentView?.systemView ?? false) || isTouchDevice()">
        <!-- disabling drag-n-drop on system views and touch devices (for touch devices is so they can scroll) -->
        <span class="tab-name" [attr.data-tab-name]="tab.name">{{ tab.name }}</span>

        <mat-icon *ngIf="!(tab.parentView?.systemView ?? false) && this.activeViews.length > 1" class="close-tab" fontIcon="close"
                  (click)="this.deleteTab($event, tab)" />
      </p>
    }

    <mat-icon fontIcon="add"
              class="create-new-tab-icon"
              (click)="this.createNewView()" />
  </div>

  <span class="add-tab" (click)="this.openAddTabPanel($event)">{{ 'overviews.title' | translate }}</span>
</div>


<p-overlayPanel #addTabPanel
                [style]="{'min-width': '500px'}"
                appendTo="body">
  <mat-icon class="close-add-tab-panel" fontIcon="close" (click)="this.addTabPanel.hide()" />

  <div class="add-tab-panel" [materialLoader]="this.loadingViewsByMeAndOthers">
    <input type="text"
           class="search-bar"
           [placeholder]="'search-placeholder' | translate"
           [ngModel]="this.currentFilter"
           (ngModelChangeDebounced)="this.updateViewsByMeAndOthers($event)">

    <div class="views-container">
      <div class="views-by-me">
        <h4>{{ 'overviews.tabs.made-by-me' | translate }}</h4>

        @if (viewsByMe.length === 0) {
          <p class="no-views-found">{{ 'overviews.tabs.no-views-found' | translate }}</p>
        } @else {
          <ul class="views">
            @for (view of viewsByMe; track view) {
              <li class="view" (click)="this.openPersonalTab(view)">
                <span class="view-name"
                      tooltipPosition="top"
                      [pTooltip]="view.name">
                  {{ view.name }}
                </span>

                @if (!view.systemView && !view.parentView?.systemView) {
                  <div class="icons">
                    <delete-icon (deleteAccepted)="this.deleteView(view, $event)" />

                    <mat-icon class="visibility-icon"
                              fontIcon="public"
                              tooltipPosition="top"
                              [pTooltip]="'overviews.settings.visibility.' + view.visibility.toLowerCase() | translate"
                              [attr.data-visibility-type]="view.visibility"
                              (click)="this.toggleVisibility(view, $event)" />
                  </div>
                }
              </li>
            }
          </ul>
        }
      </div>

      <div class="views-by-others">
        <h4>{{ 'overviews.tabs.shared-by-others' | translate }}</h4>

        @if (viewsByOthers.length === 0) {
          <p class="no-views-found">{{ 'overviews.tabs.no-views-found' | translate }}</p>
        } @else {
          <ul class="views">
            @for (view of viewsByOthers; track view) {
              <li class="view" (click)="this.openSharedTab(view)">
                <span class="view-name">{{ view.name }}</span>
              </li>
            }
          </ul>
        }
      </div>
    </div>
  </div>
</p-overlayPanel>
