<div class="warning">
  <mat-icon class="icon" fontIcon="warning"/>
  {{ 'nodes.send-text-message.price-warning' | translate }}
</div>

<tree-select [items]="this.toPaths$ | async"
             [label]="'to' | translate"
             [value]="currentNode.configuration.destinations"
             [allowMultipleValues]="true"
             [allowCustomValues]="true"
             (valueChange)="onDestinationsChange($event)" />


<span class="p-float-label">
    <textarea [(ngModel)]="currentNode.configuration.message" id="float-input" rows="5" pInputTextarea>
    </textarea>
    <label for="float-input">{{ 'content' | translate }}</label>
</span>
