<div class="conditions-container">
  <div class="trigger-node-options">
    <label class="condition-list-label" for="condition-list-checkbox">{{'condition-list.title' | translate}}</label>
    <p-checkbox class="condition-list-checkbox" [binary]="true" [(ngModel)]="workflow.triggerNode.configuration.reEnrollmentAllowed" inputId="condition-list-checkbox"></p-checkbox>
    <robaws-ng-info [info]="'condition-list.description' | translate: { value: rootMetaData.displayName.toLowerCase() }" />
  </div>

  <div class="condition-list-title-container">
    <h5 *ngIf="conditions && conditions.length > 0">{{'triggerNodes.filter' | translate}}</h5>

    <select *ngIf="conditions.length > 1"
            (change)="handleMatchTypeChange($event)">
      <option *ngFor="let matchType of getMatchTypes()"
              [selected]="workflow.triggerNode!.configuration.matchType === matchType"
              [value]="matchType">
        {{ matchType }}
      </option>
    </select>
  </div>

  <trigger-node-condition-view (onConditionEdit)="onConditionEdit.emit($event)" [editable]="true"
                               [rootMetaData]="rootMetaData"
                               [workflow]="workflow" />
</div>
