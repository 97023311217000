import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { plainToInstance } from 'class-transformer';

import { WorkflowExecutionDTO } from '@app/automation/domain';
import { PageDTO } from '@shared/domain';

@Injectable({
  providedIn: 'platform',
})
export class WorkflowExecutionService {
  public isSaving$ = new BehaviorSubject<boolean>(false);

  constructor(private httpClient: HttpClient) {}

  public findWorkflowExecution(workflowId: string, workflowExecutionId: string): Observable<WorkflowExecutionDTO> {
    return this.httpClient
      .get(`/automation/workflow-executions/${workflowId}/${workflowExecutionId}`)
      .pipe(map((it) => plainToInstance(WorkflowExecutionDTO, it)));
  }

  public getWorkflowExecutions(searchText: string, page: number, pageSize: number, workflowId?: string): Observable<PageDTO<WorkflowExecutionDTO>> {
    let params = new HttpParams().set('searchText', searchText).set('page', page).set('pageSize', pageSize);
    if (workflowId) {
      params = params.set('workflowId', workflowId);
    }
    return this.httpClient
      .get(`/automation/workflow-executions`, {
        params: params,
      })
      .pipe(
        map((it: any) => {
          it.items = it.items.map((item: any) => plainToInstance(WorkflowExecutionDTO, item));
          return it as PageDTO<WorkflowExecutionDTO>;
        }),
      );
  }
}
