import { FormTemplateElement } from '@app/form-template/domain/elementtypes/FormTemplateElement';

export class DateElement extends FormTemplateElement {
  constructor(
    public override question: string,
    public override required: boolean,
  ) {
    super(question, required);
  }
}
