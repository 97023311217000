<p-dialog [header]="header"
          [(visible)]="visible"
          [modal]="true"
          [style]="this.style ?? { width: '75vw', 'height': '70vh', 'max-height': '70vh' }"
          class="robaws-ng-dialog"
          [draggable]="false"
          [resizable]="false"
          [dismissableMask]="true"
          (onShow)="this.onShow.emit()"
          (onHide)="this.onHide.emit()">
  <ng-content />
</p-dialog>
