import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { RobawsResourceTypeService } from '@app/robaws/services';
import { DataExportConfiguration, DataExportConfigurationUpdateDTO, RobawsResourceType } from '@app/robaws/domain';

@Component({
  selector: 'data-export-resource-type',
  templateUrl: 'data-export-resource-type.component.html',
  styleUrls: ['data-export-resource-type.component.scss'],
})
export class DataExportResourceTypeComponent implements OnInit {
  @Input()
  index: number;

  @Input()
  isSelected: boolean;

  @Input()
  public data: DataExportConfiguration;

  @Input()
  exportConfiguration: DataExportConfigurationUpdateDTO;

  @Output()
  public deleteResourceType: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  public changeResourceType: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public exportResourceType: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  public setExpanded: EventEmitter<number> = new EventEmitter<number>();
  public resourceTypes: RobawsResourceType[] = [];
  public allResourceTypes: RobawsResourceType[] = [];
  public displayResourceType?: RobawsResourceType;
  public expandedIndex = -1;

  constructor(private robawsResourceTypeService: RobawsResourceTypeService) {}

  public ngOnInit(): void {
    this.populateResourceTypes();
  }

  public populateResourceTypes() {
    this.robawsResourceTypeService.getResourceTypes().subscribe({
      next: (data) => {
        const disallowedTypes = this.exportConfiguration.configurations.map((disallowed) => disallowed.module);
        this.allResourceTypes = data;
        this.resourceTypes = data.filter((resource) => {
          return this.data.module == resource.id || !disallowedTypes.includes(resource.id);
        });
        this.refresh();
      },
    });
  }

  protected onChangeResourceType(): void {
    this.changeResourceType.emit();
    this.resetAttributes();
    this.refresh();
  }

  protected refresh(): void {
    this.displayResourceType = this.allResourceTypes.find(({ id }) => id === this.data.module);
  }

  protected drop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.data.attributes, event.previousIndex, event.currentIndex);
  }

  protected deleteAttribute(index: number): void {
    this.data.attributes.splice(index, 1);
  }

  protected updateAttribute(index: number, value: string): void {
    this.data.attributes[index] = value;
  }

  protected addAttribute(): void {
    this.data.attributes.push('');
    this.expandedIndex = this.data.attributes.length - 1;
  }

  protected resetAttributes(): void {
    this.data.attributes = [];
  }

  protected syncModule(event: Event): void {
    event.stopImmediatePropagation();

    if (this.data.module) {
      this.exportResourceType.emit(this.data.module);
    }
  }
}
