import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, tap } from 'rxjs';
import { WhoAmI } from '@app/shared/domain/WhoAmI';

@Injectable({
  providedIn: 'platform',
})
export class WhoAmIService {
  constructor(private httpClient: HttpClient) {}

  public getCurrentUser(): Observable<WhoAmI> {
    const cachedUser = sessionStorage.getItem('currentUser');
    if (cachedUser) {
      return of(JSON.parse(cachedUser) as WhoAmI);
    }

    return this.httpClient.get<WhoAmI>('/api/v2/whoami').pipe(tap((user) => sessionStorage.setItem('currentUser', JSON.stringify(user))));
  }
}
