import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

import { DataType, MetadataProviderType, MetadataWithPath } from '@shared/domain';

import { AutomationResourceTypeMetadata, DelayNode, DelayStrategy, DelayUnit, NodeDTO } from '@app/automation/domain';
import { AUTOMATION_RESOURCE_TYPE_MAPPER } from '@app/automation/services';
import { Path } from '@shared/services';
import { TranslateService } from '@ngx-translate/core';
import { Pair } from '@shared/helpers';

enum DelayNodeExecutionType {
  before_date = 'before_date',
  on_date = 'on_date',
  after_date = 'after_date',
}

@Component({
  selector: 'delay-node-configurer',
  templateUrl: 'delay-node-configurer.component.html',
  styleUrls: ['delay-node-configurer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DelayNodeConfigurerComponent implements OnInit {
  @Input()
  public currentNode: NodeDTO<DelayNode>;

  @Input()
  public resourceTypeMetadata: AutomationResourceTypeMetadata;

  @Output()
  public onReload: EventEmitter<void> = new EventEmitter<void>();

  protected metadataProviderType: MetadataProviderType = 'AUTOMATION';
  protected pathBuilderData: MetadataWithPath;
  protected delayStrategyTypes: Pair<DelayStrategy, string>[] = [];
  protected delayExecutionTypes: Pair<DelayNodeExecutionType, string>[] = [];
  protected delayUnitTypes: Pair<DelayUnit, string>[] = [];
  protected executeType: DelayNodeExecutionType = DelayNodeExecutionType.after_date;
  protected currentDisplayValue = 0;

  constructor(private translateService: TranslateService) {}

  public ngOnInit(): void {
    this.delayStrategyTypes = this.mapToPairs(DelayStrategy, (strategy) => 'nodes.delay.strategy.' + strategy);
    this.delayExecutionTypes = this.mapToPairs(DelayNodeExecutionType, (executionType) => 'nodes.delay.execution-type.' + executionType);
    this.delayUnitTypes = this.mapToPairs(DelayUnit, (unit) => 'nodes.delay.unit.' + unit + '.plural');

    if (this.currentNode.configuration.value) {
      if (this.currentNode.configuration.value < 0) {
        this.executeType = DelayNodeExecutionType.before_date;
      } else if (this.currentNode.configuration.value === 0) {
        this.executeType = DelayNodeExecutionType.on_date;
      } else if (this.currentNode.configuration.value > 0) {
        this.executeType = DelayNodeExecutionType.after_date;
      }
    }

    this.reload();
  }

  protected filterDatePath(path: Path): boolean {
    return path.dataType == DataType.DATE_TIME || path.dataType == DataType.DATE;
  }

  protected handlePathSelected(path: string): void {
    this.currentNode.configuration.path = path;
    this.reload();
  }

  protected onExecuteTypeChange(executionType: DelayNodeExecutionType): void {
    this.executeType = executionType;
    this.reload();
  }

  protected onValueChange(value: number): void {
    this.currentNode.configuration.value = value;
    this.reload();
  }

  private reload(): void {
    this.pathBuilderData = {
      metadata: AUTOMATION_RESOURCE_TYPE_MAPPER.mapMetadata(this.resourceTypeMetadata),
      path: this.currentNode.configuration.path ?? '',
    };
    this.updateValue();
    this.onReload.emit();
  }

  private updateValue() {
    if (this.executeType && this.currentNode.configuration.value) {
      switch (this.executeType) {
        case DelayNodeExecutionType.before_date:
          if (this.currentNode.configuration.value > 0) {
            this.currentNode.configuration.value = -this.currentNode.configuration.value;
          }
          break;
        case DelayNodeExecutionType.on_date:
          this.currentNode.configuration.value = 0;
          break;
        case DelayNodeExecutionType.after_date:
          if (this.currentNode.configuration.value < 0) {
            this.currentNode.configuration.value = Math.abs(this.currentNode.configuration.value);
          }
          break;
      }
    }

    this.updateDisplayValue();
  }

  private updateDisplayValue() {
    if (!this.currentNode.configuration.value) {
      this.currentDisplayValue = 0;
      return;
    }

    this.currentDisplayValue = Math.abs(this.currentNode.configuration.value);
  }

  private mapToPairs<T extends object>(enumType: T, translationPathMapper: (str: string) => string): Pair<T[keyof T], string>[] {
    const pairs = [];

    for (const value of Object.values(enumType)) {
      pairs.push(new Pair(this.translateService.instant(translationPathMapper(value.toString().toLowerCase())), value));
    }

    return pairs;
  }
}
