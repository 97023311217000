import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { DynamicResourceItem, PageDTO, ResourceTypeMetadata } from '@shared/domain';
import { Path, PathService, ResourceTypeEntityParams } from '@shared/services';
import { DynamicTypeInputComponent, InputData } from '@shared/components/inputs/dynamic-type-input';
import { TranslateModule } from '@ngx-translate/core';
import { RobawsOperator, ViewContentType, ViewFilter } from '@app/robaws/domain';
import { DynamicResourceTypeProvider } from '@app/shared/services/dynamic-resource-type.provider';
import { detectInputTypeFromPath, InputType } from '@shared/helpers';
import { NgIf } from '@angular/common';
import { RobawsNgTemplateDirective } from '@shared/components/robaws-ng-template.directive';
import { MatIcon } from '@angular/material/icon';
import { TooltipModule } from 'primeng/tooltip';
import { Observable } from 'rxjs';
import { ViewFilterValueService } from '@app/robaws/services/view-filter-value-service.service';

@Component({
  selector: 'view-quick-filter',
  templateUrl: 'view-quick-filter.component.html',
  styleUrls: ['view-quick-filter.component.scss'],
  standalone: true,
  imports: [DynamicTypeInputComponent, TranslateModule, NgIf, RobawsNgTemplateDirective, MatIcon, TooltipModule],
})
export class ViewQuickFilterComponent implements OnInit, OnChanges {
  @Input({ required: true })
  public viewContentType: ViewContentType;
  @Input({ required: true })
  public metadata: ResourceTypeMetadata;
  @Input({ required: true })
  public path: string;
  @Input({ required: true })
  public additionalFilters: ViewFilter[];
  @Output()
  public additionalFiltersChanged = new EventEmitter<ViewFilter[]>();

  protected currentPath?: Path;
  protected currentResourceTypeMetadata?: ResourceTypeMetadata;
  protected currentValue?: string;
  protected currentInputData?: InputData;
  protected currentInputType: InputType;
  private dynamicResourceTypeProvider = new DynamicResourceTypeProvider('ROBAWS');
  private operatorOverride?: RobawsOperator;

  constructor(
    private pathService: PathService,
    private viewFilterValueService: ViewFilterValueService,
  ) {}

  public ngOnInit(): void {
    this.update();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['additionalFilters'] && !changes['additionalFilters'].firstChange) {
      this.updateCurrentValue();
    }

    if (changes['metadata'] && !changes['metadata'].firstChange) {
      this.update();
    }

    if (changes['path'] && !changes['path'].firstChange) {
      this.update();
    }
  }

  protected onValueChange(event: string | undefined): void {
    this.currentValue = event;

    const operator = this.operatorOverride ?? 'EQUALS';

    if (!event || event === '') {
      this.additionalFilters = this.additionalFilters.filter((filter) => filter.path !== this.path);
    } else {
      const filter = this.additionalFilters.find((filter) => filter.path === this.path);

      if (filter) {
        filter.operator = operator;
        filter.value = event;
      } else {
        this.additionalFilters.push({
          path: this.path,
          operator: operator,
          value: event,
        });
      }
    }

    this.additionalFiltersChanged.emit(this.additionalFilters);
  }

  protected clear(): void {
    this.onValueChange('');
  }

  protected resourceEntityProvider(resourceType: string, params: ResourceTypeEntityParams): Observable<PageDTO<DynamicResourceItem>> {
    return this.viewFilterValueService.getFilterValues(
      this.viewContentType,
      resourceType,
      params.filter,
      params.page,
      params.pageSize ?? 100,
      params.id,
    );
  }

  protected shouldShowCount(): boolean {
    return (
      !!this.currentValue &&
      this.currentValue.length > 0 &&
      (this.currentInputType === 'MULTISELECT_POSSIBLE_VALUES' || this.currentInputType === 'MULTISELECT_COMBOBOX')
    );
  }

  protected getCurrentValueCount(): number {
    if (!this.currentValue) {
      return 0;
    }

    return this.currentValue.split(',').length;
  }

  private update(): void {
    this.updateCurrentPath();
    this.updateCurrentValue();
  }

  private updateCurrentValue(): void {
    const filter = this.additionalFilters.find((filter) => filter.path === this.path);

    if (filter) {
      this.currentValue = filter.value;
    } else {
      this.currentValue = undefined;
    }
  }

  private updateCurrentPath(): void {
    this.pathService.getPath(this.dynamicResourceTypeProvider, this.metadata.name, this.path, true).subscribe((path): void => {
      this.currentPath = path;

      if (path) {
        this.currentResourceTypeMetadata = path.parentMetadata;
        this.currentInputType = detectInputTypeFromPath(path);
        this.operatorOverride = undefined;

        if (this.currentInputType === 'COMBOBOX') {
          this.currentInputType = 'MULTISELECT_COMBOBOX';
          this.operatorOverride = 'IS_ONE_OF';
        } else if (this.currentInputType === 'POSSIBLE_VALUES') {
          this.currentInputType = 'MULTISELECT_POSSIBLE_VALUES';
          this.operatorOverride = 'IS_ONE_OF';
        } else if (this.currentInputType === 'STRING' && path.dataType === 'TEXT') {
          this.operatorOverride = 'CONTAINS';
        } else if (path.dataType === 'DATE' || path.dataType === 'DATE_TIME') {
          this.operatorOverride = 'IS_BETWEEN';
          this.currentInputType = 'DATE_RANGE';
        }

        if (path.targetResourceType) {
          this.dynamicResourceTypeProvider.getMetadata(path.targetResourceType).subscribe((metadata) => {
            this.currentInputData = {
              expectedInputTypeMetadata: metadata,
              possibleValues: path.possibleValues,
            };
          });
        } else {
          this.currentInputData = {
            possibleValues: path.possibleValues,
          };
        }
      }
    });
  }
}
