import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatButtonModule } from '@angular/material/button';

import { DataTableModule } from '@ui/data-table';

import { ListFormTemplatesComponent } from './list-form-templates.component';

@NgModule({
  declarations: [ListFormTemplatesComponent],
  imports: [CommonModule, DataTableModule, MatButtonModule, MatSnackBarModule],
})
export class ListFormTemplateModule {}
