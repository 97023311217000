<span [materialLoader]="isLoading$" [forceFullscreen]="true"></span>

<div class="form-template-wizard-container" *ngIf="formTemplate">
  <div class="name">
    <mat-card>
      <mat-card-content>
        <form class="name-form">
          <mat-form-field class="name-field">
            <mat-label>{{ 'form-templates.form-name' | translate }}</mat-label>
            <input #inputField
                   [(ngModel)]="formTemplate.name"
                   matInput
                   name="formName"
                   type="text"
                   (click)="selectText(inputField)"/>
          </mat-form-field>
        </form>
      </mat-card-content>
      <mat-card-footer>
        <div *ngIf="formTemplate.sections.length===0">
          <p>{{'form-templates.no-sections-yet' | translate}}
            <a class="create-section"
               (click)="addSection()">{{'form-templates.create-in-sentence' | translate}}</a> {{'form-templates.one-in-sentence' |
              translate}}</p>
        </div>
      </mat-card-footer>
    </mat-card>
  </div>

  <div class="sections">
    <edit-form-sections [sections]="formTemplate.sections"/>
  </div>
</div>
