export enum ElementType {
  multiple_choice = 'multiple_choice',
  date = 'date',
  time = 'time',
  text_area = 'text_area',
  number_input = 'number_input',
  description = 'description',
  image = 'image',
  signature = 'signature',
  radio = 'radio',
  drawing = 'drawing',
}
