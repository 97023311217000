import { Component, Input, OnInit } from '@angular/core';
import { AutomationResourceTypeMetadata, NodeDTO } from '@app/automation/domain';
import { CreateRobawsResourceNode } from '@app/automation/domain/nodes/CreateRobawsResourceNode';
import { AUTOMATION_RESOURCE_TYPE_MAPPER, AutomationResourceTypeService } from '@app/automation/services';
import { MetadataProviderType, PathType, ResourceTypeMetadata, ResourceTypeProperty } from '@shared/domain';
import { detectInputType } from '@shared/helpers';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

type HasPathType = {
  pathType: PathType;
};

type CreateRobawsResourceLinkVO = HasPathType & {
  metadata: ResourceTypeMetadata;
  name: string;
  displayName: string;
};

@Component({
  selector: 'create-robaws-resource-node-configurer',
  templateUrl: 'create-robaws-resource-node-configurer.component.html',
  styleUrls: ['create-robaws-resource-node-configurer.component.scss'],
})
export class CreateRobawsResourceNodeConfigurerComponent implements OnInit {
  @Input()
  public currentNode: NodeDTO<CreateRobawsResourceNode>;
  @Input()
  public resourceTypeMetadata: AutomationResourceTypeMetadata;
  public metadata: ResourceTypeMetadata;

  protected metadataProviderType: MetadataProviderType = 'AUTOMATION';
  protected properties: (ResourceTypeProperty & HasPathType)[] = [];
  protected links: CreateRobawsResourceLinkVO[] = [];
  protected readonly detectInputType = detectInputType;

  constructor(private automationResourceTypeService: AutomationResourceTypeService) {}

  public ngOnInit(): void {
    this.metadata = AUTOMATION_RESOURCE_TYPE_MAPPER.mapMetadata(this.resourceTypeMetadata);

    this.automationResourceTypeService.getMetadata(this.currentNode.configuration.resourceType).subscribe((metadata) => {
      const creatingResourceType = AUTOMATION_RESOURCE_TYPE_MAPPER.mapMetadata(metadata);

      this.properties = [
        ...creatingResourceType.attributes
          .filter((it) => !it.readOnly)
          .map((attribute) => ({
            pathType: PathType.attributes,
            ...attribute,
          })),
        ...creatingResourceType.extraFields.map((extraField) => ({
          pathType: PathType.extraFields,
          ...extraField,
        })),
      ];

      if (creatingResourceType.links.length > 0) {
        forkJoin(
          creatingResourceType.links.map((link) => {
            return this.automationResourceTypeService.getMetadata(link.resource).pipe(
              map((linkMetadata) => ({
                pathType: PathType.links,
                metadata: AUTOMATION_RESOURCE_TYPE_MAPPER.mapMetadata(linkMetadata),
                name: link.name,
                displayName: link.displayName,
              })),
            );
          }),
        ).subscribe((links) => (this.links = links));
      }
    });
  }

  protected handleParameterChange(pathType: PathType, param: string, value: string) {
    this.currentNode.configuration.parameters[pathType.toString() + '.' + param] = value;
  }
}
