import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, tap } from 'rxjs';

import { AbstractWebComponent } from '@app/shared';
import { PageService, UrlService } from '@shared/services';

import {
  AutomationResourceType,
  MatchType,
  NodeDTO,
  RobawsTriggerNode,
  WorkflowDTO,
  WorkflowNodeType,
  WorkflowUpdateDTO,
} from '@app/automation/domain';
import { AutomationResourceTypeService, WorkflowService } from '@app/automation/services';

@Component({
  selector: 'workflow-wizard',
  templateUrl: 'workflow-wizard.component.html',
  styleUrls: ['workflow-wizard.component.scss'],
})
export class WorkflowWizardComponent extends AbstractWebComponent implements OnInit {
  public resourceTypes: AutomationResourceType[] = [];
  protected readonly workflow: WorkflowUpdateDTO;
  protected isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly iconMappings: { [key: string]: string[] } = {
    'supply-order': [
      'purchase-supply-order',
      'transport-supply-order',
      'internal-supply-order',
      'price-request-supply-order',
      'rental-supply-order',
      'subcontractor-supply-order',
    ],
  };
  private readonly allowedResourceTypes = [
    'client',
    'sales-invoice',
    'purchase-invoice',
    'offer',
    'project',
    'stock-change',
    'sales-order',
    'subscription',
    'claim-state',
    'work-order',
    'supplier',
    'employee',
    'planning-item',
    'article',
    'purchase-supply-order',
    'transport-supply-order',
    'internal-supply-order',
    'price-request-supply-order',
    'rental-supply-order',
    'subcontractor-supply-order',
    'task',
  ];

  constructor(
    protected override viewContainerRef: ViewContainerRef,
    private urlService: UrlService,
    private automationResourceTypeService: AutomationResourceTypeService,
    private workflowService: WorkflowService,
    private pageService: PageService,
    private translateService: TranslateService,
  ) {
    super(viewContainerRef);

    this.workflow = new WorkflowUpdateDTO();

    this.workflow.triggerNode = new NodeDTO(WorkflowNodeType.robaws_trigger, new RobawsTriggerNode([], MatchType.all, false));
  }

  public ngOnInit(): void {
    this.pageService.setPageTitle(this.translateService.instant('workflows.initialize.title'));
    this.isLoading$.next(true);
    this.automationResourceTypeService.getResourceTypes().subscribe({
      next: (resourceTypes) => {
        this.resourceTypes = resourceTypes
          .filter((it) => this.allowedResourceTypes.includes(it.name))
          .sort((a, b) => a.displayName.localeCompare(b.displayName));
      },
      complete: () => {
        this.isLoading$.next(false);
      },
    });
  }

  protected getIconUrl(resourceType: string): string {
    let iconType = resourceType;

    for (const key in this.iconMappings) {
      const values = this.iconMappings[key];

      for (const value of values) {
        if (resourceType === value) {
          iconType = key;
        }
      }
    }

    return this.urlService.getRobawsNgUrl('/assets/media/' + iconType + '.png');
  }

  protected selectResourceType(resourceType: AutomationResourceType): void {
    this.workflow.resourceType = resourceType.name;

    this.createWorkflow().subscribe({
      next: (workflow) => {
        window.history.replaceState(null, '', '/#/automation/workflows-overview');
        this.urlService.navigateToEditWorkflow(workflow.workflowId);
      },
    });
  }

  private createWorkflow(): Observable<WorkflowDTO> {
    this.workflowService.isSaving$.next(true);

    return this.workflowService.createWorkflow(this.workflow).pipe(tap(() => this.workflowService.isSaving$.next(false)));
  }
}
