import { connect, EMPTY, merge, Observable, OperatorFunction } from 'rxjs';
import { isEmpty, mergeMap } from 'rxjs/operators';

/**
 * Similar to defaultIfEmpty but switches subscribing to observable if the source is empty.
 */
export function concatIfEmpty<T, D = T>(observable: Observable<D>): OperatorFunction<T, D | T> {
  return (source) =>
    source.pipe(
      connect((sharedSource) =>
        merge(
          sharedSource,
          sharedSource.pipe(
            isEmpty(),
            mergeMap((empty) => (empty ? observable : EMPTY)),
          ),
        ),
      ),
    );
}
