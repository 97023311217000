import { Observable, of } from 'rxjs';

import { WorkflowNodeType } from '@app/automation/domain';

import { WorkflowNodeCategoryType, WorkflowNodeTypeVO } from '../../add-node-configurer/add-node-configurer.component';
import { NodeProvider } from '../node-provider';

export const updateRobawsResourceNodeProvider: NodeProvider = (): Observable<WorkflowNodeTypeVO[]> => {
  return of([
    {
      type: WorkflowNodeType.update_robaws_resource,
      category: WorkflowNodeCategoryType.ACTION,
      icon: 'upgrade',
      color: 'darkorange',
      priority: 1,
    },
  ]);
};
