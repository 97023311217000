import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { AlertHelper } from '@shared/helpers';

import { FormTemplateSection } from '@app/form-template/domain';

@Component({
  selector: 'edit-form-section',
  templateUrl: './edit-form-section.component.html',
  styleUrls: ['./edit-form-section.component.scss'],
})
export class EditFormSectionComponent {
  @Input({ required: true })
  public section: FormTemplateSection;
  @Output()
  public onSectionAdd: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  public onSectionDelete: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private translateService: TranslateService,
    private alertHelper: AlertHelper,
  ) {}

  public handleSpacebar(event: KeyboardEvent): void {
    if (event.code === 'Space') {
      event.stopPropagation();
    }
  }

  protected addSection(): void {
    this.onSectionAdd.emit();
  }

  protected onDeleteClick(): void {
    this.onSectionDelete.emit();
  }
}
