<mat-form-field class="question-input">
  <mat-label>{{'question' | translate}}</mat-label>
  <input matInput [(ngModel)]="element.configuration.question">
</mat-form-field>

<mat-form-field>
  <mat-label>{{'form-templates.choose-date' | translate}}</mat-label>
  <input matInput [matDatepicker]="datepicker" disabled>
  <mat-datepicker-toggle matIconSuffix [for]="datepicker"></mat-datepicker-toggle>
  <mat-datepicker #datepicker>
  </mat-datepicker>
</mat-form-field>
