import {
  AfterContentInit,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { NgForOf, NgIf, NgStyle, NgTemplateOutlet } from '@angular/common';
import { v4 as uuidv4 } from 'uuid';
import { OverlayPanel, OverlayPanelModule } from 'primeng/overlaypanel';
import { FormsModule } from '@angular/forms';
import { ScrollerModule } from 'primeng/scroller';
import { MaterialLoaderDirective } from '@ui/material-loader/material-loader.directive';
import { StyleClassModule } from 'primeng/styleclass';
import { NgModelChangeDebouncedDirective } from '@ui/ng-model-change-debounced.directive';
import { TranslateService } from '@ngx-translate/core';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextModule } from 'primeng/inputtext';
import { MatIcon } from '@angular/material/icon';
import { debounceTime, distinctUntilChanged, Subject, Subscription } from 'rxjs';
import { RobawsNgTemplateDirective } from '@shared/components/robaws-ng-template.directive';
import { Nullable } from 'primeng/ts-helpers';

export type RobawsNgSelectFilterEvent = {
  filter: string;
};

export type RobawsNgSelectLazyLoadEvent = {
  first: number;
  last: number;
};

@Component({
  selector: 'robaws-ng-select',
  templateUrl: 'robaws-ng-select.component.html',
  styleUrls: ['robaws-ng-select.component.scss'],
  standalone: true,
  imports: [
    NgForOf,
    NgTemplateOutlet,
    OverlayPanelModule,
    FormsModule,
    ScrollerModule,
    MaterialLoaderDirective,
    NgStyle,
    StyleClassModule,
    NgModelChangeDebouncedDirective,
    CheckboxModule,
    InputTextModule,
    MatIcon,
    NgIf,
  ],
  encapsulation: ViewEncapsulation.None,
})
export class RobawsNgSelectComponent implements OnInit, OnChanges, OnDestroy, AfterContentInit {
  @Input()
  public multiple: boolean = false;

  @Input()
  public placeholder: string | undefined | null;

  @Input()
  public showPlaceholder: boolean = true;

  @Input()
  public value: any | any[] | undefined | null;

  @Input()
  public lazy: boolean = false;

  @Input()
  public items: any[] = [];

  @Input()
  public itemSize: number = 100;

  @Input()
  public totalItems: number = 0;

  @Input()
  public appendTo: HTMLElement | ElementRef | TemplateRef<any> | string | null | undefined | any;

  @Input()
  public optionLabel: string = 'label';

  @Input()
  public optionValue: string = 'value';

  @Input()
  public loading: boolean = false;

  @Input()
  public showFilter: boolean = false;

  @Input()
  public inputClearIcon: boolean = true;

  @Input()
  public style: { [key: string]: any } = { height: '400px', width: '400px' };

  @Input()
  public maxDisplaySelectLabels: number = 3;

  @Input()
  public debounceTime: number = 500;

  @Output()
  public valueChange: EventEmitter<any | any[] | undefined | null> = new EventEmitter<any | any[] | undefined | null>();

  @Output()
  public onLazyLoad: EventEmitter<RobawsNgSelectLazyLoadEvent> = new EventEmitter<RobawsNgSelectLazyLoadEvent>();

  @Output()
  public onFilter: EventEmitter<RobawsNgSelectFilterEvent> = new EventEmitter<RobawsNgSelectFilterEvent>();

  protected uid = uuidv4();
  protected selectedValuesLabel: string = '';
  protected filter: string = '';
  protected inputTemplate: TemplateRef<any> | undefined;
  protected readonly onInputClick = this.showPanel.bind(this);
  private valueChangeSubject = new Subject<any | any[] | undefined | null>();
  private valueChangeSubscription: Subscription;
  @ViewChild('dropdownPanel')
  private dropdownPanel: OverlayPanel;
  @ViewChild('scrollPanel')
  private scrollPanel: ElementRef;
  private canRequestNextPage = true;
  @ContentChildren(RobawsNgTemplateDirective)
  private templates: Nullable<QueryList<RobawsNgTemplateDirective>>;
  @ViewChild('filterInput')
  private filterInput: ElementRef;

  constructor(private translateService: TranslateService) {}

  public ngOnInit(): void {
    this.valueChangeSubscription = this.valueChangeSubject
      .pipe(distinctUntilChanged(), debounceTime(this.debounceTime))
      .subscribe((debouncedValue) => {
        this.valueChange.emit(debouncedValue);
      });
  }

  public ngOnDestroy(): void {
    this.valueChangeSubscription.unsubscribe();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['items']) {
      this.canRequestNextPage = true;
    }
    if (changes['items'] || changes['value'] || changes['multiple'] || changes['maxDisplaySelectLabels'] || changes['optionLabel']) {
      this.updateSelectedValuesLabel();
    }
  }

  public ngAfterContentInit(): void {
    if (this.templates) {
      this.templates.forEach((template) => {
        if (template.type === 'input') {
          this.inputTemplate = template.template;
        }
      });
    }
  }

  protected showPanel(event: MouseEvent): void {
    if (this.dropdownPanel.overlayVisible) {
      this.dropdownPanel.hide();
    } else {
      this.dropdownPanel.show(event);

      setTimeout(() => {
        if (this.filterInput) {
          this.filterInput.nativeElement.focus();
        }
      }, 0);

      if (this.lazy && this.items.length === 0) {
        this.onLazyLoad.emit({ first: 0, last: this.itemSize });
      }
    }
  }

  protected onItemClick(item: any): void {
    const itemValue = item[this.optionValue];

    if (this.multiple) {
      if (!this.value) {
        this.value = [];
      }

      const exists = (this.value as any[]).some((value) => value === itemValue);

      if (exists) {
        this.value = (this.value as any[]).filter((value) => value !== itemValue);
      } else {
        this.value = [...this.value, itemValue];
      }
    } else {
      this.value = this.value === itemValue ? undefined : itemValue;
      this.dropdownPanel.hide();
    }

    this.valueChangeSubject.next(this.value);
  }

  protected onScroll(): void {
    if (!this.lazy || (this.totalItems > 0 && this.items.length >= this.totalItems)) {
      return;
    }

    const element = this.scrollPanel.nativeElement;

    if (this.canRequestNextPage && element.scrollTop >= (element.scrollHeight - element.clientHeight) * 0.9) {
      this.canRequestNextPage = false;
      this.onLazyLoad.emit({
        first: this.items.length,
        last: this.items.length + this.itemSize,
      });
    }
  }

  protected onFilterChanged(event: string): void {
    this.filter = event;
    this.onFilter.emit({ filter: event });
  }

  protected isSelected(item: any): boolean {
    if (this.value === undefined || this.value === null) {
      return false;
    }
    return this.multiple ? (this.value as any[]).some((value) => value === item[this.optionValue]) : this.value === item[this.optionValue];
  }

  protected clear(event: MouseEvent, close = false): void {
    event.preventDefault();
    event.stopPropagation();

    this.value = undefined;
    this.valueChangeSubject.next(this.value);
    this.filter = '';

    if (close) {
      this.dropdownPanel.hide();
    }
  }

  private updateSelectedValuesLabel(): void {
    if (this.value) {
      const filteredItems = this.items.filter((item) => item && item[this.optionValue]);

      if (this.multiple) {
        const values = this.value as any[];

        if (values.length > this.maxDisplaySelectLabels) {
          this.selectedValuesLabel = this.translateService.instant('common.selectedItems', { count: values.length });
        } else {
          this.selectedValuesLabel = filteredItems
            .filter((item) => values.includes(item[this.optionValue]))
            .map((value) => {
              return value[this.optionLabel] ?? '';
            })
            .join(', ');
        }
      } else {
        const item = filteredItems.find((item) => this.value === item[this.optionValue]);

        this.selectedValuesLabel = item ? (item[this.optionLabel] ?? '') : '';
      }
    } else {
      this.selectedValuesLabel = '';
    }
  }
}
