import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { plainToInstance } from 'class-transformer';

import { PageDTO } from '@shared/domain';

import { EnableWorkflowDTO, WorkflowDTO, WorkflowUpdateDTO } from '@app/automation/domain';

@Injectable({
  providedIn: 'platform',
})
export class WorkflowService {
  public isSaving$ = new BehaviorSubject<boolean>(false);

  constructor(private httpClient: HttpClient) {}

  public createWorkflow(workflowUpdateDTO: WorkflowUpdateDTO): Observable<WorkflowDTO> {
    return this.httpClient.post('/automation/workflows', workflowUpdateDTO).pipe(map((it) => plainToInstance(WorkflowDTO, it)));
  }

  public updateWorkflow(workflowId: string, workflowUpdateDTO: WorkflowUpdateDTO): Observable<WorkflowDTO> {
    return this.httpClient.put(`/automation/workflows/${workflowId}`, workflowUpdateDTO).pipe(map((it) => plainToInstance(WorkflowDTO, it)));
  }

  public findWorkflowById(id: string): Observable<WorkflowDTO> {
    return this.httpClient.get('/automation/workflows/' + id).pipe(map((it) => plainToInstance(WorkflowDTO, it)));
  }

  public findWorkflowByRevisionId(revisionId: string): Observable<WorkflowDTO> {
    return this.httpClient.get(`/automation/workflows/revisions/${revisionId}`).pipe(map((it) => plainToInstance(WorkflowDTO, it)));
  }

  public getWorkflows(filter: string, page: number, pageSize: number): Observable<PageDTO<WorkflowDTO>> {
    return this.httpClient
      .get('/automation/workflows', {
        params: new HttpParams().set('filter', filter).set('page', page).set('pageSize', pageSize),
      })
      .pipe(
        map((it: any) => {
          it.items = it.items.map((item: any) => plainToInstance(WorkflowDTO, item));
          return it as PageDTO<WorkflowDTO>;
        }),
      );
  }

  public deleteWorkflow(workflows: WorkflowDTO[]): Observable<void> {
    return this.httpClient.delete<void>('/automation/workflows', {
      params: new HttpParams().set('ids', workflows.map((it) => it.workflowId).join(',')),
    });
  }

  public enableWorkflow(workflowId: string, enableWorkflowDTO: EnableWorkflowDTO): Observable<void> {
    return this.httpClient.post<void>('/automation/workflows/' + workflowId + '/enable', enableWorkflowDTO);
  }

  public disableWorkflow(workflowId: string): Observable<void> {
    return this.httpClient.post<void>('/automation/workflows/' + workflowId + '/disable', {});
  }

  public getWorkflowRevisions(workflowId: string, page: number): Observable<PageDTO<WorkflowDTO>> {
    return this.httpClient
      .get(`/automation/workflows/${workflowId}/revisions`, {
        params: new HttpParams().set('page', page),
      })
      .pipe(
        map((it: any) => {
          it.items = it.items.map((item: any) => plainToInstance(WorkflowDTO, item));
          return it as PageDTO<WorkflowDTO>;
        }),
      );
  }
}
