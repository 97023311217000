import { Component, Input } from '@angular/core';

import { ElementDTO, DateElement } from '@app/form-template/domain';

@Component({
  selector: 'app-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss'],
})
export class DateComponent {
  @Input({ required: true })
  element: ElementDTO<DateElement>;
}
