import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';

import { FormTemplateConfigurationComponent } from './form-template-configuration.component';
import { PrimaryColorDirective } from '@ui/theme/primary-color.directive';

@NgModule({
  declarations: [FormTemplateConfigurationComponent],
  imports: [CommonModule, MatCardModule, MatButtonModule, TranslateModule, PrimaryColorDirective],
})
export class FormTemplateConfigurationModule {}
