import { catchError, forkJoin, map, Observable, of } from 'rxjs';

import { HasDisplayName, HasName } from '@shared/domain';

import { AutomationResourceTypeMetadata } from '@app/automation/domain';
import { AutomationResourceTypeService } from '@app/automation/services';

export function getResourceTypeWithLinksMetadata(
  resourceTypeService: AutomationResourceTypeService,
  resourceTypeMetadata: AutomationResourceTypeMetadata,
): Observable<(ResourceTypeWithLinksMetadata | undefined)[]> {
  const observables: Observable<ResourceTypeWithLinksMetadata | undefined>[] = [];

  observables.push(
    of({
      name: '',
      displayName: resourceTypeMetadata.displayName,
      metadata: resourceTypeMetadata,
    }),
  );

  resourceTypeMetadata.links.forEach((link) => {
    observables.push(
      resourceTypeService.getMetadata(link.resource).pipe(
        map((metadata) => ({
          name: link.name,
          displayName: link.displayName,
          metadata: metadata,
        })),
        catchError(() => of(undefined)),
      ),
    );
  });

  return forkJoin(observables);
}

export type HasMetadata = {
  metadata: AutomationResourceTypeMetadata;
};

export type ResourceTypeWithLinksMetadata = HasDisplayName & HasName & HasMetadata;
