import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { ImageElement } from '@app/form-template/domain/elementtypes/ImageElement';
import { SignatureElement } from '@app/form-template/domain/elementtypes/SignatureElement';
import { RadioElement } from '@app/form-template/domain/elementtypes/RadioElement';

import {
  TimeElement,
  ElementDTO,
  ElementType,
  DateElement,
  DescriptionElement,
  MultipleChoiceElement,
  NumberInputElement,
  TextAreaElement,
} from '@app/form-template/domain';
import { DrawingElement } from '@app/form-template/domain/elementtypes/DrawingElement';

@Injectable({
  providedIn: 'platform',
})
export class ElementDTOMapperService {
  constructor(private translateService: TranslateService) {}

  public createNewElementDTO(elementType: ElementType): ElementDTO<any> {
    switch (elementType) {
      case ElementType.multiple_choice:
        return new ElementDTO<any>(
          ElementType.multiple_choice,
          new MultipleChoiceElement('', false, [this.translateService.instant('form-templates.empty-option')], false),
        );
      case ElementType.date:
        return new ElementDTO<any>(ElementType.date, new DateElement('', false));
      case ElementType.time:
        return new ElementDTO<any>(ElementType.time, new TimeElement('', false));
      case ElementType.text_area:
        return new ElementDTO<any>(ElementType.text_area, new TextAreaElement('', false, 0));
      case ElementType.number_input:
        return new ElementDTO<any>(ElementType.number_input, new NumberInputElement('', false, 2, 0, 1));
      case ElementType.description:
        return new ElementDTO<any>(ElementType.description, new DescriptionElement(''));
      case ElementType.image:
        return new ElementDTO<any>(ElementType.image, new ImageElement('', false));
      case ElementType.signature:
        return new ElementDTO<any>(ElementType.signature, new SignatureElement('', false));
      case ElementType.radio:
        return new ElementDTO<any>(ElementType.radio, new RadioElement('', false, [this.translateService.instant('form-templates.empty-option')]));
      case ElementType.drawing:
        return new ElementDTO<any>(ElementType.drawing, new DrawingElement('', false));
    }
  }
}
