import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { BehaviorSubject, forkJoin, map, Observable, of, switchMap } from 'rxjs';

import { MetadataProviderType, PathType, ResourceTypeMetadata } from '@shared/domain';
import { Path, PathService } from '@shared/services';
import { ViewFilter } from '@app/robaws/domain/ViewFilter';
import { DynamicResourceTypeProvider } from '@app/shared/services/dynamic-resource-type.provider';
import { DynamicResourceTypeEntityProvider } from '@app/shared/services/dynamic-resource-type-entity-provider';
import { MaterialLoaderDirective } from '@ui/material-loader/material-loader.directive';
import { TranslateModule } from '@ngx-translate/core';
import { NgForOf, NgIf } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { DeleteIconComponent } from '@ui/delete-icon/delete-icon.component';

type FilterVO = {
  filter: ViewFilter;
  pathDisplayName: string;
  valueDisplayName: string;
};

@Component({
  selector: 'filter-list',
  templateUrl: 'filter-list.component.html',
  styleUrls: ['filter-list.component.scss'],
  standalone: true,
  imports: [MaterialLoaderDirective, TranslateModule, NgForOf, NgIf, MatIcon, DeleteIconComponent],
})
export class FilterListComponent implements OnInit, OnChanges {
  @Input({ required: true })
  public metadataProviderType: MetadataProviderType;
  @Input({ required: true })
  public rootMetaData: ResourceTypeMetadata;
  @Input({ required: true })
  public filters: ViewFilter[];
  @Input()
  public editable = true;
  @Output()
  public onEditFilterClicked = new EventEmitter<ViewFilter>();
  @Output()
  public onDeleteFilterClicked = new EventEmitter<ViewFilter>();
  protected filterVOs: FilterVO[] = [];
  protected $loading = new BehaviorSubject(false);
  private dynamicResourceTypeProvider: DynamicResourceTypeProvider;
  private dynamicResourceTypeEntityProvider: DynamicResourceTypeEntityProvider;

  constructor(private pathService: PathService) {}

  public ngOnInit(): void {
    this.dynamicResourceTypeProvider = new DynamicResourceTypeProvider(this.metadataProviderType);
    this.dynamicResourceTypeEntityProvider = new DynamicResourceTypeEntityProvider(this.metadataProviderType);

    this.updateFilterVOs();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['filters'] && !changes['filters'].firstChange) {
      this.updateFilterVOs();
    }
  }

  protected updateFilterVOs(): void {
    this.$loading.next(true);

    this.pathService
      .getPaths(this.dynamicResourceTypeProvider, this.rootMetaData.name)
      .pipe(
        switchMap((paths): Observable<FilterVO[]> => {
          const observables = [];

          for (const filter of this.filters) {
            observables.push(
              this.getValueName(paths, filter).pipe(
                map(
                  (valueName): FilterVO => ({
                    filter: filter,
                    pathDisplayName: this.getDisplayName(paths, filter.path),
                    valueDisplayName: valueName,
                  }),
                ),
              ),
            );
          }

          return observables.length == 0 ? of([]) : forkJoin(observables);
        }),
      )
      .subscribe((filterVOs) => {
        this.filterVOs = filterVOs;
        this.$loading.next(false);
      });
  }

  protected editFilter(filter: ViewFilter) {
    this.onEditFilterClicked.emit(filter);
  }

  protected deleteFilter(filter: ViewFilter) {
    this.onDeleteFilterClicked.emit(filter);
  }

  private getDisplayName(paths: Path[], pathStr: string): string {
    const path = paths.find((it) => it.path === pathStr);

    return path ? path.displayNameDeep : pathStr;
  }

  private getValueName(paths: Path[], filter: ViewFilter): Observable<string> {
    const path = paths.find((it) => it.path === filter.path);

    if (filter.value && path) {
      if (path.pathType === PathType.links && path.targetResourceType) {
        return this.dynamicResourceTypeEntityProvider.searchEntity(path.targetResourceType, filter.value).pipe(map((it) => it.label));
      } else if (path.possibleValues && path.possibleValues.length > 0) {
        const possibleValue = path.possibleValues.find((it) => it.value === filter.value);

        return of(possibleValue ? possibleValue.name : (filter.value ?? ''));
      }
    }

    return of(filter.value ?? '');
  }
}
