export type RobawsWindow = {
  isRobawsNgPage: () => boolean;
  _robaws: RobawsApplicationOptions;
  startLoader: () => void;
  stopLoader: () => void;
  moment: any;
};

export type RobawsApplicationOptions = {
  appVersion: string;
  env: string;
  robawsNgBaseUrl: string;
};

export const robawsWindow: RobawsWindow = window as any as RobawsWindow;
