<trigger-node-condition-builder *ngIf="this.currentCondition"
                                [metadataProviderType]="this.metadataProviderType"
                                [condition]="this.currentCondition!"
                                [data]="this.conditionBuilderData"
                                (cancelled)="this.handleCancel()"
                                (conditionBuildFinished)="this.handleConditionBuilderResult($event)" />

<div *ngIf="!this.currentCondition" class="condition-list-container">
  <trigger-node-condition-list (onConditionEdit)="editCondition($event)"
                               [rootMetaData]="data.resourceTypeMetadata"
                               [workflow]="data.workflow" />

  <button (click)="this.createCondition()" class="add-filter" mat-button primaryColor>
    {{ 'create-filter' | translate }}
  </button>
</div>
