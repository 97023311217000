<mat-expansion-panel class="attribute-panel" [expanded]="isSelected" hideToggle>
  <mat-expansion-panel-header>
    <mat-panel-title>
      <mat-icon class="attribute-icon">drag_indicator</mat-icon>
      {{ displayName }}
    </mat-panel-title>
    <mat-icon (click)="deleteAttribute.emit();" class="attribute-delete">delete</mat-icon>
  </mat-expansion-panel-header>
  <property-path-selector *ngIf="propertyPathBuilderData"
                          [metadataProviderType]="metadataProviderType"
                          (propertySelected)="handlePropertySelect($event)"
                          [data]="this.propertyPathBuilderData"
                          [includeLinks]="true"
                          [fetchDeep]="true"
                          [appendToBody]="true"
                          [spreadComplexTypes]="true" />
</mat-expansion-panel>
