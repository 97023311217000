<div class="search-wrapper" primaryDarkColor>
  <div class="input-wrapper">
    <input #searchInput
           (change)="onSearch($event)"
           class="search-input"
           [placeholder]="placeholder || 'search-placeholder' | translate"
           primaryColor
           type="text" />

    <mat-icon class="icon search-icon"
              fontIcon="search"
              textAccentColor />
    <div class="input-actions">
      <mat-icon (click)="clearQuery()"
                class="close-icon"
                fontIcon="close"
                textAccentColor />
  </div>

  <data-table-bulk-actions *ngIf="actions.length > 0"
                           [selectedItems]="selectedItems"
                           [actions]="actions"></data-table-bulk-actions>
</div>
