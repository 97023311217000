import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { detectInputTypeFromPath, InputType } from '@shared/helpers';
import { PathService } from '@shared/services';
import { MetadataProviderType, MetadataWithPath, PathType, ResourceTypeMetadata, ResourceTypePropertyPossibleValue } from '@shared/domain';

import { WorkflowNodeData } from '@app/automation/components/nodes/workflow-node/workflow-node-data';
import { RobawsResourcePatch, UpdateRobawsResourceNode } from '@app/automation/domain';
import { AUTOMATION_RESOURCE_TYPE_MAPPER, AutomationResourceTypeService } from '@app/automation/services';
import { DynamicTypeInputComponent } from '@app/shared/components/inputs/dynamic-type-input';
import { DynamicResourceTypeProvider } from '@app/shared/services/dynamic-resource-type.provider';

type UpdateRobawsResourceNodeConfigurerData = {
  inputType: InputType;
  possibleValues?: ResourceTypePropertyPossibleValue[];
} & MetadataWithPath;

@Component({
  selector: 'update-robaws-resource-node-configurer',
  templateUrl: 'update-robaws-resource-node-configurer.component.html',
  styleUrls: ['update-robaws-resource-node-configurer.component.scss'],
})
export class UpdateRobawsResourceNodeConfigurerComponent {
  @Output()
  public patchChanged: EventEmitter<RobawsResourcePatch> = new EventEmitter<RobawsResourcePatch>();

  protected metadataProviderType: MetadataProviderType = 'AUTOMATION';

  protected patchNode: RobawsResourcePatch;
  protected pathBuilderData?: UpdateRobawsResourceNodeConfigurerData;
  protected currentMetadata: ResourceTypeMetadata;
  protected expectedMetadata: ResourceTypeMetadata;
  protected propertyPathBuilderData: MetadataWithPath;

  @ViewChild(DynamicTypeInputComponent)
  protected dynamicTypeInput?: DynamicTypeInputComponent;

  private dynamicResourceTypeProvider = new DynamicResourceTypeProvider(this.metadataProviderType);

  constructor(
    private automationResourceTypeService: AutomationResourceTypeService,
    private pathService: PathService,
  ) {}

  private _workflowNodeData: WorkflowNodeData<UpdateRobawsResourceNode>;

  @Input()
  public get workflowNodeData(): WorkflowNodeData<UpdateRobawsResourceNode> {
    return this._workflowNodeData;
  }

  public set workflowNodeData(workflowNodeData: WorkflowNodeData<UpdateRobawsResourceNode>) {
    this._workflowNodeData = workflowNodeData;

    if (workflowNodeData.currentNode.configuration.patches.length === 0) {
      workflowNodeData.currentNode.configuration.patches.push(new RobawsResourcePatch(null, null));
    }

    this.patchNode = workflowNodeData.currentNode.configuration.patches[0];
    this.updatePathBuilderData();
  }

  protected handleValueChange(value: string): void {
    this.patchNode.value = value;
    this.firePatchChange();
  }

  protected handlePropertySelect(path: string) {
    this.patchNode.path = path;
    this.patchNode.value = '';
    this.updatePathBuilderData();
    this.firePatchChange();
  }

  protected firePatchChange(): void {
    this.patchChanged.emit(this.patchNode);
  }

  private updatePropertyPathBuilderData(): void {
    if (this.pathBuilderData) {
      this.propertyPathBuilderData = {
        metadata: this.pathBuilderData.metadata,
        path: this.pathBuilderData.path,
      };
    }
  }

  private updatePathBuilderData(): void {
    if (this.patchNode.path) {
      this.pathService
        .getPath(this.dynamicResourceTypeProvider, this.workflowNodeData.resourceTypeMetadata.name, this.patchNode.path)
        .subscribe((path) => {
          if (path) {
            this.currentMetadata = path.parentMetadata;

            if (path.pathType === PathType.links) {
              if (this.dynamicTypeInput) {
                this.dynamicTypeInput.clear();
              }

              if (path.targetResourceType) {
                this.automationResourceTypeService.getMetadata(path.targetResourceType).subscribe((metadata) => {
                  this.expectedMetadata = AUTOMATION_RESOURCE_TYPE_MAPPER.mapMetadata(metadata);

                  this.pathBuilderData = {
                    metadata: AUTOMATION_RESOURCE_TYPE_MAPPER.mapMetadata(this.workflowNodeData.resourceTypeMetadata),
                    path: path.path,
                    inputType: 'COMBOBOX',
                  };
                });
              } else {
                this.pathBuilderData = {
                  metadata: AUTOMATION_RESOURCE_TYPE_MAPPER.mapMetadata(this.workflowNodeData.resourceTypeMetadata),
                  path: path.path,
                  inputType: 'COMBOBOX',
                };
              }
            } else {
              this.pathBuilderData = {
                metadata: AUTOMATION_RESOURCE_TYPE_MAPPER.mapMetadata(this.workflowNodeData.resourceTypeMetadata),
                path: path.path,
                inputType: detectInputTypeFromPath(path),
                possibleValues: path.possibleValues,
              };
            }

            this.updatePropertyPathBuilderData();
          } else {
            // TODO: how should we handle if a path is not found? (previously we just did nothing, but wanted to at least mark this)
          }
        });
      return;
    }

    this.pathBuilderData = {
      metadata: AUTOMATION_RESOURCE_TYPE_MAPPER.mapMetadata(this.workflowNodeData.resourceTypeMetadata),
      path: '',
      inputType: 'STRING',
    };
    this.updatePropertyPathBuilderData();
  }
}
