import { Component } from '@angular/core';
import { EnableWorkflowDTO } from '@app/automation/domain';
import { MatDialogRef } from '@angular/material/dialog';
import { EnableWorkflowDialogResult } from '@app/automation/components/workflows/edit-workflow/enable-workflow-dialog/enable-workflow-dialog-result';

@Component({
  selector: 'enable-workflow-dialog',
  templateUrl: 'enable-workflow-dialog.component.html',
  styleUrls: ['enable-workflow-dialog.component.scss'],
})
export class EnableWorkflowDialogComponent {
  protected enableWorkflowDTO: EnableWorkflowDTO = new EnableWorkflowDTO();

  constructor(public dialogRef: MatDialogRef<EnableWorkflowDialogComponent>) {}

  protected enableWorkflow(): void {
    this.dialogRef.close({
      enableWorkflowDTO: this.enableWorkflowDTO,
    } as EnableWorkflowDialogResult);
  }
}
