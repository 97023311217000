import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { RobawsNgConfigurerCardService } from '@shared/services';

import { AutomationResourceTypeMetadata } from '@app/automation/domain';

import { AddNodeConfigurerComponent, CreateNewNodeRequest } from './add-node-configurer/add-node-configurer.component';

@Component({
  selector: 'add-node',
  templateUrl: 'add-node.component.html',
  styleUrls: ['add-node.component.scss'],
})
export class AddNodeComponent {
  @Output()
  public createNewNodeRequest: EventEmitter<CreateNewNodeRequest> = new EventEmitter<CreateNewNodeRequest>();

  @Input()
  public resourceTypeMetadata: AutomationResourceTypeMetadata;

  constructor(
    private robawsNgConfigurerCardService: RobawsNgConfigurerCardService,
    private translateService: TranslateService,
  ) {}

  protected createNewNode(createNewNodeRequest: CreateNewNodeRequest): void {
    this.createNewNodeRequest.emit(createNewNodeRequest);
  }

  protected openNodeTypeSelector(): void {
    const currentConfigurer = this.robawsNgConfigurerCardService.createAndOpenConfigurerCard(AddNodeConfigurerComponent, {
      title: this.translateService.instant('choose_action'),
      inputs: {
        resourceTypeMetadata: this.resourceTypeMetadata,
      },
    });

    currentConfigurer.contentRef.instance.createNewNodeRequest.subscribe((createNewNodeRequest: CreateNewNodeRequest) => {
      this.createNewNode(createNewNodeRequest);
      this.robawsNgConfigurerCardService.closeConfigurerCard(currentConfigurer.uuid);
    });
  }
}
