import { Component, DestroyRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

import { DataType, MetadataWithPath, PathType } from '@shared/domain';
import { Path, PathService } from '@shared/services';
import { DynamicResourceTypeProvider } from '@app/shared/services/dynamic-resource-type.provider';
import { RobawsOperatorService } from '@app/robaws/services';
import { getLinkRobawsOperators, getPossibleValuesRobawsOperators, RobawsOperator } from '@app/robaws/domain/RobawsOperator';
import { TranslateService } from '@ngx-translate/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';

type AvailableOperatorVO = {
  operator: RobawsOperator;
  label: string;
};

@Component({
  selector: 'robaws-operator-selector',
  templateUrl: 'robaws-operator-selector.component.html',
  styleUrls: ['robaws-operator-selector.component.scss'],
  standalone: true,
  imports: [NgSelectModule, FormsModule],
})
export class RobawsOperatorSelectorComponent implements OnInit, OnChanges {
  @Input({ required: true })
  public metadataWithPath: MetadataWithPath;

  @Input({ required: true })
  public operator: RobawsOperator;

  @Output()
  public operatorChanged = new EventEmitter<RobawsOperator>();

  protected availableOperators: AvailableOperatorVO[] = [];

  private currentPath?: Path;
  private dynamicResourceTypeProvider: DynamicResourceTypeProvider = new DynamicResourceTypeProvider('ROBAWS');

  constructor(
    private translateService: TranslateService,
    private robawsOperatorService: RobawsOperatorService,
    private pathService: PathService,
    private destroyRef: DestroyRef,
  ) {}

  public ngOnInit() {
    this.updatePath();
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes['metadataWithPath']) {
      this.updatePath();
    }
  }

  protected handleOperatorSelection(operator: RobawsOperator): void {
    this.operator = operator;
    this.operatorChanged.emit(operator);
  }

  private updatePath(): void {
    this.pathService
      .getPath(this.dynamicResourceTypeProvider, this.metadataWithPath.metadata.name, this.metadataWithPath.path, true)
      .subscribe((path) => {
        this.currentPath = path;
        this.fetchAvailableOperations();
      });
  }

  private fetchAvailableOperations() {
    if (!this.currentPath) {
      return;
    }

    const currentPath = this.currentPath;

    if (currentPath.pathType === PathType.links) {
      this.availableOperators = getLinkRobawsOperators().map((operator) => ({
        operator: operator.operator,
        label: this.translateService.instant('operation.' + operator.operator),
      }));
    } else if (currentPath.pathType === PathType.attributes && currentPath.possibleValues && currentPath.possibleValues.length > 0) {
      // attributes with possibleValues are likely enums and so should only support limited operations, extraFields with possibleValues are not a problem
      this.availableOperators = getPossibleValuesRobawsOperators().map((operator) => ({
        operator: operator.operator,
        label: this.translateService.instant('operation.' + operator.operator),
      }));
    } else {
      this.robawsOperatorService
        .getSupportedOperations(currentPath.dataType ?? DataType.TEXT)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((availableOperators) => {
          this.availableOperators = availableOperators.map((availableOperator) => ({
            operator: availableOperator.robawsOperator,
            label: this.translateService.instant('operation.' + availableOperator.robawsOperator),
          }));
        });
    }
  }
}
