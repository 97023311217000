<workflow-node (nodeClick)="this.openConfigurer()"
               [allowPointerCursor]="actionMetadatas.length > 1"
               [workflowNodeData]="workflowNodeData">

  <span slot="title">{{ this.getActionDisplayName() }}</span>

  <div slot="content">
    <p [innerHTML]="this.translationHelper.translateOrDefault('nodes.action.' + this.workflowNodeData.currentNode.configuration.action, this.getActionDisplayName(), {
        resourceType: this.getResourceTypeDisplayName()
    })" class="description"></p>
  </div>
</workflow-node>
