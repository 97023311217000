import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { DialogModule } from 'primeng/dialog';

import { DataTableModule } from '@ui/data-table';

import { WorkflowExecutionMessagesComponent } from './workflow-execution-messages/workflow-execution-messages.component';
import { ListWorkflowExecutionsComponent } from './list-workflow-executions.component';

@NgModule({
  imports: [CommonModule, DataTableModule, MatSnackBarModule, TranslateModule, DialogModule],
  declarations: [ListWorkflowExecutionsComponent, WorkflowExecutionMessagesComponent],
})
export class ListWorkflowExecutionsModule {}
