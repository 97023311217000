import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';

import { AutomationResourceTypeMetadata, WorkflowNodeType } from '@app/automation/domain';
import { AutomationResourceTypeService } from '@app/automation/services';

import * as NodeProviders from '../node-provider/providers';
import { NodeProvider, NodeProviderContext } from '../node-provider/node-provider';

export type WorkflowNodeCategoryVO = {
  name: string;
  nodes: WorkflowNodeTypeVO[];
};

export enum WorkflowNodeCategoryType {
  ACTION = 'actions',
  CREATE = 'create',
  OTHER = 'other',
}

export type WorkflowNodeTypeVO = {
  type: WorkflowNodeType;
  category: WorkflowNodeCategoryType;
  icon: string;
  color: string;
  priority: number;
  name?: string;
  data?: any;
};

export type CreateNewNodeRequest = {
  nodeType: WorkflowNodeType;
  data: any;
};

@Component({
  selector: 'add-node-configurer',
  templateUrl: 'add-node-configurer.component.html',
  styleUrls: ['add-node-configurer.component.scss'],
})
export class AddNodeConfigurerComponent implements OnInit {
  @Output()
  public createNewNodeRequest: EventEmitter<CreateNewNodeRequest> = new EventEmitter<CreateNewNodeRequest>();

  @Input()
  public resourceTypeMetadata: AutomationResourceTypeMetadata;

  protected categories: WorkflowNodeCategoryVO[] = [];
  protected isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private translateService: TranslateService,
    private automationResourceTypeService: AutomationResourceTypeService,
  ) {}

  ngOnInit(): void {
    this.isLoading$.next(true);
    const nodeProviderContext: NodeProviderContext = {
      automationResourceTypeService: this.automationResourceTypeService,
      metadata: this.resourceTypeMetadata,
    };

    const nodeProviders: NodeProvider[] = Object.values(NodeProviders);
    const nodesObservables: Observable<WorkflowNodeTypeVO[]>[] = [];

    for (const provider of nodeProviders) {
      nodesObservables.push(provider(nodeProviderContext));
    }

    forkJoin(nodesObservables).subscribe((nodes) => {
      const flattenedNodes = nodes.flat().sort((a, b) => {
        const sortNumber = a.priority - b.priority;

        if (sortNumber === 0) {
          if (!a.name || !b.name) {
            return 0;
          }
          return a.name.localeCompare(b.name);
        }
        return sortNumber;
      });

      for (const categoryType of Object.values(WorkflowNodeCategoryType)) {
        const categoryNodes = flattenedNodes.filter((node) => node.category === categoryType);

        if (categoryNodes.length > 0) {
          this.categories.push({
            name: this.translateService.instant(`nodeCategoryType.${categoryType}`),
            nodes: categoryNodes,
          });
        }
      }

      this.isLoading$.next(false);
    });
  }

  protected createNewNode(nodeType: WorkflowNodeType, data: any): void {
    this.createNewNodeRequest.emit({
      nodeType,
      data: data ?? {},
    });
  }
}
