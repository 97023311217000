<label>{{'nodes.format-data.expression' | translate}}</label>
<div id="codeMirror"></div>
<p *ngIf="issue" style="color: red">{{ issue }}</p>
<div *ngIf="exampleData.length > 0">
  <label>{{ 'nodes.format-data.example-data'|translate }}</label>
  <table>
    <thead>
    <tr>
      <td style="font-weight: bold">{{ 'nodes.format-data.variable.name' | translate }}</td>
      <td style="font-weight: bold">{{ 'nodes.format-data.variable.value' | translate }}</td>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let exampleDataItem of exampleData">
      <td>{{exampleDataItem.label}}</td>
      <td style="font-style: italic"><input (blur)="evaluate()" [(ngModel)]="exampleDataItem.value" type="text"></td>
    </tr>
    </tbody>
  </table>
</div>
<div *ngIf="exampleOutputVisible">
  <label>{{ 'nodes.format-data.output' | translate }}</label><br>
  {{exampleOutput}}
</div>

