export type TableColumnData = {
  content: string;
  link?: () => void;
};

export class TableColumn<T> {
  constructor(
    public property: string,
    public display: string,
    public component: any,
    public dataSupplier: (obj: T) => TableColumnData,
    public sortable = false,
    public type: 'attributes' | 'extraFields' | 'links' = 'attributes',
    public parentId = '',
    public parentName = '',
  ) {}
}

export type ConfiguredColumn = {
  name: string;
  type: string;
  displayName: string;
  active?: boolean;
  parentName?: string;
};
