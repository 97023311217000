<div class="filters-container">
  <div class="operator-switcher" *ngIf="!this.isEmpty() && !this.isParentFilterGroup">
    <span class="operator-switcher-border"></span>

    <p class="operator" (click)="this.switchOperator()">
      <span>{{ this.viewFilterGroup.operator.toLowerCase() | translate }}</span>

      <mat-icon fontIcon="cached" />
    </p>
  </div>

  <div class="filter-editors"
       cdkDropList
       [attr.data-parent-group]="this.isParentFilterGroup"
       (cdkDropListDropped)="dropFilter($event)">

    @for (filter of this.viewFilterGroup.filters; track filter) {
      <div class="view-filter-editor" cdkDrag style="display: flex; width: 100%; margin-top: 10px; align-items: center;">
        <!-- Setting style here to not have broken drag-n-drop styling -->
        <mat-icon fontIcon="drag_indicator"
                  class="drag-icon"
                  cdkDragHandle />

        <view-filter-editor [viewContentType]="this.viewContentType"
                            [metadata]="this.metadata"
                            [viewFilter]="filter" />

        <delete-icon [askConfirmation]="false"
                     (deleteAccepted)="this.deleteFilter(filter)"
                     class="delete-icon" />
      </div>
    }

    @for (filterGroup of viewFilterGroup.filterGroups; track filterGroup) {
      <view-filter-group-editor [viewContentType]="this.viewContentType"
                                [metadata]="this.metadata"
                                [viewFilterGroup]="filterGroup"
                                (filterGroupDeleted)="this.onFilterGroupDeleted(filterGroup)" />

      @if (this.isParentFilterGroup) {
        <div class="filter-group-divider">
          <hr />
          <span>{{ this.viewFilterGroup.operator.toLowerCase() | translate }}</span>
          <hr />
        </div>
      }
    }
  </div>
</div>

<div class="add-filters">
  @if (!this.isParentFilterGroup) {
    <button class="add-filter" (click)="this.addFilter()">
      <mat-icon fontIcon="add" />

      <span>{{ 'overviews.filters.add-filter' | translate }}</span>
    </button>
  }
  <button class="add-filter-group" (click)="this.addFilterGroup()">
    <mat-icon fontIcon="add" />

    <span>{{ 'overviews.filters.add-group' | translate }}</span>
  </button>

  @if (this.isParentFilterGroup && !this.isEmpty()) {
    <button class="clear-all" (click)="this.clearAll()">
      <span>{{ 'overviews.filters.delete-all' | translate }}</span>
    </button>
  }
</div>
