import { Component } from '@angular/core';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

@Component({
  templateUrl: 'material-loader-overlay.component.html',
  styleUrls: ['material-loader-overlay.component.scss'],
  inputs: ['posX', 'posY', 'height', 'width', 'position'],
  standalone: true,
  imports: [MatProgressSpinner],
})
export class MaterialLoaderOverlayComponent {
  public posX = 0;
  public posY = 0;
  public height = '100vh';
  public width = '100vw';
  public position = 'fixed';
}
