<div style="display: none">
  <span slot="title">{{ 'triggerNodes.title' | translate }}</span>
  <div slot="content">
    <ng-container *ngIf="workflowNodeData">
      <trigger-node-condition-view *ngIf="conditions.length; else defaultContent" [editable]="false"
                                   [rootMetaData]="workflowNodeData.resourceTypeMetadata"
                                   [workflow]="workflow" />
      <ng-template #defaultContent>
        <p [innerHTML]="'triggerNodes.empty' | translate: { value: workflowNodeData.resourceTypeMetadata.displayName }"
           class="description">
        </p>
      </ng-template>
    </ng-container>
  </div>
</div>

