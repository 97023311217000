import { Component, Input } from '@angular/core';

import { WorkflowExecutionMessage } from '@app/automation/domain';

@Component({
  selector: 'workflow-execution-messages',
  templateUrl: 'workflow-execution-messages.component.html',
  styleUrls: ['workflow-execution-messages.scss'],
})
export class WorkflowExecutionMessagesComponent {
  @Input()
  data: WorkflowExecutionMessage[] = [];
}
