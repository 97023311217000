import { Component, Input } from '@angular/core';
import { ElementDTO } from '@app/form-template/domain/ElementDTO';
import { TranslateService } from '@ngx-translate/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { RadioElement } from '@app/form-template/domain/elementtypes/RadioElement';

@Component({
  selector: 'app-radio',
  standalone: false,
  templateUrl: './radio.component.html',
  styleUrl: './radio.component.scss',
})
export class RadioComponent {
  @Input({ required: true })
  element: ElementDTO<RadioElement>;

  constructor(private translateService: TranslateService) {}

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.element.configuration.answerOptions, event.previousIndex, event.currentIndex);
  }

  public addOption() {
    this.element.configuration.answerOptions.push(this.translateService.instant('form-templates.empty-option'));
  }

  public deleteOption(index: number): void {
    this.element.configuration.answerOptions.splice(index, 1);
  }

  selectText(input: HTMLInputElement): void {
    input.select();
  }

  selectTitle(event: MouseEvent): void {
    event.stopPropagation();
  }

  trackIndex(index: number) {
    return index;
  }
}
