import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CalendarModule } from 'primeng/calendar';
import { FormsModule } from '@angular/forms';
import { NgTemplateOutlet } from '@angular/common';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'date-time-input',
  templateUrl: 'date-time-input.component.html',
  standalone: true,
  imports: [CalendarModule, FormsModule, NgTemplateOutlet],
})
export class DateTimeInputComponent implements OnInit {
  @Input()
  public label?: string | undefined | null;

  @Input()
  public value: string | undefined | null;

  @Input()
  public showLabel: boolean;

  @Output()
  public valueChange: EventEmitter<string> = new EventEmitter<string>();

  protected dateValue?: Date;
  protected uid: string = uuidv4();

  public ngOnInit(): void {
    if (this.value) {
      this.dateValue = new Date(this.value);
    }
  }

  protected onValueChange(event: Date): void {
    this.dateValue = event;

    this.value = event ? event.toISOString() : '';
    this.valueChange.emit(this.value);
  }
}
