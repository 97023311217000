import { Injector, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';

import { registerWebComponent } from '@shared/helpers';

import { DataExportConfigurationService, RobawsResourceTypeService } from '@app/robaws/services';

import { DataExportResourceTypeComponent } from './data-export-resource-type/data-export-resource-type.component';
import { DataExportResourceTypeAttributeComponent } from './data-export-resource-type-attribute/data-export-resource-type-attribute.component';
import { DataExportConfigurationComponent } from './data-export-configuration.component';
import { MatButton } from '@angular/material/button';
import { PropertyPathSelectorComponent } from '@shared/components/property-path-selector';
import { MaterialLoaderDirective } from '@ui/material-loader/material-loader.directive';
import { PrimaryColorDirective } from '@ui/theme/primary-color.directive';
import { TextPrimaryDarkColorDirective } from '@ui/theme/text-primary-dark-color.directive';
import { PrimaryDarkColorDirective } from '@ui/theme/primary-dark-color.directive';

@NgModule({
  imports: [
    CommonModule,
    MatCardModule,
    MatExpansionModule,
    MatIconModule,
    MatSlideToggleModule,
    FormsModule,
    DragDropModule,
    NgSelectModule,
    MatTooltipModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    TranslateModule,
    MatButton,
    PropertyPathSelectorComponent,
    MaterialLoaderDirective,
    PrimaryColorDirective,
    TextPrimaryDarkColorDirective,
    PrimaryDarkColorDirective,
  ],
  declarations: [DataExportConfigurationComponent, DataExportResourceTypeComponent, DataExportResourceTypeAttributeComponent],
  exports: [DataExportConfigurationComponent],
  providers: [DataExportConfigurationService, RobawsResourceTypeService],
})
export class DataExportConfigurationModule {
  constructor(injector: Injector) {
    registerWebComponent(injector, 'data-export-configuration', DataExportConfigurationComponent);
  }
}
