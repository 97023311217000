import { WorkflowNode } from './WorkflowNode';
import { NodeDTO } from './NodeDTO';

export class UpdateRobawsResourceNode extends WorkflowNode {
  constructor(
    public patches: RobawsResourcePatch[],
    public override nextNode?: NodeDTO<any>,
  ) {
    super(nextNode);
  }
}

export class RobawsResourcePatch {
  constructor(
    public path: string | null,
    public value: string | null,
  ) {}
}
