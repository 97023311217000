import { Component, DestroyRef, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { EMPTY, MetadataProviderType, PageDTO, ResourceTypeMetadata } from '@shared/domain';
import { DynamicResourceTypeProvider } from '@app/shared/services/dynamic-resource-type.provider';
import { OverlayPanel, OverlayPanelModule } from 'primeng/overlaypanel';
import { View, ViewFilter } from '@app/robaws/domain';
import { RobawsConstants } from '@app/robaws/domain/RobawsConstants';
import { HasValueComponent } from '@shared/components/has-value.component';
import { ViewContentType } from '@app/robaws/domain/ViewContentType';
import { ViewService } from '@app/robaws/services/view.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatIcon } from '@angular/material/icon';
import { FilterBuilderComponent } from '@app/robaws/components/standalone-filter-builder/filter-builder/filter-builder.component';
import { NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MaterialLoaderDirective } from '@ui/material-loader/material-loader.directive';
import { ViewFilterGroupService } from '@app/robaws/services/view-filter-group.service';
import { MatButton } from '@angular/material/button';
import { PrimaryColorDirective } from '@ui/theme/primary-color.directive';
import { TableLazyLoadEvent, TableModule } from 'primeng/table';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AlertHelper } from '@shared/helpers';
import { TreeSelectComponent } from '@ui/tree-select/tree-select.component';
import { DeleteIconComponent } from '@ui/delete-icon/delete-icon.component';

@Component({
  selector: 'standalone-filter-builder',
  templateUrl: 'standalone-filter-builder.component.html',
  styleUrls: ['standalone-filter-builder.component.scss'],
  standalone: true,
  imports: [
    OverlayPanelModule,
    MatIcon,
    FilterBuilderComponent,
    TreeSelectComponent,
    NgIf,
    FormsModule,
    MaterialLoaderDirective,
    MatButton,
    PrimaryColorDirective,
    TableModule,
    TranslateModule,
    DeleteIconComponent,
  ],
})
export class StandaloneFilterBuilderComponent extends HasValueComponent<string> implements OnInit {
  @Input()
  public module: string;
  @Input()
  public viewContentType: ViewContentType;

  @Output()
  public valueChanged = new EventEmitter<void>();

  protected metadataProviderType: MetadataProviderType = 'ROBAWS';
  protected metadata: ResourceTypeMetadata;
  protected opened = false;
  @ViewChild('overlayPanel')
  protected overlayPanel: OverlayPanel;
  protected filters: ViewFilter[] = [];
  protected isSelectingView = false;
  @ViewChild(FilterBuilderComponent)
  protected filterBuilderComponent: FilterBuilderComponent;
  protected views: PageDTO<View> = EMPTY;
  protected currentPage = 0;
  private dynamicResourceTypeProvider: DynamicResourceTypeProvider;

  constructor(
    protected override viewContainerRef: ViewContainerRef,
    protected override elementRef: ElementRef,
    private viewService: ViewService,
    private viewFilterGroupService: ViewFilterGroupService,
    private destroyRef: DestroyRef,
    private alertHelper: AlertHelper,
    private translateService: TranslateService,
  ) {
    super(viewContainerRef, elementRef);
  }

  private _currentView: View;

  protected get currentView(): View {
    return this._currentView;
  }

  protected set currentView(view: View) {
    this._currentView = view;
    this.filters = view.filterGroup.filters ?? [];
  }

  public ngOnInit(): void {
    this.dynamicResourceTypeProvider = new DynamicResourceTypeProvider(this.metadataProviderType);
    this.dynamicResourceTypeProvider.getMetadata(this.module).subscribe((metadata) => {
      this.metadata = metadata;
    });
    this.viewService
      .getViewsWithContentType(this.viewContentType, '', 0, 1, 'lastUsedAt:desc')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((views) => {
        if (views.size === 0) {
          this.createView();
        } else {
          this.handleViewSelect(views.items[0]);
          this.valueChanged.emit(); // trigger initial load
        }
      });
  }

  override setValue(value: string, fireChangeEvent: boolean): void {
    this.filters = JSON.parse(value);

    if (fireChangeEvent) {
      this.valueChanged.emit();
    }
  }

  override getValue(): string {
    return JSON.stringify(this.filters);
  }

  protected fetchViews(event: TableLazyLoadEvent) {
    this.loadViews((event.first ?? 0) / (event.rows ?? 15));
  }

  protected createView(): void {
    // this.viewService
    //   .createView(this.viewContentType)
    //   .pipe(takeUntilDestroyed(this.destroyRef))
    //   .subscribe((view) => {
    //     this.handleViewSelect(view);
    //   });
  }

  @HostListener('document:' + RobawsConstants.STANDALONE_FILTER_OVERLAY_UPDATE)
  protected onFilterViewUpdate(): void {
    this.realignOverlay();
  }

  protected handleClick(event: MouseEvent) {
    this.opened = !this.opened;

    if (this.opened) {
      this.overlayPanel.show(event);
    } else {
      this.overlayPanel.hide();
    }
  }

  protected onHide(): void {
    this.opened = false;
  }

  protected filtersChanged(filters: ViewFilter[]): void {
    this.filters = filters;
    this.realignOverlay();
  }

  protected searchClicked(): void {
    this.valueChanged.emit();
    this.overlayPanel.hide();

    if (this.currentView) {
      this.viewService.updateLastUsedAt(this.currentView.id, new Date()).pipe(takeUntilDestroyed(this.destroyRef)).subscribe();

      // TODO: restore or remove
      // this.viewFilterGroupService
      //   .updateViewFilterGroupFilters(this.currentView.filterGroup.id, this.filters)
      //   .pipe(takeUntilDestroyed(this.destroyRef))
      //   .subscribe(() => {
      //     this.currentView.filterGroup.filters = this.filters;
      //   });
    }
  }

  protected saveViewName(): void {
    // this.viewService.updateViewName(this.currentView.id, this.currentView.name).pipe(takeUntilDestroyed(this.destroyRef)).subscribe();
  }

  protected toggleViewSelector(): void {
    this.isSelectingView = !this.isSelectingView;
  }

  protected handleViewSelect(view: View): void {
    this.isSelectingView = false;
    this.currentView = view;
  }

  protected handleViewListPrevious(): void {
    this.isSelectingView = false;
  }

  protected deleteView(view: View): void {
    this.viewService.deleteView(view.id).subscribe(() => {
      this.loadViews(this.currentPage);
    });
  }

  private realignOverlay(): void {
    setTimeout(() => {
      if (this.overlayPanel && this.overlayPanel.overlayVisible) {
        this.overlayPanel.align();
      }
    }, 100);
  }

  private loadViews(page = 0): void {
    this.currentPage = page;
    this.viewService.getViewsWithContentType(this.viewContentType, '', page, 5, 'name:asc').subscribe({
      next: (views) => {
        this.views = views;

        if (page > 0 && views.size === 0) {
          // this is in case an item is deleted and was alone on it's page
          this.loadViews(page - 1);
        }

        this.realignOverlay();
      },
    });
  }
}
