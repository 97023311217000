import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { TooltipModule } from 'primeng/tooltip';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { ConfirmationService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'delete-icon',
  templateUrl: 'delete-icon.component.html',
  styleUrls: ['delete-icon.component.scss'],
  standalone: true,
  imports: [MatIcon, TooltipModule, ConfirmPopupModule],
  providers: [ConfirmationService],
})
export class DeleteIconComponent {
  @Input()
  public askConfirmation: boolean = true;

  @Input()
  public tooltip: string = '';

  @Input()
  public confirmationMessage: string = '';

  @Input()
  public styleClass: string = '';

  @Output()
  public deleteAccepted: EventEmitter<MouseEvent> = new EventEmitter();

  @Output()
  public deleteRejected: EventEmitter<MouseEvent> = new EventEmitter();

  constructor(
    private confirmationService: ConfirmationService,
    private translateService: TranslateService,
  ) {}

  protected handleDeleteClick(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();

    if (this.askConfirmation) {
      this.confirmationService.confirm({
        target: event.target as EventTarget,
        message: this.confirmationMessage || this.translateService.instant('confirmation.title'),
        acceptButtonStyleClass: 'p-button-danger p-button-sm',
        rejectLabel: this.translateService.instant('no'),
        acceptLabel: this.translateService.instant('yes'),
        accept: () => {
          this.confirmationService.close();

          // ugly hack to ensure that the event is emitted after the confirmation dialog is closed
          setTimeout(() => {
            this.deleteAccepted.emit(event);
          }, 5);
        },
        reject: () => {
          this.confirmationService.close();

          // ugly hack to ensure that the event is emitted after the confirmation dialog is closed
          setTimeout(() => {
            this.deleteRejected.emit(event);
          }, 5);
        },
      });
    } else {
      this.deleteAccepted.emit(event);
    }
  }
}
