import { Component, DestroyRef, EventEmitter, Injector, Input, OnChanges, OnInit, Output, SimpleChanges, Type } from '@angular/core';

import { DataType, MetadataWithPath, PathType, ResourceTypeMetadata, ResourceTypePropertyPossibleValue } from '@shared/domain';
import { Path, PathService } from '@shared/services';
import { DynamicResourceTypeProvider } from '@app/shared/services/dynamic-resource-type.provider';
import { getLinkOperators } from '@app/shared/domain/Operator';
import * as AutomationOperationBuilders from './builders';
import { detectInputTypeFromPath, InputType } from '@shared/helpers';
import { AutomationOperatorService } from '@app/automation/services';
import { AutomationOperator } from '@app/automation/domain/AutomationOperator';
import { DynamicComponent, DynamicIoDirective } from 'ng-dynamic-component';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { MatButton } from '@angular/material/button';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TextPrimaryColorDirective } from '@ui/theme/text-primary-color.directive';
import { PrimaryColorDirective } from '@ui/theme/primary-color.directive';

type AvailableOperatorVO = {
  operator: AutomationOperator;
  needsValue: boolean;
  inputType: InputType;
};

export type AutomationOperationData = {
  operator?: AutomationOperator;
  value?: string;
};

@Component({
  selector: 'automation-operation-builder',
  templateUrl: 'automation-operation-builder.component.html',
  styleUrls: ['automation-operation-builder.component.scss'],
  standalone: true,
  imports: [
    DynamicComponent,
    DynamicIoDirective,
    MatIconModule,
    TranslateModule,
    CommonModule,
    MatButton,
    TextPrimaryColorDirective,
    PrimaryColorDirective,
  ],
})
export class AutomationOperationBuilderComponent implements OnInit, OnChanges {
  @Input({ required: true })
  public data: MetadataWithPath & AutomationOperationData;

  @Output()
  public operationConfigured: EventEmitter<AutomationOperationData> = new EventEmitter<AutomationOperationData>();

  @Output()
  public cancelled: EventEmitter<void> = new EventEmitter<void>();

  protected availableOperators: AvailableOperatorVO[] = [];

  protected possibleValues: ResourceTypePropertyPossibleValue[];
  protected currentResourceTypeMetadata: ResourceTypeMetadata;
  protected expectedMetadata: ResourceTypeMetadata;
  protected outputHandlers = {
    valueChange: (value: unknown) => this.onValueChange(value as string),
  };
  private currentPath: Path;
  private dynamicResourceTypeProvider: DynamicResourceTypeProvider = new DynamicResourceTypeProvider('AUTOMATION');

  constructor(
    protected injector: Injector,
    private automationOperatorService: AutomationOperatorService,
    private pathService: PathService,
    private destroyRef: DestroyRef,
  ) {}

  public ngOnInit() {
    this.updatePath();
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes['data']) {
      this.updatePath();
    }
  }

  protected handleOperatorSelection(operator: AutomationOperator): void {
    if (this.data.operator !== operator) {
      // avoid clearing value if the operator is the same
      this.data.operator = operator;
      this.data.value = undefined;
    }
  }

  protected isSelected(operator: AutomationOperator): boolean {
    return this.data.operator === operator;
  }

  protected submit(): void {
    this.operationConfigured.emit({
      operator: this.data.operator,
      value: this.data.value,
    });
  }

  protected onValueChange(value: string): void {
    this.data.value = value;
  }

  protected getOperationBuilderComponentForOperator(operator: AutomationOperator): Type<any> {
    const operationBuilder = Object.values(AutomationOperationBuilders).find((builder) => {
      return builder.isSupported(operator);
    });

    if (!operationBuilder) {
      throw new Error(`Operation builder for operator ${operator} not found`);
    }

    return operationBuilder;
  }

  private updatePath(): void {
    this.pathService.getPath(this.dynamicResourceTypeProvider, this.data.metadata.name, this.data.path, true).subscribe((path) => {
      if (path) {
        this.currentPath = path;
        this.currentResourceTypeMetadata = path.parentMetadata;

        if (path.targetResourceType) {
          this.dynamicResourceTypeProvider.getMetadata(path.targetResourceType).subscribe((metadata) => {
            this.expectedMetadata = metadata;

            this.fetchAvailableOperations();
          });
        } else {
          this.fetchAvailableOperations();
        }
      } else {
        // path not found, cancelling
        this.cancelled.emit();
      }
    });
  }

  private fetchAvailableOperations() {
    if (!this.currentPath) {
      return;
    }

    if (this.currentPath.possibleValues && this.currentPath.possibleValues.length > 0) {
      this.possibleValues = this.currentPath.possibleValues;
    }

    if (this.currentPath.pathType === PathType.links) {
      this.availableOperators = getLinkOperators();
    } else {
      this.automationOperatorService
        .getSupportedOperations(this.currentPath.dataType ?? DataType.TEXT)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((availableOperators) => {
          this.availableOperators = availableOperators.map((availableOperator) => ({
            operator: availableOperator.operation,
            needsValue: availableOperator.needsValue,
            inputType: detectInputTypeFromPath(this.currentPath),
          }));
        });
    }
  }
}
