import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PageDTO } from '@shared/domain';
import { WorkflowExecutionDTO } from '@app/automation/domain';

@Injectable({
  providedIn: 'platform',
})
export class WorkflowDelayNodeService {
  constructor(private httpClient: HttpClient) {}

  public getSuspendedExecutions(workflowId: string, delayNodeId: string, page: number, pageSize: number): Observable<PageDTO<WorkflowExecutionDTO>> {
    return this.httpClient.get<PageDTO<WorkflowExecutionDTO>>(`/automation/workflows/${workflowId}/nodes/delay/${delayNodeId}/suspended-executions`, {
      params: new HttpParams().set('page', page).set('pageSize', pageSize),
    });
  }
}
