import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { Path, PathService, RobawsNgConfigurerCardService } from '@shared/services';

import { AbstractWorkflowNodeComponent } from '../../nodes/abstract-workflow-node-component';
import { WorkflowNodeData } from '../workflow-node/workflow-node-data';
import { SendTextMessageNode } from '@app/automation/domain/nodes/SendTextMessageNode';
import { SendTextMessageNodeConfigurerComponent } from '@app/automation/components/nodes/send-text-message-node/send-text-message-node-configurer/send-text-message-node-configurer.component';
import { DynamicResourceTypeProvider } from '@app/shared/services/dynamic-resource-type.provider';

@Component({
  selector: 'send-text-message-node',
  templateUrl: 'send-text-message-node.component.html',
})
export class SendTextMessageNodeComponent extends AbstractWorkflowNodeComponent implements OnInit, OnDestroy {
  @Input({ required: true })
  public workflowNodeData: WorkflowNodeData<SendTextMessageNode>;

  protected receivers = '';

  private onReloadSubscription: Subscription;
  private dynamicResourceTypeProvider = new DynamicResourceTypeProvider('AUTOMATION');

  constructor(
    private robawsNgConfigurerCardService: RobawsNgConfigurerCardService,
    private translateService: TranslateService,
    private pathService: PathService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.updateReceivers();
  }

  public ngOnDestroy(): void {
    this.unsubscribe();
  }

  public openConfigurer(): void {
    this.unsubscribe();

    const currentConfigurer = this.robawsNgConfigurerCardService.createAndOpenConfigurerCard(SendTextMessageNodeConfigurerComponent, {
      title: this.translateService.instant('nodes.send-text-message.configurer-title'),
      inputs: {
        currentNode: this.workflowNodeData.currentNode,
        resourceTypeMetadata: this.workflowNodeData.resourceTypeMetadata,
      },
    });

    this.onReloadSubscription = currentConfigurer.contentRef.instance.onReload.subscribe(() => this.updateReceivers());
  }

  private updateReceivers(): void {
    if (this.workflowNodeData.currentNode.configuration.destinations.length > 0) {
      this.pathService
        .getPaths(this.dynamicResourceTypeProvider, this.workflowNodeData.resourceTypeMetadata.name, true)
        .subscribe((paths: Path[]) => {
          const receivers = [];

          for (const destination of this.workflowNodeData.currentNode.configuration.destinations) {
            if (destination.startsWith('$')) {
              const path = paths.find((it) => it.path === destination);

              if (path) {
                receivers.push(path.displayNameDeep);
              }
            } else {
              receivers.push(destination);
            }
          }

          this.receivers = receivers.join(', ');
        });
    }
  }

  private unsubscribe() {
    if (this.onReloadSubscription) {
      this.onReloadSubscription.unsubscribe();
    }
  }
}
