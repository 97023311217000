import { DataExportConfiguration } from '@app/robaws/domain';
import { DataExportConfigurationUpdateDTO } from '@app/robaws/domain';

export class DataExportConfigurationDTO {
  constructor(public readonly configurations: DataExportConfiguration[]) {}

  public toDataExportConfigurationUpdateDTO(): DataExportConfigurationUpdateDTO {
    const dataExportConfigurationUpdateDTO = new DataExportConfigurationUpdateDTO();
    dataExportConfigurationUpdateDTO.configurations = this.configurations;

    return dataExportConfigurationUpdateDTO;
  }
}
