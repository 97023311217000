<mat-form-field class="question-input">
  <mat-label>{{'question' | translate}}</mat-label>
  <input matInput [(ngModel)]="element.configuration.question">
</mat-form-field>

<mat-form-field>
  <mat-label>{{'form-templates.choose-photo' | translate}}</mat-label>
  <input matInput disabled>
  <mat-icon matSuffix fontIcon="upload_file"></mat-icon>
</mat-form-field>
