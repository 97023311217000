<robaws-ng-card>
  <robaws-ng-card-header primaryDarkColor>
    <robaws-ng-card-title>
      <span>{{ title }}</span>
    </robaws-ng-card-title>
    <robaws-ng-card-close [uuid]="uuid" />
  </robaws-ng-card-header>

  <robaws-ng-card-content>
    <ng-content select="[slot=content]" />
  </robaws-ng-card-content>
</robaws-ng-card>
