<div class="delay-node-configurer">
  <p-dropdown [options]="delayStrategyTypes"
              [(ngModel)]="currentNode.configuration.strategy"
              optionLabel="key"
              optionValue="value"
              class="strategy-input" />

  <div *ngIf="currentNode.configuration.strategy === 'RELATIVE_TO_PATH_VALUE'">
    <property-path-selector [metadataProviderType]="metadataProviderType"
                            [data]="pathBuilderData"
                            [filter]="filterDatePath"
                            (propertySelected)="handlePathSelected($event)" />

    <div class="execution-types">
      <strong>{{ 'nodes.delay.execute_at' | translate }}</strong>
      <div *ngFor="let executionType of delayExecutionTypes">
        <p-radioButton name="executionType"
                       [value]="executionType.getValue()"
                       [inputId]="executionType.getValue()"
                       (ngModelChange)="onExecuteTypeChange($event)"
                       [ngModel]="executeType" />

        <label [for]="executionType.getValue()" class="ml-2">{{ executionType.getKey() }}</label>
      </div>
    </div>
  </div>

  <div class="value-container"
       *ngIf="currentNode.configuration.strategy !== 'RELATIVE_TO_PATH_VALUE' || executeType !== 'on_date'">
    <p-inputNumber [ngModel]="currentDisplayValue"
                   (ngModelChange)="onValueChange($event)"
                   [min]="0" />

    <p-dropdown [options]="delayUnitTypes"
                [(ngModel)]="currentNode.configuration.unit"
                class="unit-input"
                optionLabel="key"
                optionValue="value" />
  </div>
</div>
