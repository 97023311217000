<robaws-ng-select [items]="this.filteredPossibleValues()"
                  [showPlaceholder]="!!this.label() && this.label() !== ''"
                  [placeholder]="this.label() || ''"
                  [multiple]="this.multiple()"
                  [showFilter]="true"
                  [value]="this.multiple() ? this.multiSelectValue() : this.value()"
                  [inputClearIcon]="this.clearIcon()"
                  (valueChange)="this.multiple() ? this.onMultiSelectValueChange($event) : this.onValueChange($event)"
                  (onFilter)="this.onFilter($event)"
                  appendTo="body"
                  optionLabel="name"
                  optionValue="value"
                  #multiSelect>

  @if (this.inputTemplate()) {
    <ng-template robawsNgTemplate="input" let-onClick>
      <ng-container [ngTemplateOutlet]="this.inputTemplate()!"
                    [ngTemplateOutletContext]="{ $implicit: onClick }" />
    </ng-template>
  }

</robaws-ng-select>
