@if (suspendedExecutions.items.length > 0 || loading) {
  <p-table [value]="suspendedExecutions.items"
           [paginator]="true"
           [rows]="15"
           [lazy]="true"
           (onLazyLoad)="fetchSuspendedExecutions($event)"
           [totalRecords]="suspendedExecutions.totalItems"
           [loading]="loading">
    <ng-template pTemplate="header">
      <tr>
        <th style="width: 50%; text-transform: none; font-weight: bold;">{{ 'target' | translate }}</th>
        <th style="width: 50%; text-transform: none; font-weight: bold;">{{ 'remainingTime' | translate }}</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-execution>
      <tr>
        <td><span class="link" (click)="this.navigateToExecutionResource(execution)">{{ execution.resourceDescription }}</span></td>
        <td>{{ execution.resumeAt | timeDiff }}</td>
      </tr>
    </ng-template>
  </p-table>
} @else {
  <p class="no-executions">{{ 'nodes.delay.suspended-executions.no-executions' | translate }}</p>
}
