<ng-container [ngTemplateOutlet]="this.inputTemplate || defaultInputTemplate"
              [ngTemplateOutletContext]="{ $implicit: this.onInputClick }" />

<ng-template #defaultInputTemplate let-onClick>
  <div class="date-range" (click)="onClick($event)">
    @if (this.dateValue && this.dateValue.length === 2 && this.dateValue[0] && this.dateValue[1]) {
      <span>{{ this.dateValue[0].toLocaleDateString() }}</span>
      <span class="separator">-</span>
      <span>{{ this.dateValue[1].toLocaleDateString() }}</span>
    } @else {
      <span class="select-date-range">{{ 'date-range-input.select-date-range' | translate }}</span>
    }
  </div>
</ng-template>

<p-overlayPanel #dateRangePickerPanel>
  <div class="range-picker">
    <div class="quick-ranges">

      @for (dynamicDateRangeGroup of this.dynamicDateRangeGroups; track dynamicDateRangeGroup) {
        <div class="date-range-group">
          <strong class="t-cap">{{ dynamicDateRangeGroup.id | translate }}</strong>

          @for (dynamicDateRange of dynamicDateRangeGroup.ranges; track dynamicDateRange) {
            <button [class]="'robaws-ng-muted-button' + (this.isDynamicDateRangeSelected(dynamicDateRange) ? ' selected' : '')"
                    (click)="this.onDynamicDateRangeClick(dynamicDateRange)">
              {{ (dynamicDateRange | replace: '_' : '-') | lowercase | translate }}
            </button>
          }
        </div>
      }
    </div>

    <p-calendar [style]="{'width': '100%'}"
                [selectionMode]="'range'"
                [inline]="true"
                [showButtonBar]="true"
                [firstDayOfWeek]="1"
                [ngModel]="this.dateValue"
                (ngModelChange)="this.onDateValueChange($event)"
                dateFormat="dd-mm-yy"
                hourFormat="24"
                todayButtonStyleClass="v-hidden" />
  </div>
</p-overlayPanel>
