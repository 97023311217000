import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { TreeNode } from '@ui/tree-select/tree-node.type';
import { OverlayPanel, OverlayPanelModule } from 'primeng/overlaypanel';
import { Tree } from '@ui/tree-select/tree';
import { v4 as uuidv4 } from 'uuid';
import { NgForOf, NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatIcon } from '@angular/material/icon';
import { InputTextModule } from 'primeng/inputtext';
import { TreeSelectGroupComponent } from '@ui/tree-select/tree-select-group/tree-select-group.component';
import { TreeSelectItemComponent } from '@ui/tree-select/tree-select-item/tree-select-item.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'tree-select',
  templateUrl: 'tree-select.component.html',
  styleUrls: ['tree-select.component.scss'],
  standalone: true,
  imports: [
    NgForOf,
    FormsModule,
    NgIf,
    MatIcon,
    InputTextModule,
    OverlayPanelModule,
    TreeSelectGroupComponent,
    TreeSelectItemComponent,
    TranslateModule,
  ],
})
export class TreeSelectComponent implements OnInit, OnChanges, AfterViewChecked {
  @Input({ required: true })
  public items: TreeNode[] | null;

  @Input()
  public appendTo = '';

  @Input()
  public label = '';
  @Input()
  public allowCustomValues = false;
  @Input()
  public allowMultipleValues = false;
  @Output()
  public valueChange: EventEmitter<any> = new EventEmitter<any>();
  public searchText: string;
  @ViewChild('treeSelectInput')
  protected inputRef: ElementRef;
  @ViewChild('treeSelectRoot')
  protected rootRef: ElementRef;
  @ViewChild('treeSelectPanel')
  protected panel: OverlayPanel;
  protected panelWidth = 0;
  protected tree?: Tree;
  protected uid = uuidv4();

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  public _value: any[] = [];

  public get value(): any {
    if (this.allowMultipleValues) {
      return this._value;
    }
    return this._value[0];
  }

  @Input()
  public set value(_value: any) {
    const newValue = _value && _value.constructor === Array ? [..._value] : [_value];
    this._value = newValue;

    this.tree?.setValue(newValue);

    if (this.panel?.overlayVisible) {
      this.redrawPanel();
    }

    this.updateTree();
  }

  public get selectedValue(): { id: string; name: string }[] {
    return this.value.map((node: string) => {
      return {
        id: node,
        name: this.tree?.findNode(node)?.selectedLabel || node,
      };
    });
  }

  get nodesWithChildren(): TreeNode[] {
    return this.tree?.getNodesWithChildren() || [];
  }

  get nodesWithoutChildren(): TreeNode[] {
    return this.tree?.getNodesWithoutChildren() || [];
  }

  get availableItems(): TreeNode[] {
    return this.tree?.getAvailableItems() || [];
  }

  get shouldShowCustomValueNode(): boolean {
    if (this._value.includes(this.tree?.searchText)) {
      return false;
    }
    return this.tree?.shouldShowCustomValueNode() || false;
  }

  get getCustomValueNode(): TreeNode | undefined {
    return this.tree?.getCustomValueNode();
  }

  public ngOnInit(): void {
    this.updateTree();
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes['items'] || changes['allowCustomValues']) {
      this.updateTree();
    }
  }

  public ngAfterViewChecked(): void {
    this.panelWidth = this.rootRef.nativeElement.offsetWidth;
    this.changeDetectorRef.detectChanges();
  }

  protected redrawPanel(): void {
    this.panel?.hide();
    this.changeDetectorRef.detectChanges();
    this.panel?.show(new MouseEvent('click'), this.rootRef.nativeElement);
    this.changeDetectorRef.detectChanges();
  }

  protected onItemSelect(node: TreeNode): void {
    if (this.allowMultipleValues) {
      const isSelected = this._value.includes(node.value);
      if (isSelected) {
        this.remove(node.value);
      } else {
        if (!this._value.includes(node.value)) {
          this._value.push(node.value);
        }
      }
    } else {
      this.searchText = node.selectedLabel;
      this._value = [node.value];
    }

    this.inputRef.nativeElement.value = this.searchText || '';
    this.valueChange.emit(this.value);

    if (!this.allowMultipleValues) {
      setTimeout(() => this.panel.hide(), 50);
    }
  }

  protected showTreeSelectPanel(event: any) {
    if (!this.panel.overlayVisible) {
      this.panel.show(event, this.inputRef.nativeElement);
    }

    // ugly fix to scroll into view
    setTimeout(() => {
      const nativeElement = this.panel.container;

      if (nativeElement) {
        const selectedElement = nativeElement.querySelector('.selected');

        if (selectedElement) {
          selectedElement.scrollIntoView();
        }
      }
    }, 50);
  }

  protected onSearchTextInput(searchText: string): void {
    this.tree?.setSearchText(searchText);

    const parent = this.inputRef.nativeElement?.parentNode;
    if (parent) {
      parent.setAttribute('data-value', searchText);
    }

    if (this.panel.overlayVisible) {
      setTimeout(() => {
        this.panel.align();
      }, 100);
    } else {
      this.panel.show(searchText, this.inputRef.nativeElement);
    }
  }

  protected focusInput(): void {
    this.inputRef.nativeElement?.focus();
  }

  protected remove(value: string): void {
    this._value = this._value.filter((item) => item !== value);
    this.valueChange.emit(this.value);
  }

  protected clear(): void {
    this._value = [];
    this.searchText = '';
    this.inputRef.nativeElement.value = this.searchText;

    this.updateTree();

    this.valueChange.emit(this.value);
  }

  protected onHide(): void {
    if (this.allowMultipleValues) {
      this.searchText = '';
      this.tree?.setSearchText('');
    }
  }

  protected realignPanel(): void {
    this.panel.align();
  }

  private updateTree(): void {
    this.tree = new Tree(this.items ?? [], this.value);
    this.tree?.setValue(this.value);

    if (this.allowMultipleValues) {
      // this.searchText = '';
    } else {
      this.searchText = this.tree?.getCurrentNode()?.selectedLabel ?? '';
      this.tree?.setSearchText(this.searchText);
    }
  }
}
