<workflow-node (nodeClick)="this.openConfigurer()" [workflowNodeData]="workflowNodeData">

  <span slot="title">
    {{ 'nodes.create-robaws-resource.title' | translate: { resourceType: this.resourceTypeDisplayName.toLowerCase() } }}
  </span>

  <div slot="content">
    <p [innerHTML]="'nodes.create-robaws-resource.description' | translate: {
        resourceType: this.resourceTypeDisplayName.toLowerCase()
    }" class="description"></p>
  </div>
</workflow-node>
