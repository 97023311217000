import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ViewContentType } from '@app/robaws/domain';

@Injectable({
  providedIn: 'root',
})
export class ViewQuickFiltersService {
  constructor(private httpClient: HttpClient) {}

  public getQuickFilterPaths(viewContentType: ViewContentType): Observable<string[]> {
    return this.httpClient.get<string[]>(`/api/v2/view-quick-filters/${viewContentType}`);
  }

  public updateQuickFilterPaths(viewContentType: ViewContentType, paths: string[]): Observable<void> {
    return this.httpClient.put<void>(`/api/v2/view-quick-filters/${viewContentType}`, paths);
  }
}
