import { FormTemplateElement } from '@app/form-template/domain/elementtypes/FormTemplateElement';

export class NumberInputElement extends FormTemplateElement {
  constructor(
    public override question: string,
    public override required: boolean,
    public amountOfDecimals: number,
    public minimum: number,
    public interval: number,
    public maximum?: number,
  ) {
    super(question, required);
  }
}
