import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { catchError, concat, debounceTime, distinctUntilChanged, map, Observable, of, Subject, switchMap, tap } from 'rxjs';

import { AUTOMATION_RESOURCE_TYPE_MAPPER } from '@app/automation/services';
import { AutomationResourceTypeMetadata, NodeDTO, SendEmailNode } from '@app/automation/domain';
import { Path, PathService, UserService } from '@shared/services';
import { PathType } from '@shared/domain';
import { DynamicResourceTypeProvider } from '@app/shared/services/dynamic-resource-type.provider';

@Component({
  selector: 'send-email-node-configurer',
  templateUrl: 'send-email-node-configurer.component.html',
  styleUrls: ['send-email-node-configurer.component.scss'],
  inputs: ['resourceTypeMetadata'],
})
export class SendEmailNodeConfigurerComponent implements OnInit {
  @Input()
  public currentNode: NodeDTO<SendEmailNode>;

  @Input()
  public availableTemplates: AvailableTemplateVO[];

  public availableSenders$: Observable<AvailableSender[]>;
  public toPaths$: Observable<Path[]>;
  public ccPaths$: Observable<Path[]>;
  public bccPaths$: Observable<Path[]>;
  public senderSearchText$ = new Subject<string>();
  public sendersLoading = false;
  private dynamicResourceTypeProvider = new DynamicResourceTypeProvider('AUTOMATION');

  constructor(
    private userService: UserService,
    private translateService: TranslateService,
    private pathService: PathService,
  ) {}

  private _resourceTypeMetadata: AutomationResourceTypeMetadata;

  public get resourceTypeMetadata() {
    return this._resourceTypeMetadata;
  }

  public set resourceTypeMetadata(resourceTypeMetadata: AutomationResourceTypeMetadata) {
    this._resourceTypeMetadata = resourceTypeMetadata;
  }

  public get templateBased(): boolean {
    return this.currentNode.configuration.template !== '';
  }

  addLiteralValue(name: string) {
    return { path: name, displayName: name };
  }

  public loadToPaths() {
    this.toPaths$ = concat(
      of(this.currentNode.configuration.to.filter((it) => !it.startsWith('$')).map((it) => this.emailToPath(it))),
      this.pathService.getPaths(this.dynamicResourceTypeProvider, this.resourceTypeMetadata.name, true),
    );
  }

  ngOnInit(): void {
    this.loadTemplates();
    this.loadToPaths();
    this.loadCCPaths();
    this.loadBCCPaths();
    this.loadUsers();
  }

  private loadTemplates() {
    if (this.currentNode.configuration.template === undefined) {
      if (this.resourceTypeMetadata.emailTemplates.length > 0) {
        this.currentNode.configuration.template = this.resourceTypeMetadata.emailTemplates[0].id;
      } else {
        this.currentNode.configuration.template = '';
      }
    }
    this.availableTemplates = this.resourceTypeMetadata.emailTemplates.map((it) => new AvailableTemplateVO(it.id, it.name));
    this.availableTemplates.push(new AvailableTemplateVO('', '-- ' + this.translateService.instant('sendEmailNode.createNew')));
    if (
      this.currentNode.configuration.template != null &&
      this.availableTemplates.filter((it) => it.id === this.currentNode.configuration.template).length === 0
    ) {
      this.availableTemplates.push(new AvailableTemplateVO(this.currentNode.configuration.template, '(Verwijderde template)'));
    }
  }

  private async loadUsers() {
    const pathGroup = this.translateService.instant('property');
    const userGroup = this.translateService.instant('user');

    this.availableSenders$ = this.pathService.getPaths(this.dynamicResourceTypeProvider, this.resourceTypeMetadata.name, true).pipe(
      map((paths) => paths.filter((path) => path.pathType == PathType.links).filter((path) => path.targetResourceType == 'user')),
      switchMap((paths) => {
        let defaultItems: Observable<AvailableSender[]> | undefined;

        if (this.currentNode.configuration.sender != null) {
          if (this.currentNode.configuration.sender.startsWith('$')) {
            defaultItems = of(
              paths
                .filter((p) => p.path === this.currentNode.configuration.sender)
                .map((it) => new AvailableSender(it.path, it.displayNameDeep, pathGroup)),
            );
          } else {
            defaultItems = this.userService
              .findById(this.currentNode.configuration.sender)
              .pipe(map((user) => [new AvailableSender(user.id, user.fullName, userGroup)]));
          }
        }

        return concat(
          defaultItems ?? of([]),
          this.senderSearchText$.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            tap(() => (this.sendersLoading = true)),
            switchMap((term) =>
              this.userService
                .getUsers(term, 0, 100)
                .pipe(
                  map((it) => {
                    const items: AvailableSender[] = [];
                    items.push(
                      ...paths
                        .map((path) => new AvailableSender(path.path, path.displayNameDeep, pathGroup))
                        .filter((it) => it.label.toLowerCase().indexOf(term.toLowerCase()) >= 0),
                    );
                    items.push(...it.items.map((user) => new AvailableSender(user.id, user.fullName, userGroup)));
                    return items;
                  }),
                )
                .pipe(
                  catchError(() => of([])), // empty list on error
                  tap(() => (this.sendersLoading = false)),
                ),
            ),
          ),
        );
      }),
    );
  }

  private loadCCPaths() {
    this.ccPaths$ = concat(
      of(this.currentNode.configuration.cc.filter((it) => !it.startsWith('$')).map((it) => this.emailToPath(it))),
      this.pathService.getPaths(this.dynamicResourceTypeProvider, this.resourceTypeMetadata.name, true),
    );
  }

  private loadBCCPaths() {
    this.bccPaths$ = concat(
      of(this.currentNode.configuration.bcc.filter((it) => !it.startsWith('$')).map((it) => this.emailToPath(it))),
      this.pathService.getPaths(this.dynamicResourceTypeProvider, this.resourceTypeMetadata.name, true),
    );
  }

  private emailToPath(email: string): Path {
    return {
      pathType: null,
      path: email,
      name: email,
      sortable: false,
      primary: false,
      displayName: email,
      displayNameDeep: email,
      groupName: '',
      groupDisplayName: '',
      possibleValues: [],
      parentMetadata: AUTOMATION_RESOURCE_TYPE_MAPPER.mapMetadata(this.resourceTypeMetadata),
      depth: 0,
    };
  }
}

class AvailableTemplateVO {
  constructor(
    public id: string,
    public name: string,
  ) {}
}

class AvailableSender {
  constructor(
    public value: string,
    public label: string,
    public group: string,
  ) {}
}
