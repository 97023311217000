import { Component, Input } from '@angular/core';

import { ElementDTO, DescriptionElement } from '@app/form-template/domain';

@Component({
  selector: 'app-description',
  templateUrl: './description.component.html',
  styleUrls: ['./description.component.scss'],
})
export class DescriptionComponent {
  @Input({ required: true })
  element: ElementDTO<DescriptionElement>;
}
