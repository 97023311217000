import { Injector, NgModule } from '@angular/core';

import { registerWebComponent } from '@shared/helpers';

import { FormTemplateService } from '@app/form-template/services';

import { ListFormTemplateModule } from './list-form-templates/list-form-template.module';
import { FormTemplateConfigurationModule } from './form-template-configuration/form-template-configuration.module';
import { EditFormTemplateModule } from './edit-form-template/edit-form-template.module';

import { ListFormTemplatesComponent } from './list-form-templates/list-form-templates.component';
import { FormTemplateConfigurationComponent } from './form-template-configuration/form-template-configuration.component';
import { EditFormTemplateComponent } from './edit-form-template/edit-form-template.component';

@NgModule({
  imports: [ListFormTemplateModule, FormTemplateConfigurationModule, EditFormTemplateModule],
  exports: [ListFormTemplateModule, FormTemplateConfigurationModule, EditFormTemplateModule],
  providers: [FormTemplateService],
  declarations: [],
})
export class FormTemplateModule {
  constructor(injector: Injector) {
    registerWebComponent(injector, 'form-templates-configuration', FormTemplateConfigurationComponent);
    registerWebComponent(injector, 'edit-form-template', EditFormTemplateComponent);
    registerWebComponent(injector, 'list-form-template', ListFormTemplatesComponent);
  }
}
