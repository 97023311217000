import { AfterContentInit, Component, ContentChildren, EventEmitter, Input, OnInit, Output, QueryList, TemplateRef } from '@angular/core';
import { CalendarModule } from 'primeng/calendar';
import { FormsModule } from '@angular/forms';
import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { concat, debounceTime, distinctUntilChanged, map, Observable, of, Subject, switchMap, tap } from 'rxjs';
import { MetadataProviderType, PathType, ResourceTypeMetadata } from '@shared/domain';
import { PathService } from '@shared/services';
import { DynamicResourceTypeProvider } from '@app/shared/services/dynamic-resource-type.provider';
import { RobawsNgSelectComponent, RobawsNgSelectFilterEvent } from '@ui/robaw-ng-select/robaws-ng-select.component';
import { RobawsNgTemplateDirective } from '@shared/components/robaws-ng-template.directive';
import { Nullable } from 'primeng/ts-helpers';

type ResourceRefVO = {
  id: string;
  label: string;
};

@Component({
  selector: 'resource-ref-input',
  templateUrl: 'resource-ref-input.component.html',
  standalone: true,
  imports: [CalendarModule, FormsModule, NgTemplateOutlet, RobawsNgSelectComponent, RobawsNgTemplateDirective, AsyncPipe],
})
export class ResourceRefInputComponent implements OnInit, AfterContentInit {
  @Input({ required: true })
  public metadataProviderType: MetadataProviderType;

  @Input({ required: true })
  public metadata: ResourceTypeMetadata;

  @Input()
  public label?: string | undefined | null;

  @Input()
  public value: string | undefined | null;

  @Input()
  public showLabel: boolean;

  @Input()
  public clearIcon: boolean = true;

  @Output()
  public valueChange: EventEmitter<string> = new EventEmitter<string>();

  protected resourceRefSearchText$ = new Subject<string>();
  protected availableResourceRefs$: Observable<ResourceRefVO[]>;
  protected resourceRefsLoading = false;
  protected inputTemplate: TemplateRef<any> | undefined;
  private dynamicResourceTypeProvider: DynamicResourceTypeProvider;
  @ContentChildren(RobawsNgTemplateDirective)
  private templates: Nullable<QueryList<RobawsNgTemplateDirective>>;

  constructor(private pathService: PathService) {}

  public ngOnInit(): void {
    this.dynamicResourceTypeProvider = new DynamicResourceTypeProvider(this.metadataProviderType);
    this.loadAvailableResourceRefs();
  }

  public ngAfterContentInit(): void {
    if (this.templates) {
      this.templates.forEach((template) => {
        if (template.type === 'input') {
          this.inputTemplate = template.template;
        }
      });
    }
  }

  protected onValueChange(value: string | undefined | null): void {
    this.value = value || '';
    this.valueChange.emit(this.value);
  }

  protected onFilter(event: RobawsNgSelectFilterEvent): void {
    this.resourceRefSearchText$.next(event.filter);
  }

  private loadAvailableResourceRefs(): void {
    this.availableResourceRefs$ = this.pathService.getPaths(this.dynamicResourceTypeProvider, this.metadata.name, true).pipe(
      map((paths) => {
        return [
          { id: '${root}', label: this.metadata.displayName },
          ...paths.filter((path) => path.pathType == PathType.links).map((it) => ({ id: it.path, label: it.displayNameDeep })),
        ];
      }),
      switchMap((resourceRefs) =>
        concat(
          of(resourceRefs.filter((it) => it.id.includes(this.value ?? ''))),
          this.resourceRefSearchText$.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            tap(() => (this.resourceRefsLoading = true)),
            map((term) => resourceRefs.filter((it) => it.label.includes(term))),
            tap(() => (this.resourceRefsLoading = false)),
          ),
        ),
      ),
    );
  }
}
