import { DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable } from 'rxjs';

import { PageDTO } from '@shared/domain';

export class DynamicDataSource<T> implements DataSource<T> {
  public currentPage?: PageDTO<T>;
  private dataSubject = new BehaviorSubject<T[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();

  private currentFilter = '';
  private currentPageIndex = 0;
  private currentPageSize = 20;
  private currentSort = '';

  constructor(private dataProvider: (filter: string, page: number, pageSize: number, sort: string) => Promise<PageDTO<T>> | Observable<PageDTO<T>>) {}

  get items(): T[] {
    return this.dataSubject.value;
  }

  connect(): Observable<T[]> {
    return this.dataSubject.asObservable();
  }

  disconnect(): void {
    this.dataSubject.complete();
    this.loadingSubject.complete();
  }

  setLoading = (isLoading: boolean) => {
    this.loadingSubject.next(isLoading);
  };

  sort(sort = '') {
    this.load(this.currentFilter, this.currentPageIndex, this.currentPageSize, sort);
  }

  load(filter = '', pageIndex = 0, pageSize = 20, sort = ''): void {
    this.loadingSubject.next(true);

    this.currentFilter = filter;
    this.currentPageIndex = pageIndex;
    this.currentPageSize = pageSize;
    this.currentSort = sort;

    const future = this.dataProvider(filter, pageIndex, pageSize, sort);

    if (future instanceof Observable) {
      future.subscribe({
        next: this.handleData.bind(this),
        error: this.handleError.bind(this),
      });
    } else {
      future.then(this.handleData.bind(this)).catch(this.handleError.bind(this));
    }
  }

  refresh(): void {
    this.load(this.currentFilter ?? '', this.currentPageIndex ?? 0, this.currentPageSize ?? 20, this.currentSort ?? '');
  }

  private handleData(data: PageDTO<T>) {
    this.currentPage = {
      ...data,
      size: this.currentPageSize,
    };

    this.dataSubject.next(data.items);
    this.loadingSubject.next(false);
  }

  private handleError(e: Error) {
    this.dataSubject.next([]);
    this.loadingSubject.next(false);

    console.error(e);
  }
}
