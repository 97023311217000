import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { SendEmailNode } from '@app/automation/domain';
import { RobawsNgConfigurerCardService } from '@shared/services';

import { SendEmailNodeConfigurerComponent } from './send-email-node-configurer/send-email-node-configurer.component';
import { AbstractWorkflowNodeComponent } from '../abstract-workflow-node-component';
import { WorkflowNodeData } from '../workflow-node/workflow-node-data';

@Component({
  selector: 'send-email-node',
  templateUrl: 'send-email-node.component.html',
})
export class SendEmailNodeComponent extends AbstractWorkflowNodeComponent {
  @Input({ required: true })
  public workflowNodeData: WorkflowNodeData<SendEmailNode>;

  constructor(
    private robawsNgConfigurerCardService: RobawsNgConfigurerCardService,
    private translateService: TranslateService,
  ) {
    super();
  }

  public openConfigurer(): void {
    this.robawsNgConfigurerCardService.createAndOpenConfigurerCard(SendEmailNodeConfigurerComponent, {
      title: this.translateService.instant('sendEmailNode.heading'),
      inputs: {
        currentNode: this.workflowNodeData.currentNode,
        resourceTypeMetadata: this.workflowNodeData.resourceTypeMetadata,
      },
    });
  }
}
