import { Component, Input } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import { RobawsNgCardComponent } from '@ui/robaws-ng-card/robaws-ng-card.component';
import { RobawsNgCardHeaderComponent } from '@ui/robaws-ng-card/robaws-ng-card-header/robaws-ng-card-header.component';
import { RobawsNgCardTitleComponent } from '@ui/robaws-ng-card/robaws-ng-card-title/robaws-ng-card-title.component';
import { RobawsNgCardCloseComponent } from '@ui/robaws-ng-card/robaws-ng-card-close/robaws-ng-card-close.component';
import { RobawsNgCardContentComponent } from '@ui/robaws-ng-card/robaws-ng-card-content/robaws-ng-card-content.component';
import { PrimaryDarkColorDirective } from '@ui/theme/primary-dark-color.directive';

@Component({
  selector: 'robaws-ng-configurer-card',
  templateUrl: 'robaws-ng-configurer-card.component.html',
  styleUrls: ['robaws-ng-configurer-card.component.scss'],
  standalone: true,
  imports: [
    RobawsNgCardComponent,
    RobawsNgCardHeaderComponent,
    RobawsNgCardTitleComponent,
    RobawsNgCardCloseComponent,
    RobawsNgCardContentComponent,
    PrimaryDarkColorDirective,
  ],
})
export class RobawsNgConfigurerCardComponent {
  @Input({ required: true })
  public title: string;
  public uuid: string = uuidv4();
}
