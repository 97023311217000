import { ComponentRef, Directive, ElementRef, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { Observable } from 'rxjs';

import { MaterialLoaderOverlayComponent } from './overlay/material-loader-overlay.component';

@Directive({
  selector: '[materialLoader]',
  inputs: ['materialLoader', 'forceFullscreen'],
  standalone: true,
})
export class MaterialLoaderDirective implements OnInit, OnDestroy {
  public forceFullscreen = false;
  private isLoading = false;
  private spinnerRef?: ComponentRef<MaterialLoaderOverlayComponent>;

  constructor(
    private viewContainerRef: ViewContainerRef,
    private elRef: ElementRef<HTMLElement>,
  ) {}

  set materialLoader(value: boolean | Observable<boolean>) {
    if (value instanceof Observable) {
      (value as Observable<boolean>).subscribe((status) => this.updateStatus(status));
    } else {
      this.updateStatus(value as boolean);
    }
  }

  ngOnInit(): void {
    if (this.isLoading) {
      this.startLoading();
    } else {
      this.stopLoading();
    }
  }

  ngOnDestroy(): void {
    this.stopLoading();
  }

  private updateStatus(isLoading: boolean): void {
    this.isLoading = isLoading;

    if (this.isLoading) {
      this.startLoading();
    } else {
      this.stopLoading();
    }
  }

  private startLoading(): void {
    this.stopLoading(); // destroy any previous instances

    const spinnerRef = this.viewContainerRef.createComponent(MaterialLoaderOverlayComponent);
    this.spinnerRef = spinnerRef;

    if (this.forceFullscreen) {
      spinnerRef.instance.posY = 0;
      spinnerRef.instance.posX = 0;
      spinnerRef.instance.width = '100vw';
      spinnerRef.instance.height = '100vh';
      spinnerRef.instance.position = 'fixed';
    } else {
      setTimeout(() => {
        spinnerRef.instance.posY = this.elRef.nativeElement.offsetTop;
        spinnerRef.instance.posX = this.elRef.nativeElement.offsetLeft;
        spinnerRef.instance.width = this.elRef.nativeElement.offsetWidth + 'px';
        spinnerRef.instance.height = this.elRef.nativeElement.offsetHeight + 'px';
        spinnerRef.instance.position = 'absolute';
      }, 5);
    }
  }

  private stopLoading(): void {
    if (this.spinnerRef) {
      this.spinnerRef.destroy();
    }
  }
}
