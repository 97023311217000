import { Observable, of } from 'rxjs';

import { WorkflowNodeType } from '@app/automation/domain';

import { WorkflowNodeCategoryType, WorkflowNodeTypeVO } from '../../add-node-configurer/add-node-configurer.component';
import { NodeProvider, NodeProviderContext } from '../node-provider';

export const sendEmailNodeProvider: NodeProvider = (context: NodeProviderContext): Observable<WorkflowNodeTypeVO[]> => {
  if (!context.metadata.emailTemplates) {
    return of([]);
  }
  return of([
    {
      type: WorkflowNodeType.send_email,
      category: WorkflowNodeCategoryType.ACTION,
      icon: 'mail',
      color: 'darkorange',
      priority: 3,
    },
  ]);
};
