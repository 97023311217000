import { Directive, OnInit, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[primaryDarkColor]',
  standalone: true,
})
export class PrimaryDarkColorDirective implements OnInit {
  constructor(public viewContainerRef: ViewContainerRef) {}

  ngOnInit(): void {
    this.viewContainerRef.element.nativeElement.classList.add('background-primary-color-dark');
  }
}
