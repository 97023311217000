import { Observable, of, switchMap } from 'rxjs';

import { WorkflowNodeType } from '@app/automation/domain';

import { WorkflowNodeCategoryType, WorkflowNodeTypeVO } from '../../add-node-configurer/add-node-configurer.component';
import { NodeProvider, NodeProviderContext } from '../node-provider';

const creatableResourceTypes = ['task'];

export const getCreateRobawsResourceNodes: NodeProvider = (context: NodeProviderContext): Observable<WorkflowNodeTypeVO[]> => {
  return context.automationResourceTypeService.getResourceTypes().pipe(
    switchMap((resourceTypes) => {
      return of(
        creatableResourceTypes.map((resourceType) => ({
          type: WorkflowNodeType.create_robaws_resource,
          icon: getNodeIcon(resourceType),
          category: WorkflowNodeCategoryType.CREATE,
          color: 'purple',
          priority: 8,
          name: resourceTypes.find((it) => it.name === resourceType)?.displayName ?? resourceType,
          data: {
            resourceType,
          },
        })),
      );
    }),
  );
};

function getNodeIcon(resourceType: string): string {
  switch (resourceType) {
    default:
      return resourceType;
  }
}
