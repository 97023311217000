import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { plainToInstance } from 'class-transformer';

import { PageDTO } from '@shared/domain';

import { FormTemplate, FormTemplateSection, FormTemplateDTO, FormTemplateListViewDTO } from '@app/form-template/domain';

@Injectable({
  providedIn: 'platform',
})
export class FormTemplateService {
  public isSaving$ = new BehaviorSubject<boolean>(false);

  constructor(private httpClient: HttpClient) {}

  public getFormTemplates(filter: string, page: number, pageSize: number): Observable<PageDTO<FormTemplateListViewDTO>> {
    return this.httpClient
      .get('form-templates/search', {
        params: new HttpParams().set('filter', filter).set('page', page).set('pageSize', pageSize),
      })
      .pipe(
        map((it: any) => {
          it.items = it.items.map((item: any) => plainToInstance(FormTemplateListViewDTO, item));
          return it as PageDTO<FormTemplateListViewDTO>;
        }),
      );
  }

  public getFormTemplateById(id: number): Observable<FormTemplateDTO> {
    return this.httpClient.get(`form-templates/${id}`).pipe(map((it) => plainToInstance(FormTemplateDTO, it)));
  }

  public createEmptyFormTemplate(): Observable<number> {
    const emptySections: FormTemplateSection[] = [];
    return this.httpClient.post('form-templates', new FormTemplate('', emptySections)).pipe(map((it) => Number(it)));
  }

  public updateFormTemplate(formTemplateId: number, formTemplateDto: FormTemplateDTO): Observable<FormTemplateDTO> {
    return this.httpClient.put(`form-templates/${formTemplateId}`, formTemplateDto).pipe(map((it) => plainToInstance(FormTemplateDTO, it)));
  }

  public deleteFormTemplate(formTemplates: FormTemplateListViewDTO[]): Observable<void> {
    return this.httpClient.delete<void>('form-templates', {
      params: new HttpParams().set('ids', formTemplates.map((it) => it.formTemplateId).join(',')),
    });
  }
}
