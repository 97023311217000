<span [forceFullscreen]="true" [materialLoader]="isLoading$"></span>

<div class="workflow-wizard-container">
  <div class="info">
    <h2>{{ 'info' | translate }}</h2>

    <form class="info-form">
      <div class="p-float-label">
          <input [(ngModel)]="workflow.name"
                 pInputText
                 name="workflowName"
                 type="text"/>
        <label [htmlFor]="'workflow-wizard-name'">{{ 'workflows.workflowName' | translate }}</label>
      </div>
    </form>
  </div>

  <h2>{{ 'workflow-wizard.heading' | translate }}</h2>
  <div class="resource-types">
    <robaws-ng-card (click)="selectResourceType(resourceType)"
                    *ngFor="let resourceType of resourceTypes"
                    class="resource-type-card">

      <robaws-ng-card-content>
        <img [alt]="'Icon of ' + resourceType.name"
             [src]="getIconUrl(resourceType.name)"
             class="resource-type-icon"/>

        <h5>
          <span class="resource-type-name">{{ resourceType.displayName }}</span>
        </h5>

        <p class="resource-type-description">
          {{ 'workflow-wizard.description' | translate }}
          <strong>{{ resourceType.displayName }}</strong>.
        </p>
      </robaws-ng-card-content>
    </robaws-ng-card>
  </div>
</div>
