import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PageDTO } from '@shared/domain';
import { ViewFilterValueDTO } from '@app/robaws/domain/ViewFilterValueDTO';

@Injectable({
  providedIn: 'root',
})
export class ViewFilterValueService {
  constructor(private httpClient: HttpClient) {}

  public getFilterValues(
    viewId: string,
    resourceType: string,
    searchText: string,
    page: number,
    pageSize: number,
    ids?: string[],
  ): Observable<PageDTO<ViewFilterValueDTO>> {
    return this.httpClient.get<PageDTO<ViewFilterValueDTO>>(`/api/v2/views/${viewId}/filter-values/${resourceType}`, {
      params: {
        searchText,
        page,
        pageSize,
        ids: ids ?? [],
      },
    });
  }
}
