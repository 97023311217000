import { Component, Input, OnDestroy, ViewEncapsulation } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { Path, PathService, RobawsNgConfigurerCardService } from '@shared/services';

import { RobawsResourcePatch, UpdateRobawsResourceNode } from '@app/automation/domain';

import { UpdateRobawsResourceNodeConfigurerComponent } from '../update-robaws-resource-node/configurer/update-robaws-resource-node-configurer.component';
import { AbstractWorkflowNodeComponent } from '../abstract-workflow-node-component';
import { WorkflowNodeData } from '../workflow-node/workflow-node-data';
import { DynamicResourceTypeProvider } from '@app/shared/services/dynamic-resource-type.provider';
import { DynamicResourceTypeEntityProvider } from '@app/shared/services/dynamic-resource-type-entity-provider';
import { transformValue } from 'src/app/automation/helpers/value.helpers';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'update-robaws-resource-node',
  templateUrl: 'update-robaws-resource-node.component.html',
  styleUrls: ['update-robaws-resource-node.component.scss'],
  inputs: ['currentNode', 'resourceTypeMetadata'],
  encapsulation: ViewEncapsulation.None,
})
export class UpdateRobawsResourceNodeComponent extends AbstractWorkflowNodeComponent implements OnDestroy {
  protected propertyName$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  protected value$: BehaviorSubject<string> = new BehaviorSubject('');
  private pathChangeSubscription: Subscription;
  private valueChangeSubscription: Subscription;
  private currentPath: Path | undefined;
  private dynamicResourceTypeProvider = new DynamicResourceTypeProvider('AUTOMATION');
  private dynamicResourceTypeEntityProvider = new DynamicResourceTypeEntityProvider('AUTOMATION');

  constructor(
    private robawsNgConfigurerCardService: RobawsNgConfigurerCardService,
    private pathService: PathService,
    private translateService: TranslateService,
    private datePipe: DatePipe,
  ) {
    super();
  }

  private _workflowNodeData: WorkflowNodeData<UpdateRobawsResourceNode>;

  @Input({ required: true })
  public get workflowNodeData(): WorkflowNodeData<UpdateRobawsResourceNode> {
    return this._workflowNodeData;
  }

  public set workflowNodeData(workflowNodeData: WorkflowNodeData<UpdateRobawsResourceNode>) {
    this._workflowNodeData = workflowNodeData;

    this.updatePatchDisplayNames(workflowNodeData.currentNode.configuration.patches[0] ?? null);
  }

  public ngOnDestroy(): void {
    this.unsubscribe();
  }

  public openConfigurer(): void {
    this.unsubscribe();

    const currentConfigurer = this.robawsNgConfigurerCardService.createAndOpenConfigurerCard(UpdateRobawsResourceNodeConfigurerComponent, {
      title: this.translateService.instant('nodes.update-robaws-resource.title'),
      inputs: {
        workflowNodeData: this.workflowNodeData,
      },
    });

    this.pathChangeSubscription = currentConfigurer.contentRef.instance.patchChanged.subscribe((patch) => {
      this.updatePatchDisplayNames(patch);
    });
  }

  protected hasPatch(): boolean {
    return (
      this.workflowNodeData.currentNode.configuration.patches.length > 0 && this.workflowNodeData.currentNode.configuration.patches[0].path != null
    );
  }

  private unsubscribe() {
    if (this.pathChangeSubscription) {
      this.pathChangeSubscription.unsubscribe();
    }
    if (this.valueChangeSubscription) {
      this.valueChangeSubscription.unsubscribe();
    }
  }

  private updatePatchDisplayNames(patch: RobawsResourcePatch | null): void {
    if (patch && patch.path) {
      this.pathService.getPath(this.dynamicResourceTypeProvider, this.workflowNodeData.resourceTypeMetadata.name, patch.path).subscribe((path) => {
        this.currentPath = path;
        this.propertyName$.next(path?.displayNameDeep ?? '');

        if (path && patch.value && patch.value !== '') {
          transformValue(this.dynamicResourceTypeEntityProvider, this.datePipe, path, patch.value).subscribe((value) => this.value$.next(value));
        } else {
          this.value$.next('<' + this.translateService.instant('empty').toLowerCase() + '>');
        }
      });
    } else {
      this.propertyName$.next('');
      this.value$.next('');
    }
  }
}
