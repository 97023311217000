import { WorkflowNode } from './WorkflowNode';
import { NodeDTO } from './NodeDTO';

export class SendEmailNode extends WorkflowNode {
  constructor(
    public to: string[],
    public cc: string[],
    public bcc: string[],
    public subject: string,
    public body: string,
    public override nextNode?: NodeDTO<any>,
    public sender?: string,
    public template?: string,
  ) {
    super(nextNode);
  }
}
