export class Pair<K, V> {
  constructor(
    private key: K,
    private value: V,
  ) {}

  public setKey(key: K) {
    this.key = key;
  }

  public setValue(value: V) {
    this.value = value;
  }

  public getKey(): K {
    return this.key;
  }

  public getValue(): V {
    return this.value;
  }
}
