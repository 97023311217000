<span class="paginator-current">{{
    'paginator-current-showing' | translate: {
      firstItem: this.firstItem,
      lastItem: this.lastItem,
      totalItems: this.totalItems
    }
  }}</span>

<button class="paginator-icon" [disabled]="this.page === 0" (click)="this.goToFirst()">
  <mat-icon fontIcon="keyboard_double_arrow_left" pRipple />
</button>
<button class="paginator-icon" [disabled]="this.page === 0" (click)="this.goToPrevious()">
  <mat-icon fontIcon="keyboard_arrow_left" pRipple />
</button>

@for (visiblePage of this.visiblePages; track visiblePage) {
  <span class="paginator-icon"
        [class.active]="visiblePage.page === this.page"
        (click)="this.goTo(visiblePage.page)">
    {{ visiblePage.label }}
  </span>
}

<button class="paginator-icon" [disabled]="this.page === (this.totalPages - 1)" (click)="this.goToNext()">
  <mat-icon fontIcon="keyboard_arrow_right" pRipple />
</button>
<button class="paginator-icon" [disabled]="this.page === (this.totalPages - 1)" (click)="this.goToLast()">
  <mat-icon fontIcon="keyboard_double_arrow_right" pRipple />
</button>

<p-inputNumber [ngModel]="pageSize"
               (ngModelChangeDebounced)="this.onPageSizeChange($event)"
               [showButtons]="true"
               [min]="1"
               [max]="200"
               mode="decimal"
               inputId="minmax-buttons" />
