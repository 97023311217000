<workflow-node (nodeClick)="openConfigurer()"
               [workflowNodeData]="workflowNodeData">

  <span slot="title">{{'sendEmailNode.title' | translate}}</span>

  <div slot="content">
    <p class="description">
      {{'sendEmailNode.description' | translate}}
    </p>
  </div>
</workflow-node>
