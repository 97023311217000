import { Injectable } from '@angular/core';
import { catchError, firstValueFrom, map, Observable, of, switchMap } from 'rxjs';

import { DataType, PathType, ResourceTypeMetadata, ResourceTypePropertyPossibleValue } from '@shared/domain';
import { counts } from '@app/shared/helpers/array.helper';
import { DynamicResourceTypeProvider } from '@app/shared/services/dynamic-resource-type.provider';

@Injectable({
  providedIn: 'platform',
})
export class PathService {
  private _unsupportedResourceTypes: string[] = [];

  public getPath(
    dynamicResourceTypeProvider: DynamicResourceTypeProvider,
    resourceType: string,
    path: string,
    spreadComplexTypes = false,
    includeComplexType = false,
  ): Observable<Path | undefined> {
    return this.getPaths(dynamicResourceTypeProvider, resourceType, false, true, true, true, spreadComplexTypes, includeComplexType).pipe(
      map((paths): Path | undefined => {
        const filteredPaths = paths.filter((it) => it.path === path);

        if (filteredPaths.length === 0) {
          return undefined;
        } else {
          return filteredPaths[0] ?? null;
        }
      }),
    );
  }

  public getPaths(
    dynamicResourceTypeProvider: DynamicResourceTypeProvider,
    resourceType: string,
    asVariables = false,
    includeLinks = true,
    includeReadOnly = true,
    fetchDeep = true,
    spreadComplexTypes = false,
    includeComplexType = false,
  ): Observable<Path[]> {
    return this.getTreePaths(
      dynamicResourceTypeProvider,
      resourceType,
      asVariables,
      includeLinks,
      includeReadOnly,
      fetchDeep,
      spreadComplexTypes,
      includeComplexType,
    ).pipe(
      map((treePaths) => {
        const paths: Path[] = [];

        for (const path of treePaths) {
          paths.push(...this.flattenPaths(path));
        }

        return paths;
      }),
    );
  }

  public getTreePaths(
    dynamicResourceTypeProvider: DynamicResourceTypeProvider,
    resourceType: string,
    asVariables = false,
    includeLinks = true,
    includeReadOnly = true,
    fetchDeep = true,
    spreadComplexTypes = false,
    includeComplexType = false,
  ): Observable<TreePath[]> {
    return this.fetchMetadata(dynamicResourceTypeProvider, resourceType).pipe(
      switchMap((metadata) => {
        return metadata === null
          ? of([])
          : this.collectPaths(
              dynamicResourceTypeProvider,
              metadata,
              '',
              '',
              includeLinks,
              includeReadOnly,
              0,
              undefined,
              undefined,
              spreadComplexTypes,
              fetchDeep,
              includeComplexType,
            ).then((it) => {
              return asVariables ? this.wrapAsVariables(it) : it;
            });
      }),
    );
  }

  public wrapAsVariables(paths: TreePath[]): TreePath[] {
    return paths.map((it) => ({
      pathType: it.pathType,
      path: '${' + it.path + '}',
      name: it.name,
      sortable: it.sortable,
      primary: it.primary,
      displayName: it.displayName,
      displayNameDeep: it.displayNameDeep,
      groupName: it.groupName,
      groupDisplayName: it.groupDisplayName,
      dataType: it.dataType,
      possibleValues: it.possibleValues,
      targetResourceType: it.targetResourceType,
      children: this.wrapAsVariables(it.children),
      parentMetadata: it.parentMetadata,
      depth: it.depth,
    }));
  }

  private async collectPaths(
    dynamicResourceTypeProvider: DynamicResourceTypeProvider,
    metadata: ResourceTypeMetadata,
    pathPrefix: string,
    namePrefix: string,
    includeLinks: boolean,
    includeReadOnly: boolean,
    depth: number,
    groupName?: string,
    groupDisplayName?: string,
    spreadComplexTypes = false,
    fetchDeep = true,
    includeComplexType = false,
    currentDepth = 0,
  ): Promise<TreePath[]> {
    const paths: TreePath[] = [];

    if (depth > 5) {
      return [];
    }

    for (const attribute of metadata.attributes) {
      if (!includeReadOnly && attribute.readOnly) {
        continue;
      }

      if (attribute.dataType !== DataType.COMPLEX || includeComplexType) {
        paths.push({
          pathType: PathType.attributes,
          path: pathPrefix + 'attributes.' + attribute.name,
          name: attribute.name,
          sortable: attribute.sortable,
          primary: attribute.primary,
          displayName: attribute.displayName,
          displayNameDeep: namePrefix + attribute.displayName,
          groupName: groupName ?? metadata.name,
          groupDisplayName: groupDisplayName ?? metadata.displayName,
          dataType: attribute.dataType,
          possibleValues: attribute.possibleValues ?? [],
          children: [],
          parentMetadata: metadata,
          depth: currentDepth,
        });
      }

      if (spreadComplexTypes && attribute.dataType === DataType.COMPLEX) {
        for (const subAttribute of attribute.attributes ?? []) {
          const name = attribute.name + '_' + subAttribute.name;
          const displayName = attribute.displayName + ' → ' + subAttribute.displayName;

          paths.push({
            pathType: PathType.attributes,
            path: pathPrefix + 'attributes.' + name,
            name: name,
            sortable: attribute.sortable,
            primary: attribute.primary,
            displayName: displayName,
            displayNameDeep: namePrefix + displayName,
            groupName: groupName ?? metadata.name,
            groupDisplayName: groupDisplayName ?? metadata.displayName,
            dataType: subAttribute.dataType,
            possibleValues: subAttribute.possibleValues ?? [],
            children: [],
            parentMetadata: metadata,
            depth: currentDepth,
          });
        }
      }
    }
    for (const extraField of metadata.extraFields) {
      paths.push({
        pathType: PathType.extraFields,
        path: pathPrefix + 'extraFields.' + extraField.name,
        name: extraField.name,
        sortable: extraField.sortable,
        primary: false,
        displayName: extraField.displayName,
        displayNameDeep: namePrefix + extraField.displayName,
        groupName: groupName ?? metadata.name,
        groupDisplayName: groupDisplayName ?? metadata.displayName,
        dataType: extraField.dataType,
        possibleValues: extraField.possibleValues ?? [],
        children: [],
        parentMetadata: metadata,
        depth: currentDepth,
      });
    }
    for (const link of metadata.links) {
      const linkPath = pathPrefix + 'links.' + link.name;

      // if (!leavesOnly && !loopDetected) {
      if (includeLinks && !this.isLoopDetected(linkPath)) {
        if (fetchDeep) {
          const linkMetadata = await firstValueFrom(this.fetchMetadata(dynamicResourceTypeProvider, link.resource));

          if (linkMetadata !== null) {
            paths.push(
              await this.collectPaths(
                dynamicResourceTypeProvider,
                linkMetadata,
                pathPrefix + 'links.' + link.name + '.',
                namePrefix + link.displayName + ' → ',
                includeLinks,
                includeReadOnly,
                depth + 1,
                (groupName ? groupName + '.' : '') + link.name,
                (groupDisplayName ? groupDisplayName + ' → ' : '') + link.displayName,
                spreadComplexTypes,
                fetchDeep,
                includeComplexType,
                currentDepth + 1,
              ).then((children: TreePath[]) => {
                return {
                  pathType: PathType.links,
                  path: pathPrefix + 'links.' + link.name,
                  name: link.name,
                  sortable: false,
                  primary: false,
                  displayName: link.displayName,
                  displayNameDeep: namePrefix + link.displayName,
                  groupName: groupName ?? metadata.name,
                  groupDisplayName: groupDisplayName ?? metadata.displayName,
                  possibleValues: [],
                  targetResourceType: link.resource,
                  children: children,
                  parentMetadata: metadata,
                  depth: currentDepth,
                };
              }),
            );
          }
        } else {
          paths.push({
            pathType: PathType.links,
            path: pathPrefix + 'links.' + link.name,
            name: link.name,
            sortable: false,
            primary: false,
            displayName: link.displayName,
            displayNameDeep: namePrefix + link.displayName,
            groupName: groupName ?? metadata.name,
            groupDisplayName: groupDisplayName ?? metadata.displayName,
            possibleValues: [],
            targetResourceType: link.resource,
            children: [],
            parentMetadata: metadata,
            depth: currentDepth,
          });
        }
      }
    }

    return paths;
  }

  private fetchMetadata(dynamicResourceTypeProvider: DynamicResourceTypeProvider, resourceType: string): Observable<ResourceTypeMetadata | null> {
    if (this._unsupportedResourceTypes.indexOf(resourceType) >= 0) {
      return of(null); //avoid a second 404
    }
    return dynamicResourceTypeProvider.getMetadata(resourceType).pipe(
      catchError(() => {
        this._unsupportedResourceTypes.push(resourceType);
        return of(null);
      }),
    );
  }

  private flattenPaths(path: TreePath): Path[] {
    const paths: Path[] = [];

    paths.push(path);

    if (path.children) {
      for (const child of path.children) {
        paths.push(...this.flattenPaths(child));
      }
    }

    return paths;
  }

  private isLoopDetected(linkPath: string): boolean {
    const pathParts = linkPath.split('.').filter((part) => part !== 'links');
    const countsResult = counts(pathParts);

    for (const value of countsResult.values()) {
      if (value > 1) {
        return true;
      }
    }
    return false;
  }
}

export type TreePath = Path & {
  children: TreePath[];
};

export type Path = {
  pathType: PathType | null;
  path: string;
  name: string;
  sortable: boolean;
  primary: boolean;
  displayName: string;
  displayNameDeep: string;
  groupName: string;
  groupDisplayName: string;
  dataType?: DataType;
  possibleValues: ResourceTypePropertyPossibleValue[];
  targetResourceType?: string;
  parentMetadata: ResourceTypeMetadata;
  depth: number;
};
