<data-table [columns]="columns"
                *ngIf="dataSource"
                class="robaws-data-table overview-table"
                [allowRowSelection]="false"
                [dataSource]="dataSource" />


<p-dialog [header]="'workflow-executions.messages' | translate"
          [draggable]="false"
          [resizable]="false"
          [dismissableMask]="true"
          [modal]="true"
          [(visible)]="visible"
          [style]="{width: '80vw'}">
  <workflow-execution-messages *ngIf="visible && selectedItem" [data]="selectedItem.messages || []"></workflow-execution-messages>
</p-dialog>
