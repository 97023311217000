import { Component, ComponentRef, DoCheck, Injector, Input, OnChanges, SimpleChanges, Type } from '@angular/core';
import { RobawsOperator, ViewContentType, ViewFilter } from '@app/robaws/domain';
import { PropertyPathSelectorComponent } from '@shared/components/property-path-selector';
import { DynamicResourceItem, MetadataWithPath, PageDTO, ResourceTypeMetadata, ResourceTypePropertyPossibleValue } from '@shared/domain';
import { RobawsOperatorSelectorComponent } from '@app/robaws/components/robaws-operation-builder/operator-selector/robaws-operator-selector.component';
import { DynamicComponent, DynamicIoDirective } from 'ng-dynamic-component';
import * as RobawsOperationBuilders from '@app/robaws/components/robaws-operation-builder/builders';
import { InputType } from '@shared/helpers';
import { PathService, ResourceTypeEntityParams } from '@shared/services';
import { DynamicResourceTypeProvider } from '@app/shared/services/dynamic-resource-type.provider';
import { NgIf } from '@angular/common';
import { detectInputTypeFromPathAndRobawsOperator } from '@app/robaws/helpers/robaws-input-type.helper';
import { Observable } from 'rxjs';
import { ViewFilterValueService } from '@app/robaws/services/view-filter-value-service.service';

@Component({
  selector: 'view-filter-editor',
  templateUrl: 'view-filter-editor.component.html',
  styleUrls: ['view-filter-editor.component.scss'],
  standalone: true,
  imports: [PropertyPathSelectorComponent, RobawsOperatorSelectorComponent, DynamicComponent, DynamicIoDirective, NgIf],
})
export class ViewFilterEditorComponent implements OnChanges, DoCheck {
  @Input({ required: true })
  public viewContentType: ViewContentType;
  @Input({ required: true })
  public viewFilter: ViewFilter;
  @Input({ required: true })
  public metadata: ResourceTypeMetadata;

  protected operationInputDataLoading = true;
  protected metadataWithPath?: MetadataWithPath;
  protected outputHandlers = {
    valueChange: (value: unknown) => this.onValueChange(value as string),
  };
  protected currentResourceTypeMetadata: ResourceTypeMetadata;
  protected expectedMetadata: ResourceTypeMetadata;
  protected possibleValues: ResourceTypePropertyPossibleValue[];
  protected currentInputType: InputType;
  protected currentOperationComponent?: Type<any>;
  private dynamicResourceTypeProvider = new DynamicResourceTypeProvider('ROBAWS');
  private previousViewFilter: ViewFilter;

  constructor(
    protected injector: Injector,
    private pathService: PathService,
    private viewFilterValueService: ViewFilterValueService,
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['viewFilter'] || changes['metadata']) {
      this.metadataWithPath = {
        metadata: this.metadata,
        path: this.viewFilter.path,
      };

      this.onFilterUpdate();
    }
  }

  public ngDoCheck(): void {
    if (!this.previousViewFilter) {
      this.previousViewFilter = { ...this.viewFilter };
    }

    if (this.viewFilter.operator !== this.previousViewFilter.operator || this.viewFilter.path !== this.previousViewFilter.path) {
      this.onFilterUpdate();
      this.previousViewFilter = { ...this.viewFilter };
    }
  }

  protected handlePathSelected(path: string): void {
    this.viewFilter.path = path;

    if (this.metadataWithPath) {
      this.metadataWithPath = {
        metadata: this.metadata,
        path: path,
      };
    }

    this.viewFilter.operator = 'EQUALS';
    this.onFilterUpdate();
  }

  protected handleOperatorSelected(event: RobawsOperator) {
    this.viewFilter.operator = event;
    this.viewFilter.value = '';
    this.onFilterUpdate();
  }

  protected onComponentCreated(componentRef: ComponentRef<any>): void {
    componentRef.location.nativeElement.classList.add('filter-value-input');
  }

  protected resourceEntityProvider(resourceType: string, params: ResourceTypeEntityParams): Observable<PageDTO<DynamicResourceItem>> {
    return this.viewFilterValueService.getFilterValues(
      this.viewContentType,
      resourceType,
      params.filter,
      params.page,
      params.pageSize ?? 100,
      params.id,
    );
  }

  private updateOperatorComponent(): void {
    const operationBuilder = Object.values(RobawsOperationBuilders).find((builder) => {
      // @ts-ignore
      return builder.isSupported(this.viewFilter.operator ?? 'EQUALS');
    });

    if (!operationBuilder) {
      this.currentOperationComponent = undefined;
    }
    this.currentOperationComponent = operationBuilder;
  }

  private onFilterUpdate(): void {
    if (!this.viewFilter.path) {
      return;
    }

    this.operationInputDataLoading = true;

    this.pathService.getPath(this.dynamicResourceTypeProvider, this.metadata.name, this.viewFilter.path, true).subscribe((path): void => {
      if (path) {
        this.currentResourceTypeMetadata = path.parentMetadata;
        this.currentInputType = detectInputTypeFromPathAndRobawsOperator(path, this.viewFilter.operator ?? 'EQUALS');

        if (path.possibleValues && path.possibleValues.length > 0) {
          this.possibleValues = path.possibleValues;
        }

        if (path.targetResourceType) {
          this.dynamicResourceTypeProvider.getMetadata(path.targetResourceType).subscribe((metadata) => {
            this.expectedMetadata = metadata;
            this.operationInputDataLoading = false;

            this.updateOperatorComponent();
          });
        } else {
          this.operationInputDataLoading = false;

          this.updateOperatorComponent();
        }
      }
    });
  }

  private onValueChange(value: string): void {
    this.viewFilter.value = value;
  }
}
