import { ComponentRef, ViewContainerRef } from '@angular/core';

import {
  AutomationResourceTypeMetadata,
  CopyValueNode,
  CreateRobawsResourceNode,
  DelayNode,
  DelayStrategy,
  DelayUnit,
  ExecuteRobawsResourceActionNode,
  FormatDataNode,
  NodeDTO,
  SendEmailNode,
  SendTextMessageNode,
  UnsupportedOperationException,
  UpdateRobawsResourceNode,
  WorkflowNodeType,
} from '@app/automation/domain';

import { UpdateRobawsResourceNodeComponent } from './update-robaws-resource-node/update-robaws-resource-node.component';
import { SendEmailNodeComponent } from './send-email-node/send-email-node.component';
import { FormatDataNodeComponent } from './format-data-node/format-data-node.component';
import { ExecuteRobawsResourceActionNodeComponent } from './execute-robaws-resource-action-node/execute-robaws-resource-action-node.component';
import { WorkflowNodeData } from './workflow-node/workflow-node-data';
import { CopyValueNodeComponent } from './copy-value-node/copy-value-node.component';
import { DelayNodeComponent } from './delay-node/delay-node.component';
import { SendTextMessageNodeComponent } from './send-text-message-node/send-text-message-node.component';
import { CreateRobawsResourceNodeComponent } from './create-robaws-resource-node/create-robaws-resource-node.component';

const componentMappings = new Map<WorkflowNodeType, any>([
  [WorkflowNodeType.update_robaws_resource, UpdateRobawsResourceNodeComponent],
  [WorkflowNodeType.send_email, SendEmailNodeComponent],
  [WorkflowNodeType.format_data, FormatDataNodeComponent],
  [WorkflowNodeType.execute_robaws_resource_action, ExecuteRobawsResourceActionNodeComponent],
  [WorkflowNodeType.copy_value, CopyValueNodeComponent],
  [WorkflowNodeType.delay, DelayNodeComponent],
  [WorkflowNodeType.send_text_message, SendTextMessageNodeComponent],
  [WorkflowNodeType.create_robaws_resource, CreateRobawsResourceNodeComponent],
]);

export function createNodeRepresentation(nodeType: WorkflowNodeType, nextNode: NodeDTO<any> | undefined, data?: any): NodeDTO<any> {
  switch (nodeType) {
    case WorkflowNodeType.update_robaws_resource: {
      return new NodeDTO<UpdateRobawsResourceNode>(nodeType, new UpdateRobawsResourceNode([], nextNode));
    }
    case WorkflowNodeType.send_email: {
      return new NodeDTO<SendEmailNode>(nodeType, new SendEmailNode([], [], [], '', '', nextNode));
    }
    case WorkflowNodeType.format_data: {
      return new NodeDTO<FormatDataNode>(nodeType, new FormatDataNode('', nextNode));
    }
    case WorkflowNodeType.execute_robaws_resource_action: {
      return new NodeDTO<ExecuteRobawsResourceActionNode>(
        nodeType,
        new ExecuteRobawsResourceActionNode(data.actionId, '', nextNode, data.parameters || {}),
      );
    }
    case WorkflowNodeType.copy_value: {
      return new NodeDTO<CopyValueNode>(nodeType, new CopyValueNode(undefined, undefined, nextNode));
    }
    case WorkflowNodeType.delay: {
      return new NodeDTO<DelayNode>(nodeType, new DelayNode(DelayStrategy.FIXED, DelayUnit.MINUTES, undefined, undefined, undefined, nextNode));
    }
    case WorkflowNodeType.send_text_message: {
      return new NodeDTO<SendTextMessageNode>(nodeType, new SendTextMessageNode([], '', nextNode));
    }
    case WorkflowNodeType.create_robaws_resource: {
      return new NodeDTO<CreateRobawsResourceNode>(nodeType, new CreateRobawsResourceNode(data.resourceType, {}, nextNode));
    }
    default: {
      throw new UnsupportedOperationException();
    }
  }
}

export function createNodeComponent(
  workflowId: string,
  previousNode: NodeDTO<any>,
  nodeType: WorkflowNodeType,
  node: NodeDTO<any>,
  resourceTypeMetadata: AutomationResourceTypeMetadata,
  viewContainerRef: ViewContainerRef,
  nextNode: ComponentRef<any> | undefined,
): ComponentRef<any> {
  let component: ComponentRef<any>;

  if (!componentMappings.has(nodeType)) {
    throw new UnsupportedOperationException();
  } else {
    if (nextNode) {
      component = viewContainerRef.createComponent(componentMappings.get(nodeType), {
        projectableNodes: [nextNode.location.nativeElement],
      });
    } else {
      component = viewContainerRef.createComponent(componentMappings.get(nodeType));
    }
  }

  component.instance.workflowNodeData = {
    currentNode: node,
    previousNode: previousNode,
    resourceTypeMetadata: resourceTypeMetadata,
    workflowId: workflowId,
  } as WorkflowNodeData<any>;

  return component;
}
