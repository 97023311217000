import { Injectable } from '@angular/core';
import { InternalServiceMessageEvent, InternalServiceMessageService } from './internal-service-message.service';

export type ActionButton = {
  tooltip: string;
  icon: string;
  callbackAction: string;
};

@Injectable({
  providedIn: 'platform',
})
export class PageService {
  constructor(private internalServiceMessageService: InternalServiceMessageService) {}

  public setPageTitle(title: string, subtitle: string | null = null) {
    this.internalServiceMessageService.send(new InternalServiceMessageEvent('robaws-ng', 'robaws', 'update-page-title', { title, subtitle }));
  }

  public setActionButtons(buttons: ActionButton[]) {
    this.internalServiceMessageService.send(new InternalServiceMessageEvent('robaws-ng', 'robaws', 'update-button-actions', buttons));
  }
}
