import { Injectable, ViewContainerRef } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RootContainerService {
  private viewContainerRef: ViewContainerRef;

  public setViewContainerRef(viewContainerRef: ViewContainerRef) {
    this.viewContainerRef = viewContainerRef;
  }

  public getViewContainerRef(): ViewContainerRef {
    return this.viewContainerRef;
  }
}
