<mat-card cdkDragLockAxis="y"
          cdkDrag (click)="selectCard()" tabindex="0" class="container-card drag-item">
  <hr class="accent-line" #accentLine primaryColor>
  <mat-card-title>
    <mat-icon fontIcon="quiz" />
    <p>{{ 'element-types.' + element.type | translate }}</p>
    <span class="spacer"></span>
    <mat-icon cdkDragHandle class="drag-handle" fontIcon="drag_handle"></mat-icon>
    <span class="spacer"></span>
    <div class="add-question-button">
      <mat-icon matTooltip="{{'form-templates.add-question-below' | translate}}" [matTooltipPosition]="'above'"
                fontIcon="add" class="add-question-icon"
                [matMenuTriggerFor]="questionMenu"></mat-icon>
      <mat-menu #questionMenu="matMenu" class="mat-menu-custom-style">
        <button mat-menu-item class="mat-menu-item-custom-style" *ngFor="let type of elementTypes"
                (click)="addElement(type)">{{
            'element-types.' + type |
              translate
          }}
        </button>
      </mat-menu>
    </div>

    <delete-icon styleClass="delete-button"
                 [confirmationMessage]="'confirmation.content.element' | translate"
                 (deleteAccepted)="deleteElement()" />
  </mat-card-title>
  <mat-card-content>
    <div [ngSwitch]="element.type">
      <app-multiple-choice [element]="element" *ngSwitchCase="'multiple_choice'" />
      <app-dropdown [element]="element" *ngSwitchCase="'dropdown'" />
      <app-date [element]="element" *ngSwitchCase="'date'" />
      <app-time [element]="element" *ngSwitchCase="'time'" />
      <app-text-area [element]="element" *ngSwitchCase="'text_area'" />
      <app-number-input [element]="element" *ngSwitchCase="'number_input'" />
      <app-description [element]="element" *ngSwitchCase="'description'" />
      <app-image [element]="element" *ngSwitchCase="'image'" />
      <app-signature [element]="element" *ngSwitchCase="'signature'" />
      <app-radio [element]="element" *ngSwitchCase="'radio'"></app-radio>
      <app-drawing [element]="element" *ngSwitchCase="'drawing'"></app-drawing>
    </div>
  </mat-card-content>
  @if (element.type !== 'description') {
    <mat-card-footer>
      <mat-card-actions>
        <mat-slide-toggle [(ngModel)]="element.configuration.required" color="primary">{{ 'required' | translate }}</mat-slide-toggle>
      </mat-card-actions>
    </mat-card-footer>
  }
</mat-card>
