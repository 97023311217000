<div [forceFullscreen]="true" [materialLoader]="isLoading$">
  <span class="alert info">
    <mat-icon class="icon" fontIcon="info" />
    {{ 'dataExportConfiguration.timeOfExportInfo' | translate }}
  </span>

  <mat-card *ngIf="data" class="card">
    <mat-card-header>
      <mat-card-title-group>
        <mat-card-title textPrimaryDarkColor>
          <span class="title">{{ 'status' | translate }}</span>
        </mat-card-title>

        <div class="actions">
          <button (click)="connect()" *ngIf="status && !status.status" mat-button primaryColor>
            {{ 'connect' | translate }}
          </button>
          <ng-container *ngIf="status && status.status">
            <button (click)="export('')" mat-button primaryColor>
              {{ 'exportNow' | translate }}
            </button>
            <button (click)="disconnect()" mat-button primaryColor>
              {{ 'disconnect' | translate }}
            </button>
          </ng-container>
        </div>
      </mat-card-title-group>
    </mat-card-header>
    <mat-card-content>
      @if (status && status.status) {
        <div class="exporting-status" *ngIf="status.exporting">
          <mat-spinner diameter="30" />
          <span>{{ 'dataExportConfiguration.exporting' | translate }}</span>
        </div>
        <div *ngIf="!status.exporting && status.lastSuccessfulExport">
          <p class="last-successful-export">
            {{ 'last_successful_export' | translate }}
            {{ status.lastSuccessfulExport | date : 'dd/MM/yyyy HH:mm' }}
          </p>

          <button mat-button primaryColor (click)="openSheet()">
            {{ 'dataExportConfiguration.openSheet' | translate }}
          </button>
        </div>
      }
    </mat-card-content>
  </mat-card>

  <mat-card (copy)="handleCopy()" (paste)="handlePaste($event)" class="card">
    <mat-card-header>
      <mat-card-title-group>
        <mat-card-title textPrimaryDarkColor>
          <span class="title">{{ 'dataExportConfiguration.name' | translate }}</span>
        </mat-card-title>
        <div class="actions">
          <button #tooltip="matTooltip" (click)="addResourceType()" [matTooltipPosition]="'above'"
                  [matTooltip]="'clickToAddModule' | translate"
                  mat-button
                  primaryColor>
            <mat-icon>add</mat-icon>
          </button>
          <button #tooltip="matTooltip" (click)="handleCopy()" [matTooltipPosition]="'above'"
                  [matTooltip]="'clickToCopy' | translate"
                  mat-button
                  primaryColor>
            <mat-icon>file_copy</mat-icon>
          </button>
          <button #tooltip="matTooltip" (click)="handleClickPaste()" [matTooltipPosition]="'above'"
                  [matTooltip]="'clickToPaste' | translate"
                  mat-button
                  primaryColor>
            <mat-icon>content_paste</mat-icon>
          </button>
        </div>
      </mat-card-title-group>
    </mat-card-header>
    <mat-card-content>
      <mat-accordion>
          <ng-container *ngFor="let config of data?.configurations || []; let i = index">
            <data-export-resource-type (deleteResourceType)="this.deleteResourceType($event)"
                                       (changeResourceType)="this.updateResourceTypeComponents()"
                                       (exportResourceType)="this.export($event)"
                                       (setExpanded)="setExpanded($event)"
                                       [data]="config"
                                       [index]="i"
                                       [isSelected]="i === expandedIndex"
                                       [exportConfiguration]="data" />
          </ng-container>
      </mat-accordion>
    </mat-card-content>
  </mat-card>
</div>
