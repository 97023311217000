import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { getResourceTypeWithLinksMetadata, ResourceTypeWithLinksMetadata } from '@shared/helpers';
import { CreatedConfigurerCard, RobawsNgConfigurerCardService } from '@shared/services';

import { ExecuteRobawsResourceActionNode, AutomationResourceTypeAction, AutomationResourceTypeLink } from '@app/automation/domain';
import { AutomationResourceTypeService } from '@app/automation/services';

import { ExecuteRobawsResourceActionNodeConfigurerComponent } from '../execute-robaws-resource-action-node/configurer/execute-robaws-resource-action-node-configurer.component';
import { AbstractWorkflowNodeComponent } from '../abstract-workflow-node-component';
import { WorkflowNodeData } from '../workflow-node/workflow-node-data';
import { TranslationHelper } from '@app/shared/helpers/translation.helper';

@Component({
  selector: 'execute-robaws-resource-action-node',
  templateUrl: 'execute-robaws-resource-action-node.component.html',
})
export class ExecuteRobawsResourceActionNodeComponent extends AbstractWorkflowNodeComponent {
  protected actionMetadatas: ResourceTypeWithLinksMetadata[] = [];
  private currentConfigurerCard: CreatedConfigurerCard<ExecuteRobawsResourceActionNodeConfigurerComponent>;

  // loading boolean is used so the configurer can still auto open even if there are less than 2 actions
  // once the actions are loaded and there are less than 2 actions, the configurer will close itself
  private loading = false;
  private isActionWithParameters = false;

  constructor(
    private automationResourceTypeService: AutomationResourceTypeService,
    private robawsNgConfigurerCardService: RobawsNgConfigurerCardService,
    private translateService: TranslateService,
    protected translationHelper: TranslationHelper,
  ) {
    super();
  }

  private _workflowNodeData: WorkflowNodeData<ExecuteRobawsResourceActionNode>;

  @Input({ required: true })
  get workflowNodeData(): WorkflowNodeData<ExecuteRobawsResourceActionNode> {
    return this._workflowNodeData;
  }

  set workflowNodeData(workflowNodeData: WorkflowNodeData<ExecuteRobawsResourceActionNode>) {
    this._workflowNodeData = workflowNodeData;
    this.loading = true;

    getResourceTypeWithLinksMetadata(this.automationResourceTypeService, workflowNodeData.resourceTypeMetadata).subscribe((metadatas) => {
      for (const metadata of metadatas) {
        if (metadata) {
          const actions = metadata.metadata.actions.filter((action) => {
            return action.id === this.workflowNodeData.currentNode.configuration.action;
          });

          if (actions.length > 0) {
            const action = actions[0];

            this.actionMetadatas.push(metadata);
            this.isActionWithParameters = action.parameters != undefined && action.parameters.length > 0;
          }
        }
      }
      this.loading = false;
      if (this.currentConfigurerCard && this.actionMetadatas.length <= 1) {
        // close configurer if there is only one action
        this.robawsNgConfigurerCardService.closeConfigurerCard(this.currentConfigurerCard.uuid);
      }
    });
  }

  public openConfigurer(): void {
    if (!this.loading && this.actionMetadatas.length <= 1 && !this.isActionWithParameters) {
      // don't open configurer if there is only one action
      return;
    }

    this.currentConfigurerCard = this.robawsNgConfigurerCardService.createAndOpenConfigurerCard(ExecuteRobawsResourceActionNodeConfigurerComponent, {
      title: this.translateService.instant('nodes.execute-robaws-resource.title'),
      inputs: {
        currentNode: this.workflowNodeData.currentNode,
        resourceTypeMetadata: this.workflowNodeData.resourceTypeMetadata,
        actionMetadatas: this.actionMetadatas,
      },
    });
  }

  protected getActionDisplayName(): string {
    const action = this.workflowNodeData.resourceTypeMetadata.actions.find(
      (action: AutomationResourceTypeAction) => action.id === this.workflowNodeData.currentNode.configuration.action,
    );

    return action ? action.name : '';
  }

  protected getResourceTypeDisplayName(): string {
    if (this.workflowNodeData.currentNode.configuration.link) {
      const link = this.workflowNodeData.resourceTypeMetadata.links.find(
        (link: AutomationResourceTypeLink) => link.name === this.workflowNodeData.currentNode.configuration.link,
      );

      return link ? link.displayName.toLowerCase() : '';
    }
    return this.workflowNodeData.resourceTypeMetadata.displayName.toLowerCase();
  }
}
