<workflow-node (nodeClick)="openConfigurer()" [workflowNodeData]="workflowNodeData">

  <span slot="title">{{ 'nodes.copy-value.title' | translate }}</span>

  <div slot="content">
    <div
      *ngIf="workflowNodeData.currentNode.configuration.sourcePath && workflowNodeData.currentNode.configuration.targetPath">
      <p class="description"
         [innerHTML]="'nodes.copy-value.description.detailed' | translate: {
        'source': sourcePathDisplayName$ | async,
        'target': targetPathDisplayName$ | async
       }">
      </p>
    </div>
    <p
      *ngIf="!workflowNodeData.currentNode.configuration.sourcePath || !workflowNodeData.currentNode.configuration.targetPath"
      class="description">
      {{ 'nodes.copy-value.description.default' | translate }}
    </p>
  </div>
</workflow-node>
