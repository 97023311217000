import { Component, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Subscription, switchMap } from 'rxjs';
import { filter, map, pairwise } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { DynamicDataSource, LinkTableColumnComponent, TableColumn, TextTableColumnComponent } from '@ui/data-table';

import { InternalServiceMessageService, PageService, ToastService, UrlService } from '@shared/services';
import { AbstractWebComponent } from '@shared/components/abstract-web.component';
import { CallbackActions } from '@shared/domain';

import { AutomationResourceType, WorkflowDTO } from '@app/automation/domain';
import { AutomationResourceTypeService, WorkflowService } from '@app/automation/services';
import { DataTableComponent } from '@ui/data-table/data-table.component';

@Component({
  selector: 'automation-workflows-overview',
  templateUrl: 'list-workflows.component.html',
})
export class ListWorkflowsComponent extends AbstractWebComponent implements OnInit, OnDestroy {
  protected dataSource: DynamicDataSource<WorkflowDTO>;
  @ViewChild(DataTableComponent)
  protected workflowsTable?: DataTableComponent<WorkflowDTO>;
  private messageReceiver?: Subscription;
  private workflowReceiver?: Subscription;
  private resourceTypes: AutomationResourceType[] = [];

  protected columns: TableColumn<WorkflowDTO>[] = [
    new TableColumn<WorkflowDTO>('name', this.translateService.instant('name'), LinkTableColumnComponent, (it) => {
      return {
        content: it.name,
        link: () => this.urlService.navigateToEditWorkflow(it.workflowId),
      };
    }),
    new TableColumn<WorkflowDTO>('resourceType', this.translateService.instant('resourceType'), TextTableColumnComponent, (it) => {
      const resourceType = this.resourceTypes.find(({ name }) => it.resourceType === name);
      return { content: resourceType?.displayName || it.resourceType };
    }),
    new TableColumn<WorkflowDTO>('active', this.translateService.instant('active'), TextTableColumnComponent, (it) => {
      return { content: this.translateService.instant(it.enabled ? 'yes' : 'no') };
    }),
  ];

  constructor(
    protected override viewContainerRef: ViewContainerRef,
    public workflowService: WorkflowService,
    private automationResourceTypeService: AutomationResourceTypeService,
    private pageService: PageService,
    private internalServiceMessageService: InternalServiceMessageService,
    private translateService: TranslateService,
    private toastService: ToastService,
    private urlService: UrlService,
  ) {
    super(viewContainerRef);
  }

  ngOnInit(): void {
    this.pageService.setPageTitle(this.translateService.instant('workflows.title'));

    this.initDataSource();

    // Skip first state
    this.workflowReceiver = this.workflowService.isSaving$
      .pipe(
        pairwise(),
        filter(([prev, curr]) => {
          return prev !== curr;
        }),
        map(([, curr]) => curr),
      )
      .subscribe((isLoading) => {
        this.dataSource?.setLoading(isLoading);
        if (!isLoading) {
          this.dataSource?.refresh();
        }
      });

    this.messageReceiver = this.internalServiceMessageService
      .onMessageReceive('any')
      .pipe(
        filter((it) => it.action === 'button-clicked'),
        filter((it) => it.data === CallbackActions.DELETE_SELECTED_WORKFLOWS),
      )
      .subscribe(() => {
        if (!this.workflowsTable) {
          return;
        }

        this.deleteSelectedWorkflows();
      });
  }

  initDataSource() {
    this.dataSource = new DynamicDataSource<WorkflowDTO>((filter, page, pageSize) => {
      return this.automationResourceTypeService.getResourceTypes().pipe(
        switchMap((data) => {
          this.resourceTypes = data;
          return this.workflowService.getWorkflows(filter, page, pageSize);
        }),
      );
    });
  }

  ngOnDestroy() {
    if (this.messageReceiver) {
      this.messageReceiver.unsubscribe();
    }

    if (this.workflowReceiver) {
      this.workflowReceiver.unsubscribe();
    }
  }

  private deleteSelectedWorkflows(): void {
    const selectedItems = this.workflowsTable?.getCurrentSelections();

    if (!selectedItems || selectedItems.length == 0) {
      this.toastService.fireToast(this.translateService.instant('noItemsSelected'));
      return;
    }

    this.workflowService.deleteWorkflow(selectedItems).subscribe({
      complete: () => {
        this.dataSource.refresh();
      },
    });
  }
}
