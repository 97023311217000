import { Condition } from '@shared/domain';

import { MatchType } from '../MatchType';
import { WorkflowNode } from './WorkflowNode';
import { NodeDTO } from './NodeDTO';

export class RobawsTriggerNode extends WorkflowNode {
  constructor(
    public conditions: Condition[],
    public matchType: MatchType,
    public reEnrollmentAllowed: boolean,
    public override nextNode?: NodeDTO<any>,
  ) {
    super(nextNode);
  }
}
