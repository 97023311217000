import { Observable, of } from 'rxjs';

import { WorkflowNodeType } from '@app/automation/domain';

import { WorkflowNodeCategoryType, WorkflowNodeTypeVO } from '../../add-node-configurer/add-node-configurer.component';
import { NodeProvider } from '../node-provider';

export const sendTextMessageNodeProvider: NodeProvider = (): Observable<WorkflowNodeTypeVO[]> => {
  return of([
    {
      type: WorkflowNodeType.send_text_message,
      category: WorkflowNodeCategoryType.ACTION,
      icon: 'sms',
      color: 'darkorange',
      priority: 7,
    },
  ]);
};
