import { FormTemplateElement } from '@app/form-template/domain/elementtypes/FormTemplateElement';

export class MultipleChoiceElement extends FormTemplateElement {
  constructor(
    public override question: string,
    public override required: boolean,
    public answerOptions: string[],
    public isExpandable: boolean,
  ) {
    super(question, required);
  }
}
