import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { BrowserModule } from '@angular/platform-browser';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { NodesModule } from '../../nodes/nodes.module';
import { WorkflowRevisionComponent } from './workflow-revision.component';
import { MaterialLoaderDirective } from '@ui/material-loader/material-loader.directive';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    MatButtonModule,
    NodesModule,
    MatDialogModule,
    MatInputModule,
    FormsModule,
    TranslateModule,
    MatCheckboxModule,
    MaterialLoaderDirective,
  ],
  declarations: [WorkflowRevisionComponent],
})
export class WorkflowRevisionModule {}
