import { Component, Input } from '@angular/core';

import { FormTemplateSection } from '@app/form-template/domain';

@Component({
  selector: 'edit-form-sections',
  templateUrl: './edit-form-sections.component.html',
  styleUrls: ['./edit-form-sections.component.scss'],
})
export class EditFormSectionsComponent {
  @Input({ required: true })
  public sections: FormTemplateSection[];

  public addSectionBelow(index: number): void {
    this.sections.splice(index + 1, 0, new FormTemplateSection('', []));
  }
  public deleteSection(section: FormTemplateSection): void {
    const index = this.sections.indexOf(section, 0);
    if (index > -1) {
      this.sections.splice(index, 1);
    }
  }
}
