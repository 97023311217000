import { Component, Input, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { PathService, RobawsNgConfigurerCardService } from '@shared/services';

import { CopyValueNode } from '@app/automation/domain';

import { AbstractWorkflowNodeComponent } from '../../nodes/abstract-workflow-node-component';
import { WorkflowNodeData } from '../workflow-node/workflow-node-data';
import { CopyValueNodeConfigurerComponent } from './copy-value-node-configurer/copy-value-node-configurer.component';
import { DynamicResourceTypeProvider } from 'src/app/shared/services/dynamic-resource-type.provider';

@Component({
  selector: 'copy-value-node',
  templateUrl: 'copy-value-node.component.html',
})
export class CopyValueNodeComponent extends AbstractWorkflowNodeComponent implements OnDestroy {
  public sourcePathDisplayName$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public targetPathDisplayName$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private configurationChangeSubscription: Subscription;
  private dynamicResourceTypeProvider = new DynamicResourceTypeProvider('AUTOMATION');

  constructor(
    private robawsNgConfigurerCardService: RobawsNgConfigurerCardService,
    private translateService: TranslateService,
    private pathService: PathService,
  ) {
    super();
  }

  private _workflowNodeData: WorkflowNodeData<CopyValueNode>;

  @Input({ required: true })
  public get workflowNodeData(): WorkflowNodeData<CopyValueNode> {
    return this._workflowNodeData;
  }

  public set workflowNodeData(workflowNodeData: WorkflowNodeData<CopyValueNode>) {
    this._workflowNodeData = workflowNodeData;
    this.updateSourcePathDisplayName();
    this.updateTargetPathDisplayName();
  }

  public ngOnDestroy(): void {
    this.unsubscribe();
  }

  public openConfigurer(): void {
    this.unsubscribe();

    const currentConfigurer = this.robawsNgConfigurerCardService.createAndOpenConfigurerCard(CopyValueNodeConfigurerComponent, {
      title: this.translateService.instant('nodes.copy-value.title'),
      inputs: {
        currentNode: this.workflowNodeData.currentNode,
        resourceTypeMetadata: this.workflowNodeData.resourceTypeMetadata,
      },
    });

    this.configurationChangeSubscription = currentConfigurer.contentRef.instance.onConfigurationChange.subscribe(() => {
      this.updateSourcePathDisplayName();
      this.updateTargetPathDisplayName();
    });
  }

  private unsubscribe() {
    if (this.configurationChangeSubscription) {
      this.configurationChangeSubscription.unsubscribe();
    }
  }

  private updateSourcePathDisplayName() {
    if (this.workflowNodeData.currentNode.configuration.sourcePath) {
      this.pathService
        .getPath(
          this.dynamicResourceTypeProvider,
          this.workflowNodeData.resourceTypeMetadata.name,
          this.workflowNodeData.currentNode.configuration.sourcePath,
        )
        .subscribe((path) => {
          this.sourcePathDisplayName$.next(path?.displayNameDeep ?? '');
        });
    } else {
      this.sourcePathDisplayName$.next('');
    }
  }

  private updateTargetPathDisplayName() {
    if (this.workflowNodeData.currentNode.configuration.targetPath) {
      this.pathService
        .getPath(
          this.dynamicResourceTypeProvider,
          this.workflowNodeData.resourceTypeMetadata.name,
          this.workflowNodeData.currentNode.configuration.targetPath,
        )
        .subscribe((path) => {
          this.targetPathDisplayName$.next(path?.displayNameDeep ?? '');
        });
    } else {
      this.targetPathDisplayName$.next('');
    }
  }
}
