import { WorkflowNode } from './WorkflowNode';
import { NodeDTO } from '@app/automation/domain';

export class DelayNode extends WorkflowNode {
  constructor(
    public strategy: DelayStrategy,
    public unit: DelayUnit,
    public path?: string,
    public value?: number,
    public delayNodeId?: string,
    public override nextNode?: NodeDTO<any>,
  ) {
    super(nextNode);
  }
}

export enum DelayStrategy {
  FIXED = 'FIXED',
  RELATIVE_TO_PATH_VALUE = 'RELATIVE_TO_PATH_VALUE',
}

export enum DelayUnit {
  MINUTES = 'MINUTES',
  HOURS = 'HOURS',
  DAYS = 'DAYS',
}
