import { Component, Input, OnInit, ViewContainerRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { AbstractWebComponent } from '@shared/components/abstract-web.component';
import { PageService } from '@shared/services';

import { WorkflowService } from '@app/automation/services';
import { WorkflowDTO, WorkflowUpdateDTO } from '@app/automation/domain';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'workflow-revision',
  templateUrl: 'workflow-revision.component.html',
  styleUrls: ['workflow-revision.component.scss'],
})
export class WorkflowRevisionComponent extends AbstractWebComponent implements OnInit {
  @Input({ required: true })
  public revisionId: string;
  protected currentWorkflow?: WorkflowUpdateDTO;
  protected isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  protected workflowId: string;

  constructor(
    protected override viewContainerRef: ViewContainerRef,
    private pageService: PageService,
    private workflowService: WorkflowService,
    private translateService: TranslateService,
  ) {
    super(viewContainerRef);
  }

  public ngOnInit(): void {
    this.isLoading$.next(true);

    this.workflowService.findWorkflowByRevisionId(this.revisionId).subscribe({
      next: (workflow: WorkflowDTO) => {
        this.workflowId = workflow.workflowId;
        this.currentWorkflow = workflow.toWorkflowUpdateDTO();
      },
      complete: () => {
        this.updatePageName();
        this.isLoading$.next(false);
      },
    });
  }

  private updatePageName(): void {
    if (this.currentWorkflow && this.currentWorkflow.name) {
      this.pageService.setPageTitle(this.currentWorkflow.name, '(' + this.translateService.instant('revision') + ' ' + this.revisionId + ')');
    }
  }
}
