import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { InternalHttpInterceptor } from '@app/shared/http';
import { UrlService } from '@shared/services';

@Injectable()
export class LoginUnauthorizedInterceptor extends InternalHttpInterceptor {
  constructor(private urlService: UrlService) {
    super();
  }

  interceptInternally(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          this.urlService.navigateToUnauthorizedLogin();
        }
        return throwError(() => error);
      }),
    );
  }
}
