import { InputType } from '@shared/helpers';

export type Operator =
  | 'EQUALS'
  | 'NOT_EQUALS'
  | 'STARTS_WITH'
  | 'ENDS_WITH'
  | 'CONTAINS'
  | 'NOT_CONTAINS'
  | 'IS_EMPTY'
  | 'IS_NOT_EMPTY'
  | 'LOWER_THAN'
  | 'LOWER_THAN_OR_EQUALS'
  | 'GREATER_THAN'
  | 'GREATER_THAN_OR_EQUALS'
  | 'IS_BEFORE_NOW'
  | 'IS_AFTER_NOW';

export function getLinkOperators(): { operator: Operator; needsValue: boolean; inputType: InputType }[] {
  return [
    { operator: 'EQUALS', needsValue: true, inputType: 'COMBOBOX' },
    { operator: 'NOT_EQUALS', needsValue: true, inputType: 'COMBOBOX' },
    { operator: 'IS_EMPTY', needsValue: false, inputType: 'STRING' },
    { operator: 'IS_NOT_EMPTY', needsValue: false, inputType: 'STRING' },
  ];
}
