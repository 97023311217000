import { Component, Input } from '@angular/core';

import { TableColumnData } from '../table-column';

@Component({
  templateUrl: 'link-table-column.component.html',
})
export class LinkTableColumnComponent {
  @Input()
  public data?: TableColumnData;

  onLinkClicked(event: MouseEvent): void {
    event.stopPropagation();
    if (this.data && this.data.link) {
      this.data.link();
    }
  }
}
