<mat-form-field>
  <mat-label>{{'question' | translate}}</mat-label>
  <input matInput [(ngModel)]="element.configuration.question" (click)="selectTitle($event)">
</mat-form-field>
<div>
  <div class="drop-list" cdkDropList (cdkDropListDropped)="drop($event)">
    <div class="option-list drag-item"
         *ngFor="let option of element.configuration.answerOptions; let i = index; trackBy: trackIndex;"
         cdkDragLockAxis="y"
         cdkDragBoundary=".drop-list"
         cdkDrag>
      <mat-icon class="drag-icon-hidden" fontIcon="drag_indicator" cdkDragHandle></mat-icon>
      <mat-icon fontIcon="radio_button_unchecked"></mat-icon>

      <input #inputField
             class="option-input"
             type="text"
             placeholder="Give an option"
             [(ngModel)]="element.configuration.answerOptions[i]"
             [ngModelOptions]="{standalone: true}"
             (click)="selectText(inputField)">

      <mat-icon class="delete-option" fontIcon="close" (click)="deleteOption(i)"></mat-icon>
    </div>
  </div>
  <div class="btn-container">
    <button class="add-option-element" mat-raised-button
            (click)="addOption()">{{'form-templates.add-option' | translate}}</button>
  </div>
</div>
