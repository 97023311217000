export interface PageDTO<T> {
  readonly items: T[];
  readonly size: number;
  readonly currentPage: number;
  readonly totalPages: number;
  readonly totalItems: number;
}

export const EMPTY = new (class EmptyPage<T> implements PageDTO<T> {
  items = [];
  size = 1;
  currentPage = 1;
  totalPages = 1;
  totalItems = 0;
})();
