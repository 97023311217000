import { Component, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { filter, map, pairwise } from 'rxjs/operators';

import { AbstractWebComponent } from '@app/shared';
import { CallbackActions } from '@shared/domain';
import { InternalServiceMessageService, PageService, ToastService, UrlService } from '@shared/services';

import { DynamicDataSource, LinkTableColumnComponent, TableColumn } from '@ui/data-table';

import { FormTemplateService } from '@app/form-template/services';
import { FormTemplateListViewDTO } from '@app/form-template/domain';
import { DataTableComponent } from '@ui/data-table/data-table.component';

@Component({
  selector: 'app-list-form-templates',
  templateUrl: './list-form-templates.component.html',
  styleUrls: ['./list-form-templates.component.scss'],
})
export class ListFormTemplatesComponent extends AbstractWebComponent implements OnInit, OnDestroy {
  protected columns: TableColumn<FormTemplateListViewDTO>[] = [
    new TableColumn<FormTemplateListViewDTO>('name', this.translateService.instant('name'), LinkTableColumnComponent, (it) => {
      return { content: it.name, link: () => this.urlService.navigateToEditFormTemplate(String(it.formTemplateId)) };
    }),
  ];

  protected dataSource: DynamicDataSource<FormTemplateListViewDTO>;
  @ViewChild(DataTableComponent)
  protected formTemplateTable?: DataTableComponent<FormTemplateListViewDTO>;
  private messageReceiver?: Subscription;
  private formTemplateReceiver?: Subscription;

  constructor(
    public formTemplateService: FormTemplateService,
    private pageService: PageService,
    protected override viewContainerRef: ViewContainerRef,
    private translateService: TranslateService,
    private internalServiceMessageService: InternalServiceMessageService,
    private urlService: UrlService,
    private toastService: ToastService,
  ) {
    super(viewContainerRef);
  }

  ngOnInit(): void {
    this.pageService.setPageTitle(this.translateService.instant('form-templates.title'));

    // Skip first state
    this.formTemplateReceiver = this.formTemplateService.isSaving$
      .pipe(
        pairwise(),
        filter(([prev, curr]) => {
          return prev !== curr;
        }),
        map(([, curr]) => curr),
      )
      .subscribe((isLoading) => {
        this.dataSource?.setLoading(isLoading);
        if (!isLoading) {
          this.dataSource?.refresh();
        }
      });

    this.messageReceiver = this.internalServiceMessageService
      .onMessageReceive('any')
      .pipe(filter((it) => it.action === 'button-clicked'))
      .subscribe((event) => {
        if (!this.formTemplateTable) {
          return;
        }

        if (event.data === CallbackActions.DELETE_SELECTED_FORM_TEMPLATES) {
          this.deleteSelectedFormTemplates();
        } else if (event.data === CallbackActions.CREATE_NEW_FORM_TEMPLATES) {
          this.createNewFormTemplate();
        }
      });
    this.initDataSource();
  }

  initDataSource() {
    this.dataSource = new DynamicDataSource<FormTemplateListViewDTO>((filter, page, pageSize) => {
      return this.formTemplateService.getFormTemplates(filter, page, pageSize);
    });
  }

  ngOnDestroy(): void {
    if (this.messageReceiver) {
      this.messageReceiver.unsubscribe();
    }
  }

  private deleteSelectedFormTemplates(): void {
    const selectedItems = this.formTemplateTable?.getCurrentSelections();

    if (!selectedItems || selectedItems.length == 0) {
      this.toastService.fireToast(this.translateService.instant('noItemsSelected'));
      return;
    }
    this.formTemplateService.deleteFormTemplate(selectedItems).subscribe({
      complete: () => {
        this.dataSource.refresh();
      },
    });
  }

  private createNewFormTemplate(): void {
    this.formTemplateService.createEmptyFormTemplate().subscribe((formTemplateId) => {
      this.urlService.navigateToEditFormTemplate(String(formTemplateId));
    });
  }
}
