import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatSortModule } from '@angular/material/sort';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatInputModule } from '@angular/material/input';

import { TableModule } from 'primeng/table';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { CheckboxModule } from 'primeng/checkbox';
import { DataTableComponent } from './data-table.component';
import { TableColumnModule } from './column/table-column.module';
import { DataTableSearchComponent } from './search/data-table-search.component';
import { DataTableBulkActionsComponent } from './bulk-actions/bulk-actions.component';
import { PropertyPathSelectorComponent } from '@shared/components/property-path-selector';
import { MaterialLoaderDirective } from '@ui/material-loader/material-loader.directive';
import { TextAccentColorDirective } from '@ui/theme/text-accent-color.directive';
import { PrimaryColorDirective } from '@ui/theme/primary-color.directive';
import { PrimaryDarkColorDirective } from '@ui/theme/primary-dark-color.directive';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,

    //Material UI
    MatPaginatorModule,
    MatTableModule,
    MatSortModule,
    TableColumnModule,
    MatIconModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatInputModule,
    DragDropModule,

    //PrimeNG
    TableModule,
    DialogModule,
    DividerModule,
    DropdownModule,
    InputTextModule,
    CheckboxModule,

    PropertyPathSelectorComponent,
    MaterialLoaderDirective,
    TextAccentColorDirective,
    PrimaryColorDirective,
    PrimaryDarkColorDirective,
  ],
  exports: [DataTableComponent],
  declarations: [DataTableComponent, DataTableSearchComponent, DataTableBulkActionsComponent],
})
export class DataTableModule {}
