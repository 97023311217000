import { getLinkOperators, Operator } from '@app/shared/domain/Operator';
import { InputType } from '@shared/helpers';

export type RobawsOperator = Operator | 'IS_BETWEEN' | 'IS_NOT_BETWEEN' | 'IS_ONE_OF' | 'IS_NOT_ONE_OF';

export function getLinkRobawsOperators(): { operator: RobawsOperator; needsValue: boolean; inputType: InputType }[] {
  return [
    ...getLinkOperators(),
    { operator: 'IS_ONE_OF', needsValue: true, inputType: 'MULTISELECT_COMBOBOX' },
    { operator: 'IS_NOT_ONE_OF', needsValue: true, inputType: 'MULTISELECT_COMBOBOX' },
  ];
}

export function getPossibleValuesRobawsOperators(): { operator: RobawsOperator; needsValue: boolean; inputType: InputType }[] {
  return [...getLinkRobawsOperators()];
}
