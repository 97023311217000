import { Component, Input } from '@angular/core';
import { AutomationResourceTypeMetadata, ExecuteRobawsResourceActionNode, NodeDTO } from '@app/automation/domain';
import { detectInputType, InputType, ResourceTypeWithLinksMetadata } from '@shared/helpers';
import { MetadataProviderType, ResourceTypeActionParameter, ResourceTypeMetadata } from '@shared/domain';
import { AUTOMATION_RESOURCE_TYPE_MAPPER } from '@app/automation/services';

type CustomParameter = ResourceTypeActionParameter & {
  inputType: InputType;
};

@Component({
  selector: 'execute-robaws-resource-action-node-configurer',
  templateUrl: 'execute-robaws-resource-action-node-configurer.component.html',
  styleUrls: ['execute-robaws-resource-action-node-configurer.component.scss'],
})
export class ExecuteRobawsResourceActionNodeConfigurerComponent {
  @Input()
  public currentNode: NodeDTO<ExecuteRobawsResourceActionNode>;

  public currentMetadata: ResourceTypeMetadata;

  public parameters: CustomParameter[] = [];
  public actionMetadatas: ResourceTypeWithLinksMetadata[];
  protected metadataProviderType: MetadataProviderType = 'AUTOMATION';

  @Input()
  public set resourceTypeMetadata(data: AutomationResourceTypeMetadata) {
    this.currentMetadata = AUTOMATION_RESOURCE_TYPE_MAPPER.mapMetadata(data);
    this.parameters = this.getParameters();
  }

  get values(): Record<string, string> {
    return this.currentNode.configuration.parameters || {};
  }

  protected handleParameterChange(key: string, value: string): void {
    const {
      configuration: { parameters },
    } = this.currentNode;

    this.currentNode.configuration.parameters = {
      ...(parameters || {}),
      [key]: value,
    };
  }

  protected getParameters(): CustomParameter[] {
    const parameters = this.currentMetadata?.actions?.find(({ name }) => name === this.currentNode.configuration.action)?.parameters || [];

    return parameters.map((item) => {
      return {
        ...item,
        inputType: detectInputType(item.dataType, item.possibleValues || []),
      } as CustomParameter;
    });
  }
}
