<ng-select *ngIf="actionMetadatas.length > 1"
           [(ngModel)]="currentNode.configuration.link"
           [items]="actionMetadatas"
           bindLabel="displayName"
           bindValue="name"
           [placeholder]="'resourceType' | translate" />

<ng-container *ngFor="let parameter of parameters">
  <dynamic-type-input [value]="values[parameter.name] || ''"
                      [label]="parameter.displayName"
                      [metadataProviderType]="metadataProviderType"
                      [metadata]="this.currentMetadata"
                      (valueChange)="handleParameterChange(parameter.name, $event)"
                      [inputData]="{ expectedInputTypeMetadata: this.currentMetadata, possibleValues: parameter.possibleValues }"
                      [inputType]="parameter.inputType" />
</ng-container>
