import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { MetadataProviderType } from '@shared/domain';

import { AutomationResourceTypeMetadata, NodeDTO } from '@app/automation/domain';
import { SendTextMessageNode } from '@app/automation/domain/nodes/SendTextMessageNode';
import { concat, firstValueFrom, Observable, of } from 'rxjs';
import { PathService, TreePath } from '@shared/services';
import { AUTOMATION_RESOURCE_TYPE_MAPPER } from '@app/automation/services';
import { map } from 'rxjs/operators';
import { DynamicResourceTypeProvider } from '@app/shared/services/dynamic-resource-type.provider';
import { TreeNode } from '@ui/tree-select/tree-node.type';

@Component({
  selector: 'send-text-message-node-configurer',
  templateUrl: 'send-text-message-node-configurer.component.html',
  styleUrls: ['send-text-message-node-configurer.component.scss'],
})
export class SendTextMessageNodeConfigurerComponent implements OnInit {
  @Input()
  public currentNode: NodeDTO<SendTextMessageNode>;

  @Input()
  public resourceTypeMetadata: AutomationResourceTypeMetadata;

  @Output()
  public onReload: EventEmitter<void> = new EventEmitter<void>();
  toPath: any[];
  protected metadataProviderType: MetadataProviderType = 'AUTOMATION';
  protected toPaths$: Observable<any[]>;
  private dynamicResourceTypeProvider = new DynamicResourceTypeProvider(this.metadataProviderType);

  constructor(private pathService: PathService) {}

  public ngOnInit(): void {
    this.loadToPaths();
  }

  protected addLiteralValue(name: string) {
    return { path: name, displayName: name };
  }

  protected onDestinationsChange(destinations: string[]): void {
    this.currentNode.configuration.destinations = destinations;
    this.onReload.emit();
  }

  private loadToPaths() {
    this.toPaths$ = concat(
      of(this.currentNode.configuration.destinations.filter((it) => !it.startsWith('$')).map((it) => this.phoneNumberToPath(it))),
      this.pathService.getTreePaths(this.dynamicResourceTypeProvider, this.resourceTypeMetadata.name, true),
    ).pipe(map((paths) => this.mapPathsToNodes(paths)));

    firstValueFrom(this.toPaths$).then((values) => {
      this.toPath = values || [];
    });
  }

  private mapPathsToNodes(paths: TreePath[]): TreeNode[] {
    return paths.map((path) => ({
      value: path.path,
      label: path.displayName,
      selectedLabel: path.displayNameDeep,
      children: this.mapPathsToNodes(path.children),
      selectable: true,
    }));
  }

  private phoneNumberToPath(phoneNumber: string): TreePath {
    return {
      pathType: null,
      path: phoneNumber,
      name: phoneNumber,
      sortable: false,
      primary: false,
      displayName: phoneNumber,
      displayNameDeep: phoneNumber,
      groupName: '',
      groupDisplayName: '',
      possibleValues: [],
      parentMetadata: AUTOMATION_RESOURCE_TYPE_MAPPER.mapMetadata(this.resourceTypeMetadata),
      children: [],
      depth: 0,
    };
  }
}
