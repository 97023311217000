import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuditLogType } from '@app/automation/domain/AuditLogType';
import { AuditLogDTO } from '@app/automation/domain/AuditLogDTO';

@Injectable({
  providedIn: 'platform',
})
export class AutomationAuditLogService {
  constructor(private httpClient: HttpClient) {}

  public getAuditLogs(type: AuditLogType, id: string): Observable<AuditLogDTO[]> {
    return this.httpClient.get<AuditLogDTO[]>(`/automation/auditlog/${type}/${id}`);
  }
}
