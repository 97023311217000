import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

import { InternalHttpInterceptor } from './internal-http-interceptor';

@Injectable()
export class AddNoCacheHeadersInterceptor extends InternalHttpInterceptor {
  interceptInternally(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(
      req.clone({
        headers: req.headers.set('Cache-Control', 'no-cache').set('Pragma', 'no-cache').set('If-Modified-Since', '0'),
      }),
    );
  }
}
