<workflow-node (nodeClick)="openConfigurer()" [workflowNodeData]="workflowNodeData">

  <span slot="title">{{ 'nodeType.send_text_message' | translate }}</span>

  <div slot="content">
    <p *ngIf="!receivers"
       [innerHTML]="'nodes.send-text-message.description.default' | translate"
       class="description">
    </p>
    <p *ngIf="receivers"
       [innerHTML]="'nodes.send-text-message.description.detailed' | translate: {
              receivers: receivers
       }"
       class="description">
    </p>
  </div>
</workflow-node>
