<mat-form-field class="question-input">
  <mat-label>{{'question' | translate}}</mat-label>
  <input matInput [(ngModel)]="element.configuration.question">
</mat-form-field>

<mat-form-field>
  <mat-label>{{'element-types.drawing' | translate}}</mat-label>
  <input matInput disabled>
  <mat-icon matSuffix fontIcon="draw"></mat-icon>
</mat-form-field>
