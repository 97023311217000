import { Component, Input } from '@angular/core';
import { ElementDTO } from '@app/form-template/domain';
import { DrawingElement } from '@app/form-template/domain/elementtypes/DrawingElement';

@Component({
  selector: 'app-drawing',
  templateUrl: './drawing.component.html',
  styleUrl: './drawing.component.scss',
})
export class DrawingComponent {
  @Input({ required: true })
  element: ElementDTO<DrawingElement>;
}
