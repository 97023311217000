import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TreeNode } from '@ui/tree-select/tree-node.type';
import { Tree } from '@ui/tree-select/tree';
import { MatIcon } from '@angular/material/icon';
import { NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'tree-select-item',
  templateUrl: 'tree-select-item.component.html',
  styleUrls: ['tree-select-item.component.scss'],
  standalone: true,
  imports: [MatIcon, NgIf, TranslateModule],
})
export class TreeSelectItemComponent implements OnInit, OnChanges {
  @Input({ required: true })
  public tree: Tree;

  @Input({ required: true })
  public node: TreeNode;

  @Input({ required: true })
  public value: string | string[] | undefined;

  @Input()
  public showExpandIcon = false;

  @Input()
  public expanded = false;

  @Input()
  public isCustomValue = false;

  @Output()
  public itemSelected = new EventEmitter<TreeNode>();

  @Output()
  public expandedChange = new EventEmitter<boolean>();

  protected selected = false;

  protected get showCustomValue(): boolean {
    return this.isCustomValue;
  }

  public ngOnInit(): void {
    this.reload();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['tree'] || changes['value']) {
      this.reload();
    }
  }

  protected onIconClick(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    this.expandedChange.emit(!this.expanded);
  }

  protected onItemSelect(): void {
    if (this.node.selectable) {
      this.itemSelected.emit(this.node);
    }
  }

  private reload(): void {
    this.selected = this.tree.isSelected(this.node);
  }
}
