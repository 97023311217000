import { Component, DestroyRef, Input, OnInit, ViewEncapsulation } from '@angular/core';

import { EMPTY, PageDTO } from '@shared/domain';

import { WorkflowExecutionDTO } from '@app/automation/domain';
import { WorkflowDelayNodeService } from '@app/automation/services/workflow-delay-node.service';
import { TableLazyLoadEvent } from 'primeng/table';
import { UrlService } from '@shared/services';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'delay-node-suspended-executions-configurer',
  templateUrl: 'delay-node-suspended-executions-configurer.component.html',
  styleUrls: ['delay-node-suspended-executions-configurer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DelayNodeSuspendedExecutionsConfigurerComponent implements OnInit {
  @Input()
  public workflowId: string;
  @Input()
  public delayNodeId: string;

  protected suspendedExecutions: PageDTO<WorkflowExecutionDTO> = EMPTY;
  protected currentPage = 0;
  protected loading = false;

  constructor(
    private destroyRef: DestroyRef,
    private workflowDelayNodeService: WorkflowDelayNodeService,
    private urlService: UrlService,
  ) {}

  public ngOnInit(): void {
    this.loadSuspendedExecutions();
  }

  protected fetchSuspendedExecutions(event: TableLazyLoadEvent) {
    this.loadSuspendedExecutions((event.first ?? 0) / (event.rows ?? 15));
  }

  protected navigateToExecutionResource(execution: WorkflowExecutionDTO): void {
    this.urlService.navigateToResourceType(execution.resourceTypeId, execution.resourceId);
  }

  private loadSuspendedExecutions(page = 0): void {
    this.currentPage = page;
    this.loading = true;

    this.workflowDelayNodeService
      .getSuspendedExecutions(this.workflowId, this.delayNodeId, page, 15)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (executions) => {
          this.suspendedExecutions = executions;
          this.loading = false;
        },
        error: () => {
          this.suspendedExecutions = EMPTY;
          this.loading = false;
        },
      });
  }
}
