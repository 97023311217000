<mat-icon class="toggle-icon" (click)="handleClick($event)" fontIcon="tune" />

<p-overlayPanel #overlayPanel
                [style]="{'width': '450px', '--overlayArrowDisplay': 'none'}"
                (onHide)="onHide()"
                styleClass="standalone-filter-builder-overlay">

  @if (this.isSelectingView || !this.currentView) {
    <div class="view-list">
      <p-table [value]="views.items"
               [paginator]="views.totalItems > 5"
               [rows]="5"
               [lazy]="true"
               (onLazyLoad)="fetchViews($event)"
               [totalRecords]="views.totalItems">
        <ng-template pTemplate="body" let-view>
          <tr (click)="this.handleViewSelect(view)">
            <td style="width: 90%;">{{ view.name }}</td>
            <td style="width: 10%;">
              <delete-icon (deleteAccepted)="this.deleteView(view)" />
            </td>
          </tr>
        </ng-template>
      </p-table>

      <button (click)="this.handleViewListPrevious()" class="robaws-ng-button" mat-button primaryColor>
        {{ 'previous' | translate }}
      </button>

      <button (click)="this.createView()" class="robaws-ng-button add-button" mat-button primaryColor>
        {{ 'add' | translate }}
      </button>
    </div>
  } @else {
    <!-- Only show when there is no filter being built -->
    <div class="view-combo" *ngIf="!filterBuilderComponent || !filterBuilderComponent.filterBuilder">
      <input type="text" [(ngModel)]="this.currentView.name" (blur)="this.saveViewName()" />

      <mat-icon fontIcon="expand_more" (click)="this.toggleViewSelector()" />
    </div>

    <filter-builder *ngIf="this.metadata"
                    [metadataProviderType]="this.metadataProviderType"
                    [metadata]="this.metadata"
                    [filters]="this.filters"
                    (filtersChanged)="this.filtersChanged($event)"
                    (searchClicked)="this.searchClicked()" />
  }
</p-overlayPanel>
