export const dynamicDateRanges = [
  'PREVIOUS_WEEK',
  'CURRENT_WEEK',
  'NEXT_WEEK',
  'PREVIOUS_MONTH',
  'CURRENT_MONTH',
  'NEXT_MONTH',
  'PREVIOUS_QUARTER',
  'CURRENT_QUARTER',
  'NEXT_QUARTER',
  'PREVIOUS_YEAR',
  'CURRENT_YEAR',
  'NEXT_YEAR',
];

export type DynamicDateRange = (typeof dynamicDateRanges)[number];
