import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { BrowserModule } from '@angular/platform-browser';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextModule } from 'primeng/inputtext';

import { NodesModule } from '../../nodes/nodes.module';
import { EditWorkflowComponent } from './edit-workflow.component';
import { EditWorkflowNameDialogComponent } from './edit-workflow-name-dialog/edit-workflow-name-dialog.component';
import { EnableWorkflowDialogComponent } from './enable-workflow-dialog/enable-workflow-dialog.component';
import { MaterialLoaderDirective } from '@ui/material-loader/material-loader.directive';
import { PrimaryColorDirective } from '@ui/theme/primary-color.directive';
import { AutomationAuditLogDialogComponent } from '@app/automation/components/automation-audit-log-dialog/automation-audit-log-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    MatButtonModule,
    NodesModule,
    MatDialogModule,
    FormsModule,
    TranslateModule,
    CheckboxModule,
    InputTextModule,
    MaterialLoaderDirective,
    PrimaryColorDirective,
    AutomationAuditLogDialogComponent,
  ],
  declarations: [EditWorkflowComponent, EditWorkflowNameDialogComponent, EnableWorkflowDialogComponent],
})
export class EditWorkflowModule {}
