import { Component, Input } from '@angular/core';

import { ElementDTO, TextAreaElement } from '@app/form-template/domain';

@Component({
  selector: 'app-text-area',
  templateUrl: './text-area.component.html',
  styleUrls: ['./text-area.component.scss'],
})
export class TextAreaComponent {
  @Input({ required: true })
  element: ElementDTO<TextAreaElement>;
}
