<mat-icon class="icon"
          fontIcon="help"
          textAccentColor
          (mouseover)="infoPanel.show($event)"
          (mouseout)="infoPanel.hide()" />

<p-overlayPanel #infoPanel [style]="{'width': '400px'}">
  <ng-template pTemplate="content">
    <strong>{{ title }}</strong>

    <p [innerHTML]="info"></p>
  </ng-template>
</p-overlayPanel>
