import { Component, Input } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

import { ElementDTOMapperService } from '@app/form-template/services';
import { ElementType, ElementDTO } from '@app/form-template/domain';

@Component({
  selector: 'edit-form-elements',
  templateUrl: './edit-form-elements.component.html',
  styleUrls: ['./edit-form-elements.component.scss'],
})
export class EditFormElementsComponent {
  @Input({ required: true })
  public elements: ElementDTO<any>[];

  constructor(private elementDTOMapper: ElementDTOMapperService) {}

  public elementTypes: ElementType[] = Object.keys(ElementType).map((key) => ElementType[key as keyof typeof ElementType]);

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.elements, event.previousIndex, event.currentIndex);
  }

  public addElementBelow(elementType: ElementType, index: number): void {
    this.elements.splice(index + 1, 0, this.elementDTOMapper.createNewElementDTO(elementType));
  }

  protected deleteElement(element: ElementDTO<any>): void {
    const index = this.elements.indexOf(element, 0);
    if (index > -1) {
      this.elements.splice(index, 1);
    }
  }
}
