import { Injector, NgModule } from '@angular/core';
import { DataExportConfigurationModule } from '@app/robaws/components/data-export-configuration';
import { registerWebComponent } from '@shared/helpers';
import { StandaloneFilterBuilderComponent } from '@app/robaws/components/standalone-filter-builder/standalone-filter-builder.component';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { MatButtonModule } from '@angular/material/button';
import { RobawsOperationBuilderComponent } from '@app/robaws/components/robaws-operation-builder/robaws-operation-builder.component';
import { PropertyPathSelectorComponent } from '@shared/components/property-path-selector';
import { DynamicOverviewComponent } from '@app/robaws/components/dynamic-overview/dynamic-overview.component';
import { DynamicOverviewSettingsComponent } from '@app/robaws/components/dynamic-overview-settings/dynamic-overview-settings.component';

@NgModule({
  imports: [
    CommonModule,
    DataExportConfigurationModule,
    MatIconModule,
    TranslateModule,
    PropertyPathSelectorComponent,
    OverlayPanelModule,
    MatButtonModule,
    RobawsOperationBuilderComponent,
    StandaloneFilterBuilderComponent,
    DynamicOverviewComponent,
  ],
  exports: [DataExportConfigurationModule],
})
export class RobawsModule {
  constructor(injector: Injector) {
    registerWebComponent(injector, 'standalone-filter-builder', StandaloneFilterBuilderComponent);
    registerWebComponent(injector, 'dynamic-overview', DynamicOverviewComponent);
    registerWebComponent(injector, 'dynamic-overview-settings', DynamicOverviewSettingsComponent);
  }
}
