import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

import { EXTERNAL_REQUEST } from '../context-tokens';

export abstract class InternalHttpInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.context.has(EXTERNAL_REQUEST) && req.context.get(EXTERNAL_REQUEST)) {
      return next.handle(req);
    }

    return this.interceptInternally(req, next);
  }

  abstract interceptInternally(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>;
}
