import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Calendar, CalendarModule } from 'primeng/calendar';
import { FormsModule } from '@angular/forms';
import { OverlayPanel, OverlayPanelModule } from 'primeng/overlaypanel';
import { TranslateModule } from '@ngx-translate/core';
import { RobawsNgTemplateDirective } from '@shared/components/robaws-ng-template.directive';
import { Nullable } from 'primeng/ts-helpers';
import { KeyValuePipe, LowerCasePipe, NgTemplateOutlet } from '@angular/common';
import { DynamicDateRange, dynamicDateRanges } from '@app/robaws/domain/DynamicDateRanges';
import { ReplacePipe } from '@shared/components/replace.pipe';

const dynamicDateRangeGroupTypes = ['week', 'month', 'quarter', 'year'] as const;

type DynamicDateRangeGroupType = (typeof dynamicDateRangeGroupTypes)[number];

type DynamicDateRangeGroup = {
  id: DynamicDateRangeGroupType;
  ranges: DynamicDateRange[];
};

@Component({
  selector: 'date-range-input',
  styleUrls: ['date-range-input.component.scss'],
  templateUrl: 'date-range-input.component.html',
  standalone: true,
  imports: [CalendarModule, FormsModule, OverlayPanelModule, TranslateModule, NgTemplateOutlet, KeyValuePipe, LowerCasePipe, ReplacePipe],
})
export class DateRangeInputComponent implements OnInit, OnChanges, AfterContentInit, AfterViewInit {
  @Input()
  public value: string | undefined | null;

  @Output()
  public valueChange: EventEmitter<string> = new EventEmitter<string>();
  protected dateValue: Date[] = [];
  protected inputTemplate: TemplateRef<any> | undefined;
  protected readonly onInputClick = this.toggleDateRangePicker.bind(this);
  protected dynamicDateRangeGroups: DynamicDateRangeGroup[] = [];
  @ViewChild('dateRangePickerPanel')
  private dateRangePickerPanel: OverlayPanel;
  @ContentChildren(RobawsNgTemplateDirective)
  private templates: Nullable<QueryList<RobawsNgTemplateDirective>>;
  @ViewChild(Calendar)
  private calendar: Calendar;

  public ngOnInit(): void {
    this.initDynamicDateRanges();
    this.updateDateValue(this.value);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['value']) {
      this.updateDateValue(changes['value'].currentValue);
    }
  }

  public ngAfterContentInit(): void {
    if (this.templates) {
      this.templates.forEach((template) => {
        if (template.type === 'input') {
          this.inputTemplate = template.template;
        }
      });
    }
  }

  /*
   * This (ugly) fix is necessary due to a bug that has been present in the primeng calendar component for quite some time (with range selection).
   * When clicking on the month first and then the year, an error is thrown because it assumes the value is an array with one element in it.
   * This causes all sorts of weird behavior, the year labels are empty firstly, but switching tabs also breaks because of it.
   */
  public ngAfterViewInit(): void {
    // overriding the method that errors out, the only change here is the check for the length of the value array
    this.calendar.isYearSelected = function (year: number): boolean {
      if (this.isComparable() && this.value.length > 0) {
        const value = this.isRangeSelection() ? this.value[0] : this.value;

        return !this.isMultipleSelection() ? value.getFullYear() === year : false;
      }

      return false;
    };
  }

  protected onDateValueChange(dates: Date[]): void {
    this.dateValue = dates ?? [];

    if (this.dateValue.filter((it) => !!it).length === 1) {
      return;
    }

    this.value = this.dateValue.map((date) => (date ? date.toISOString() : '')).join(',');
    this.valueChange.emit(this.value);
  }

  protected onDynamicDateRangeValueChange(dateRange: DynamicDateRange): void {
    this.value = dateRange;
    this.dateValue = [];
    this.value = dateRange;
    this.valueChange.emit(this.value);
  }

  protected toggleDateRangePicker(event: MouseEvent): void {
    event.preventDefault();

    if (this.dateRangePickerPanel.overlayVisible) {
      this.dateRangePickerPanel.hide();
    } else {
      this.dateRangePickerPanel.show(event);
    }
  }

  protected onDynamicDateRangeClick(range: DynamicDateRange): void {
    this.onDynamicDateRangeValueChange(range);
  }

  protected isDynamicDateRangeSelected(range: DynamicDateRange) {
    return this.value === range;
  }

  private initDynamicDateRanges(): void {
    for (const dynamicDateRangeGroupType of dynamicDateRangeGroupTypes) {
      this.dynamicDateRangeGroups.push({ id: dynamicDateRangeGroupType, ranges: [] });
    }

    for (const dynamicDateRange of dynamicDateRanges) {
      const group = dynamicDateRange.split('_')[1].toLowerCase();
      const dateRangeGroup = this.dynamicDateRangeGroups.find((it) => it.id === group);

      dateRangeGroup!.ranges.push(dynamicDateRange);
    }
  }

  private updateDateValue(value: string | undefined | null): void {
    if (value) {
      if (!dynamicDateRanges.includes(value)) {
        value.split(',').forEach((date) => {
          this.dateValue.push(new Date(date));
        });
      } else {
        this.dateValue = [];
      }
    } else {
      this.dateValue = [];
    }
  }
}
