<div class="drop-list" cdkDropList (cdkDropListDropped)="drop($event)">
  <edit-form-element *ngFor="let element of elements; let currentIndex = index"
                     [element]="element"
                     (onElementAdd)="addElementBelow($event, currentIndex)"
                     (onElementDelete)="deleteElement(element)"
  />

</div>
<div class="no-elements-yet" *ngIf="elements.length===0">
  <div class="add-question-button">
    <p>{{'form-templates.no-questions-yet' | translate}} <a [matMenuTriggerFor]="questionMenu"
                                                            class="question-menu-trigger">{{'form-templates.create-in-sentence' | translate}}</a>
      {{'form-templates.one-in-sentence' | translate}}</p>
    <mat-menu #questionMenu="matMenu" class="mat-menu-custom-style">
      <button mat-menu-item class="mat-menu-item-custom-style" *ngFor="let type of elementTypes"
              (click)="addElementBelow(type, 0)">{{ 'element-types.' + type | translate }}</button>
    </mat-menu>
  </div>
</div>
