import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { EditWorfklowNameDialogData } from '../edit-workflow.component';

@Component({
  selector: 'edit-workflow-name-dialog',
  templateUrl: 'edit-workflow-name-dialog.component.html',
  styleUrls: ['edit-workflow-name-dialog.component.scss'],
})
export class EditWorkflowNameDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: EditWorfklowNameDialogData) {}
}
