@if (this.filterBuilder) {
  @if (this.selectingPath) {
    <property-path-selector [metadataProviderType]="metadataProviderType"
                            [data]="{
                              metadata: this.metadata,
                              path: this.filterBuilder.path
                            }"
                            [includeLinks]="true"
                            [fetchDeep]="true"
                            (cancelled)="handleCancel()"
                            (propertySelected)="handlePathBuilderResult($event)" />
  } @else {
    <robaws-operation-builder [data]="{
                                metadata: this.metadata,
                                path: this.filterBuilder.path,
                                operator: this.filterBuilder.operator,
                                value: this.filterBuilder.value,
                              }"
                              (cancelled)="handleCancel()"
                              (operationConfigured)="handleOperationBuilderResult($event)" />
  }
} @else {
  <filter-list [metadataProviderType]="this.metadataProviderType"
               [filters]="this.filters"
               [rootMetaData]="this.metadata"
               (onEditFilterClicked)="this.editFilter($event)"
               (onDeleteFilterClicked)="this.deleteFilter($event)" />

  <button (click)="this.createFilter()" class="add-filter robaws-ng-button" mat-button primaryColor>
    {{ 'create-filter' | translate }}
  </button>
  <button (click)="this.onSearchClicked()" class="search robaws-ng-button" mat-button primaryColor>
    {{ 'search' | translate }}
  </button>
}
