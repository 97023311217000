<div class="data-table">
  <data-table-search [placeholder]="searchPlaceholder"
                     (onPageRequest)="onPageRequest($event)"
                     [page]="dataSource.currentPage"
                     [allowFilter]="allowFilter"
                     [selectedItems]="selected"
                     [actions]="bulkActions"
                     [selectedColumns]="selectedColumns"
                     (changeColumn)="changeColumn($event)"
  />

  <div class="data-table-primeng" #datatable>
    <p-table [paginator]="true"
             [columns]="calculatedColumns"
             [lazy]="true"
             (onLazyLoad)="fetch($event)"
             [value]="dataSource.currentPage?.items || []"
             [rows]="dataSource.currentPage?.size || 20"
             [totalRecords]="dataSource.currentPage?.totalItems || 0"
             [loading]="loading"
             [(selection)]="selected"
             [metaKeySelection]="true"
             [selectionMode]="allowMultipleSelections ? 'multiple' : 'single'"
             [resizableColumns]="true"
             [columnResizeMode]="'expand'"
             [rowsPerPageOptions]="[20, 50, 100]"
             [sortMode]="'multiple'"
             scrollHeight="100%">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th class="p-frozen-column" *ngIf="allowRowSelection" style="width: 4rem" pResizableColumn>
            <p-tableHeaderCheckbox *ngIf="allowMultipleSelections"></p-tableHeaderCheckbox>
          </th>
          <th *ngFor="let column of columns" [pSortableColumn]="column.sortable ? column.property: ''" pResizableColumn>
            <div class="p-title">
              <div class="p-title-content">
                <div>{{ column.display }}</div>
                <small *ngIf="column.parentName">
                  {{ column.parentName }}
                </small>
              </div>
              <p-sortIcon class="p-title-sorter" *ngIf="column.sortable" [field]="column.property"></p-sortIcon>
            </div>

          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
        <tr [pSelectableRow]="rowData" (dblclick)="onRowDblClick($event, rowData)" [pSelectableRowIndex]="rowIndex">
          <td *ngIf="allowRowSelection" class="p-frozen-column" (click)="$event.stopPropagation()">
            <p-tableCheckbox *ngIf="allowMultipleSelections" [value]="rowData"></p-tableCheckbox>
            <p-tableRadioButton *ngIf="!allowMultipleSelections" [value]="rowData"></p-tableRadioButton>
          </td>
          <td *ngFor="let column of columns">
            <ng-template
              [column]="column"
              [element]="rowData"
              tableColumn
            ></ng-template>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

