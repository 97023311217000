import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'platform',
})
export class TranslationHelper {
  constructor(private translateService: TranslateService) {}

  public translateOrDefault(key: string, defaultValue: string, params: any): string {
    const translation = this.translateService.instant(key, params);

    return translation === key ? defaultValue : translation;
  }
}
