import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ViewFilter, ViewFilterGroup } from '@app/robaws/domain';

@Injectable({
  providedIn: 'root',
})
export class ViewFilterGroupService {
  constructor(private httpClient: HttpClient) {}

  public updateViewFilterGroupFilters(filterGroupId: string, filters: ViewFilter[]): Observable<void> {
    return this.httpClient.put<void>(`/api/v2/view-filter-groups/${filterGroupId}/filters`, filters);
  }

  public updateViewFilterGroupFilterGroups(filterGroupId: string, filterGroups: ViewFilterGroup[]): Observable<void> {
    return this.httpClient.put<void>(`/api/v2/view-filter-groups/${filterGroupId}/filter-groups`, filterGroups);
  }
}
