@if (this.canDeleteView()) {
  <mat-icon class="visibility-icon"
            fontIcon="public"
            tooltipPosition="top"
            [pTooltip]="'overviews.settings.visibility.' + this.visibility.toLowerCase() | translate"
            [attr.data-visibility-type]="this.visibility"
            (click)="this.toggleVisibility()" />
  <delete-icon (deleteAccepted)="this.deleteView()" />
}

<div class="view-name">
  <p>{{ 'name' | translate }}</p>

  <input type="text"
         [(ngModel)]="this.name"
         pInputText />
</div>

<div class="column-configurator">

  <div class="available-columns">
    <h3>{{ 'overviews.settings.available-columns' | translate }}</h3>

    <input type="text"
           [placeholder]="'search-placeholder' | translate"
           class="search"
           [ngModel]="this.searchText"
           (ngModelChange)="this.onSearchTextUpdate($event)"
           pInputText />

    <div class="column-list">

      @for (column of columns; track column.path) {
        <div class="column-item">
          <p-checkbox class="column-checkbox"
                      [ngModel]="column.selected"
                      (ngModelChange)="this.onColumnSelectedChange($event, column)"
                      [binary]="true"
                      [inputId]="column.path" />

          <label class="column-label" for="{{column.path}}">{{ column.displayName }}</label>
        </div>
      }

    </div>
  </div>

  <div class="selected-columns">
    <h3>{{ 'overviews.settings.selected-columns' | translate }}</h3>

    <div class="column-list" cdkDropList (cdkDropListDropped)="onDrop($event)">

      @for (column of selectedColumns; track column.path) {
        <!-- inline styling below is because of drag-n-drop -->
        <div class="column-item" cdkDrag style="display: flex; align-items: center; background-color: #e0e0e0; border-radius: 4px; padding: 8px;">
          <mat-icon class="drag-icon" fontIcon="drag_indicator" cdkDragHandle />
          <div class="field-name" style="flex: 1;">{{ column.displayName }}</div>
          <mat-icon class="close-icon" fontIcon="close" (click)="onColumnSelectedChange(false, column)" />
        </div>
      }

    </div>
  </div>
</div>
