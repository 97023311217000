import { Component, Input } from '@angular/core';
import { ElementDTO } from '@app/form-template/domain/ElementDTO';
import { SignatureElement } from '@app/form-template/domain/elementtypes/SignatureElement';

@Component({
  selector: 'app-signature',
  templateUrl: './signature.component.html',
  styleUrl: './signature.component.scss',
})
export class SignatureComponent {
  @Input({ required: true })
  element: ElementDTO<SignatureElement>;
}
