import { ComponentRef, Injectable, Type } from '@angular/core';

import { RootContainerService } from './root-container.service';
import { RobawsNgConfigurerCardComponent } from '@ui/robaws-ng-card/robaws-ng-configurer-card/robaws-ng-configurer-card.component';

export type CreatedConfigurerCard<T> = {
  uuid: string;
  componentRef: ComponentRef<RobawsNgConfigurerCardComponent>;
  contentRef: ComponentRef<T>;
};

export type ConfigurerCardOptions = {
  title: string;
  inputs?: any;
};

@Injectable({
  providedIn: 'platform',
})
export class RobawsNgConfigurerCardService {
  private configurerCards: Map<string, CreatedConfigurerCard<any>> = new Map();

  constructor(private rootContainerService: RootContainerService) {}

  public closeConfigurerCard(uuid: string): void {
    if (this.configurerCards.has(uuid)) {
      const createdConfigurerCard = this.configurerCards.get(uuid);

      if (createdConfigurerCard) {
        createdConfigurerCard.componentRef.destroy();
        createdConfigurerCard.contentRef.destroy();
      }

      this.configurerCards.delete(uuid);
      document.dispatchEvent(new CustomEvent('robaws-ng-configurer-card-close', { detail: { uuid } }));
    }
  }

  public createAndOpenConfigurerCard<T>(contentComponent: Type<T>, options: ConfigurerCardOptions): CreatedConfigurerCard<T> {
    // Close all configurer cards
    for (const uuid of this.configurerCards.keys()) {
      this.closeConfigurerCard(uuid);
    }

    const viewContainerRef = this.rootContainerService.getViewContainerRef();

    // Creating content component
    const createdContentComponent = viewContainerRef.createComponent(contentComponent);

    if (options.inputs) {
      Object.keys(options.inputs).forEach((key) => {
        // @ts-ignore
        createdContentComponent.instance[key] = options.inputs[key];
      });
    }

    // Creating card component and injecting content component
    const configurerCard = viewContainerRef.createComponent(RobawsNgConfigurerCardComponent, {
      projectableNodes: [[createdContentComponent.location.nativeElement]],
    });

    configurerCard.instance.title = options.title;

    const createdConfigurerCard: CreatedConfigurerCard<T> = {
      uuid: configurerCard.instance.uuid,
      componentRef: configurerCard,
      contentRef: createdContentComponent,
    };
    this.configurerCards.set(configurerCard.instance.uuid, createdConfigurerCard);
    return createdConfigurerCard;
  }
}
