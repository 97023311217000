<workflow-node (nodeClick)="openConfigurer()"
               [workflowNodeData]="workflowNodeData">
  <span slot="title">{{ 'nodes.update-robaws-resource.title' | translate }}</span>

  <div slot="content">
    <div *ngIf="hasPatch()">
      <p [innerHTML]="'nodes.update-robaws-resource.description.detailed' | translate: {
        'property': this.propertyName$ | async
       }"
         class="description update-robaws-resource-node-description">
      </p>
      <p class="description update-robaws-resource-node-description value">
        <strong>{{ value$ | async }}</strong>.
      </p>
    </div>
    <p *ngIf="!this.hasPatch()" class="description">
      {{ 'nodes.update-robaws-resource.description.default' | translate }}
    </p>
  </div>
</workflow-node>
