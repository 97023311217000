import { Directive, ElementRef, ViewContainerRef } from '@angular/core';
import { AbstractWebComponent } from '@app/shared';
import { bindNativeMethod } from '@app/shared/helpers/injection.helper';

@Directive()
export abstract class HasValueComponent<T> extends AbstractWebComponent {
  protected constructor(
    protected override viewContainerRef: ViewContainerRef,
    protected elementRef: ElementRef,
  ) {
    super(viewContainerRef);

    bindNativeMethod('getValue', this.getValue.bind(this));
    bindNativeMethod('setValue', this.setValueInternal.bind(this));
  }

  abstract setValue(value: T, fireChangeEvent: boolean): void;

  abstract getValue(): T;

  private setValueInternal(value: T, fireChangeEvent: boolean = false): void {
    this.setValue(value, fireChangeEvent);
  }
}
