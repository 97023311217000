<div class="section">
  <mat-accordion>
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <mat-icon fontIcon="subtitles"/>
          <input class="title-input"
                 [(ngModel)]="section.title"
                 (click)="$event.stopPropagation()"
                 (keydown)="handleSpacebar($event)"
                 placeholder="{{'form-templates.section-title' | translate}}" type="text">
        </mat-panel-title>
        <mat-panel-description>
          <span class="spacer"></span>

          <delete-icon styleClass="delete-button"
                       [confirmationMessage]="'confirmation.content.section' | translate"
                       (deleteAccepted)="onDeleteClick()" />
        </mat-panel-description>
      </mat-expansion-panel-header>
      <hr class="accent-line" primaryDarkColor>

      <div class="sections">
        <edit-form-elements [elements]="section.elements"/>
      </div>

    </mat-expansion-panel>
  </mat-accordion>

  <div class="add-section-button">
    <div class="horizontal-line"></div>
    <div (click)="addSection()" class="add-button-container">
      <mat-icon matTooltip="{{'form-templates.add-section' | translate}}" class="add-icon" fontIcon="add"></mat-icon>
    </div>
  </div>

</div>
