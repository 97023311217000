import { Component, ElementRef } from '@angular/core';

import { RobawsNgConfigurerCardService } from '@shared/services';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'robaws-ng-card-close',
  templateUrl: 'robaws-ng-card-close.component.html',
  styleUrls: ['robaws-ng-card-close.component.scss'],
  inputs: ['uuid'],
  standalone: true,
  imports: [MatIcon],
})
export class RobawsNgCardCloseComponent {
  public uuid: string;

  constructor(
    private elementRef: ElementRef,
    private robawsNgConfigurerCardService: RobawsNgConfigurerCardService,
  ) {}

  protected fireCardCloseEvent(): void {
    this.robawsNgConfigurerCardService.closeConfigurerCard(this.uuid);
  }
}
