import { NgModule } from '@angular/core';
import { CommonModule, NgFor } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { BrowserModule } from '@angular/platform-browser';
import { EditFormElementComponent } from '@app/form-template/components/edit-form-template/edit-form-element/edit-form-element.component';
import { MatSelectModule } from '@angular/material/select';
import { MultipleChoiceComponent } from './multiple-choice/multiple-choice.component';
import { DateComponent } from './date/date.component';
import { DescriptionComponent } from './description/description.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { NumberInputComponent } from './number-input/number-input.component';
import { TextAreaComponent } from './text-area/text-area.component';
import { TimeComponent } from './time/time.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CdkDrag, CdkDragHandle, CdkDropList } from '@angular/cdk/drag-drop';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TranslateModule } from '@ngx-translate/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { ImageComponent } from './image/image.component';
import { SignatureComponent } from '@app/form-template/components/edit-form-template/edit-form-element/signature/signature.component';
import { RadioComponent } from '@app/form-template/components/edit-form-template/edit-form-element/radio/radio.component';
import { DrawingComponent } from '@app/form-template/components/edit-form-template/edit-form-element/drawing/drawing.component';
import { PrimaryColorDirective } from '@ui/theme/primary-color.directive';
import { DeleteIconComponent } from '@ui/delete-icon/delete-icon.component';

@NgModule({
  declarations: [
    EditFormElementComponent,
    MultipleChoiceComponent,
    DateComponent,
    DescriptionComponent,
    DropdownComponent,
    NumberInputComponent,
    TextAreaComponent,
    TimeComponent,
    ImageComponent,
    SignatureComponent,
    RadioComponent,
    DrawingComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    MatButtonModule,
    MatInputModule,
    FormsModule,
    MatCardModule,
    MatIconModule,
    MatSelectModule,
    MatMenuModule,
    MatTooltipModule,
    CdkDropList,
    NgFor,
    CdkDrag,
    CdkDragHandle,
    MatSlideToggleModule,
    TranslateModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatExpansionModule,
    PrimaryColorDirective,
    DeleteIconComponent,
  ],
  exports: [EditFormElementComponent],
})
export class EditFormElementModule {}
