<div [materialLoader]="$loading" class="filters-container">

  @if (filterVOs.length === 0) {
    <div class="no-filters">
      <span>{{ 'no-filters' | translate }}</span>
    </div>
  } @else {
    <div *ngFor="let filter of filterVOs" class="filter filter-{{editable ? 'editable': 'view'}}">
      <div class="filter-description">
        <strong>{{ filter.pathDisplayName }}</strong>
        <span>{{ 'operation.' + filter.filter.operator | translate }}</span>
        <strong>{{ filter.valueDisplayName }}</strong>
      </div>
      <div *ngIf="editable" class="filter-actions">
        <mat-icon (click)="editFilter(filter.filter)"
                  class="icon"
                  fontIcon="edit" />

        <delete-icon [askConfirmation]="false"
                     (deleteAccepted)="deleteFilter(filter.filter)" />
      </div>
    </div>
  }
</div>
