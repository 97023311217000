import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TextAccentColorDirective } from '@ui/theme/text-accent-color.directive';

@Component({
  selector: 'robaws-ng-info',
  templateUrl: 'robaws-ng-info.component.html',
  styleUrls: ['robaws-ng-info.component.scss'],
  standalone: true,
  imports: [CommonModule, BrowserModule, MatIconModule, MatTooltipModule, OverlayPanelModule, TextAccentColorDirective],
})
export class RobawsNgInfoComponent implements OnInit {
  @Input()
  public title: string;

  @Input({ required: true })
  public info: string;

  constructor(private translateService: TranslateService) {}

  public ngOnInit(): void {
    if (!this.title) {
      this.title = this.translateService.instant('info');
    }
  }
}
