import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OperationBuilderData } from '@app/shared/components/operation-builder/OperationBuilder';
import { Operator } from '@app/shared/domain/Operator';
import { TranslateModule } from '@ngx-translate/core';
import { DynamicTypeInputComponent } from '@shared/components/inputs/dynamic-type-input';
import { AutomationOperator } from '@app/automation/domain/AutomationOperator';
import { RobawsOperator } from '@app/robaws/domain';

@Component({
  selector: 'single-input-operation-builder',
  templateUrl: 'single-input-operation-builder.component.html',
  standalone: true,
  imports: [TranslateModule, DynamicTypeInputComponent],
})
export class SingleInputOperationBuilderComponent {
  @Input({ required: true })
  public data: OperationBuilderData;
  @Output()
  public valueChange: EventEmitter<string> = new EventEmitter<string>();

  public static isSupported(operator: Operator | AutomationOperator | RobawsOperator): boolean {
    return (
      operator === 'EQUALS' ||
      operator === 'NOT_EQUALS' ||
      operator === 'STARTS_WITH' ||
      operator === 'ENDS_WITH' ||
      operator === 'CONTAINS' ||
      operator === 'NOT_CONTAINS' ||
      operator === 'LOWER_THAN' ||
      operator === 'LOWER_THAN_OR_EQUALS' ||
      operator === 'GREATER_THAN' ||
      operator === 'GREATER_THAN_OR_EQUALS' ||
      operator === 'IS_BEFORE' ||
      operator === 'IS_AFTER' ||
      operator === 'IS_ONE_OF' ||
      operator === 'IS_NOT_ONE_OF' ||
      operator === 'IS_BETWEEN' ||
      operator === 'IS_NOT_BETWEEN'
    );
  }
}
