<tree-select-item [tree]="this.tree"
                  [node]="group"
                  [value]="value"
                  [showExpandIcon]="this.group.children.length > 0"
                  [expanded]="this.expanded"
                  (expandedChange)="this.expand($event)"
                  (itemSelected)="this.itemSelected.emit($event)" />

<tree-select-group *ngFor="let child of expanded ? tree.getNodesWithChildren(this.group.children) : []"
                   [tree]="this.tree"
                   [value]="value"
                   [group]="child"
                   (itemSelected)="this.itemSelected.emit($event)"
                   (expandedChanged)="this.expandedChanged.emit($event)"
                   class="child" />

<tree-select-item *ngFor="let child of expanded ? tree.getNodesWithoutChildren(this.group.children) : []"
                  [tree]="this.tree"
                  [value]="value"
                  [node]="child"
                  (itemSelected)="this.itemSelected.emit($event)"
                  (expandedChange)="this.expandedChanged.emit($event)"
                  class="child" />
