import 'zone.js';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from 'src/app/app.module';
import { environment } from 'src/environments/environment';
import { enableProdMode } from '@angular/core';
import { datadogLogs } from '@datadog/browser-logs';
import { robawsWindow } from '@app/shared/helpers/window.helper';
import { isBlockedMessage } from '@shared/helpers';

if (environment.production) {
  enableProdMode();

  datadogLogs.init({
    clientToken: 'pub3450232470ea550f5c037e2f8b8086dc',
    site: 'datadoghq.eu',
    service: 'robaws-ng',
    forwardConsoleLogs: undefined,
    forwardReports: undefined,
    forwardErrorsToLogs: undefined,
    sessionSampleRate: 100,
    version: environment.version,
    beforeSend: (event) => {
      if (robawsWindow.isRobawsNgPage && !robawsWindow.isRobawsNgPage()) {
        return false;
      }
      return !isBlockedMessage(event.message);
    },
  });
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => {
    console.error(err);

    if (environment.production) {
      datadogLogs.logger.error(err);
    }
  });
