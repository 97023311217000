import { Component, DestroyRef, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { PathService, RobawsNgConfigurerCardService } from '@shared/services';

import { DelayNode, DelayStrategy, DelayUnit, WorkflowExecutionDTO } from '@app/automation/domain';

import { AbstractWorkflowNodeComponent } from '@app/automation/components/nodes/abstract-workflow-node-component';
import { WorkflowNodeData } from '@app/automation/components/nodes/workflow-node/workflow-node-data';
import { DelayNodeConfigurerComponent } from '@app/automation/components/nodes/delay-node/delay-node-configurer/delay-node-configurer.component';
import { DynamicResourceTypeProvider } from '@app/shared/services/dynamic-resource-type.provider';
import { WorkflowDelayNodeService } from '@app/automation/services/workflow-delay-node.service';
import { EMPTY, PageDTO } from '@shared/domain';
import { DelayNodeSuspendedExecutionsConfigurerComponent } from '@app/automation/components/nodes/delay-node/delay-node-suspended-executions-configurer/delay-node-suspended-executions-configurer.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'delay-node',
  templateUrl: 'delay-node.component.html',
})
export class DelayNodeComponent extends AbstractWorkflowNodeComponent implements OnInit, OnDestroy {
  @Input({ required: true })
  public workflowNodeData: WorkflowNodeData<DelayNode>;
  protected suspendedExecutions: PageDTO<WorkflowExecutionDTO>;
  private onReloadSubscription: Subscription;
  private pathDisplayName = '';
  private dynamicResourceTypeProvider = new DynamicResourceTypeProvider('AUTOMATION');

  constructor(
    private destroyRef: DestroyRef,
    private robawsNgConfigurerCardService: RobawsNgConfigurerCardService,
    private translateService: TranslateService,
    private pathService: PathService,
    private workflowDelayNodeService: WorkflowDelayNodeService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.updatePathDisplayName();

    if (this.workflowNodeData.currentNode.configuration.delayNodeId) {
      this.workflowDelayNodeService
        .getSuspendedExecutions(this.workflowNodeData.workflowId, this.workflowNodeData.currentNode.configuration.delayNodeId, 0, 10)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: (executions) => {
            this.suspendedExecutions = executions;
          },
          error: () => {
            this.suspendedExecutions = EMPTY;
          },
        });
    }
  }

  public ngOnDestroy(): void {
    this.unsubscribe();
  }

  public openConfigurer(): void {
    this.unsubscribe();

    const currentConfigurer = this.robawsNgConfigurerCardService.createAndOpenConfigurerCard(DelayNodeConfigurerComponent, {
      title: this.translateService.instant('nodes.delay.configurer-title'),
      inputs: {
        currentNode: this.workflowNodeData.currentNode,
        resourceTypeMetadata: this.workflowNodeData.resourceTypeMetadata,
      },
    });

    this.onReloadSubscription = currentConfigurer.contentRef.instance.onReload.subscribe(() => this.updatePathDisplayName());
  }

  protected getDescription(): string {
    const currentNode = this.workflowNodeData.currentNode.configuration;
    const value = this.workflowNodeData.currentNode.configuration.value ?? 0;
    let message = this.translateService.instant('nodes.delay.description.default');

    if (this.isSetup()) {
      if (currentNode.strategy === DelayStrategy.FIXED) {
        message = this.translateService.instant('nodes.delay.description.fixed', {
          duration: this.getDurationParameter(currentNode.unit, value),
        });
      } else if (currentNode.strategy === DelayStrategy.RELATIVE_TO_PATH_VALUE) {
        if (currentNode.path) {
          const executeType = value > 0 ? 'after' : value < 0 ? 'before' : 'on';

          message = this.translateService.instant('nodes.delay.description.relative-to-path.' + executeType, {
            duration: this.getDurationParameter(currentNode.unit, value),
            property: this.pathDisplayName,
          });
        }
      }
    }

    return message;
  }

  protected openSuspendedExecutionsCard(event: MouseEvent): void {
    event.preventDefault();
    event.stopImmediatePropagation(); // prevents the normal configurer from opening

    this.robawsNgConfigurerCardService.createAndOpenConfigurerCard(DelayNodeSuspendedExecutionsConfigurerComponent, {
      title: this.translateService.instant('nodes.delay.suspended-executions.configurer-title'),
      inputs: {
        delayNodeId: this.workflowNodeData.currentNode.configuration.delayNodeId,
        workflowId: this.workflowNodeData.workflowId,
      },
    });
  }

  private updatePathDisplayName() {
    if (this.workflowNodeData.currentNode.configuration.path) {
      this.pathService
        .getPath(
          this.dynamicResourceTypeProvider,
          this.workflowNodeData.resourceTypeMetadata.name,
          this.workflowNodeData.currentNode.configuration.path,
        )
        .subscribe((path) => {
          this.pathDisplayName = path?.displayNameDeep ?? '';
        });
    } else {
      this.pathDisplayName = '';
    }
  }

  private isSetup(): boolean {
    return (
      this.workflowNodeData.currentNode.configuration.strategy &&
      this.workflowNodeData.currentNode.configuration.unit &&
      this.workflowNodeData.currentNode.configuration.value != undefined
    );
  }

  private getDurationParameter(unit: DelayUnit, duration: number): string {
    return (
      Math.abs(duration) +
      ' ' +
      this.translateService.instant('nodes.delay.unit.' + unit.toString().toLowerCase() + (duration == 1 ? '.singular' : '.plural')).toLowerCase()
    );
  }

  private unsubscribe(): void {
    if (this.onReloadSubscription) {
      this.onReloadSubscription.unsubscribe();
    }
  }
}
