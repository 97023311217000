<workflow-node (nodeClick)="openConfigurer()"
               [workflowNodeData]="workflowNodeData">

  <span slot="title">{{ 'formatDataNode.title' | translate }}</span>
  <div slot="content">
    <p class="description">
      {{ 'formatDataNode.description' | translate }}
    </p>
  </div>
</workflow-node>
