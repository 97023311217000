<mat-form-field class="question-input">
  <mat-label>{{'question' | translate}}</mat-label>
  <input matInput [(ngModel)]="element.configuration.question">
</mat-form-field>

<div class="row">
  <div class="text-area-input">
  <mat-form-field>
    <mat-label>{{'element-types.text_area' | translate}}</mat-label>
    <textarea matInput disabled rows="3"></textarea>
  </mat-form-field>
  </div>
  <div class="options-container">
    <div class="options-sub-container">
<!--      <mat-accordion class="options">-->
<!--        <mat-expansion-panel>-->
<!--          <mat-expansion-panel-header>-->
<!--            <mat-panel-title>-->
<!--              <mat-icon fontIcon="more_vert"></mat-icon>-->
<!--              Opties-->
<!--            </mat-panel-title>-->
<!--          </mat-expansion-panel-header>-->
<!--          <mat-label>Minimum lengte:</mat-label>-->
<!--          <input type="number" matInput minlength="0" maxlength="1000" [(ngModel)]="element.configuration.minLength">-->
<!--          <mat-label>Maximum lengte:</mat-label>-->
<!--          <input type="number" minlength="1" maxlength="1000" matInput [(ngModel)]="element.configuration.maxLength">-->
<!--        </mat-expansion-panel>-->
<!--      </mat-accordion>-->
    </div>
  </div>
</div>
