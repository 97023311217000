import { Directive, Input, OnInit, Type, ViewContainerRef } from '@angular/core';

import { TableColumn } from './table-column';
import { isClass } from '@shared/helpers';

@Directive({
  selector: '[tableColumn]',
})
export class TableColumnDirective<T> implements OnInit {
  @Input()
  public column?: TableColumn<T>;

  @Input()
  public element?: T;

  constructor(public viewContainerRef: ViewContainerRef) {}

  ngOnInit(): void {
    if (!this.column || !this.element) {
      return;
    }
    this.viewContainerRef.clear();

    const componentType: Type<any> = isClass(this.column.component) ? this.column.component : this.column.component(this.element);
    const component = this.viewContainerRef.createComponent(componentType);
    component.instance.data = this.column.dataSupplier(this.element);
  }
}
