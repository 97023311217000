import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { BrowserModule } from '@angular/platform-browser';
import { MatMenuModule } from '@angular/material/menu';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatExpansionModule } from '@angular/material/expansion';

import { InputTextModule } from 'primeng/inputtext';

import { EditFormSectionComponent } from './edit-form-section/edit-form-section.component';
import { EditFormSectionsComponent } from './edit-form-sections/edit-form-sections.component';
import { EditFormTemplateComponent } from './edit-form-template.component';
import { EditFormElementsComponent } from './edit-form-elements/edit-form-elements.component';
import { EditFormElementModule } from './edit-form-element/edit-form-element.module';

import { ElementDTOMapperService } from '@app/form-template/services';
import { MaterialLoaderDirective } from '@ui/material-loader/material-loader.directive';
import { PrimaryDarkColorDirective } from '@ui/theme/primary-dark-color.directive';
import { DeleteIconComponent } from '@ui/delete-icon/delete-icon.component';

@NgModule({
  declarations: [EditFormTemplateComponent, EditFormSectionComponent, EditFormSectionsComponent, EditFormElementsComponent],
  imports: [
    CommonModule,
    BrowserModule,
    MatButtonModule,
    FormsModule,
    MatCardModule,
    MatIconModule,
    MatMenuModule,
    DragDropModule,
    TranslateModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatExpansionModule,
    MatInputModule,
    EditFormElementModule,
    InputTextModule,
    MaterialLoaderDirective,
    PrimaryDarkColorDirective,
    DeleteIconComponent,
  ],
  exports: [],
  providers: [ElementDTOMapperService],
})
export class EditFormTemplateModule {}
