import { NodeDTO } from '@app/automation/domain/nodes/NodeDTO';
import { WorkflowUpdateDTO } from './WorkflowUpdateDTO';

export class WorkflowDTO {
  constructor(
    public readonly enabled: boolean,
    public readonly revisionId: string,
    public readonly workflowId: string,
    public readonly name: string,
    public readonly resourceType: string,
    public readonly triggerNode: NodeDTO<any>,
    public readonly deletedAt?: string,
  ) {}

  public toWorkflowUpdateDTO(): WorkflowUpdateDTO {
    const workflowUpdateDTO = new WorkflowUpdateDTO();
    workflowUpdateDTO.name = this.name;
    workflowUpdateDTO.resourceType = this.resourceType;
    workflowUpdateDTO.triggerNode = this.triggerNode;
    return workflowUpdateDTO;
  }
}
