import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TreeNode } from '@ui/tree-select/tree-node.type';
import { Tree } from '@ui/tree-select/tree';
import { TreeSelectItemComponent } from '@ui/tree-select/tree-select-item/tree-select-item.component';
import { NgForOf } from '@angular/common';

@Component({
  selector: 'tree-select-group',
  templateUrl: 'tree-select-group.component.html',
  styleUrls: ['tree-select-group.component.scss'],
  standalone: true,
  imports: [TreeSelectItemComponent, NgForOf],
})
export class TreeSelectGroupComponent implements OnInit, OnChanges {
  @Input({ required: true })
  public tree: Tree;

  @Input({ required: true })
  public group: TreeNode;

  @Input({ required: true })
  public value: string | string[] | undefined;

  @Output()
  public itemSelected = new EventEmitter<TreeNode>();

  @Output()
  public expandedChanged = new EventEmitter<boolean>();

  protected expanded = false;
  protected selected = false;

  public ngOnInit(): void {
    this.reload();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['tree'] || changes['group'] || changes['value']) {
      this.reload();
    }
  }

  protected expand($event: boolean): void {
    this.expanded = $event;
    this.expandedChanged.emit($event);
  }

  private reload(): void {
    this.selected = this.tree.isSelected(this.group);
    this.expanded = this.tree.hasSelectionInside(this.group);
  }
}
