const blockedMessages = [
  'tweensContainer',
  'XHR error POST https://app.robaws.com/gwtRequest?',
  'XHR error POST https://staging.robaws.com/gwtRequest?',
];

export function isBlockedError(error: any): boolean {
  if (typeof error === 'string') {
    return isBlockedMessage(error);
  }
  if (error.message) {
    return isBlockedMessage(error.message);
  }
  if (error.error && typeof error.error === 'string') {
    return isBlockedMessage(error.error);
  }
  return false;
}

export function isBlockedMessage(message: string): boolean {
  for (const blockedMessage of blockedMessages) {
    if (message.includes(blockedMessage)) {
      return true;
    }
  }

  return false;
}
