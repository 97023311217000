<workflow-node (nodeClick)="openConfigurer()"
               [workflowNodeData]="workflowNodeData"
               [deleteConfirmationTranslationKey]="'confirmation.content.delay-node'">

  <span slot="title">{{ 'nodeType.delay' | translate }}</span>

  <div slot="content">
    <p class="description" [innerHTML]="this.getDescription()"></p>

    <p class="description-2"
       *ngIf="this.suspendedExecutions"
       [innerHTML]="'nodes.delay.description.suspended-executions' | translate: { suspendedExecutions: this.suspendedExecutions.totalItems }"
       (click)="this.openSuspendedExecutionsCard($event)"></p>
  </div>
</workflow-node>
