import { FormTemplateElement } from '@app/form-template/domain';

export class DrawingElement extends FormTemplateElement {
  constructor(
    public override question: string,
    public override required: boolean,
  ) {
    super(question, required);
  }
}
