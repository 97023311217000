import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';

import { UrlService } from '@shared/services';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

export class TranslationHttpLoader implements TranslateLoader {
  private readonly http: HttpClient;

  constructor(
    httpBackend: HttpBackend,
    private urlService: UrlService,
  ) {
    this.http = new HttpClient(httpBackend);
  }

  getTranslation(language: string) {
    return this.http
      .get(this.urlService.getRobawsNgUrl('/assets/i18n/messages_' + language + '.properties?version=' + environment.version), {
        headers: new HttpHeaders().set('Content-Type', 'text/plain; charset=UTF-8'),
        responseType: 'text',
      })
      .pipe(
        map((it) => {
          const lines = it.split('\n');
          const obj = {};

          this.injectProperties(lines, obj);

          return obj;
        }),
      );
  }

  private injectProperties(lines: string[], obj: any): void {
    for (const line of lines) {
      const key = line.split('=')[0];
      const value = line.split(key + '=')[1];

      if (key && value) {
        this.injectProperty(key.trim(), value.trim(), obj);
      }
    }
  }

  private injectProperty(key: string, value: string, obj: any) {
    if (key.includes('.')) {
      const keyParts = key.split('.');

      if (keyParts.length > 1) {
        if (!obj[keyParts[0]]) {
          obj[keyParts[0]] = {};
        }
        this.injectProperty(keyParts.splice(1).join('.'), value, obj[keyParts[0]]);
      } else {
        obj[key] = value;
      }
    } else {
      obj[key] = value;
    }
  }
}
