import { Observable, of } from 'rxjs';

import { WorkflowNodeType } from '@app/automation/domain';

import { WorkflowNodeCategoryType, WorkflowNodeTypeVO } from '../../add-node-configurer/add-node-configurer.component';
import { NodeProvider } from '../node-provider';

export const delayNodeProvider: NodeProvider = (): Observable<WorkflowNodeTypeVO[]> => {
  return of([
    {
      type: WorkflowNodeType.delay,
      category: WorkflowNodeCategoryType.OTHER,
      icon: 'alarm_red',
      color: '#2a88c4',
      priority: 5,
    },
  ]);
};
