import { Component, Input, ViewChild } from '@angular/core';
import { AuditLogType } from '@app/automation/domain/AuditLogType';
import { RobawsNgDialogComponent } from '@ui/robaws-ng-dialog/robaws-ng-dialog.component';
import { AuditLogDTO } from '@app/automation/domain';
import { AutomationAuditLogService } from '@app/automation/services/automation-audit-log.service';
import { UserService } from '@shared/services';
import { User } from '@shared/domain';
import { BehaviorSubject, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';
import { fetchAllPages } from '@app/shared/helpers/page.helper';
import { MaterialLoaderDirective } from '@ui/material-loader/material-loader.directive';
import { TableModule } from 'primeng/table';
import { TranslateModule } from '@ngx-translate/core';
import { DatePipe, NgIf, NgOptimizedImage } from '@angular/common';

type AuditLogWithUser = AuditLogDTO & { user: User | undefined };

@Component({
  selector: 'automation-audit-log-dialog',
  templateUrl: 'automation-audit-log-dialog.component.html',
  styleUrls: ['automation-audit-log-dialog.component.scss'],
  standalone: true,
  imports: [MaterialLoaderDirective, RobawsNgDialogComponent, TableModule, TranslateModule, DatePipe, NgOptimizedImage, NgIf],
})
export class AutomationAuditLogDialogComponent {
  @Input({ required: true })
  public auditLogType: AuditLogType | string;
  @Input({ required: true })
  public entityId: string;
  @Input({ required: true })
  public navigateToRevisionFn: (revision: string) => void;
  protected auditLogs: AuditLogWithUser[] = [];
  protected isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  @ViewChild('dialog')
  private dialog: RobawsNgDialogComponent;

  constructor(
    private auditLogService: AutomationAuditLogService,
    private userService: UserService,
  ) {}

  public openDialog(): void {
    this.fetchAuditLogs();

    this.dialog.openDialog();
  }

  protected navigateToRevision(revisionId: any): void {
    this.navigateToRevisionFn(revisionId);
  }

  private fetchAuditLogs(): void {
    this.isLoading$.next(true);

    this.auditLogService
      .getAuditLogs(this.auditLogType as AuditLogType, this.entityId)
      .pipe(
        switchMap((auditLogs: AuditLogDTO[]) => {
          const ids = auditLogs.map((auditLog) => auditLog.userId).filter((item, index, array) => array.indexOf(item) == index);

          return fetchAllPages((page: number) => this.userService.findByIds(ids, page, ['avatar', 'avatar.presignedPreviewUrl'])).pipe(
            map((users: User[]) => {
              return auditLogs.map((auditLog) => {
                return {
                  ...auditLog,
                  user: users.find((user) => user.id === auditLog.userId),
                };
              });
            }),
          );
        }),
      )
      .subscribe((auditLogs) => {
        this.auditLogs = auditLogs;
        this.isLoading$.next(false);
      });
  }
}
