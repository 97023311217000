<span [forceFullscreen]="true" [materialLoader]="isLoading$"></span>

<robaws-ng-dialog header="Audit log"
                  [style]="{ width: '55vw', 'max-height': '70vh' }"
                  #dialog>
  <p-table [value]="auditLogs" class="robaws-ng-audit-log-table" [scrollable]="true">
    <ng-template pTemplate="header">
      <tr>
        <th>{{ 'element-types.date' | translate }}</th>
        <th>{{ 'user' | translate }}</th>
        <th>{{ 'detail' | translate }}</th>
        <th>{{ 'revision' | translate }}</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-auditLog>
      <tr>
        <td>{{ auditLog.createdDate | date: 'dd/MM/yyyy HH:mm:ss' }}</td>
        <td class="audit-log-user-column">
          @if (auditLog.user) {
            <img [ngSrc]="auditLog.user.avatar.presignedPreviewUrl ?? '/unknown.png'"
                 [alt]="'Avatar of ' + auditLog.user.fullName"
                 height="40"
                 width="40"
                 class="audit-log-avatar" />
            <span>{{ auditLog.user.fullName }}</span>
          }
        </td>
        <td>{{ 'audit-logs.automation.' + auditLog.action | translate }}</td>
        <td>
          <span *ngIf="auditLog.additionalData.revisionId"
                (click)="this.navigateToRevision(auditLog.additionalData.revisionId)"
                class="custom-link">
            {{ auditLog.additionalData.revisionId }}
          </span>
        </td>
      </tr>
    </ng-template>
  </p-table>
</robaws-ng-dialog>
