@if (inputType === 'STRING' || inputType === 'NUMBER' || inputType === 'DECIMAL' || inputType === 'BOOLEAN') {
  <basic-input [inputType]="this.inputType"
               [showLabel]="this.floatingLabel"
               [label]="this.label"
               [openInDropdown]="this.openInDropdown"
               [value]="this.value"
               (valueChange)="this.onValueChange($event)">

    @if (this.inputTemplate) {
      <ng-template robawsNgTemplate="input" let-onClick>
        <ng-container [ngTemplateOutlet]="this.inputTemplate"
                      [ngTemplateOutletContext]="{ $implicit: onClick }"/>
      </ng-template>
    }

  </basic-input>
} @else if (inputType === 'DATE') {
  <date-input [pTooltip]="this.label ?? ''"
              [tooltipPosition]="'top'"
              [tooltipDisabled]="!this.label"
              [label]="this.label"
              [showLabel]="this.floatingLabel"
              [value]="this.value"
              (valueChange)="this.onValueChange($event)"/>
} @else if (inputType === 'DATE_TIME') {
  <date-time-input [pTooltip]="this.label ?? ''"
                   [tooltipPosition]="'top'"
                   [tooltipDisabled]="!this.label"
                   [label]="this.label"
                   [showLabel]="this.floatingLabel"
                   [value]="this.value"
                   (valueChange)="this.onValueChange($event)"/>
} @else if (inputType === 'DATE_RANGE') {
  <date-range-input [pTooltip]="this.label ?? ''"
                    [tooltipPosition]="'top'"
                    [tooltipDisabled]="!this.label"
                    [value]="this.value"
                    (valueChange)="this.onValueChange($event)">

    @if (this.inputTemplate) {
      <ng-template robawsNgTemplate="input" let-onClick>
        <ng-container [ngTemplateOutlet]="this.inputTemplate"
                      [ngTemplateOutletContext]="{ $implicit: onClick }"/>
      </ng-template>
    }

  </date-range-input>
} @else if (inputType === 'RESOURCE_REF') {

  <resource-ref-input [metadataProviderType]="this.metadataProviderType"
                      [metadata]="this.metadata"
                      [showLabel]="this.floatingLabel"
                      [label]="this.label"
                      [value]="this.value"
                      (valueChange)="this.onValueChange($event)"/>
} @else if ((inputType === 'POSSIBLE_VALUES' || inputType === 'MULTISELECT_POSSIBLE_VALUES') && inputData.possibleValues) {
  <possible-values-combo [possibleValues]="inputData.possibleValues"
                         [label]="label"
                         [pTooltip]="this.label ?? ''"
                         [tooltipPosition]="'top'"
                         [tooltipDisabled]="!this.label"
                         [multiple]="inputType === 'MULTISELECT_POSSIBLE_VALUES'"
                         [clearIcon]="this.allowClear"
                         [value]="value"
                         (valueChange)="onValueChange($event)">

    @if (this.inputTemplate) {
      <ng-template robawsNgTemplate="input" let-onClick>
        <ng-container [ngTemplateOutlet]="this.inputTemplate"
                      [ngTemplateOutletContext]="{ $implicit: onClick }"/>
      </ng-template>
    }

  </possible-values-combo>
} @else if ((inputType === 'COMBOBOX' || inputType === 'MULTISELECT_COMBOBOX') && inputData.expectedInputTypeMetadata) {
  <dynamic-resource-combo [metadataProviderType]="metadataProviderType"
                          (valueChange)="onValueChange($event)"
                          [metadata]="inputData.expectedInputTypeMetadata"
                          [value]="value"
                          [label]="label"
                          [pTooltip]="this.label ?? ''"
                          [tooltipPosition]="'top'"
                          [tooltipDisabled]="!this.label"
                          [multiple]="inputType === 'MULTISELECT_COMBOBOX'"
                          [clearIcon]="this.allowClear"
                          [resourceEntityProvider]="this.resourceEntityProvider">

    @if (this.inputTemplate) {
      <ng-template robawsNgTemplate="input" let-onClick>
        <ng-container [ngTemplateOutlet]="this.inputTemplate"
                      [ngTemplateOutletContext]="{ $implicit: onClick }"/>
      </ng-template>
    }

  </dynamic-resource-combo>
}
