<div class="condition-options">
  <label class="operation-builder-label" for="operation-builder-action">{{ 'operation-builder.action' | translate }}</label>
  <p-checkbox class="operation-builder-checkbox" [binary]="true" [(ngModel)]="conditionBuilder.previous"
              (onChange)="onPreviousChange($event.checked)"
              inputId="operation-builder-action"></p-checkbox>

  <robaws-ng-info info="{{ 'operation-builder.description' | translate }}" />
</div>

<property-path-selector [metadataProviderType]="metadataProviderType"
                        [data]="this.conditionBuilderData"
                        [includeLinks]="!this.conditionBuilder.previous"
                        [fetchDeep]="!this.conditionBuilder.previous"
                        (propertySelected)="handlePathBuilderResult($event)" />

<automation-operation-builder *ngIf="this.conditionBuilder.path"
                              [data]="this.conditionBuilderData"
                              (cancelled)="this.cancelled.emit()"
                              (operationConfigured)="handleOperationBuilderResult($event)" />


<div class="actions-row" *ngIf="!this.conditionBuilder.path">
  <button (click)="this.cancelled.emit()"
          class="cancel-button robaws-ng-button"
          mat-button
          primaryColor>
    {{ 'cancel' | translate }}
  </button>
</div>
