import { DataExportConfiguration } from './DataExportConfiguration';

export class DataExportConfigurationUpdateDTO {
  public configurations: DataExportConfiguration[];

  public toData(): DataExportConfigurationUpdateDTO {
    const dataExportConfigurationUpdateDTO = new DataExportConfigurationUpdateDTO();
    dataExportConfigurationUpdateDTO.configurations = this.configurations.reduce<DataExportConfiguration[]>((list, item) => {
      if (item.module) {
        list.push({
          ...item,
          attributes: item.attributes.filter((value) => !!value),
        });
      }
      return list;
    }, []);

    return dataExportConfigurationUpdateDTO;
  }
}
