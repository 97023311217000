import { Directive, OnInit, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[textPrimaryColor]',
  standalone: true,
})
export class TextPrimaryColorDirective implements OnInit {
  constructor(public viewContainerRef: ViewContainerRef) {}

  ngOnInit(): void {
    this.viewContainerRef.element.nativeElement.classList.add('text-primary-color');
  }
}
