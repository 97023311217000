import { Component, Input } from '@angular/core';

import { ElementDTO, NumberInputElement } from '@app/form-template/domain';

@Component({
  selector: 'app-number-input',
  templateUrl: './number-input.component.html',
  styleUrls: ['./number-input.component.scss'],
})
export class NumberInputComponent {
  @Input({ required: true })
  element: ElementDTO<NumberInputElement>;
}
