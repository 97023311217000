import { Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';

import { ElementDTO, ElementType } from '@app/form-template/domain';

@Component({
  selector: 'edit-form-element',
  templateUrl: './edit-form-element.component.html',
  styleUrls: ['./edit-form-element.component.scss'],
})
export class EditFormElementComponent {
  @Input({ required: true })
  public element: ElementDTO<any>;
  @Output()
  public onElementAdd: EventEmitter<ElementType> = new EventEmitter<ElementType>();
  @Output()
  public onElementDelete: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('accentLine')
  accentLine: ElementRef;
  elementTypes: ElementType[] = Object.keys(ElementType).map((key) => ElementType[key as keyof typeof ElementType]);

  public deleteElement(): void {
    this.onElementDelete.emit();
  }

  @HostListener('document:form-element-selected')
  public onFormElementSelected() {
    this.accentLine.nativeElement.classList.remove('active');
  }

  public selectCard(): void {
    document.dispatchEvent(new CustomEvent('form-element-selected'));
    this.accentLine.nativeElement.classList.add('active');
  }

  protected addElement(elementType: ElementType): void {
    this.onElementAdd.emit(elementType);
  }
}
