import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

import { InternalHttpInterceptor } from '@app/shared/http';

const SESSION_ALIAS_KEY = '_s';

@Injectable()
export class AddSessionQueryParamInterceptor extends InternalHttpInterceptor {
  interceptInternally(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const sessionAlias = sessionStorage.getItem(SESSION_ALIAS_KEY);

    if (!sessionAlias) {
      return next.handle(req);
    }

    return next.handle(
      req.clone({
        params: req.params.set(SESSION_ALIAS_KEY, sessionAlias),
      }),
    );
  }
}
