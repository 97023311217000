import { Injector, NgModule } from '@angular/core';
import { DatePipe } from '@angular/common';

import { registerWebComponent } from '@shared/helpers';
import { AutomationResourceTypeService, ExpressionService, WorkflowExecutionService, WorkflowService } from '@app/automation/services';

import { ListWorkflowsModule } from '@app/automation/components/workflows/list-workflows.module';
import { WorkflowWizardModule } from '@app/automation/components/workflows/workflow-wizard/workflow-wizard.module';
import { EditWorkflowModule } from '@app/automation/components/workflows/edit-workflow/edit-workflow.module';
import { ListWorkflowExecutionsModule } from '@app/automation/components/workflows-executions/list-workflow-executions.module';

import { ListWorkflowsComponent } from '@app/automation/components/workflows/list-workflows.component';
import { EditWorkflowComponent } from '@app/automation/components/workflows/edit-workflow/edit-workflow.component';
import { WorkflowWizardComponent } from '@app/automation/components/workflows/workflow-wizard/workflow-wizard.component';
import { ListWorkflowExecutionsComponent } from '@app/automation/components/workflows-executions/list-workflow-executions.component';
import { AutomationAuditLogService } from '@app/automation/services/automation-audit-log.service';
import { WorkflowRevisionComponent } from '@app/automation/components/workflows/workflow-revision/workflow-revision.component';
import { WorkflowRevisionModule } from '@app/automation/components/workflows/workflow-revision/workflow-revision.module';
import { WorkflowDelayNodeService } from '@app/automation/services/workflow-delay-node.service';

@NgModule({
  imports: [ListWorkflowsModule, WorkflowWizardModule, EditWorkflowModule, ListWorkflowExecutionsModule, WorkflowRevisionModule],
  exports: [ListWorkflowsModule, WorkflowWizardModule, EditWorkflowModule, ListWorkflowExecutionsModule, WorkflowRevisionModule],
  providers: [
    WorkflowService,
    WorkflowExecutionService,
    AutomationResourceTypeService,
    ExpressionService,
    DatePipe,
    AutomationAuditLogService,
    WorkflowDelayNodeService,
  ],
})
export class AutomationModule {
  constructor(injector: Injector) {
    registerWebComponent(injector, 'automation-workflows-overview', ListWorkflowsComponent);
    registerWebComponent(injector, 'automation-edit-workflow', EditWorkflowComponent);
    registerWebComponent(injector, 'automation-workflow-wizard', WorkflowWizardComponent);
    registerWebComponent(injector, 'automation-workflow-executions-overview', ListWorkflowExecutionsComponent);
    registerWebComponent(injector, 'automation-workflow-revision-view', WorkflowRevisionComponent);
  }
}
