import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { RobawsNgConfigurerCardService } from '@shared/services';
import { AutomationResourceTypeService } from '@app/automation/services';

import { AbstractWorkflowNodeComponent } from '../abstract-workflow-node-component';
import { WorkflowNodeData } from '../workflow-node/workflow-node-data';
import { CreateRobawsResourceNode } from '@app/automation/domain/nodes/CreateRobawsResourceNode';
import { CreateRobawsResourceNodeConfigurerComponent } from '@app/automation/components/nodes/create-robaws-resource-node/configurer/create-robaws-resource-node-configurer.component';

@Component({
  selector: 'create-robaws-resource-node',
  templateUrl: 'create-robaws-resource-node.component.html',
})
export class CreateRobawsResourceNodeComponent extends AbstractWorkflowNodeComponent {
  protected resourceTypeDisplayName = '';

  constructor(
    private automationResourceTypeService: AutomationResourceTypeService,
    private robawsNgConfigurerCardService: RobawsNgConfigurerCardService,
    private translateService: TranslateService,
  ) {
    super();
  }

  private _workflowNodeData: WorkflowNodeData<CreateRobawsResourceNode>;

  public get workflowNodeData() {
    return this._workflowNodeData;
  }

  @Input({ required: true })
  public set workflowNodeData(workflowNodeData: WorkflowNodeData<CreateRobawsResourceNode>) {
    this._workflowNodeData = workflowNodeData;

    if (workflowNodeData.currentNode.configuration.resourceType) {
      this.automationResourceTypeService
        .getMetadata(workflowNodeData.currentNode.configuration.resourceType)
        .subscribe((metadata) => (this.resourceTypeDisplayName = metadata.displayName));
    }
  }

  public openConfigurer(): void {
    this.robawsNgConfigurerCardService.createAndOpenConfigurerCard(CreateRobawsResourceNodeConfigurerComponent, {
      title: this.translateService.instant('nodes.create-robaws-resource.configurer-title', {
        resourceType: this.resourceTypeDisplayName.toLowerCase(),
      }),
      inputs: {
        currentNode: this.workflowNodeData.currentNode,
        resourceTypeMetadata: this.workflowNodeData.resourceTypeMetadata,
      },
    });
  }
}
