import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TextTableColumnComponent } from './text-column/text-table-column.component';
import { LinkTableColumnComponent } from './link-column/link-table-column.component';
import { TableColumnDirective } from './table-column.directive';

@NgModule({
  imports: [CommonModule],
  exports: [TextTableColumnComponent, LinkTableColumnComponent, TableColumnDirective],
  declarations: [TextTableColumnComponent, LinkTableColumnComponent, TableColumnDirective],
})
export class TableColumnModule {}
