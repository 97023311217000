<div [materialLoader]="$loading" class="conditions-container">
  <div *ngFor="let condition of conditions" class="condition condition-{{editable ? 'editable': 'view'}}">
    <div class="condition-description">
      <span *ngIf="condition.condition.previous">{{ 'previous' | translate }}</span>
      <strong>{{ condition.pathDisplayName }}</strong>
      <span>{{ 'operation.' + condition.condition.operation | translate }}</span>
      <strong>{{ condition.valueDisplayName }}</strong>
    </div>

    <div *ngIf="editable" class="condition-actions">
      <mat-icon (click)="editCondition(condition.condition)"
                class="icon"
                fontIcon="edit" />

      <delete-icon [askConfirmation]="false"
                   (deleteAccepted)="deleteCondition(condition.condition)" />
    </div>
  </div>
</div>
