import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BehaviorSubject, forkJoin, fromEventPattern, map, Observable, of, Subscription, switchMap } from 'rxjs';

import { Condition } from '@shared/domain';
import { Path, PathService } from '@shared/services';

import { AutomationConstants, AutomationResourceTypeMetadata, WorkflowUpdateDTO } from '@app/automation/domain';
import { DynamicResourceTypeProvider } from '@app/shared/services/dynamic-resource-type.provider';
import { DynamicResourceTypeEntityProvider } from '@app/shared/services/dynamic-resource-type-entity-provider';
import { DatePipe } from '@angular/common';
import { transformValue } from '@app/automation/helpers/value.helpers';

type ConditionWithPathNameAndValue = {
  condition: Condition;
  pathDisplayName: string;
  valueDisplayName: string;
};

@Component({
  selector: 'trigger-node-condition-view',
  templateUrl: 'trigger-node-condition-view.component.html',
  styleUrls: ['trigger-node-condition-view.component.scss'],
})
export class TriggerNodeConditionViewComponent implements OnInit, OnDestroy {
  @Input()
  public editable = true;
  @Input()
  public workflow: WorkflowUpdateDTO;
  @Input()
  public rootMetaData: AutomationResourceTypeMetadata;
  @Output()
  public onConditionEdit = new EventEmitter<Condition>();
  protected conditions: ConditionWithPathNameAndValue[] = [];
  protected $loading = new BehaviorSubject(false);
  private messageListener: Subscription;
  private dynamicResourceTypeProvider = new DynamicResourceTypeProvider('AUTOMATION');
  private dynamicResourceTypeEntityProvider = new DynamicResourceTypeEntityProvider('AUTOMATION');

  constructor(
    private pathService: PathService,
    private datePipe: DatePipe,
  ) {}

  public ngOnInit(): void {
    this.fetchConditions();

    this.messageListener = fromEventPattern(
      (handler) => document.addEventListener(AutomationConstants.TRIGGER_NODE_CONDITIONS_CHANGED_EVENT, handler),
      (handler) => document.removeEventListener(AutomationConstants.TRIGGER_NODE_CONDITIONS_CHANGED_EVENT, handler),
    ).subscribe(() => this.fetchConditions());
  }

  public ngOnDestroy(): void {
    this.messageListener.unsubscribe();
  }

  protected fetchConditions(): void {
    this.$loading.next(true);

    this.pathService
      .getPaths(this.dynamicResourceTypeProvider, this.rootMetaData.name)
      .pipe(
        switchMap((paths): Observable<ConditionWithPathNameAndValue[]> => {
          const observables = [];

          for (const condition of this.workflow.triggerNode.configuration.conditions) {
            observables.push(
              this.getValueName(paths, condition).pipe(
                map(
                  (valueName): ConditionWithPathNameAndValue => ({
                    condition: condition,
                    pathDisplayName: this.getDisplayName(paths, condition.path),
                    valueDisplayName: valueName,
                  }),
                ),
              ),
            );
          }

          return observables.length == 0 ? of([]) : forkJoin(observables);
        }),
      )
      .subscribe((conditions) => {
        this.conditions = conditions;
        this.$loading.next(false);
      });
  }

  protected editCondition(condition: Condition) {
    this.onConditionEdit.emit(condition);
  }

  protected deleteCondition(condition: Condition) {
    const index = this.workflow.triggerNode.configuration.conditions.indexOf(condition);

    if (index > -1) {
      this.workflow.triggerNode.configuration.conditions.splice(index, 1);
      document.dispatchEvent(new CustomEvent(AutomationConstants.TRIGGER_NODE_CONDITIONS_CHANGED_EVENT));
    }
  }

  private getDisplayName(paths: Path[], pathStr: string): string {
    const path = paths.find((it) => it.path === pathStr);

    return path ? path.displayNameDeep : pathStr;
  }

  private getValueName(paths: Path[], condition: Condition): Observable<string> {
    const path = paths.find((it) => it.path === condition.path);

    if (condition.value && path) {
      return transformValue(this.dynamicResourceTypeEntityProvider, this.datePipe, path, condition.value);
    }

    return of(condition.value ?? '');
  }
}
