import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DialogModule } from 'primeng/dialog';

@Component({
  selector: 'robaws-ng-dialog',
  templateUrl: 'robaws-ng-dialog.component.html',
  styleUrls: ['robaws-ng-dialog.component.scss'],
  standalone: true,
  imports: [DialogModule],
})
export class RobawsNgDialogComponent {
  @Input()
  public visible: boolean = false;

  @Input()
  public header: string = '';

  @Input()
  public style?: any;

  @Output()
  public onShow: EventEmitter<void> = new EventEmitter();

  @Output()
  public onHide: EventEmitter<void> = new EventEmitter();

  public openDialog(): void {
    this.visible = true;
  }

  public closeDialog(): void {
    this.visible = false;
  }
}
