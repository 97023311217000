import { DynamicResourceItem } from '@shared/domain';

export interface ResourceTypeEntityMapper<T> {
  toDynamicResourceItems(entities: T[]): DynamicResourceItem[];

  toDynamicResourceItem(entity: T): DynamicResourceItem;
}

export class NoOpResourceTypeEntityMapper implements ResourceTypeEntityMapper<any> {
  toDynamicResourceItems(entities: any[]): DynamicResourceItem[] {
    return entities.map((item) => {
      return this.toDynamicResourceItem(item);
    });
  }

  toDynamicResourceItem(entity: any): DynamicResourceItem {
    return entity;
  }
}

export const NO_OP_RESOURCE_TYPE_ENTITY_MAPPER = new NoOpResourceTypeEntityMapper();
