import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { plainToInstance } from 'class-transformer';

import { DataExportConfigurationDTO, DataExportConfigurationUpdateDTO, DataExportStatusDto } from '@app/robaws/domain';

@Injectable({
  providedIn: 'platform',
})
export class DataExportConfigurationService {
  constructor(private httpClient: HttpClient) {}

  public getConfig(): Observable<DataExportConfigurationDTO> {
    return this.httpClient.get('/robaws-data-export/config').pipe(map((it) => plainToInstance(DataExportConfigurationDTO, it)));
  }

  public updateConfig(value: DataExportConfigurationUpdateDTO): Observable<any> {
    return this.httpClient.put(`/robaws-data-export/config`, value).pipe(map((it) => plainToInstance(DataExportConfigurationUpdateDTO, it)));
  }

  public getConnectionUrl(): Observable<string> {
    return this.httpClient.get(`/robaws-data-export/connection-url?redirectTo=${encodeURIComponent(window.location.href)}`, {
      responseType: 'text',
    });
  }

  public getConnectionStatus(): Observable<DataExportStatusDto> {
    return this.httpClient.get<DataExportStatusDto>(`/robaws-data-export/status`);
  }

  public disconnect(): Observable<any> {
    return this.httpClient.get(`/robaws-data-export/disconnect`);
  }

  public sync(module: string): Observable<any> {
    return this.httpClient.get(`/robaws-data-export/sync`, {
      params: module ? new HttpParams().set('module', module) : new HttpParams(),
    });
  }
}
