import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { PageDTO, User } from '@shared/domain';

@Injectable({
  providedIn: 'platform',
})
export class UserService {
  constructor(private httpClient: HttpClient) {}

  public getUsers(searchText: string, page: number, size: number): Observable<PageDTO<User>> {
    return this.httpClient.get<PageDTO<User>>('/api/v2/users', {
      params: new HttpParams().set('searchText', searchText).set('page', page).set('size', size),
    });
  }

  public findById(id: string): Observable<User> {
    return this.httpClient.get<User>('/api/v2/users/' + id, {
      headers: {
        'x-robaws-deleted-resource-behaviour': 'disclose',
      },
    });
  }

  public findByIds(ids: string[], page: number, include: string[] = []): Observable<PageDTO<User>> {
    return this.httpClient.get<PageDTO<User>>('/api/v2/users', {
      params: new HttpParams().set('id', ids.join(',')).set('include', include.join(',')).set('page', page),
    });
  }
}
