import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableModule } from 'primeng/table';
import { View, ViewContentType } from '@app/robaws/domain';
import { OverlayPanel, OverlayPanelModule } from 'primeng/overlaypanel';
import { ViewService } from '@app/robaws/services/view.service';
import { forkJoin, Observable, switchMap, tap } from 'rxjs';
import { MatIcon } from '@angular/material/icon';
import { MatButton } from '@angular/material/button';
import { PrimaryColorDirective } from '@ui/theme/primary-color.directive';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { RobawsNgDialogComponent } from '@ui/robaws-ng-dialog/robaws-ng-dialog.component';
import { InputTextModule } from 'primeng/inputtext';
import { ResourceTypeMetadata } from '@shared/domain';
import { TooltipModule } from 'primeng/tooltip';
import { CdkDrag, CdkDragDrop, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';
import { UserViewsInfo } from '@app/robaws/domain/UserViewsInfo';
import { map } from 'rxjs/operators';
import { MaterialLoaderDirective } from '@ui/material-loader/material-loader.directive';
import { NgModelChangeDebouncedDirective } from '@ui/ng-model-change-debounced.directive';
import { robawsWindow } from '@app/shared/helpers/window.helper';
import { DeleteIconComponent } from '@ui/delete-icon/delete-icon.component';
import { RobawsConstants } from '@app/robaws/domain/RobawsConstants';
import { AlertHelper } from '@shared/helpers';
import { isTouchDevice } from '@app/shared/helpers/device.helper';

export type Tab = {
  view: View;
  isNew: boolean;
};

@Component({
  selector: 'view-tabs',
  templateUrl: 'view-tabs.component.html',
  styleUrls: ['view-tabs.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TableModule,
    OverlayPanelModule,
    MatIcon,
    MatButton,
    PrimaryColorDirective,
    TranslateModule,
    FormsModule,
    RobawsNgDialogComponent,
    InputTextModule,
    TooltipModule,
    CdkDropList,
    CdkDrag,
    MaterialLoaderDirective,
    NgModelChangeDebouncedDirective,
    DeleteIconComponent,
  ],
})
export class ViewTabsComponent implements OnInit {
  @Input({ required: true })
  public viewContentType: ViewContentType;

  @Input({ required: true })
  public metadata: ResourceTypeMetadata;

  @Output() public tabChange = new EventEmitter<Tab>();

  @ViewChild('viewTabsElement')
  protected viewTabsElement: ElementRef;
  @ViewChild('addTabPanel')
  protected addTabPanel: OverlayPanel;

  protected viewsByMe: View[] = [];
  protected viewsByOthers: View[] = [];
  protected activeViews: View[] = [];
  protected focussedView: View;
  protected loadingViewsByMeAndOthers: boolean = false;
  protected currentFilter: string = '';
  protected readonly isTouchDevice = isTouchDevice;
  private userViewsInfo: UserViewsInfo;

  constructor(
    private viewService: ViewService,
    private alertHelper: AlertHelper,
    private translateService: TranslateService,
  ) {}

  public ngOnInit(): void {
    this.updateTabs();
  }

  public updateTabs(): void {
    this.viewService
      .getUserViewsInfo(this.viewContentType)
      .pipe(
        tap((userViewsInfo) => (this.userViewsInfo = userViewsInfo)),
        switchMap(() => this.fetchActiveViews()),
      )
      .subscribe((activeViews) => {
        this.setFocussedView(activeViews.find((view) => view.id === this.userViewsInfo.focussedViewId));
      });
  }

  protected deleteTab(event: Event, tab: View): void {
    event.preventDefault();
    event.stopImmediatePropagation();

    if (this.activeViews.length <= 1) {
      return;
    }

    this.userViewsInfo.activeViews = this.userViewsInfo.activeViews.filter((it) => it.viewId !== tab.id);
    this.activeViews = this.activeViews.filter((it) => it.id !== tab.id);

    if (tab.id === this.userViewsInfo.focussedViewId) {
      this.activateAndFocusTab(this.activeViews[0]);
    }

    this.viewService.updateUserViewsInfo(this.viewContentType, this.userViewsInfo).subscribe();
  }

  protected openAddTabPanel(event: MouseEvent): void {
    this.updateViewsByMeAndOthers();

    this.addTabPanel.show(event);
  }

  protected openPersonalTab(view: View): void {
    this.activateAndFocusTab(view);
  }

  protected openSharedTab(view: View): void {
    this.viewService.findViewByParentViewId(this.viewContentType, view).subscribe((personalView) => {
      if (personalView) {
        this.activateAndFocusTab(personalView);
      } else {
        this.viewService.createCopyWithParentView(view).subscribe((personalCopy) => {
          this.activateAndFocusTab(personalCopy);
        });
      }
    });
  }

  protected activateAndFocusTab(tab: View | undefined, isNew: boolean = false): void {
    this.setFocussedView(tab, isNew);

    if (this.focussedView) {
      this.userViewsInfo.focussedViewId = this.focussedView.id;

      if (!this.userViewsInfo.activeViews.find((it) => it.viewId === this.focussedView.id)) {
        this.userViewsInfo.activeViews.push({ index: this.activeViews.length, viewId: this.focussedView.id });
      }

      this.viewService.updateUserViewsInfo(this.viewContentType, this.userViewsInfo).subscribe();
    }

    if (this.addTabPanel.overlayVisible) {
      this.addTabPanel.hide();
    }
  }

  protected handleMouseUpEvent(event: MouseEvent, tab: View): void {
    // middle mouse button clicked
    if (!(tab.parentView?.systemView ?? false) && event.button === 1) {
      this.deleteTab(event, tab);
    }
  }

  protected onTabDrop(event: CdkDragDrop<any, any>): void {
    let newIndex = event.currentIndex;

    // not allowing the view to be dropped in front of the system view
    if (newIndex === 0) {
      newIndex = 1;
    }

    moveItemInArray(this.activeViews, event.previousIndex, newIndex);

    this.userViewsInfo.activeViews = [];
    for (let i = 0; i < this.activeViews.length; i++) {
      this.userViewsInfo.activeViews.push({ index: i, viewId: this.activeViews[i].id });
    }

    this.viewService.updateUserViewsInfo(this.viewContentType, this.userViewsInfo).subscribe();
  }

  /**
   * Changes the behavior of scrolling to scroll horizontally instead of vertically.
   */
  protected handleWheelEvent(event: WheelEvent): void {
    if (event.deltaX < 0 || event.deltaX > 0) {
      return;
    }

    event.preventDefault();

    if (event.deltaMode === 0) {
      this.viewTabsElement.nativeElement.scrollLeft += event.deltaY; // can ignore the soft warning, this is intended
    }
  }

  protected updateViewsByMeAndOthers(filter: string = '') {
    this.currentFilter = filter;
    this.loadingViewsByMeAndOthers = true;

    forkJoin([
      this.viewService.getViewsCreatedByCurrentUser(this.viewContentType, filter, 0, 100, 'name:asc'),
      this.viewService.getViewsCreatedByOtherUsers(this.viewContentType, filter, 0, 100, 'name:asc'),
    ]).subscribe(([viewsByMe, viewsByOthers]) => {
      this.viewsByMe = viewsByMe.items;
      this.viewsByOthers = viewsByOthers.items;
      this.loadingViewsByMeAndOthers = false;
    });
  }

  protected createNewView(): void {
    robawsWindow.startLoader();

    this.viewService.copyView(this.focussedView).subscribe((personalCopy) => {
      this.activateAndFocusTab(personalCopy, true);
      robawsWindow.stopLoader();
    });
  }

  protected deleteView(view: View, event: MouseEvent): void {
    event.preventDefault();
    event.stopImmediatePropagation();
    if (view.systemView || view.parentView?.systemView) {
      return;
    }
    this.viewService.deleteView(view.id).subscribe(() => {
      this.updateTabs();
      this.updateViewsByMeAndOthers(this.currentFilter);
    });
  }

  protected toggleVisibility(view: View, event: MouseEvent): void {
    event.preventDefault();
    event.stopImmediatePropagation();
    view.visibility = view.visibility === 'PRIVATE' ? 'EVERYONE' : 'PRIVATE';

    this.viewService.updateViewVisibility(view.id, view.visibility).subscribe(() => {
      this.updateTabs();

      this.alertHelper.fireToast(
        'success',
        this.translateService.instant('overviews.settings.visibility-changed-to-' + view.visibility.toLowerCase()),
        RobawsConstants.TOAST_DURATION_SHORT,
      );
    });
  }

  private setFocussedView(view: View | undefined, isNew: boolean = false): void {
    if (view === undefined) {
      if (this.activeViews.length > 0) {
        this.activateAndFocusTab(this.activeViews[0]);
      } else {
        this.viewService.getPersonalSystemView(this.viewContentType).subscribe((systemView) => {
          if (systemView) {
            this.activateAndFocusTab(systemView);
          }
        });
      }
    } else {
      if (this.focussedView !== view) {
        this.tabChange.emit({ view: view, isNew: isNew });
        this.focussedView = view;
      }

      if (!this.activeViews.find((it) => it.id === this.focussedView.id)) {
        this.activeViews.push(this.focussedView);
      } else {
        this.activeViews = this.activeViews.map((it) => (it.id === this.focussedView.id ? this.focussedView : it));
      }
    }
  }

  private fetchActiveViews(): Observable<View[]> {
    return this.viewService
      .getViewsByIds(
        this.viewContentType,
        this.userViewsInfo.activeViews.map((it) => it.viewId),
      )
      .pipe(
        map((page) => page.items),
        tap((views) => (this.activeViews = views.sort((a, b) => this.getActiveViewIndex(a.id) - this.getActiveViewIndex(b.id)))),
      );
  }

  private getActiveViewIndex(viewId: string): number {
    return this.userViewsInfo.activeViews.find((it) => it.viewId === viewId)?.index ?? 0;
  }
}
