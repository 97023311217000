<robaws-ng-select [items]="this.entities"
                  [itemSize]="100"
                  [totalItems]="totalItems"
                  [showPlaceholder]="!!this.label && this.label !== ''"
                  [placeholder]="this.label || ''"
                  [multiple]="this.multiple"
                  [lazy]="true"
                  [loading]="this.loading"
                  [showFilter]="true"
                  [inputClearIcon]="this.clearIcon"
                  [value]="this.multiple ? this.multiSelectValue : this.value"
                  (valueChange)="this.multiple ? this.onMultiSelectValueChange($event) : this.onValueChange($event)"
                  (onLazyLoad)="this.onLazyLoad($event)"
                  (onFilter)="this.onFilter($event)"
                  appendTo="body"
                  optionLabel="label"
                  optionValue="id">

  @if (this.inputTemplate) {
    <ng-template robawsNgTemplate="input" let-onClick>
      <ng-container [ngTemplateOutlet]="this.inputTemplate"
                    [ngTemplateOutletContext]="{ $implicit: onClick }" />
    </ng-template>
  }

</robaws-ng-select>
