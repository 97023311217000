import { Component, Input, NgZone, OnDestroy, OnInit } from '@angular/core';
import { AutomationResourceTypeMetadata, FormatDataNode, NodeDTO } from '@app/automation/domain';
import { EditorView, minimalSetup } from 'codemirror';
import { Compartment } from '@codemirror/state';
import { Decoration, DecorationSet, ViewPlugin, ViewUpdate, WidgetType } from '@codemirror/view';
import { javascript } from '@codemirror/lang-javascript';
import { syntaxTree } from '@codemirror/language';
import { autocompletion, CompletionContext } from '@codemirror/autocomplete';

import { DataType } from '@shared/domain';
import { Path, PathService } from '@shared/services';

import { ExpressionService } from '@app/automation/services';
import { BehaviorSubject, debounceTime, forkJoin, Observable } from 'rxjs';
import { DynamicResourceTypeProvider } from 'src/app/shared/services/dynamic-resource-type.provider';

@Component({
  selector: 'format-data-node-configurer',
  templateUrl: 'format-data-node-configurer.component.html',
})
export class FormatDataNodeConfigurerComponent implements OnInit, OnDestroy {
  @Input()
  public currentNode: NodeDTO<FormatDataNode>;

  @Input()
  public resourceTypeMetadata: AutomationResourceTypeMetadata;

  public issue: string | null;
  public exampleOutput: string | null;
  public exampleOutputVisible = false;
  public exampleData: ExampleDataItem[] = [];
  public expression: BehaviorSubject<string>;
  private _editor: EditorView;
  private dynamicResourceTypeProvider = new DynamicResourceTypeProvider('AUTOMATION');

  constructor(
    private pathService: PathService,
    private expressionService: ExpressionService,
    private ngZone: NgZone,
  ) {}

  static encodePath(path: string): string {
    return path.replaceAll('_', '__').replaceAll('.', '_').replaceAll('-', '___');
  }

  static getVariableName(path: Path) {
    return path.displayNameDeep.replaceAll(' → ', '__').replaceAll(' ', '_');
  }

  updateModel(expression: string): void {
    this.exampleOutputVisible = false;
    this.currentNode.configuration.expression = expression;
    this.expressionService.validateExpression(this.currentNode.configuration.expression, this.resourceTypeMetadata.name).subscribe((result) => {
      this.issue = result.error ?? null;
      this.exampleData = this.exampleData.filter((it) => result.usedVariables.indexOf(it.path) >= 0);
      const pathObservables: Observable<Path | undefined>[] = [];

      for (const usedVariable of result.usedVariables) {
        if (this.exampleData.filter((it) => it.path === usedVariable).length === 0) {
          pathObservables.push(this.pathService.getPath(this.dynamicResourceTypeProvider, this.resourceTypeMetadata.name, usedVariable));
        }
      }

      forkJoin(pathObservables).subscribe((paths) => {
        for (const path of paths) {
          if (path != null && path.dataType) {
            let value = 'abc';
            switch (path.dataType) {
              case DataType.BOOLEAN:
                value = 'true';
                break;
              case DataType.NUMBER:
                value = '1234';
                break;
              case DataType.DECIMAL:
                value = '50,85';
                break;
              case DataType.DATE:
                value = '2023-01-01';
                break;
              case DataType.DATE_TIME:
                value = '2023-01-01 12:30';
                break;
            }
            this.exampleData.push(new ExampleDataItem(path.path, path.displayNameDeep, path.dataType, value));
          }
        }
        if (this.issue == null) {
          this.evaluate();
        }
      });
    });
  }

  async ngOnInit(): Promise<void> {
    this.expression = new BehaviorSubject<string>(this.currentNode.configuration.expression ?? '');
    this.expression.pipe(debounceTime(1000)).subscribe((expr) => this.updateModel(expr));
    this.expressionService.getAvailableFunctions().subscribe((availableFunctions) => {
      this.initializeCodeMirror(availableFunctions);
    });
  }

  ngOnDestroy(): void {
    this._editor.destroy();
  }

  evaluate() {
    const variables: any = {};
    for (const exampleDataItem of this.exampleData) {
      variables[FormatDataNodeConfigurerComponent.encodePath(exampleDataItem.path)] = exampleDataItem.value;
    }
    this.expressionService
      .evaluateExpression(this.currentNode.configuration.expression ?? '', this.resourceTypeMetadata.name, variables)
      .subscribe((result) => {
        this.issue = result.error ?? null;
        this.exampleOutputVisible = this.issue == null;
        this.exampleOutput = result.resultAsString ?? null;
      });
  }

  private initializeCodeMirror(supportedFunctions: string[]) {
    this.pathService.getPaths(this.dynamicResourceTypeProvider, this.resourceTypeMetadata.name, false, false).subscribe((supportedVariables) => {
      const variablePlugin = ViewPlugin.fromClass(
        class {
          decorations: DecorationSet;

          constructor(view: EditorView) {
            this.decorations = variables(view);
          }

          update(update: ViewUpdate) {
            if (update.docChanged || update.viewportChanged) {
              this.decorations = variables(update.view);
            }
          }
        },
        {
          decorations: (v) => v.decorations,
          provide: (plugin) =>
            EditorView.atomicRanges.of((view) => {
              return view.plugin(plugin)?.decorations || Decoration.none;
            }),
        },
      );

      function variables(view: EditorView): DecorationSet {
        const widgets: any[] = [];
        for (const { from, to } of view.visibleRanges) {
          syntaxTree(view.state).iterate({
            from,
            to,
            enter: (node) => {
              if (node.name == 'VariableName') {
                const variableName = view.state.doc.sliceString(node.from, node.to);
                const variables = supportedVariables.filter((it: any) => FormatDataNodeConfigurerComponent.encodePath(it.path) === variableName);
                if (variables.length > 0) {
                  const repl = Decoration.replace({
                    widget: new MyWidget(FormatDataNodeConfigurerComponent.getVariableName(variables[0])),
                  });
                  widgets.push(repl.range(node.from, node.to));
                }
              }
            },
          });
        }
        return Decoration.set(widgets);
      }

      function myCompletions(context: CompletionContext) {
        const word = context.matchBefore(/\w*/);
        if (word == null) {
          return null;
        }
        if (word.from == word.to && !context.explicit) return null;
        return {
          from: word.from,
          options: [
            ...supportedFunctions.map((f) => {
              return {
                label: f,
                type: 'function',
              };
            }),
            ...supportedVariables.map((it: any) => {
              return {
                label: FormatDataNodeConfigurerComponent.getVariableName(it),
                apply: FormatDataNodeConfigurerComponent.encodePath(it.path),
                type: 'variable',
              };
            }),
          ],
        };
      }

      const updateListenerExtension = EditorView.updateListener.of((update) => {
        if (update.docChanged) {
          this.ngZone.run(() => {
            this.expression.next(update.state.doc.toString());
          });
        }
      });

      const language = new Compartment();
      const targetElement = document.getElementById('codeMirror');
      this._editor = new EditorView({
        doc: this.currentNode.configuration.expression,
        extensions: [
          minimalSetup,
          language.of(javascript()),
          variablePlugin,
          autocompletion({
            override: [myCompletions],
          }),
          updateListenerExtension,
        ],
        parent: targetElement as HTMLElement,
      });
    });
  }
}

class MyWidget extends WidgetType {
  constructor(private label: string) {
    super();
  }

  toDOM(): HTMLElement {
    const span = document.createElement('span');
    span.setAttribute('style', 'display: inline-block; padding: 3px; font-weight: bold;');
    span.innerHTML = this.label;
    return span;
  }
}

class ExampleDataItem {
  constructor(
    public path: string,
    public label: string,
    public dataType: string,
    public value: string,
  ) {}
}
