import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'robaws-ng-switch',
  templateUrl: 'robaws-ng-switch.component.html',
  styleUrls: ['robaws-ng-switch.component.scss'],
  standalone: true,
  imports: [],
})
export class RobawsNgSwitchComponent {
  @Input({ required: true })
  public value: any;

  @Input()
  public onLabel: string = 'On';

  @Input()
  public offLabel: string = 'Off';

  @Output()
  public valueChange = new EventEmitter<any>();

  protected switch(): void {
    this.value = !this.value;
    this.valueChange.emit(this.value);
  }
}
