<robaws-ng-select [items]="(this.availableResourceRefs$ | async) ?? []"
                  [itemSize]="100"
                  [showPlaceholder]="!!this.label && this.label !== ''"
                  [placeholder]="this.label || ''"
                  [loading]="this.resourceRefsLoading"
                  [showFilter]="true"
                  [inputClearIcon]="this.clearIcon"
                  [value]=" this.value"
                  (valueChange)=" this.onValueChange($event)"
                  (onFilter)="this.onFilter($event)"
                  appendTo="body"
                  optionLabel="label"
                  optionValue="id">

  @if (this.inputTemplate) {
    <ng-template robawsNgTemplate="input" let-onClick>
      <ng-container [ngTemplateOutlet]="this.inputTemplate"
                    [ngTemplateOutletContext]="{ $implicit: onClick }"/>
    </ng-template>
  }

</robaws-ng-select>
