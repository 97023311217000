<div *ngFor="let category of categories" [materialLoader]="isLoading$" class="node-category">
  <span class="node-category-title">{{ category.name }}</span>

  <div
    (click)="createNewNode(nodeType.type, nodeType.data)"
    *ngFor="let nodeType of category.nodes"
    class="node-container"
  >
    <mat-icon
      [fontIcon]="nodeType.icon"
      [style]="'background: ' + nodeType.color"
      class="icon"
    />

    <span class="node-name">{{ nodeType.name ?? 'nodeType.' + nodeType.type | translate }}</span>
  </div>
</div>
