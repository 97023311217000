import { ErrorHandler } from '@angular/core';
import { environment } from 'src/environments/environment';
import { datadogLogs } from '@datadog/browser-logs';
import { robawsWindow } from './shared/helpers/window.helper';
import { isBlockedError } from '@shared/helpers';

export class DatadogErrorHandler implements ErrorHandler {
  public handleError(error: any): void {
    if (isBlockedError(error)) {
      return;
    }

    if (environment.production) {
      if (!robawsWindow.isRobawsNgPage()) {
        return;
      }

      datadogLogs.logger.error('Something went wrong:', {}, error);
    } else {
      console.error('Something went wrong:', error);
    }
  }
}
