import { Component, Input } from '@angular/core';

import { ElementDTO, TimeElement } from '@app/form-template/domain';

@Component({
  selector: 'app-time',
  templateUrl: './time.component.html',
  styleUrls: ['./time.component.scss'],
})
export class TimeComponent {
  @Input({ required: true })
  element: ElementDTO<TimeElement>;
}
