import { ResourceTypeMetadata } from '@app/shared/domain/ResourceType';

export interface ResourceTypeMapper<T> {
  mapMetadata(metadata: T): ResourceTypeMetadata;
}

export class NoOpResourceTypeMapper implements ResourceTypeMapper<any> {
  mapMetadata(metadata: any): ResourceTypeMetadata {
    return metadata;
  }
}

export const NO_OP_RESOURCE_TYPE_MAPPER = new NoOpResourceTypeMapper();
