export interface WorkflowExecutionMessage {
  message: string;
  nodeDescription: string;
  type: string;
}

export class WorkflowExecutionDTO {
  constructor(
    public readonly id: string,
    public readonly workflowId: string,
    public readonly status: string,
    public readonly errorMessage: string,
    public readonly workflowName: string,
    public readonly resourceTypeId: string,
    public readonly resourceTypeName: string,
    public readonly resourceDescription: string,
    public readonly resourceHref: string,
    public readonly resourceId: string,
    public readonly completed?: string,
    public readonly resumeAt?: string,
    public readonly messages?: WorkflowExecutionMessage[],
  ) {}
}
