import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { SidebarModule } from 'primeng/sidebar';
import { ViewContentType, ViewFilter, ViewFilterGroup } from '@app/robaws/domain';
import { MatIcon } from '@angular/material/icon';
import { FilterBuilderComponent } from '@app/robaws/components/standalone-filter-builder/filter-builder/filter-builder.component';
import { LowerCasePipe, NgIf } from '@angular/common';
import { ResourceTypeMetadata } from '@shared/domain';
import { ViewFilterGroupEditorComponent } from '@app/robaws/components/dynamic-overview/view-filters/view-filter-group-editor/view-filter-group-editor.component';
import { ViewFilterGroupService } from '@app/robaws/services/view-filter-group.service';
import { forkJoin, Observable } from 'rxjs';
import { ViewQuickFiltersService } from '@app/robaws/services/view-quick-filters.service';
import { ViewQuickFilterComponent } from '@app/robaws/components/dynamic-overview/view-filters/view-quick-filters/view-quick-filter/view-quick-filter.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModelChangeDebouncedDirective } from '@ui/ng-model-change-debounced.directive';
import { ViewSettingsDialogComponent } from '@app/robaws/components/dynamic-overview/view-settings-dialog/view-settings-dialog.component';
import { TranslateModule } from '@ngx-translate/core';
import { CheckboxModule } from 'primeng/checkbox';
import { ViewService } from '@app/robaws/services/view.service';
import { RobawsConstants } from '@app/robaws/domain/RobawsConstants';

export type ViewFilters = {
  filterGroup: ViewFilterGroup;
  additionalFilters: ViewFilter[];
};

@Component({
  selector: 'view-filters',
  templateUrl: 'view-filters.component.html',
  styleUrls: ['view-filters.component.scss'],
  standalone: true,
  imports: [
    SidebarModule,
    MatIcon,
    FilterBuilderComponent,
    NgIf,
    ViewFilterGroupEditorComponent,
    ViewQuickFilterComponent,
    FormsModule,
    NgModelChangeDebouncedDirective,
    ViewSettingsDialogComponent,
    TranslateModule,
    CheckboxModule,
    LowerCasePipe,
    ReactiveFormsModule,
  ],
})
export class ViewFiltersComponent implements OnInit, OnChanges {
  @Input({ required: true })
  public viewId: string;
  @Input({ required: true })
  public viewContentType: ViewContentType;
  @Input({ required: true })
  public metadata: ResourceTypeMetadata;
  @Input({ required: true })
  public viewFilterGroup: ViewFilterGroup;
  @Input({ required: true })
  public additionalFilters: ViewFilter[];
  @Input({ required: true })
  public searchQuery: string;

  @Output()
  public filtersChanged = new EventEmitter<ViewFilters>();
  @Output()
  public searchQueryChanged = new EventEmitter<string>();

  protected quickFilterPaths: string[] = [];
  protected editingAdvancedFilters: boolean = false;
  protected amountOfFilters: number = 0;
  protected showArchived = false;
  protected showDeleted = false;
  private shouldSaveAdditionalFiltersOnSave: boolean = false;

  constructor(
    private viewService: ViewService,
    private viewFilterGroupService: ViewFilterGroupService,
    private viewQuickFiltersService: ViewQuickFiltersService,
  ) {}

  public ngOnInit(): void {
    this.update(true);
    this.onAdditionalFiltersUpdate();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['viewFilterGroup']) {
      this.update();
    }
    if (changes['additionalFilters']) {
      this.onAdditionalFiltersUpdate();
    }
  }

  protected editAdvancedFilters(): void {
    this.editingAdvancedFilters = true;
  }

  protected save(): void {
    const id = this.viewFilterGroup.id;

    if (!id) {
      return;
    }

    this.amountOfFilters = this.getTotalFiltersCount(this.viewFilterGroup, true);

    const saveRequests: Observable<any>[] = [
      this.viewFilterGroupService.updateViewFilterGroupFilters(id, this.viewFilterGroup.filters),
      this.viewFilterGroupService.updateViewFilterGroupFilterGroups(id, this.viewFilterGroup.filterGroups),
    ];

    if (this.shouldSaveAdditionalFiltersOnSave) {
      saveRequests.push(this.viewService.updateAdditionalFilters(this.viewId, this.additionalFilters));
      this.shouldSaveAdditionalFiltersOnSave = false;
    }

    forkJoin(saveRequests).subscribe(() => {
      this.viewFilterGroup = { ...this.viewFilterGroup }; // trigger change detection
      this.filtersChanged.emit({
        filterGroup: this.viewFilterGroup,
        additionalFilters: this.additionalFilters,
      });
    });
  }

  protected updateQuickFilterPaths(paths: string[]): void {
    this.quickFilterPaths = paths;
  }

  protected onSearchQueryUpdate(text: string): void {
    if (this.searchQuery === text) {
      return;
    }
    this.searchQuery = text;
    this.searchQueryChanged.emit(text);
  }

  protected clearSearch(): void {
    this.onSearchQueryUpdate('');
  }

  protected toggleArchived(archived: boolean): void {
    this.shouldSaveAdditionalFiltersOnSave = true;

    if (archived) {
      if (!this.additionalFilters.find((filter) => filter.path === RobawsConstants.IGNORE_ARCHIVED_AT)) {
        this.additionalFilters.push({
          path: RobawsConstants.IGNORE_ARCHIVED_AT,
          operator: 'IS_NOT_EMPTY',
          value: '',
        });
      }
    } else {
      this.additionalFilters = this.additionalFilters.filter((filter) => filter.path !== RobawsConstants.IGNORE_ARCHIVED_AT);
    }
    this.onAdditionalFiltersUpdate();
  }

  protected toggleDeleted(deleted: boolean): void {
    this.shouldSaveAdditionalFiltersOnSave = true;

    if (deleted) {
      if (!this.additionalFilters.find((filter) => filter.path === RobawsConstants.IGNORE_DELETED_AT)) {
        this.additionalFilters.push({
          path: RobawsConstants.IGNORE_DELETED_AT,
          operator: 'IS_NOT_EMPTY',
          value: '',
        });
      }
    } else {
      this.additionalFilters = this.additionalFilters.filter((filter) => filter.path !== RobawsConstants.IGNORE_DELETED_AT);
    }

    this.onAdditionalFiltersUpdate();
  }

  protected onAdditionalFiltersChanged(additionalFilters: ViewFilter[]): void {
    this.additionalFilters = additionalFilters;
    this.onAdditionalFiltersUpdate();

    this.viewService.updateAdditionalFilters(this.viewId, this.additionalFilters).subscribe(() => {
      this.filtersChanged.emit({
        filterGroup: this.viewFilterGroup,
        additionalFilters: this.additionalFilters,
      });
    });
  }

  private update(refreshPaths = false): void {
    this.amountOfFilters = this.getTotalFiltersCount(this.viewFilterGroup, true);

    if (refreshPaths) {
      this.viewQuickFiltersService.getQuickFilterPaths(this.viewContentType).subscribe((paths) => this.updateQuickFilterPaths(paths));
    }
  }

  private getTotalFiltersCount(viewFilterGroup: ViewFilterGroup, includeAdditionalFilters = false): number {
    return (
      viewFilterGroup.filters.filter((it) => it.path && it.path !== '' && it.operator).length +
      viewFilterGroup.filterGroups.reduce((acc, filterGroup) => acc + this.getTotalFiltersCount(filterGroup), 0) +
      (includeAdditionalFilters
        ? this.additionalFilters.filter((it) => it.path === RobawsConstants.IGNORE_ARCHIVED_AT || it.path === RobawsConstants.IGNORE_DELETED_AT)
            .length
        : 0)
    );
  }

  private onAdditionalFiltersUpdate(): void {
    this.amountOfFilters = this.getTotalFiltersCount(this.viewFilterGroup, true);
    this.showArchived = !!this.additionalFilters.find((filter) => filter.path === RobawsConstants.IGNORE_ARCHIVED_AT);
    this.showDeleted = !!this.additionalFilters.find((filter) => filter.path === RobawsConstants.IGNORE_DELETED_AT);
  }
}
