import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { PageDTO } from '@shared/domain';

import { BulkAction } from '../bulk-actions/bulk-actions';
import { ConfiguredColumn } from '@ui/data-table';

@Component({
  selector: 'data-table-search',
  templateUrl: 'data-table-search.component.html',
  styleUrls: ['data-table-search.component.scss'],
})
export class DataTableSearchComponent<T> {
  @Output()
  onPageRequest = new EventEmitter<PageRequest>();

  @Output()
  changeColumn = new EventEmitter<ConfiguredColumn[]>();

  @Input()
  allowFilter: boolean;

  @Input()
  public page: PageDTO<T> | undefined;

  @Input()
  selectedItems: T[];

  @Input()
  actions: BulkAction<T>[] = [];

  @Input()
  selectedColumns: ConfiguredColumn[] = [];

  @Input()
  placeholder?: string;

  protected currentQuery = '';

  protected currentPage = 0;

  protected currentLimit = 20;

  @ViewChild('searchInput') protected searchInput: ElementRef | undefined;

  clearQuery() {
    if (this.currentQuery.length == 0) {
      return;
    }
    this.currentQuery = '';
    this.currentPage = 0;
    if (this.searchInput) {
      this.searchInput.nativeElement.value = '';
    }
    this.firePageRequest();
  }

  onSearch(event: Event) {
    if (event.target instanceof HTMLInputElement) {
      this.currentQuery = event.target.value;
      this.currentPage = 0;
      this.firePageRequest();
    }
  }

  private firePageRequest() {
    this.onPageRequest.emit(new PageRequest(this.currentQuery, this.currentPage, this.currentLimit));
  }
}

export class PageRequest {
  constructor(
    public query: string,
    public page: number,
    public limit: number,
  ) {}
}
