@if (this.openInDropdown) {
  <ng-container [ngTemplateOutlet]="this.inputTemplate || defaultInputTemplate"
                [ngTemplateOutletContext]="{ $implicit: this.onInputClick }" />

  <ng-template #defaultInputTemplate let-click>
    <div class="open-in-dropdown-button" (click)="click($event)"></div>
  </ng-template>

  <p-overlayPanel #simpleInputOverlayPanel>
    <div class="simple-input-overlay-panel">
      <ng-container *ngTemplateOutlet="basicInputTemplate" />
    </div>
  </p-overlayPanel>
} @else {
  <ng-container *ngTemplateOutlet="basicInputTemplate" />
}

<ng-template #basicInputTemplate>
  @if (this.inputType === 'BOOLEAN') {
    <ng-container *ngTemplateOutlet="booleanSwitch" />
  } @else {
    @if (this.showLabel) {
      <span class="p-float-label">
        <ng-container *ngTemplateOutlet="basicInput"/>

        <label [htmlFor]="'dynamic-id'">{{ label ?? '' }}</label>
      </span>
    } @else {
      <ng-container *ngTemplateOutlet="basicInput" />
    }
  }
</ng-template>

<ng-template #basicInput>
  <input pInputText
         class="basic-input"
         id="dynamic-id"
         [type]="this.getHtmlInputType()"
         [step]="this.getStep()"
         [ngModel]="value"
         (ngModelChangeDebounced)="onValueChange($event)"
         [pTooltip]="this.label ?? ''"
         [tooltipPosition]="'top'"
         [tooltipDisabled]="!this.label"
         #inputField />
</ng-template>

<ng-template #booleanSwitch>
  <robaws-ng-switch [value]="value"
                    (valueChange)="onValueChange($event)"
                    [pTooltip]="this.label ?? ''"
                    [tooltipPosition]="'top'"
                    [tooltipDisabled]="!this.label" />
</ng-template>
