import {
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import { concat, debounceTime, distinctUntilChanged, map, mergeMap, Observable, Subject, Subscription, switchMap, tap } from 'rxjs';

import { DynamicResourceItem, MetadataProviderType, PageDTO, ResourceTypeMetadata } from '@shared/domain';
import { DynamicResourceTypeEntityProvider } from '@app/shared/services/dynamic-resource-type-entity-provider';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { MultiSelectModule } from 'primeng/multiselect';
import { NgTemplateOutlet, SlicePipe } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { RobawsNgSelectComponent, RobawsNgSelectFilterEvent, RobawsNgSelectLazyLoadEvent } from '@ui/robaw-ng-select/robaws-ng-select.component';
import { RobawsNgTemplateDirective } from '@shared/components/robaws-ng-template.directive';
import { Nullable } from 'primeng/ts-helpers';
import { ResourceTypeEntityParams } from '@shared/services';

export type ResourceEntityProvider = (resourceType: string, params: ResourceTypeEntityParams) => Observable<PageDTO<DynamicResourceItem>>;

@Component({
  selector: 'dynamic-resource-combo',
  templateUrl: 'dynamic-resource-combo.component.html',
  standalone: true,
  imports: [NgSelectModule, FormsModule, MultiSelectModule, SlicePipe, MatIcon, RobawsNgSelectComponent, RobawsNgTemplateDirective, NgTemplateOutlet],
})
export class DynamicResourceComboComponent implements OnInit, OnChanges, OnDestroy, AfterContentInit {
  @Input()
  public label?: string | undefined | null;
  @Input()
  public value?: string | undefined | null;
  @Input()
  public multiple: boolean = false;
  @Input({ required: true })
  public metadataProviderType: MetadataProviderType;
  @Input({ required: true })
  public metadata: ResourceTypeMetadata;
  @Input()
  public clearIcon: boolean = true;
  @Input()
  public resourceEntityProvider?: ResourceEntityProvider;

  @Output()
  public valueChange: EventEmitter<string> = new EventEmitter<string>();

  protected entities: DynamicResourceItem[] = [];
  protected searchInput$ = new Subject<string>();
  protected loading = true;
  protected currentPage: number;
  protected currentSearchText: string;
  protected totalItems: number = 0;
  protected multiSelectValue: string[];
  protected inputTemplate: TemplateRef<any> | undefined;
  private subscription: Subscription;
  private maxPages = 0;
  private dynamicResourceTypeEntityProvider: DynamicResourceTypeEntityProvider;
  @ContentChildren(RobawsNgTemplateDirective)
  private templates: Nullable<QueryList<RobawsNgTemplateDirective>>;

  public ngOnInit(): void {
    if (this.multiple) {
      this.multiSelectValue = this.value ? this.value.split(',') : [];
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['metadataProviderType']) {
      this.dynamicResourceTypeEntityProvider = new DynamicResourceTypeEntityProvider(this.metadataProviderType);
    }
    if (changes['value']) {
      if (this.multiple || (changes['multiple'] && changes['multiple'].currentValue)) {
        this.multiSelectValue = this.value ? this.value.split(',') : [];
      }
    }
    if (changes['metadata']) {
      this.initObservable();
    }
  }

  public ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  public ngAfterContentInit(): void {
    if (this.templates) {
      this.templates.forEach((template) => {
        if (template.type === 'input') {
          this.inputTemplate = template.template;
        }
      });
    }
  }

  public onValueChange(value: string | undefined): void {
    this.value = value;
    this.valueChange.emit(this.value);
  }

  public onMultiSelectValueChange(value: any[]): void {
    value = value || [];

    this.multiSelectValue = value;
    this.value = this.multiSelectValue.join(',');
    this.valueChange.emit(this.value);
  }

  public clear(event?: MouseEvent): void {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.currentSearchText = '';
    this.onValueChange(undefined);
  }

  public searchEntitiesByIds(ids: string[]): Observable<DynamicResourceItem[]> {
    if (this.resourceEntityProvider) {
      return this.resourceEntityProvider(this.metadata.name, {
        filter: '',
        page: 0,
        id: ids,
      }).pipe(map((page) => page.items));
    }

    return this.dynamicResourceTypeEntityProvider
      .searchEntities(this.metadata.name, {
        filter: '',
        page: 0,
        id: ids,
      })
      .pipe(map((page) => page.items));
  }

  protected searchNextPage(): void {
    if (this.maxPages === 0 || this.maxPages > this.currentPage + 1) {
      this.searchPage(this.currentPage + 1);
    }
  }

  protected searchPage(page: number, clear = false): void {
    this.loading = true;

    this.searchResourceTypeEntities(this.currentSearchText, page).subscribe((entities) => {
      this.entities = clear ? entities : [...this.entities, ...entities];
      this.loading = false;
    });
  }

  protected onLazyLoad(event: RobawsNgSelectLazyLoadEvent): void {
    const page = Math.ceil((event.first + 1) / 100);

    if (this.currentPage !== page) {
      this.searchNextPage();
    }
  }

  protected onFilter(event: RobawsNgSelectFilterEvent) {
    this.currentSearchText = event.filter;
    this.searchPage(0, true);
  }

  private searchResourceTypeEntities(searchText = '', page = 0): Observable<DynamicResourceItem[]> {
    this.currentSearchText = searchText;
    this.currentPage = page;

    if (!this.value || this.entities.filter((it) => it.id === this.value).length > 0) {
      return this.searchEntities(searchText, page);
    } else {
      if (this.multiple) {
        return this.searchEntitiesByIds(this.multiSelectValue).pipe(
          mergeMap((entitiesById) => {
            return this.searchEntities(searchText, page).pipe(
              map((entities) => {
                return [...entitiesById, ...entities.filter((it) => !entitiesById.find((entity) => entity.id === it.id))];
              }),
            );
          }),
        );
      } else {
        return this.searchEntitiesByIds([this.value]).pipe(
          mergeMap((entityById) => {
            return this.searchEntities(searchText, page).pipe(
              map((entities) => {
                return [...entityById, ...entities.filter((it) => !entityById.find((entity) => entity.id === it.id))];
              }),
            );
          }),
        );
      }
    }
  }

  private searchEntities(searchText = '', page = 0): Observable<DynamicResourceItem[]> {
    if (this.resourceEntityProvider) {
      return this.resourceEntityProvider(this.metadata.name, {
        filter: searchText,
        page,
        pageSize: 100,
        sort: '',
      }).pipe(
        tap((page) => {
          this.maxPages = page.totalPages;
          this.totalItems = page.totalItems;
        }),
        map((page) => page.items),
      );
    }

    return this.dynamicResourceTypeEntityProvider
      .searchEntities(this.metadata.name, {
        filter: searchText,
        page,
        pageSize: 100,
        sort: '',
      })
      .pipe(
        tap((page) => {
          this.maxPages = page.totalPages;
          this.totalItems = page.totalItems;
        }),
        map((page) => page.items),
      );
  }

  private initObservable(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.subscription = concat(
      this.searchResourceTypeEntities(),
      this.searchInput$.pipe(
        distinctUntilChanged(),
        debounceTime(300),
        tap(() => {
          this.loading = true;
        }),
        switchMap((searchText) => this.searchResourceTypeEntities(searchText, 0)),
      ),
    ).subscribe((entities: DynamicResourceItem[]) => {
      this.entities = entities;
      this.loading = false;
    });
  }
}
