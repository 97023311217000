import { Component, Input } from '@angular/core';

import { BulkAction } from '../bulk-actions/bulk-actions';

@Component({
  selector: 'data-table-bulk-actions',
  templateUrl: 'bulk-actions.component.html',
  styleUrls: ['bulk-actions.component.scss'],
})
export class DataTableBulkActionsComponent<T> {
  @Input()
  selectedItems: T[];

  @Input()
  actions: BulkAction<T>[] = [];
}
