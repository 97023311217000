import { Component, EventEmitter, Input, Output } from '@angular/core';

import { MetadataProviderType, ResourceTypeMetadata } from '@shared/domain';
import { ViewFilter } from '@app/robaws/domain';
import { RobawsConstants } from '@app/robaws/domain/RobawsConstants';
import {
  RobawsOperationBuilderComponent,
  RobawsOperationData,
} from '@app/robaws/components/robaws-operation-builder/robaws-operation-builder.component';
import { PropertyPathSelectorComponent } from '@shared/components/property-path-selector';
import { FilterListComponent } from '@app/robaws/components/standalone-filter-builder/filter-list/filter-list.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatButton } from '@angular/material/button';
import { PrimaryColorDirective } from '@ui/theme/primary-color.directive';

@Component({
  selector: 'filter-builder',
  templateUrl: 'filter-builder.component.html',
  styleUrls: ['filter-builder.component.scss'],
  standalone: true,
  imports: [PropertyPathSelectorComponent, RobawsOperationBuilderComponent, FilterListComponent, TranslateModule, MatButton, PrimaryColorDirective],
})
export class FilterBuilderComponent {
  @Input({ required: true })
  public metadataProviderType: MetadataProviderType;

  @Input({ required: true })
  public metadata: ResourceTypeMetadata;

  @Input({ required: true })
  public filters: ViewFilter[];

  @Output()
  public filtersChanged: EventEmitter<ViewFilter[]> = new EventEmitter<ViewFilter[]>();

  @Output()
  public searchClicked: EventEmitter<void> = new EventEmitter<void>();

  private currentFilter: ViewFilter | undefined;

  protected _filterBuilder: ViewFilter | undefined;

  public get filterBuilder(): ViewFilter | undefined {
    return this._filterBuilder;
  }

  protected set filterBuilder(value: ViewFilter | undefined) {
    this._filterBuilder = value;
    this.selectingPath = value?.path ? value.path == '' : true;
  }

  private _selectingPath = false;

  protected get selectingPath(): boolean {
    return this._selectingPath;
  }

  protected set selectingPath(value: boolean) {
    this._selectingPath = value;
    document.dispatchEvent(new CustomEvent(RobawsConstants.STANDALONE_FILTER_OVERLAY_UPDATE));
  }

  protected handlePathBuilderResult(path: string): void {
    if (!this.filterBuilder) {
      return;
    }
    this.filterBuilder.path = path;

    if (path != '') {
      this.selectingPath = false;
    }
  }

  protected handleCancel(): void {
    this.filterBuilder = undefined;
  }

  protected handleOperationBuilderResult(operationData: RobawsOperationData): void {
    if (operationData.operator && this.filterBuilder) {
      this.filterBuilder.operator = operationData.operator;
      this.filterBuilder.value = operationData.value ?? '';

      if (this.currentFilter) {
        const index = this.filters.indexOf(this.currentFilter);

        this.filters[index] = this.filterBuilder;
      } else {
        this.filters.push(this.filterBuilder);
      }

      this.currentFilter = undefined;
      this.filterBuilder = undefined;
      this.filtersChanged.emit(this.filters);
    }
  }

  protected editFilter(filter: ViewFilter): void {
    this.currentFilter = filter;
    this.filterBuilder = { ...filter };
  }

  protected deleteFilter(filter: ViewFilter): void {
    this.filters = this.filters.filter((f) => f !== filter);
    this.filtersChanged.emit(this.filters);
  }

  protected createFilter(): void {
    this.currentFilter = undefined;
    this.filterBuilder = {
      path: '',
      operator: undefined,
      value: '',
    };
  }

  protected onSearchClicked(): void {
    this.searchClicked.emit();
  }
}
