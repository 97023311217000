import { AutomationOperator } from '@app/automation/domain/AutomationOperator';

export class Condition {
  public path: string;
  public operation?: AutomationOperator;
  public value?: string;
  public previous: boolean;

  static fromCondition(condition: Condition): Condition {
    const newCondition = new Condition();

    newCondition.path = condition.path;
    newCondition.operation = condition.operation;
    newCondition.value = condition.value;
    newCondition.previous = condition.previous;

    return newCondition;
  }
}
