import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

import { ElementDTO, DropdownElement } from '@app/form-template/domain';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent {
  @Input({ required: true })
  element: ElementDTO<DropdownElement>;

  constructor(private translateService: TranslateService) {}

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.element.configuration.answerOptions, event.previousIndex, event.currentIndex);
  }

  public addOption() {
    this.element.configuration.answerOptions.push(this.translateService.instant('form-templates.empty-option'));
  }

  public deleteOption(index: number): void {
    this.element.configuration.answerOptions.splice(index, 1);
  }

  selectText(input: HTMLInputElement): void {
    input.select();
  }

  trackIndex(index: number) {
    return index;
  }
}
