import { DataType, ResourceTypePropertyPossibleValue } from '@shared/domain';
import { Path } from '@shared/services';
import { PathType } from '@app/shared/domain';

export type InputType =
  | 'STRING'
  | 'DATE'
  | 'DATE_TIME'
  | 'NUMBER'
  | 'DECIMAL'
  | 'BOOLEAN'
  | 'COMBOBOX'
  | 'MULTISELECT_COMBOBOX'
  | 'POSSIBLE_VALUES'
  | 'MULTISELECT_POSSIBLE_VALUES'
  | 'RESOURCE_REF'
  | 'DATE_RANGE';

export function detectInputTypeFromPath(path: Path): InputType {
  if (path.pathType === PathType.links) {
    return 'COMBOBOX';
  }

  return path.dataType ? detectInputType(path.dataType, path.possibleValues) : 'STRING';
}

export function detectInputType(dataType: DataType, possibleValues?: ResourceTypePropertyPossibleValue[]): InputType {
  if (possibleValues && possibleValues.length > 0) {
    return 'POSSIBLE_VALUES';
  }

  switch (dataType) {
    default:
    case DataType.COMPLEX:
    case DataType.TEXT:
      return 'STRING';
    case DataType.BOOLEAN:
      return 'BOOLEAN';
    case DataType.NUMBER:
      return 'NUMBER';
    case DataType.DECIMAL:
      return 'DECIMAL';
    case DataType.DATE:
      return 'DATE';
    case DataType.DATE_TIME:
      return 'DATE_TIME';
    case DataType.RESOURCE_REF:
      return 'RESOURCE_REF';
  }
}
