import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Condition, MetadataProviderType, MetadataWithPath } from '@shared/domain';
import { AutomationOperationData } from '@app/automation/components/automation-operation-builder/automation-operation-builder.component';

@Component({
  selector: 'trigger-node-condition-builder',
  templateUrl: 'trigger-node-condition-builder.component.html',
  styleUrls: ['trigger-node-condition-builder.component.scss'],
})
export class TriggerNodeConditionBuilderComponent implements OnInit {
  @Input({ required: true })
  public metadataProviderType: MetadataProviderType;

  @Input({ required: true })
  public condition: Condition;

  @Input({ required: true })
  public data: MetadataWithPath & AutomationOperationData;

  @Output()
  public conditionBuildFinished: EventEmitter<Condition> = new EventEmitter<Condition>();

  @Output()
  public cancelled: EventEmitter<void> = new EventEmitter<void>();

  protected conditionBuilder: Condition;
  protected conditionBuilderData: MetadataWithPath & AutomationOperationData;

  public ngOnInit(): void {
    this.conditionBuilder = Condition.fromCondition(this.condition);
    this.updateOperationBuilderData();
  }

  protected handlePathBuilderResult(path: string): void {
    this.conditionBuilder.path = path;
    this.conditionBuilder.operation = undefined;
    this.conditionBuilder.value = undefined;

    this.updateOperationBuilderData();
  }

  protected handleOperationBuilderResult(operationData: AutomationOperationData): void {
    if (operationData.operator) {
      this.conditionBuilder.operation = operationData.operator;
      this.conditionBuilder.value = operationData.value;
      this.updateOperationBuilderData();
      this.conditionBuildFinished.emit(this.conditionBuilder);
    }
  }

  protected onPreviousChange(previous: boolean): void {
    if (previous) {
      this.conditionBuilder.path = '';
      this.conditionBuilder.operation = undefined;
      this.conditionBuilder.value = undefined;
      this.updateOperationBuilderData();
    }
    this.conditionBuilder.previous = previous;
  }

  private updateOperationBuilderData(): void {
    this.conditionBuilderData = {
      metadata: this.data.metadata,
      path: this.conditionBuilder.path,
      operator: this.conditionBuilder.operation,
      value: this.conditionBuilder.value,
    };
  }
}
