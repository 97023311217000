import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map, Observable, switchMap } from 'rxjs';

import { DynamicResourceItem, PageDTO } from '@app/shared/domain';
import { ResourceTypeEntityMapper, ResourceTypeEntityParams, ResourceTypeEntityProvider } from '@app/shared/services';

import { RobawsResourceTypeEntity } from '@app/robaws/domain';
import { RobawsResourceTypeService } from '@app/robaws/services';
import { RobawsBulkItem } from '@app/robaws/domain/RobawsBulkAction';

@Injectable({
  providedIn: 'root',
})
export class RobawsResourceTypeEntityService implements ResourceTypeEntityProvider<RobawsResourceTypeEntity> {
  constructor(
    private httpClient: HttpClient,
    private robawsResourceTypeService: RobawsResourceTypeService,
  ) {}

  public searchEntities(resourceType: string, params: ResourceTypeEntityParams): Observable<PageDTO<RobawsResourceTypeEntity>> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('searchText', params.filter);
    httpParams = httpParams.append('page', params.page);

    if (params.pageSize) {
      httpParams = httpParams.set('size', params.pageSize);
    }
    if (params.sort) {
      httpParams = httpParams.set('sort', params.sort);
    }
    if (params.id) {
      httpParams = httpParams.append('id', params.id.join(','));
    }

    return this.robawsResourceTypeService
      .getMetadata(resourceType)
      .pipe(
        switchMap((metadata) => {
          return this.httpClient.get(metadata.basePath, {
            params: httpParams,
          });
        }),
      )
      .pipe(
        map((it: any) => {
          return it as PageDTO<any>;
        }),
      );
  }

  public searchEntity(resourceType: string, id: string | number): Observable<any> {
    return this.robawsResourceTypeService.getMetadata(resourceType).pipe(
      switchMap((metadata) => {
        return this.httpClient.get<any>(`${metadata.basePath}/${id}`);
      }),
    );
  }

  public executeBulkAction(resourceType: string, items: RobawsBulkItem[]): Observable<void> {
    return this.robawsResourceTypeService.getMetadata(resourceType).pipe(
      switchMap((metadata) => {
        return this.httpClient.post<void>(
          `${metadata.basePath}`,
          {
            items: items,
          },
          {
            headers: {
              'Content-Type': 'application/x-robaws-bulk+json',
            },
          },
        );
      }),
    );
  }
}

export class RobawsResourceTypeEntityMapper implements ResourceTypeEntityMapper<RobawsResourceTypeEntity> {
  toDynamicResourceItem(entity: RobawsResourceTypeEntity): DynamicResourceItem {
    return {
      id: entity.id,
      label: entity._metadata.description,
    };
  }

  toDynamicResourceItems(entities: RobawsResourceTypeEntity[]): DynamicResourceItem[] {
    return entities.map((item) => {
      return this.toDynamicResourceItem(item);
    });
  }
}

export const ROBAWS_RESOURCE_TYPE_ENTITY_MAPPER = new RobawsResourceTypeEntityMapper();
