import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { DataType } from '@shared/domain';
import { withCache } from '@ngneat/cashew';
import { OperatorDTO } from '@app/automation/domain';

@Injectable({
  providedIn: 'root',
})
export class AutomationOperatorService {
  constructor(private httpClient: HttpClient) {}

  public getSupportedOperations(dataType: DataType): Observable<OperatorDTO[]> {
    return this.httpClient.get<OperatorDTO[]>('/automation/operators/' + dataType.toString(), {
      context: withCache(),
    });
  }
}
