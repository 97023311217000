import { WorkflowNode } from './WorkflowNode';
import { NodeDTO } from './NodeDTO';

export class ExecuteRobawsResourceActionNode extends WorkflowNode {
  constructor(
    public action: string,
    public link: string,
    public override nextNode: NodeDTO<any> | undefined,
    public parameters: Record<string, string>,
  ) {
    super(nextNode);
  }
}
