<div class="p-datatable">
  <table class="p-datatable-table">
    <thead class="p-datatable-thead">
    <tr>
      <th></th>
      <th><strong>{{ 'type' | translate }}</strong></th>
      <th><strong>{{ 'message' | translate }}</strong></th>
      <th><strong>{{ 'workflow-executions.nodeDescription' | translate }}</strong></th>
    </tr>
    </thead>
    <tbody class="p-element p-datatable-tbody">
    <tr *ngFor="let item of data; let currentIndex = index">
      <td>{{ currentIndex + 1 }}</td>
      <td>{{ item.type }}</td>
      <td>{{ item.message }}</td>
      <td>{{ item.nodeDescription }}</td>
    </tr>
    </tbody>
  </table>
</div>
