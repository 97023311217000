import { inject, ViewContainerRef } from '@angular/core';

import { RootContainerService } from '@shared/services';

export class AbstractWebComponent {
  constructor(protected viewContainerRef: ViewContainerRef) {
    const rootContainerService = inject(RootContainerService);

    rootContainerService.setViewContainerRef(viewContainerRef);
  }
}
