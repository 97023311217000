import { Component, Input, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';

import { DynamicDataSource, LinkTableColumnComponent, TableColumn, TextTableColumnComponent } from '@ui/data-table';

import { PageService, UrlService } from '@shared/services';
import { AbstractWebComponent } from '@shared/components/abstract-web.component';

import { WorkflowExecutionDTO } from '@app/automation/domain';
import { WorkflowExecutionService, WorkflowService } from '@app/automation/services';

@Component({
  selector: 'automation-workflow-executions-overview',
  templateUrl: 'list-workflow-executions.component.html',
})
export class ListWorkflowExecutionsComponent extends AbstractWebComponent implements OnInit, OnDestroy {
  public visible = false;

  public selectedItem: WorkflowExecutionDTO;

  public columns: TableColumn<WorkflowExecutionDTO>[] = [
    new TableColumn<WorkflowExecutionDTO>('id', this.translateService.instant('id'), TextTableColumnComponent, (it) => {
      return {
        content: it.id.split('-')[0],
      };
    }),

    new TableColumn<WorkflowExecutionDTO>('workflowName', this.translateService.instant('workflow'), LinkTableColumnComponent, (it) => {
      return {
        content: it.workflowName,
        link: () => {
          this.urlService.navigateToEditWorkflow(it.workflowId);
        },
      };
    }),

    new TableColumn<WorkflowExecutionDTO>(
      'completed',
      this.translateService.instant('workflow-executions.completed'),
      TextTableColumnComponent,
      (it) => {
        return {
          content: it.completed ? (this.datePipe.transform(it.completed, 'dd/MM/yyyy HH:mm:ss') ?? '') : '',
        };
      },
    ),

    new TableColumn<WorkflowExecutionDTO>(
      'status',
      this.translateService.instant('status'),
      (it: WorkflowExecutionDTO) => {
        const hasWarning = it.messages?.some(({ type }) => type === 'warning');
        if (hasWarning) {
          return LinkTableColumnComponent;
        }
        return TextTableColumnComponent;
      },
      (it) => {
        const hasWarning = it.messages?.some(({ type }) => type === 'warning');

        if (hasWarning && it.status === 'success') {
          return {
            content: this.translateService.instant(`workflow-executions.listOfStatuses.successWithWarning`),
            link: () => {
              this.selectedItem = it;
              this.visible = true;
            },
          };
        }

        return {
          content: this.translateService.instant(`workflow-executions.listOfStatuses.${it.status}`),
        };
      },
    ),

    new TableColumn<WorkflowExecutionDTO>('resourceTypeName', this.translateService.instant('resourceType'), TextTableColumnComponent, (it) => {
      return {
        content: it.resourceTypeName,
      };
    }),

    new TableColumn<WorkflowExecutionDTO>('resourceDescription', this.translateService.instant('record'), LinkTableColumnComponent, (it) => {
      return {
        content: it.resourceDescription,
        link: () => {
          this.urlService.navigateToResourceType(it.resourceTypeId, it.resourceId);
        },
      };
    }),

    new TableColumn<WorkflowExecutionDTO>('errorMessage', this.translateService.instant('errorMessage'), TextTableColumnComponent, (it) => {
      return {
        content: it.errorMessage,
      };
    }),
  ];

  public dataSource: DynamicDataSource<WorkflowExecutionDTO>;

  @Input()
  public workflowId?: string;
  private messageReceiver?: Subscription;

  constructor(
    protected override viewContainerRef: ViewContainerRef,
    public workflowService: WorkflowService,
    public workflowExecutionService: WorkflowExecutionService,
    private pageService: PageService,
    private translateService: TranslateService,
    private urlService: UrlService,
    private datePipe: DatePipe,
  ) {
    super(viewContainerRef);

    this.dataSource = new DynamicDataSource<WorkflowExecutionDTO>((filter, page, pageSize) => {
      return this.workflowExecutionService.getWorkflowExecutions(filter, page, pageSize, this.workflowId);
    });
  }

  ngOnInit(): void {
    if (this.workflowId != null) {
      this.workflowService.findWorkflowById(this.workflowId).subscribe((data) => {
        this.pageService.setPageTitle(this.translateService.instant('workflow-executions.title', { name: data.name }));
      });
    } else {
      this.pageService.setPageTitle(this.translateService.instant('workflow-executions.title-all'));
    }
  }

  ngOnDestroy() {
    if (this.messageReceiver) {
      this.messageReceiver.unsubscribe();
    }
  }
}
