<mat-form-field class="question-input">
  <mat-label>{{'question' | translate}}</mat-label>
  <input type="text" matInput [(ngModel)]="element.configuration.question">
</mat-form-field>

<mat-form-field>
  <mat-label>{{'form-templates.time' | translate}}</mat-label>
  <input matInput disabled>
  <mat-icon matSuffix fontIcon="schedule"></mat-icon>
</mat-form-field>
