import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { DataType, MetadataProviderType, MetadataWithPath, PathType } from '@shared/domain';
import { Path, PathService } from '@shared/services';

import { AutomationResourceTypeMetadata, NodeDTO } from '@app/automation/domain';
import { CopyValueNode } from '@app/automation/domain/nodes/CopyValueNode';
import { AUTOMATION_RESOURCE_TYPE_MAPPER } from '@app/automation/services';
import { DynamicResourceTypeProvider } from '@app/shared/services/dynamic-resource-type.provider';

@Component({
  selector: 'copy-value-node-configurer',
  templateUrl: 'copy-value-node-configurer.component.html',
  styleUrls: ['copy-value-node-configurer.component.scss'],
})
export class CopyValueNodeConfigurerComponent implements OnInit {
  @Output()
  public onConfigurationChange: EventEmitter<string | null> = new EventEmitter<string | null>();

  @Input()
  public currentNode: NodeDTO<CopyValueNode>;

  @Input()
  public resourceTypeMetadata: AutomationResourceTypeMetadata;

  public sourcePathBuilderData: MetadataWithPath;
  public targetPathBuilderData: MetadataWithPath;
  protected metadataProviderType: MetadataProviderType = 'AUTOMATION';
  protected filter = this.filterTargetPath.bind(this); // yes, yes this is necessary to avoid an infinite loop if you type this in the HTML itself
  private currentPath: Path | undefined;
  private dynamicResourceTypeProvider = new DynamicResourceTypeProvider(this.metadataProviderType);

  constructor(private pathService: PathService) {}

  public ngOnInit(): void {
    const sourcePath = this.currentNode.configuration.sourcePath ?? '';

    this.sourcePathBuilderData = {
      metadata: AUTOMATION_RESOURCE_TYPE_MAPPER.mapMetadata(this.resourceTypeMetadata),
      path: sourcePath,
    };
    this.handleSourcePathSelected(sourcePath);
  }

  protected handleSourcePathSelected(path: string): void {
    this.currentNode.configuration.sourcePath = path;

    if (path != '') {
      this.pathService.getPath(this.dynamicResourceTypeProvider, this.resourceTypeMetadata.name, path).subscribe((path) => {
        this.currentPath = path;

        if (path) {
          this.targetPathBuilderData = {
            metadata: AUTOMATION_RESOURCE_TYPE_MAPPER.mapMetadata(this.resourceTypeMetadata),
            path: this.currentNode.configuration.targetPath ?? '',
          };
        }
      });
    } else {
      this.currentPath = undefined;
      this.targetPathBuilderData = {
        metadata: AUTOMATION_RESOURCE_TYPE_MAPPER.mapMetadata(this.resourceTypeMetadata),
        path: path,
      };
    }
    this.onConfigurationChange.emit();
  }

  protected handleTargetPathSelected(path: string): void {
    this.currentNode.configuration.targetPath = path;
    this.onConfigurationChange.emit();
  }

  private filterTargetPath(path: Path): boolean {
    if (!this.currentPath) {
      return true;
    }

    const allowedPathTypes = this.currentPath.pathType === PathType.links ? [PathType.links] : [PathType.attributes, PathType.extraFields];
    const supportedTargetResourceType = this.currentPath.targetResourceType;
    const supportedDataTypes = [DataType.TEXT];

    if (this.currentPath.dataType && !supportedDataTypes.includes(this.currentPath.dataType)) {
      if (this.currentPath.dataType == DataType.DATE || this.currentPath.dataType == DataType.DATE_TIME) {
        supportedDataTypes.push(DataType.DATE);
        supportedDataTypes.push(DataType.DATE_TIME);
      } else {
        supportedDataTypes.push(this.currentPath.dataType);
      }
    }

    if (!path.pathType || !allowedPathTypes.includes(path.pathType)) {
      return false;
    }
    if (path.pathType === PathType.links) {
      if (path.targetResourceType !== supportedTargetResourceType) {
        return false;
      }
    } else {
      if (!path.dataType || !supportedDataTypes.includes(path.dataType)) {
        return false;
      }
    }

    return true;
  }
}
