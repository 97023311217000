import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { MetadataProviderType, MetadataWithPath, ResourceTypeMetadata } from '@shared/domain';

import { ROBAWS_RESOURCE_TYPE_MAPPER, RobawsResourceTypeService } from '@app/robaws/services';
import { PathService } from '@shared/services';
import { DynamicResourceTypeProvider } from '@app/shared/services/dynamic-resource-type.provider';

type PathBuilderData = MetadataWithPath;

@Component({
  selector: 'data-export-resource-type-attribute',
  templateUrl: 'data-export-resource-type-attribute.component.html',
  styleUrls: ['data-export-resource-type-attribute.component.scss'],
})
export class DataExportResourceTypeAttributeComponent implements OnInit {
  @Input()
  public isSelected: boolean;

  @Input()
  public data: string;

  @Input()
  public resourceType: string;

  @Output()
  public deleteAttribute: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  public updateAttribute: EventEmitter<string> = new EventEmitter<string>();

  protected metadataProviderType: MetadataProviderType = 'ROBAWS';
  protected pathBuilderData: PathBuilderData;
  protected propertyPathBuilderData: MetadataWithPath;
  protected displayName: string;
  protected metadata: ResourceTypeMetadata;
  private dynamicResourceTypeProvider = new DynamicResourceTypeProvider(this.metadataProviderType);

  constructor(
    private robawsResourceTypeService: RobawsResourceTypeService,
    private pathService: PathService,
  ) {}

  public ngOnInit(): void {
    this.robawsResourceTypeService.getMetadata(this.resourceType).subscribe({
      next: (data) => {
        this.metadata = ROBAWS_RESOURCE_TYPE_MAPPER.mapMetadata(data);
        this.updatePathBuilderData();
      },
    });
  }

  handlePropertySelect(path: string) {
    this.data = path;
    this.updateAttribute.emit(path);
    this.updatePathBuilderData();
  }

  updatePropertyPathBuilderData() {
    this.propertyPathBuilderData = {
      metadata: this.metadata,
      path: this.pathBuilderData.path,
    };

    this.pathService
      .getPath(this.dynamicResourceTypeProvider, this.resourceType, this.data, true)
      .subscribe((path) => (this.displayName = path?.displayNameDeep || this.data || ''));
  }

  updatePathBuilderData() {
    this.pathBuilderData = {
      metadata: this.metadata,
      path: this.data || '',
    };
    this.updatePropertyPathBuilderData();
  }
}
