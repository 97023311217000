import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Condition } from '@shared/domain';

import { MatchType, AutomationResourceTypeMetadata, WorkflowUpdateDTO } from '@app/automation/domain';

@Component({
  selector: 'trigger-node-condition-list',
  templateUrl: 'trigger-node-condition-list.component.html',
  styleUrls: ['trigger-node-condition-list.component.scss'],
})
export class TriggerNodeConditionListComponent {
  @Output()
  public onConditionEdit = new EventEmitter<Condition>();

  @Input()
  public rootMetaData: AutomationResourceTypeMetadata;

  private _workflow: WorkflowUpdateDTO;

  public get workflow() {
    return this._workflow;
  }

  @Input()
  public set workflow(workflow: WorkflowUpdateDTO) {
    this._workflow = workflow;
  }

  protected get conditions(): Condition[] {
    return this.workflow.triggerNode.configuration.conditions;
  }

  protected handleMatchTypeChange(event: Event) {
    const target = event.target as HTMLInputElement;

    this.workflow.triggerNode.configuration.matchType = target.value as MatchType;
  }

  protected getMatchTypes(): MatchType[] {
    return [MatchType.all, MatchType.any];
  }
}
