import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { View } from '@app/robaws/domain';
import { Path } from '@shared/services';
import { CheckboxModule } from 'primeng/checkbox';
import { FormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { CdkDrag, CdkDragDrop, CdkDragHandle, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatIcon } from '@angular/material/icon';
import { ViewVisibilityType } from '@app/robaws/domain/ViewVisibilityType';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DeleteIconComponent } from '@ui/delete-icon/delete-icon.component';
import { AlertHelper } from '@shared/helpers';
import { RobawsConstants } from '@app/robaws/domain/RobawsConstants';
import { ViewService } from '@app/robaws/services/view.service';
import { robawsWindow } from '@app/shared/helpers/window.helper';
import { NgIf } from '@angular/common';
import { TooltipModule } from 'primeng/tooltip';

export type ViewSettingsDTO = {
  columns: string[];
  name: string;
  visibility: ViewVisibilityType;
};

type ViewColumnVO = {
  path: string;
  displayName: string;
  selected: boolean;
};

@Component({
  selector: 'view-settings',
  templateUrl: 'view-settings.component.html',
  styleUrls: ['view-settings.component.scss'],
  standalone: true,
  imports: [
    CheckboxModule,
    FormsModule,
    InputTextModule,
    CdkDrag,
    CdkDropList,
    MatIcon,
    TranslateModule,
    DeleteIconComponent,
    NgIf,
    TooltipModule,
    CdkDragHandle,
  ],
})
export class ViewSettingsComponent implements OnInit, OnChanges {
  @Input({ required: true })
  public view: View;
  @Input({ required: true })
  public paths: Path[];
  @Output()
  public viewDeleted = new EventEmitter<View>();

  protected columns: ViewColumnVO[] = [];
  protected name: string;
  protected visibility: ViewVisibilityType;
  protected selectedColumns: ViewColumnVO[] = [];
  protected searchText: string = '';
  private allColumns: ViewColumnVO[] = [];

  constructor(
    private alertHelper: AlertHelper,
    private translateService: TranslateService,
    private viewService: ViewService,
  ) {}

  public ngOnInit(): void {
    this.updateProperties();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['view'] || changes['paths']) {
      this.updateProperties();
    }
  }

  public getCurrentSettings(): ViewSettingsDTO {
    return {
      columns: this.selectedColumns.map((column) => column.path),
      name: this.name,
      visibility: this.visibility,
    };
  }

  protected canDeleteView(): boolean {
    return this.view && !this.view.systemView && !this.view.parentView?.systemView;
  }

  protected onSearchTextUpdate(searchText: string): void {
    this.searchText = searchText;
    this.updateColumns();
  }

  protected onDrop(event: CdkDragDrop<any, any>): void {
    moveItemInArray(this.selectedColumns, event.previousIndex, event.currentIndex);
  }

  protected onColumnSelectedChange(event: boolean, column: ViewColumnVO) {
    column.selected = event;

    if (event) {
      this.selectedColumns.push(column);
    } else {
      this.selectedColumns = this.selectedColumns.filter((selectedColumn) => selectedColumn !== column);
    }
  }

  protected toggleVisibility(): void {
    this.visibility = this.visibility === 'PRIVATE' ? 'EVERYONE' : 'PRIVATE';

    this.alertHelper.fireToast(
      'success',
      this.translateService.instant('overviews.settings.visibility-changed-to-' + this.visibility.toLowerCase()),
      RobawsConstants.TOAST_DURATION_SHORT,
    );
  }

  protected deleteView(): void {
    if (!this.canDeleteView()) {
      return;
    }
    robawsWindow.startLoader();

    this.viewService.deleteView(this.view.id).subscribe(() => {
      this.viewDeleted.emit(this.view);
      robawsWindow.stopLoader();
    });
  }

  private updateProperties() {
    if (this.view) {
      this.name = this.view.name;
      this.visibility = this.view.visibility;

      this.updateColumns();
      this.updateSelectedColumns();
    }
  }

  private updateColumns(): void {
    if (!this.paths || !this.view) {
      return;
    }

    this.allColumns = this.paths.map((path) => {
      return {
        path: path.path,
        displayName: path.displayNameDeep,
        selected: this.view.columns.some((column) => column.dataPath === path.path),
      };
    });
    this.columns = this.allColumns.filter((column) => column.displayName.toLowerCase().includes(this.searchText.toLowerCase()));
  }

  private updateSelectedColumns(): void {
    if (!this.view) {
      return;
    }
    this.selectedColumns = this.view.columns
      .map((column) => {
        return this.allColumns.find((c) => c.path === column.dataPath);
      })
      .filter((col) => col !== undefined)
      .map((col) => col as ViewColumnVO);
  }
}
