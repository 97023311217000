export enum WorkflowNodeType {
  robaws_trigger = 'robaws_trigger',
  update_robaws_resource = 'update_robaws_resource',
  send_email = 'send_email',
  format_data = 'format_data',
  execute_robaws_resource_action = 'execute_robaws_resource_action',
  copy_value = 'copy_value',
  delay = 'delay',
  send_text_message = 'send_text_message',
  create_robaws_resource = 'create_robaws_resource',
}
