import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { plainToInstance } from 'class-transformer';

import { DynamicResourceItem, PageDTO } from '@app/shared/domain';
import { ResourceTypeEntityMapper, ResourceTypeEntityParams, ResourceTypeEntityProvider } from '@app/shared/services';

import { AutomationResourceTypeEntity } from '@app/automation/domain';

@Injectable({
  providedIn: 'root',
})
export class AutomationResourceTypeEntityService implements ResourceTypeEntityProvider<AutomationResourceTypeEntity> {
  constructor(private httpClient: HttpClient) {}

  public searchEntities(resourceType: string, params: ResourceTypeEntityParams): Observable<PageDTO<AutomationResourceTypeEntity>> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('searchText', params.filter);
    httpParams = httpParams.append('page', params.page);

    if (params.id) {
      httpParams = httpParams.append('id', params.id.join(','));
    }

    return this.httpClient
      .get(`/automation/resource-types/${resourceType}/entities/search`, {
        params: httpParams,
      })
      .pipe(
        map((it: any) => {
          it.items = it.items.map((item: any) => plainToInstance(AutomationResourceTypeEntity, item));
          return it as PageDTO<AutomationResourceTypeEntity>;
        }),
      );
  }

  public searchEntity(resourceType: string, entityId: string): Observable<AutomationResourceTypeEntity> {
    return this.httpClient.get<AutomationResourceTypeEntity>(`/automation/resource-types/${resourceType}/entities/${entityId}`);
  }
}

export class AutomationResourceTypeEntityMapper implements ResourceTypeEntityMapper<AutomationResourceTypeEntity> {
  toDynamicResourceItem(entity: AutomationResourceTypeEntity): DynamicResourceItem {
    return {
      id: entity.id,
      label: entity.label,
    };
  }

  toDynamicResourceItems(entities: AutomationResourceTypeEntity[]): DynamicResourceItem[] {
    return entities.map((item) => {
      return this.toDynamicResourceItem(item);
    });
  }
}

export const AUTOMATION_RESOURCE_TYPE_ENTITY_MAPPER = new AutomationResourceTypeEntityMapper();
