import { Path } from '@shared/services';
import { PathType } from '@shared/domain';
import { map, Observable, of } from 'rxjs';
import { DynamicResourceTypeEntityProvider } from '@app/shared/services/dynamic-resource-type-entity-provider';
import { DatePipe } from '@angular/common';

export function transformValue(
  dynamicResourceTypeEntityProvider: DynamicResourceTypeEntityProvider,
  datePipe: DatePipe,
  path: Path,
  value: string,
): Observable<string> {
  if (path.pathType === PathType.links && path.targetResourceType) {
    return dynamicResourceTypeEntityProvider.searchEntity(path.targetResourceType, value).pipe(map((it) => it.label));
  } else if (path.possibleValues && path.possibleValues.length > 0) {
    const possibleValue = path.possibleValues.find((it) => it.value === value);

    return of(possibleValue ? possibleValue.name : (value ?? ''));
  } else if (path.dataType === 'DATE_TIME') {
    return of(datePipe.transform(new Date(value), 'yyyy-MM-dd HH:mm') ?? '');
  }

  return of(value);
}
