import { Component, Input } from '@angular/core';
import { ElementDTO } from '@app/form-template/domain/ElementDTO';
import { ImageElement } from '@app/form-template/domain/elementtypes/ImageElement';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
})
export class ImageComponent {
  @Input({ required: true })
  element: ElementDTO<ImageElement>;
}
