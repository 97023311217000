import { Component, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { AbstractWebComponent } from '@app/shared';
import { Subscription } from 'rxjs';
import { PageService, UrlService } from '@shared/services';
import { TranslateService } from '@ngx-translate/core';
import { FormTemplateService } from '@app/form-template/services';

@Component({
  selector: 'app-form-template-configuration',
  templateUrl: './form-template-configuration.component.html',
  styleUrls: ['./form-template-configuration.component.scss'],
})
export class FormTemplateConfigurationComponent extends AbstractWebComponent implements OnInit, OnDestroy {
  private messageReceiver?: Subscription;

  constructor(
    private formTemplateService: FormTemplateService,
    private pageService: PageService,
    protected override viewContainerRef: ViewContainerRef,
    private translateService: TranslateService,
    private urlService: UrlService,
  ) {
    super(viewContainerRef);
  }

  createAndNavigateToFormTemplate(): void {
    this.formTemplateService.createEmptyFormTemplate().subscribe((formTemplateId) => {
      this.urlService.navigateToEditFormTemplate(String(formTemplateId));
    });
  }

  navigateToManageFormTemplates(): void {
    this.urlService.navigateToManageFormTemplates();
  }

  ngOnInit(): void {
    this.pageService.setPageTitle(this.translateService.instant('form-templates.title'));
  }

  ngOnDestroy(): void {
    if (this.messageReceiver) {
      this.messageReceiver.unsubscribe();
    }
  }
}
