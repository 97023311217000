<div mat-dialog-content>
  <div class="group">
    <p-checkbox class="label" [binary]="true" [(ngModel)]="enableWorkflowDTO.enrollExistingResources" inputId="enable-workflow"></p-checkbox>
    <label for="enable-workflow">{{ 'workflows.execute-for-all-records' | translate }}</label>
  </div>

  <button (click)="enableWorkflow()"
          mat-button
          primaryColor>
    {{ 'workflows.enable-workflow' | translate }}
  </button>
</div>
