<automation-audit-log-dialog [auditLogType]="'WORKFLOW'"
                             [entityId]="id"
                             [navigateToRevisionFn]="this.navigateToRevision.bind(this)"
                             #auditLogDialog />

<span [forceFullscreen]="true" [materialLoader]="isLoading$"></span>

<trigger-node *ngIf="currentWorkflow"
              [workflow]="currentWorkflow"
              [workflowId]="this.id" />
