import { Injector, Type } from '@angular/core';
import { createCustomElement } from '@angular/elements';

export function registerWebComponent(injector: Injector, elementName: string, componentType: Type<any>): void {
  customElements.define(
    elementName,
    createCustomElement(componentType, {
      injector: injector,
    }),
  );
}
