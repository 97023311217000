import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'platform',
})
export class ExpressionService {
  constructor(private httpClient: HttpClient) {}

  public validateExpression(expression: string, rootResourceType: string): Observable<ExpressionValidationResult> {
    return this.httpClient.post('/automation/expressions/validate', {
      expression: expression,
      rootResourceType: rootResourceType,
    }) as Observable<ExpressionValidationResult>;
  }

  public evaluateExpression(expression: string, rootResourceType: string, variables: any): Observable<ExpressionEvaluationResult> {
    return this.httpClient.post('/automation/expressions/test', {
      expression: expression,
      rootResourceType: rootResourceType,
      variables: variables,
    }) as Observable<ExpressionValidationResult>;
  }

  getAvailableFunctions(): Observable<string[]> {
    return this.httpClient.get('/automation/expressions/available-functions') as Observable<string[]>;
  }
}

interface ExpressionValidationResult {
  readonly error?: string;
  readonly usedVariables: string[];
}

interface ExpressionEvaluationResult {
  readonly error?: string;
  readonly resultAsString?: string;
}
